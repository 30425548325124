import { StyleSheet, StatusBar, Platform } from "react-native";
import Cookies from "universal-cookie";
import { helpers } from "../../native/utils/helpers";

let Colors = {};
let FontFamily = {};
let FontSize = {};
let FontWeight = {};

Colors = {
  author: "#B1B1B1",
  blackColor: "#000000",
  whiteColor: "#ffffff",
  borderColor: "rgba(255, 255, 255, 0.6)",
  greyColor: "#868686",
  brandColor1: "#FC876D",
  brandColor2: "#2CB680",
  brandColor3: "#907CFF",
  speratorColor1: "#B4B4B4",
  blue: "#051462",
  blue1: "#001767",
  green: "#55C79A",
  primary: "#907CFF",
  black: "rgba(0,0,0, 1)",
  black85: "rgba(0,0,0, 0.85)",
  error: "#ff0000",
  errorBackground: "rgba(255, 0, 0, 0.4)",
  success: "#20a719",
  successBackground: "rgba(32, 167, 25, 0.4)",
  PlaceholderColor: "rgba(0, 0, 0, 0.3)",
  placeholderTextColor: "#8A8A8A",
  placeholderTextColor1: "#D8D8D8",
  placeholderTextColor2: "#DDDDDD",
  lightGray: "#FAFAFA",
  purple: "#6C7AF6",
  black9: "rgba(0,0,0, 0.9)",
  offWhite: "rgba(250, 250, 250, 1)",
  switchThumbInActive: "#FFFFFF",
  switchThumbActive: "#FFFFFF",
  switchTrackInActive: "#66666C",
  switchTrackActive: "#57B28B",
  blackColor29: "#292929",
  //DarkMode
  darkHeaderColor: "rgba(0, 0, 0, 1)",
  lightWhite: "rgba(255, 255, 255, 0.89)",
  darkBlackColorBg: "#121212",
  darkBlackColor: "#1E1E1E",
  darkBlackColor1: "#2D2D2D",
  lineColor: "rgba(255, 255, 255, 0.6)",
  lineColor1: "rgba(255, 255, 255, 0.3)",
  darkBlue: "#5263F4",
  darkPurple: "#1A1A23",
  yellowBorder: "#FF9302",
  purpleBorder: "#C52BCA",
  borderColorLight: "rgba(255, 255, 255, 0.3)",
  greyTextDark: "#999999",
  pinkColor: "#FFD1C6",
  headertextcolor: "#051462",
  giftingBackground: "rgba(161, 144, 255, 0.15)",
};

FontFamily = {
  light: "HaasGrotesk-Light",
  regular: "HaasGrotesk-Roman",
  medium: "HaasGrotesk-Medium",
  semiBold: "HaasGrotesk-Black",
  bold: "HaasGrotesk-Bold",
  abrilFatface: "PlayfairDisplay-Bold",
  merriweather: "Merriweather-Regular",
  merriweatherB: "Merriweather-Bold",
  mistralfont: "Mistral",
  andadafont: "Andada",
  lora: "Lora-Regular",
};

FontWeight = {
  light: "300",
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
  extraBold: "800",
};

FontSize = {
  h1: 34,
  h2: 26,
  h3: 22,
  h4: 19,
  h5: 15,
  h6: 12,
  f10: 10,
  f13: 13,
  f17: 17,
  f20: 20,
};

const Flex = StyleSheet.create({
  fill: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flexDirection: "column",
  },
  FlexWrap: {
    flexWrap: "wrap",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  justfiyCenter: {
    justifyContent: "center",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  alignStart: {
    alignItems: "flex-start",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignEnd: {
    alignItems: "flex-end",
  },
  alignStretch: {
    alignItems: "stretch",
  },
  col_1: {
    flex: 1,
  },
  col_5: {
    flex: 0.5,
  },
  col_3: {
    flex: 0.2,
  },
  col_4: {
    flex: 0.2,
  },
  col25: {
    width: "25%",
  },
  col50: {
    width: "50%",
  },
});

const Padding = StyleSheet.create({
  pt_0: {
    paddingTop: 0,
  },
  pt_1: {
    paddingTop: 10,
  },
  pb_1: {
    paddingBottom: 10,
  },
  pl_1: {
    paddingLeft: 10,
  },
  pr_1: {
    paddingRight: 10,
  },
  ph_1: {
    paddingHorizontal: 10,
  },
  pv_1: {
    paddingVertical: 10,
  },
  pt_2: {
    paddingTop: 20,
  },
  pb_2: {
    paddingBottom: 20,
  },
  pl_2: {
    paddingLeft: 20,
  },
  pr_2: {
    paddingRight: 20,
  },
  ph_2: {
    paddingHorizontal: 20,
  },
  pv_2: {
    paddingVertical: 20,
  },
  pt_3: {
    paddingTop: 30,
  },
  pb_3: {
    paddingBottom: 30,
  },
  pl_3: {
    paddingLeft: 30,
  },
  pr_3: {
    paddingRight: 30,
  },
  ph_3: {
    paddingHorizontal: 30,
  },
  pv_3: {
    paddingVertical: 30,
  },
  pt_4: {
    paddingTop: 40,
  },
  pb_4: {
    paddingBottom: 40,
  },
  pl_4: {
    paddingLeft: 40,
  },
  pr_4: {
    paddingRight: 40,
  },
  ph_4: {
    paddingHorizontal: 40,
  },
  pv_4: {
    paddingVertical: 40,
  },
  pt_5: {
    paddingTop: 50,
  },
  pb_5: {
    paddingBottom: 50,
  },
  pl_5: {
    paddingLeft: 50,
  },
  pr_5: {
    paddingRight: 50,
  },
  ph_5: {
    paddingHorizontal: 50,
  },
  pv_5: {
    paddingVertical: 50,
  },
  pr_05: {
    paddingRight: 5,
  },
  pl_05: {
    paddingLeft: 5,
  },
  ph_05: {
    paddingHorizontal: 5,
  },
  pv_05: {
    paddingVertical: 5,
  },
  pl_15: {
    paddingLeft: 15,
  },
  pr_15: {
    paddingRight: 15,
  },
  pt_7: {
    paddingTop: 70,
  },
  pb_7: {
    paddingBottom: 70,
  },
  pt_9: {
    paddingTop: 90,
  },
  pb_9: {
    paddingBottom: 90,
  },
  pb_8: {
    paddingBottom: 80,
  },
  pt_25: {
    paddingTop: 25,
  },
  pt_125: {
    paddingTop: 125,
  },
  pb_25: {
    paddingBottom: 25,
  },
  pt_15: {
    paddingTop: 15,
  },
  pb_15: {
    paddingBottom: 15,
  },
});

const Margin = StyleSheet.create({
  margin__15: {
    marginLeft: -10,
    marginRight: -10,
  },
  mt_0: {
    marginTop: 0,
  },
  mb_0: {
    marginBottom: 0,
  },
  ml_0: {
    marginLeft: 0,
  },
  mr_0: {
    marginRight: 0,
  },
  mh_0: {
    marginHorizontal: 0,
  },
  mv_0: {
    marginVertical: 0,
  },
  mt_1: {
    marginTop: 10,
  },
  mb_1: {
    marginBottom: 10,
  },
  ml_1: {
    marginLeft: 10,
  },
  mr_1: {
    marginRight: 10,
  },
  mh_1: {
    marginHorizontal: 10,
  },
  mv_1: {
    marginVertical: 10,
  },
  mt_2: {
    marginTop: 20,
  },
  mb_2: {
    marginBottom: 20,
  },
  ml_2: {
    marginLeft: 20,
  },
  mr_2: {
    marginRight: 20,
  },
  mh_2: {
    marginHorizontal: 20,
  },
  mv_2: {
    marginVertical: 20,
  },
  mt_3: {
    marginTop: 30,
  },
  mb_3: {
    marginBottom: 30,
  },
  ml_3: {
    marginLeft: 30,
  },
  mr_3: {
    marginRight: 30,
  },
  mh_3: {
    marginHorizontal: 30,
  },
  mv_3: {
    marginVertical: 30,
  },
  mt_4: {
    marginTop: 40,
  },
  mb_4: {
    marginBottom: 40,
  },
  ml_4: {
    marginLeft: 40,
  },
  mr_4: {
    marginRight: 40,
  },
  mh_4: {
    marginHorizontal: 40,
  },
  mv_4: {
    marginVertical: 40,
  },
  mt_5: {
    marginTop: 50,
  },
  mb_5: {
    marginBottom: 50,
  },
  ml_5: {
    marginLeft: 50,
  },
  mr_5: {
    marginRight: 50,
  },
  mh_5: {
    marginHorizontal: 50,
  },
  mv_5: {
    marginVertical: 50,
  },
  mt_15: {
    marginTop: 15,
  },
  mb_15: {
    marginBottom: 15,
  },
  mt_6: {
    marginTop: 60,
  },
  mb_6: {
    marginBottom: 60,
  },
  mb_7: {
    marginBottom: 70,
  },
  mb_9: {
    marginBottom: 90,
  },
  mt_7: {
    marginTop: 70,
  },
  mt_8: {
    marginTop: 80,
  },
  mb_8: {
    marginBottom: 80,
  },
  mb_9: {
    marginBottom: 90,
  },
  mt_9: {
    marginTop: 90,
  },
});

const postSingle = StyleSheet.create({
  mostViewedWrap: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  mostViewText: {
    color: Colors.green,
    fontSize: 13,
    lineHeight: 16,
    fontFamily: FontFamily.regular,
    paddingLeft: 10,
  },
  postThumbnail: {
    width: "100%",
    height: 435,
    resizeMode: "cover",
  },
  postThumbnailEmpty: {
    width: "100%",
    height: 435,
  },
  summaryWrapper: {
    paddingHorizontal: 20,
    justifyContent: "center",
    marginTop: -100,
    maxWidth: "100%",
    width: "100%",
    marginHorizontal: "auto",
  },
  summaryWrapperDesktop: {
    marginTop: -50,
  },
  summaryInnerWrapper: {
    width: "100%",
    backgroundColor: "#ffffff",
    paddingTop: 22,
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  summaryInnerWrappermob: {
    width: "100%",
    backgroundColor: "#ffffff",

    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  postInfoWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  authorThumb: {
    width: 34,
    height: 34,
    borderRadius: 100,
  },
  authorThumbPic: {
    width: 38,
    height: 38,
    borderRadius: 100,
  },
  authorName: {
    fontFamily: "HaasGrotesk-Roman",
    fontWeight: FontWeight.medium,
    fontSize: 14,
    lineHeight: 20,
    color: "#292929",
  },
  authorNameDesktop: {
    fontSize: 14,
  },
  authorLocation: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 13,
    lineHeight: 16,
    color: "#6A6A6A",
  },
  authorLocationDesktop: {
    fontSize: 16,
  },
  dotSeprator: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 10,
  },
  infoText: {
    fontFamily: FontFamily.medium,
    fontSize: 12,
  },
  authorDetailsWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  authorDetailsnewsWrap: {
    flexDirection: "row",
    // alignItems: 'center'
  },
  postDate: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: helpers.isDesktop() ? 16 : 12,
    lineHeight: 17,
  },
  postDateDesktop: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "HaasGrotesk-Roman",
    color: '#6B7280',
    fontWeight: 400
  },
  creditBox: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 12,
    borderWidth: 1,
    borderColor: Colors.brandColor2,
    color: Colors.brandColor2,
    paddingHorizontal: 10,
    paddingVertical: 7,
  },
  creditBoxBlue: {
    borderColor: "#707AF6",
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  creditBoxBlueNews: {
    borderColor: "rgb(144, 124, 255)",
    borderWidth: 1,
    paddingRight: 15,
    paddingVertical: 10,
  },
  creditText: {
    fontSize: 20,
    lineHeight: 24,
    color: "#414141",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  creditButtonText: {
    fontSize: 14,
    color: "#ffffff",
    lineHeight: 14,
    letterSpacing: 0.05,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  creditButton: {
    backgroundColor: "#907CFF",
    paddingHorizontal: 30,
  },
  creditButtonNews: {
    backgroundColor: "#907CFF",
    paddingHorizontal: 0,
  },
  creditButtonPurlple: {
    backgroundColor: Colors.purpleBorder,
    paddingHorizontal: 30,
  },
  postTitle: {
    fontFamily: "PlayfairDisplay-Bold",
    fontSize: 34,
    lineHeight: '150%',
    textAlign: "left",
  },
  postTitlemob: {
    color: '#292929',
    fontFamily: "PlayfairDisplay-Regulr",
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '148%'
  },
  postTitleDesktop: {
    fontSize: 34,
    lineHeight: helpers.isWeb() ? "150%" : 1,
  },
  postTitleDesktopNews: {
    fontSize: 50,
    lineHeight: helpers.isWeb() ? "normal" : 1,
  },
  unlockedStory: {
    borderWidth: 1,
    borderColor: "#55C79A",
    paddingHorizontal: 30,
    paddingVertical: 12,
  },
  unlockedStoryText: {
    fontSize: 15,
    lineHeight: 18,
    color: "#55C79A",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  insufficientText: {
    letterSpacing: 0.5,
    color: "#F02121",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  postExcerpt: {
    marginTop: 25,
    fontFamily: FontFamily.merriweather,
  },
  authorPicWrap: {
    width: 42,
    height: 42,
    borderWidth: 1,
    borderRadius: 100,
    padding: 2,
    alignItems: "center",
    justifyContent: "center",
    borderColor: Colors.brandColor1,
  },
  authorPicWrapmob: {
    width: 42,
    height: 42,
    borderWidth: 1,
    borderRadius: 100,
    padding: 2,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#907CFF",
  },
  strapline: {
    fontSize: 16,
    lineHeight: 26,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.3,
    color: "#000000",
  },
  straplineDesktop: {
    fontSize: 24,
    lineHeight: 38,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.3,
    color: "#000000",
  },
  unlockSection: {
    backgroundColor: "#fafafa",
  },
  negativeTop: {
    marginTop: -40,
  },
  negativeTopDesktop: {
    marginTop: -5,
  },
  addToHomeScreenWrapper: {
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: "white",
    width: "auto",
    paddingLeft: 30,
    paddingVertical: 15,
    shadowColor: "rgb(160,160,160)",
    shadowOffset: {
      width: 0,
      height: -10,
    },
    shadowOpacity: 0.5,
    shadowRadius: 20,

    elevation: 1,
  },
  buttons: {
    fontSize: 14,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
  },
  wrapper: helpers.isWeb()
    ? {
      shadowColor: "rgba(0, 0, 0, 0.25)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 1,
      shadowRadius: 6,
      elevation: 1,
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "white",
    }
    : {
      shadowColor: "rgba(0, 0, 0, 0.25)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 1,
      shadowRadius: 6,
      elevation: 5,
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "white",
    },
});
const Badges = StyleSheet.create({
  infoBage: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 13,
    lineHeight: 16,
    color: "#ffffff",
    textTransform: "uppercase",
    backgroundColor: "#000",
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  infoBageDesktop: {
    fontSize: 16,
    lineHeight: 20,
  },
  badegSmall: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 13,
    lineHeight: 16,
    color: "#ffffff",
    textTransform: "uppercase",
    backgroundColor: "#000",
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  badegSmallDesktop: {
    fontSize: 14,
    lineHeight: 17,
  },
  infoBageOrange: {
    color: "#ffffff",
    backgroundColor: Colors.brandColor1,
  },
  infoBageGreen: {
    color: "#ffffff",
    backgroundColor: Colors.brandColor2,
  },
  infoBagePurple: {
    textAlign: "center",
    fontSize: 14,
    letterSpacing: 1,
    color: "#ffffff",
    backgroundColor: Colors.brandColor3,
  },
  infoBagePurpleDesktop: {
    fontSize: 18,
  },
  infoBagePurpleText: {
    color: Colors.brandColor3,
    backgroundColor: "transparent",
    paddingLeft: 0,
    textAlign: "left",
    fontSize: 14,
  },
  infoBagePurpleTextDesktop: {
    fontSize: 18,
  },
  infoBadgeOutline: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 13,
    lineHeight: 16,
    color: Colors.brandColor3,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: Colors.brandColor3,
    paddingHorizontal: 15,
    paddingVertical: 5,
    textAlign: "center",
  },
  infoBadgeOutlineDesktop: {
    fontSize: 14,
    lineHeight: 20,
  },
});
const Widgets = StyleSheet.create({
  button: {
    lineHeight: 1,

    //   display: 'inline-block',
    backgroundColor: helpers.isDarkMode() ? "black" : "#ffffff",
    paddingVertical: 10,
    paddingHorizontal: 31,
    marginBottom: 32,
    marginRight: 10,

    borderWidth: 0.75,
    borderRadius: 50,
    borderColor: "#ECB731",
    // background: "none",
    //   backgroundColor: "#f6f6f6",
  },
  buttonmobn: {
    lineHeight: 1,

    // display: 'inline-block',
    backgroundColor: "#ffffff",
    paddingVertical: 5,
    paddingHorizontal: 20,
    marginBottom: 12,
    marginRight: 10,

    borderWidth: 0.75,
    borderRadius: 50,
    borderColor: "#ECB731",
    // background: "none",
    //   backgroundColor: "#f6f6f6",
  },
  buttonli: {
    //   display: 'inline-block',

    margin: "auto",
  },
});

const Helpers = StyleSheet.create({
  textCenter: {
    textAlign: "center",
  },
  textLeft: {
    textAlign: "left",
  },
  textRight: {
    textAlign: "right",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  textItalic: {
    fontStyle: "italic",
  },
  contentSeprator: {
    borderTopWidth: 1,
    borderTopColor: Colors.speratorColor1,
    opacity: 0.5,
  },
  bg_white: {
    backgroundColor: "#ffffff",
  },
  alphFilterWrap: {
    borderWidth: 1,
    borderColor: Colors.purple,
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: 3,
    paddingHorizontal: 12,
    paddingVertical: 7,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  alphText: {
    color: Colors.blue,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.5,
    paddingHorizontal: 5,
    paddingVertical: 4,
    marginBottom: 2,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  capitalLetterWrap: {
    borderBottomColor: "rgba(102, 102, 102, 0.6)",
    borderBottomWidth: 0.3,
    paddingBottom: 5,
    marginBottom: 15,
  },
  captialLetter: {
    letterSpacing: 0.3,
    textTransform: "capitalize",
    fontSize: 36,
    lineHeight: 42,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    color: Colors.black,
  },
  companyName: {
    fontSize: 24,
    lineHeight: 34,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.black,
    paddingBottom: 6,
    marginBottom: 6,
  },
  companyDetail: {
    fontSize: 20,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    color: "#6A6A6A",
  },
  seprator1: {
    borderBottomColor: "rgba(5, 20, 98, 0.6)",
    borderBottomWidth: 1,
  },
  textBold: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  filterBtnWrap: {
    backgroundColor: "#FFFFFF",
    borderColor: "#7B8FFE",
    borderWidth: helpers.isWeb() ? "0.75px" : 0.75,
    borderRadius: 40,
    paddingHorizontal: 18,
    paddingVertical: 11,
    // marginRight: 10,
    // marginBottom: 20
  },
  filterBtnWrapDesktop: {
    // marginRight: 33,
    paddingHorizontal: 33,
  },
  filterBtnWrapActive: {
    backgroundColor: "#7C91FF",
    borderColor: "#7C91FF",
  },
  filterBtnText: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: "#7B8FFE",
    //textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 17,
    letterSpacing: 0.8,
  },
  filterBtnTextDesktop: {
    fontSize: 20,
    lineHeight: 24,
  },
  filterBtnTextActive: {
    color: "#FFFFFF",
    fontWeight: FontWeight.semiBold,
  },
  fontItalic: {
    fontStyle: "italic",
  },
  creditBadgeWrap: {
    borderWidth: 1,
    borderRadius: 1,
    borderColor: Colors.green,
    paddingHorizontal: 14,
    paddingVertical: 6,
  },
  creditBadgeText: {
    fontSize: 22,
    lineHeight: 26,
    color: Colors.green,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  transRow: {
    borderTopColor: "rgba(153, 153, 153, 0.3)",
    borderTopWidth: 1,
    paddingTop: 25,
    paddingBottom: 20,
  },
  transMonth: {
    fontSize: 16,
    lineHeight: 19,
    color: "#000000",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
  },
  transAmount: {
    fontSize: 18,
    lineHeight: 22,
    color: "#000000",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  transID: {
    fontSize: 16,
    lineHeight: 19,
    color: "rgba(0,0,0, 0.4)",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  transDate: {
    fontSize: 13,
    lineHeight: 16,
    color: "rgba(0,0,0, 1)",
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
  },
  downloadTransBtnWrap: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: "rgba(5, 20, 98, 0.3)",
    borderBottomColor: "rgba(5, 20, 98, 0.3)",
  },
  downloadTransBtn: {
    paddingVertical: 15,
  },
  downloadTransBtnText: {
    fontSize: 16,
    lineHeight: 19,
    color: Colors.brandColor1,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.regular,
  },
  liDot: {
    width: 4,
    height: 4,
    borderRadius: 100,
    backgroundColor: Colors.purple,
    marginRight: 7,
    marginTop: 7,
  },
  liText: {
    fontSize: 12,
    fontWeight: FontWeight.light,
    lineHeight: 15,
  },
  sectionTitleWrap: {
    borderBottomColor: "rgba(5, 20, 98, 0.6)",
    borderBottomWidth: 0.4,
    paddingBottom: 5,
    marginBottom: 28,
  },
  sectionTitle: {
    letterSpacing: 0.5,
    textTransform: "uppercase",
    fontSize: 24,
    lineHeight: 35,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: Colors.black,
  },
  conatinerWeb: {
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  conatinerWebNews: {
    maxWidth: "100%",

    width: "100%",
  },
  conatinerWebNews: {
    width: "60%",
  },
  conatinerWebFull: {
    maxWidth: "1274px",
  },
  conatinerWeb1000: {
    maxWidth: "1000px",
  },
  conatinerWeb1240: {
    maxWidth: "1240px",
  },
  conatinerWeb1200: {
    maxWidth: "1200px",
  },
  conatinerWeb940: {
    maxWidth: "940px",
  },
  conatinerWeb900: {
    maxWidth: "900px",
  },
  conatinerWeb800: {
    maxWidth: "800px",
  },
  conatinerWeb700: {
    maxWidth: "700px",
  },
  conatinerWeb680: {
    maxWidth: "680px",
  },
  conatinerWeb660: {
    maxWidth: "660px",
  },
  conatinerWeb580: {
    maxWidth: "580px",
  },
  conatinerWeb500: {
    maxWidth: "500px",
  },
  nM45: {
    marginTop: -45,
  },
  logOutText: {
    color: "#F02121",
    fontSize: 18,
    fontFamily: FontFamily.medium,
  },
  linkText: {
    color: Colors.primary,
    textDecorationLine: "underline",
    textDecorationColor: Colors.primary,
  },
  titleLine: {
    height: 0.3,
    backgroundColor: "rgba(102, 102, 102, 0.6)",
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: [{ translateY: helpers.isWeb() ? "-50%" : 0 }],
  },
  titleLineNews: {
    height: 0.3,
    backgroundColor: "rgba(102, 102, 102, 0.6)",
    width: "100%",
    position: "absolute",
    top: "40%",
    transform: [{ translateY: helpers.isWeb() ? "-50%" : 0 }],
  },
  titleLineLight: {
    height: 0.3,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: [{ translateY: helpers.isWeb() ? "-50%" : 0 }],
  },
  borderBottom: {
    borderBottomWidth: 2,
    borderBottomColor: "#000000",
    paddingBottom: 1,
  },
  titleBgWrap: {
    backgroundColor: "#ffffff",
    zIndex: 1,
  },
  newslettertitleBgWrap: {
    backgroundColor: "#FAFAFA",
    zIndex: 1,
  },

  ourReadersTitleBgWrap: {
    zIndex: 1,
  },
  bgLightOrange: {
    backgroundColor: "#FFF4F1",
  },
  line: {
    borderWidth: 0.3,
    borderColor: "rgba(0,0,0,0.6)",
    marginTop: 9,
    marginLeft: 20,
  },
  centerAlign: {
    position: "absolute",
    top: "50%",
    transform: [{ translateY: helpers.isWeb() ? "-50%" : 0 }],
  },
});

const PostList = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    marginBottom: 15,
  },
  colLeft: {
    width: 100,
    height: 80,
    backgroundColor: "#eeeeee",
  },
  colRight: {
    flex: 1,
    paddingLeft: 15,
  },
  postTitle: {
    fontSize: 18,
    fontFamily: FontFamily.medium,
    paddingTop: 10,
  },
});

const postTags = StyleSheet.create({
  header: {
    fontFamily: FontFamily.merriweather,
    color: Colors.blue,
    textTransform: "uppercase",
    fontSize: 18,
  },
  text: {
    fontFamily: FontFamily.regular,
    color: "#907CFF",
    fontSize: 14,
    lineHeight: 17,
    textTransform: "capitalize",
  },
  wrapper: {
    backgroundColor: "#F2F2F2",
    paddingHorizontal: 12,
    paddingVertical: 5,
    borderRadius: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginRight: 10,
    marginBottom: 15,
    paddingTop: 2,
    paddingBottom: 6,
  },
});

const Typo = StyleSheet.create({
  infoText: {
    fontSize: 12,
    fontFamily: FontFamily.regular,
    color: "#000000",
  },
  btnLink: {
    fontSize: 14,
    fontFamily: FontFamily.regular,
    color: "#000000",
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
  },
  text: {
    fontSize: 16,
    fontFamily: FontFamily.merriweather,
    color: "#000000",
  },
  text13: {
    fontSize: 13,
    lineHeight: 16,
    fontFamily: FontFamily.regular,
    color: Colors.black85,
  },
  text13_italic: {
    fontSize: 13,
    fontFamily: FontFamily.regular,
    color: "rgba(0, 0, 0, 0.7)",
    fontStyle: "italic",
    opacity: 0.85,
  },
  text16: {
    fontSize: 16,
    fontFamily: FontFamily.regular,
    color: "#000000",
  },
  text15: {
    fontSize: 15,
    fontFamily: FontFamily.regular,
    color: Colors.black85,
  },
  text20_PD: {
    fontSize: 20,
    fontFamily: FontFamily.merriweatherB,
    color: "#000000",
    letterSpacing: 0.5,
  },
  text20: {
    fontSize: 20,
    fontFamily: FontFamily.regular,
    color: "#000000",
  },
  h1: {
    fontFamily: FontFamily.regular,
    marginBottom: 15,
    marginTop: 20,
    fontSize: FontSize.h1,
    color: Colors.primary,
  },
  h2: {
    fontFamily: FontFamily.regular,
    fontSize: FontSize.h2,
  },
  h3: {
    fontFamily: FontFamily.regular,
    fontSize: FontSize.h3,
  },
  h4: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: FontSize.h4,
    marginBottom: 15,
    marginTop: 20,
  },
  h5: {
    fontFamily: FontFamily.merriweather,
    fontSize: FontSize.h5,
    marginBottom: 15,
    marginTop: 20,
  },
  h6: {
    fontFamily: FontFamily.merriweather,
    fontSize: FontSize.h6,
    marginBottom: 15,
    marginTop: 20,
  },
  p: {
    fontFamily: FontFamily.merriweather,
    fontSize: FontSize.h6,
    color: "#000000",
  },
  descText: {
    fontSize: 14,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.black85,
  },
  descTextL: {
    fontSize: 14,
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    color: Colors.black85,
  },
  textSemiBold: {
    fontFamily: FontFamily.semiBold,
    fontWeight: FontWeight.semiBold,
  },
  textBold: {
    fontSize: 14,
    fontFamily: FontFamily.bold,
    fontWeight: FontWeight.bold,
    color: Colors.black85,
  },
  h3_M: {
    fontFamily: FontFamily.medium,
    fontSize: FontSize.h3,
  },
  h3_MDesktop: {
    fontFamily: FontFamily.medium,
    fontSize: 24,
  },
  h3_R: {
    fontFamily: FontFamily.bold,
    fontSize: FontSize.h3,
    fontWeight: FontWeight.bold,
  },
  h3_RDesktop: {
    fontFamily: FontFamily.bold,
    fontSize: 24,
    fontWeight: FontWeight.bold,
  },
  textMedium: {
    fontSize: 14,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: Colors.black85,
  },
  textMedium18: {
    fontSize: 16,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  h2Ab: {
    fontFamily: FontFamily.abrilFatface,
    fontSize: 18,
  },
});

// **************************************************************************
// start of post content
// styles for html tags

const postContentTags = {
  blockquote: {
    marginVertical: 20,
    padding: 20,
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    fontSize: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,

    borderLeftWidth: 1,
    borderLeftColor: "#E0E0E0",
    borderRightWidth: 1,
    borderRightColor: "#E0E0E0",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
    borderTopWidth: 3,
    borderTopColor: "#907CFF",
    letterSpacing: 0.1,
  },
  cite: {
    marginTop: 5,
    textAlign: "right",
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    fontSize: 16,
    letterSpacing: 0.1,
    color: "#404040",
  },
  p: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginBottom: 16,
    fontSize: 16,
    color: Colors.blackColor29,
    lineHeight: 28,
    letterSpacing: 0.1,
  },
  li: {
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    fontSize: 16,
    color: Colors.blackColor29,
    letterSpacing: 0.1,
    lineHeight: 22,
  },
  h1: {
    fontFamily: FontFamily.merriweatherB,
    marginBottom: 15,
    marginTop: 20,
    fontSize: FontSize.h1,
    color: Colors.blackColor29,
  },
  h2: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: FontSize.h2,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.blackColor29,
  },
  h3: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: FontSize.h3,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.blackColor29,
  },
  h4: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: FontSize.h4,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.blackColor29,
  },
  h5: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.medium,
    fontSize: FontSize.h5,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.blackColor29,
  },
  h6: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.medium,
    fontSize: FontSize.h6,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.blackColor29,
  },
  a: {
    textDecorationColor: "transparent",
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    marginBottom: 10,
    fontSize: 16,
    color: "#907CFF",
    letterSpacing: 0.1,
    textDecorationLine: "none",
    lineHeight: 28,
  },
  em: {
    fontStyle: "italic",
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
  },
  figcaption: {
    marginBottom: 20,
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 28,
    letterSpacing: 0.1,
  },
  strong: {
    fontFamily: FontFamily.merriweatherB,
    fontWeight: Platform.OS == "ios" ? "600" : FontWeight.regular,
  },
};
// styling for classes
const postContentClasses = {
  "wp-block-columns": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: -15,
  },
  "wp-block-columns-desktop": {
    flexWrap: "nowrap",
  },
  "wp-block-column": {
    flex: 0,
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
  },
  "wp-block-column-desktop": {
    flex: 1,
  },
  "wp-block-image": {
    marginBottom: 15,
    marginTop: 15,
  },
  firstletter: {
    color: "#000000",
    fontSize: 26,
    float: "left",
    fontFamily: FontFamily.merriweather,
  },
  "d-block": {
    display: "flex",
  },
  "cont-remaining": {
    display: "flex",
  },
  "content-tabbed": {
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    marginBottom: 10,
    fontSize: 16,
    color: Colors.blackColor29,
    letterSpacing: 0.1,
  },
  "expandable-content": {
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    marginBottom: 10,
    fontSize: 16,
    color: Colors.blackColor29,
    letterSpacing: 0.1,
  },
  "blockquote-content": {
    backgroundColor: "#fafafa",
    borderLeftWidth: 3,
    borderColor: "#907cff",
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 20,
    paddingBottom: 0,
    marginTop: 20,
    marginBottom: 30,
  },
  "left-free-float-content": {
    marginVertical: 40,
  },
  "floating-content": {
    fontSize: 18,
    lineHeight: 30,
    color: "#6c7af6",
    textAlign: "center",
    fontFamily: FontFamily.abrilFatface,
  },
  "dark-mode": {
    color: "#ffffff",
    fontFamily: FontFamily.merriweather,
  },
  "expandable-content": {
    backgroundColor: "#fcfbff",
    padding: 20,
    position: "relative",
    marginTop: 20,
    marginBottom: 20,
  },
  "number-block": {
    fontFamily: FontFamily.regular,
    fontSize: 36,
    lineHeight: 45,
    fontWeight: FontWeight.bold,
    color: "#a97df1",
    marginBottom: 5,
    textAlign: "center",
  },
  "number-meta": {
    fontFamily: FontFamily.regular,
    fontSize: 15,
    lineHeight: 25,
    fontWeight: FontWeight.regular,
    color: Colors.black,
  },
  "has-text-align-center": {
    textAlign: "center",
  },
};

const dynamicPageTags = {
  h1: {
    fontSize: 32,
    lineHeight: 40,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
  },
  h2: {
    fontSize: 20,
    lineHeight: 31,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    textAlign: "center",
    fontWeight: FontWeight.semiBold,
    marginTop: 10,
    marginBottom: 20,
    textDecorationLine: "underline",
  },
  h3: {
    fontSize: 18,
    lineHeight: 26,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.regular,
    marginTop: 30,
    marginBottom: 8,
  },
  h4: {
    fontSize: 14,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 30,
    marginBottom: 20,
  },
  h5: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 30,
    marginBottom: 20,
  },
  p: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: FontFamily.merriweather,
    marginBottom: 20,
    position: "relative",
  },
  img: {
    maxWidth: 250,
    height: "auto",
    marginBottom: 15,
  },
  a: {
    textDecorationLine: "none",
  },
  figure: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 15,
    marginTop: 0,
  },
  ul: {
    marginTop: 0,
    marginBottom: 0,
  },
  li: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: FontFamily.merriweather,
  },
  cite: {
    fontSize: 14,
    fontFamily: FontFamily.merriweather,
    lineHeight: 22,
  },
};

const dynamicPageClasses = {
  "schema-faq-section": {
    borderBottomWidth: 1,
    borderBottomColor: "rgba(102, 102, 102, 0.3)",
    marginBottom: 15,
  },
  "schema-faq-question": {
    fontSize: 18,
    lineHeight: 26,
    fontFamily: FontFamily.semiBold,
    fontWeight: FontWeight.semiBold,
    paddingTop: 15,
    paddingBottom: 15,
  },
  "schema-faq-answer": {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    paddingTop: 15,
  },
  "is-style-fill": {
    backgroundColor: "#997CF3",
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "rgb(144, 124, 255)",
    paddingVertical: 9,
    paddingHorizontal: 45,
    color: "white",
    fontSize: 14,
    textTransform: "uppercase",
    letterSpacing: 0.1,
    fontFamily: FontFamily.regular,
    textAlign: "center",
    width: "100%",
    height: 38,
    marginTop: 20,
    marginBottom: 20,
  },
  "is-style-outline": {
    backgroundColor: "white",
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "rgb(144, 124, 255)",
    paddingVertical: 9,
    paddingHorizontal: 45,
    color: "rgb(144, 124, 255)",
    fontSize: 14,
    textTransform: "uppercase",
    letterSpacing: 0.1,
    fontFamily: FontFamily.regular,
    textAlign: "center",
    width: "100%",
    height: 38,
    marginTop: 20,
    marginBottom: 20,
  },
  "has-background": {
    paddingHorizontal: 15,
  },
  "wp-block-quote": {
    marginBottom: 20,
  },
  "text-center": {
    textAlign: "center",
  },
  "wp-block-buttons": {
    alignItems: "center",
  },
  "has-text-align-center": {
    textAlign: "center",
  },
  "wp-block-embed__wrapper": {
    maxWidth: "100%",
    height: 200,
    alignItems: "center",
    marginLeft: 0,
    marginBottom: 70,
  },
  "wp-block-spacer": {
    marginTop: 20,
  },
  "wp-block-image": {
    marginBottom: 15,
    marginTop: 0,
  },
  "bg-light-blue": {
    backgroundColor: "#f0f3f9",
  },
};

const postExcerptTags = {
  p: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginBottom: 10,
    fontSize: 16,
    color: Colors.blackColor29,
    letterSpacing: 0.1,
    lineHeight: 26,
  },
  blockquote: {
    margin: "auto",
    width: "100%",
    padding: 19,
    borderTopWidth: 1,
    borderTopColor: "#907cff",
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
    fontWeight: FontWeight.medium,
    fontSize: 16,
    lineHeight: 37,
    letterSpacing: 0.1,
    textAlign: "center",
    color: "#404040",
    paddingTop: 10,
    position: "relative",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "white",
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,
  },
  cite: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    textAlign: "center",
    lineHeight: 30,
    color: Colors.blackColor29,
  },
  em: {
    fontFamily: FontFamily.merriweather,
  },
  li: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginBottom: 10,
    fontSize: 16,
    color: Colors.blackColor29,
    letterSpacing: 0.1,
    lineHeight: 26,
  },
};
const postExcerptClasses = {
  "post-excerpt": {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginBottom: 10,
    fontSize: 18,
    color: Colors.blackColor29,
    letterSpacing: 0.1,
    lineHeight: 26,
  },
  firstletter: {
    color: Colors.blackColor29,
    fontSize: 26,
    fontFamily: FontFamily.merriweather,
  },
  "blockquote-content": {
    backgroundColor: "#fafafa",
    borderLeftWidth: 3,
    borderColor: "#907cff",
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 20,
    paddingBottom: 0,
    marginTop: 20,
    marginBottom: 30,
  },
  "has-text-align-center": {
    textAlign: "center",
  },
};

const postNoteTags = {
  a: {
    color: "#FC876D",
    letterSpacing: 1,
    textDecorationLine: "none",
    fontFamily: FontFamily.merriweather,
    fontSize: 16,
  },
  p: {
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
    fontWeight: FontWeight.regular,
    fontSize: 16,
    letterSpacing: 1,
    lineHeight: 24,
    color: Colors.blackColor29,
  },
  div: {
    color: Colors.blackColor29,
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
  },
};
const postNoteClasses = {
  "post-note-disc": {
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 32,
    letterSpacing: 1,
  },
  "post-note-discloures": {
    color: Colors.blackColor29,
    fontFamily: FontFamily.merriweather,
  },
};

//DarkMode for content classes
const postContentTagsDarkMode = {
  blockquote: {
    marginVertical: 20,
    padding: 20,
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    fontSize: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,

    borderLeftWidth: 1,
    borderLeftColor: "#E0E0E0",
    borderRightWidth: 1,
    borderRightColor: "#E0E0E0",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
    borderTopWidth: 3,
    borderTopColor: "#907CFF",
    letterSpacing: 0.1,
  },
  cite: {
    marginTop: 5,
    textAlign: "right",
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    letterSpacing: 0.1,
    color: "#848484",
  },
  p: {
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    marginBottom: 10,
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.89)",
    lineHeight: 28,
    letterSpacing: 0.1,
  },
  li: {
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.89)",
    letterSpacing: 0.1,
    lineHeight: 26,
  },
  a: {
    textDecorationColor: "transparent",
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    marginBottom: 10,
    fontSize: 16,
    color: "#907CFF",
    letterSpacing: 0.1,
    textDecorationLine: "none",
    lineHeight: 28,
  },
  h1: {
    fontFamily: FontFamily.merriweatherB,
    marginBottom: 15,
    marginTop: 20,
    fontSize: FontSize.h1,
    color: Colors.lightWhite,
  },
  h2: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: FontSize.h2,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.lightWhite,
  },
  h3: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: FontSize.h3,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.lightWhite,
  },
  h4: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: FontSize.h4,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.lightWhite,
  },
  h5: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.medium,
    fontSize: FontSize.h5,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.lightWhite,
  },
  h6: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.medium,
    fontSize: FontSize.h6,
    marginBottom: 15,
    marginTop: 20,
    color: Colors.lightWhite,
  },
  em: {
    fontStyle: "italic",
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
  },
  figcaption: {
    marginTop: 20,
    marginBottom: 20,
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 28,
    letterSpacing: 0.1,
    color: Colors.lightWhite,
  },
  strong: {
    fontFamily: FontFamily.merriweatherB,
    fontWeight: Platform.OS == "ios" ? "600" : FontWeight.regular,
  },
};
const postContentClassesDarkMode = {
  firstletter: {
    color: "rgba(255, 255, 255, 0.89)",
    fontSize: 26,
    float: "left",
    fontFamily: FontFamily.merriweather,
  },
  "content-tabbed": {
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    marginBottom: 10,
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.89)",
    letterSpacing: 1,
  },
  "expandable-content": {
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    marginBottom: 10,
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.89)",
    letterSpacing: 1,
  },
  "blockquote-content": {
    backgroundColor: "#1E1E1E",
    borderLeftWidth: 3,
    borderColor: "#907cff",
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 20,
    paddingBottom: 0,
    marginTop: 20,
    marginBottom: 30,
  },
  "floating-content": {
    fontSize: 18,
    lineHeight: 30,
    color: "#6c7af6",
    textAlign: "center",
    fontFamily: FontFamily.abrilFatface,
  },
  "expandable-content": {
    backgroundColor: "#161519",
    padding: 20,
    marginTop: 20,
    marginBottom: 30,
  },
  "number-block": {
    fontFamily: FontFamily.regular,
    fontSize: 36,
    lineHeight: 60,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    color: "#a97df1",
    marginBottom: 5,
    textAlign: "center",
    paddingLeft: 30,
    paddingHorizontal: 30,
  },
  "number-meta": {
    fontFamily: FontFamily.regular,
    fontSize: 15,
    lineHeight: 25,
    fontWeight: FontWeight.regular,
    color: Colors.lightWhite,
    textAlign: "center",
    letterSpacing: 0.1,
    width: 500,
    maxWidth: 600,
  },
  "has-text-align-center": {
    textAlign: "center",
  },
};

const postExcerptTagsDarkMode = {
  p: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginBottom: 10,
    fontSize: 16,
    lineHeight: 26,
    color: "rgba(255, 255, 255, 0.89)",
    letterSpacing: 0.1,
  },
  blockquote: {
    margin: "auto",
    width: "100%",
    padding: 19,
    borderTopWidth: 1,
    borderTopColor: "#907cff",
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
    fontWeight: FontWeight.medium,
    fontSize: 16,
    lineHeight: 37,
    letterSpacing: 0.1,
    textAlign: "center",
    color: "#404040",
    paddingTop: 10,
    position: "relative",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "white",
    width: "100%",
    shadowColor: "#fff",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,
  },
  cite: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    textAlign: "center",
    lineHeight: 30,
  },
  em: {
    fontFamily: FontFamily.merriweather,
  },
  li: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginBottom: 10,
    fontSize: 16,
    color: "rgba(255, 255, 255, 0.89)",
    letterSpacing: 0.1,
    lineHeight: 26,
  },
};
const postExcerptClassesDarkMode = {
  "post-excerpt": {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginBottom: 10,
    fontSize: 18,
    color: "rgba(255, 255, 255, 0.89)",
    letterSpacing: 0.1,
  },
  firstletter: {
    color: "#ffffff",
    fontSize: 26,
    fontFamily: FontFamily.merriweather,
  },
  "blockquote-content": {
    backgroundColor: "#1E1E1E",
    borderLeftWidth: 3,
    borderColor: "#907cff",
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 20,
    paddingBottom: 0,
    marginTop: 20,
    marginBottom: 30,
  },
  "has-text-align-center": {
    textAlign: "center",
  },
};

const postNoteTagsDarkMode = {
  a: {
    color: "#FC876D",
    letterSpacing: 1,
    textDecorationLine: "none",
    fontWeight: FontWeight.regular,
    fontSize: 16,
  },
  p: {
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
    fontWeight: FontWeight.regular,
    fontSize: 16,
    letterSpacing: 1,
    color: "rgba(255, 255, 255, 0.89)",
  },
  div: {
    color: "rgba(255, 255, 255, 0.6)",
    fontFamily: FontFamily.merriweather,
  },
};
const activenews = {
  gridcontainer: {
    disply: "flex",
    width: "100%",
    maxWidth: "1200px",
    flexDirection: "row",
    margin: "auto",
  },
  p: {
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
    fontWeight: FontWeight.regular,
    fontSize: 16,
    letterSpacing: 1,
    color: "rgba(255, 255, 255, 0.89)",
  },
  div: {
    color: "rgba(255, 255, 255, 0.6)",
    fontFamily: FontFamily.merriweather,
  },
};
const postNoteClassesDarkMode = {
  "post-note-disc": {
    fontFamily: FontFamily.merriweather,
    fontStyle: "italic",
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 32,
    letterSpacing: 1,
  },
  "post-note-discloures": {
    color: "rgba(255, 255, 255, 0.89)",
    fontFamily: FontFamily.merriweather,
  },
};
const dynamicPageClassesDarkMode = {
  "schema-faq-section": {
    borderBottomWidth: 1,
    borderBottomColor: "rgba(255, 255, 255, 0.3)",
    marginBottom: 15,
  },
  "schema-faq-question": {
    fontSize: 18,
    lineHeight: 26,
    fontFamily: FontFamily.semiBold,
    fontWeight: FontWeight.semiBold,
    paddingTop: 15,
    paddingBottom: 15,
    color: "white",
  },
  "schema-faq-answer": {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    paddingTop: 15,
    color: "white",
  },
  "is-style-fill": {
    backgroundColor: "#997CF3",
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "rgb(144, 124, 255)",
    paddingVertical: 9,
    paddingHorizontal: 45,
    color: "white",
    fontSize: 14,
    textTransform: "uppercase",
    letterSpacing: 0.1,
    fontFamily: FontFamily.regular,
    textAlign: "center",
    width: "100%",
    height: 38,
    marginTop: 20,
    marginBottom: 20,
  },
  "is-style-outline": {
    backgroundColor: "black",
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "rgb(144, 124, 255)",
    paddingVertical: 9,
    paddingHorizontal: 45,
    color: "rgb(144, 124, 255)",
    fontSize: 14,
    textTransform: "uppercase",
    letterSpacing: 0.1,
    fontFamily: FontFamily.regular,
    textAlign: "center",
    width: "100%",
    height: 38,
    marginTop: 20,
    marginBottom: 20,
  },
  "has-background": {
    paddingHorizontal: 15,
    backgroundColor: "#1e1e1e",
  },
  "wp-block-quote": {
    marginBottom: 20,
  },
  "text-center": {
    textAlign: "center",
  },
  "wp-block-buttons": {
    alignItems: "center",
  },
  "has-text-align-center": {
    textAlign: "center",
  },
  "wp-block-embed__wrapper": {
    maxWidth: "100%",
    height: 200,
    alignItems: "center",
    marginLeft: 0,
    marginBottom: 70,
  },
  "wp-block-spacer": {
    marginTop: 20,
  },
  "bg-light-blue": {
    backgroundColor: "#2d2d2d",
  },
};
const dynamicPageTagsDarkMode = {
  h1: {
    fontSize: 32,
    lineHeight: 40,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    color: "rgba(255, 255, 255, 0.89)",
  },
  h2: {
    fontSize: 20,
    lineHeight: 31,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    textAlign: "center",
    fontWeight: FontWeight.semiBold,
    marginTop: 10,
    marginBottom: 20,
    textDecorationLine: "underline",
    color: "rgba(255, 255, 255, 0.89)",
  },
  h3: {
    fontSize: 20,
    lineHeight: 26,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.regular,
    marginTop: 30,
    marginBottom: 8,
    color: "rgba(255, 255, 255, 0.89)",
  },
  h4: {
    fontSize: 14,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 30,
    marginBottom: 20,
    color: "rgba(255, 255, 255, 0.89)",
  },
  h5: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 30,
    marginBottom: 20,
    color: "rgba(255, 255, 255, 0.89)",
  },
  p: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: FontFamily.merriweather,
    marginBottom: 20,
    position: "relative",
    color: "rgba(255, 255, 255, 0.89)",
  },
  img: {
    maxWidth: 250,
    height: "auto",
    marginBottom: 15,
  },
  a: {
    textDecorationLine: "none",
  },
  figure: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  ul: {
    marginTop: 0,
    marginBottom: 0,
  },
  li: {
    color: "rgba(255, 255, 255, 0.89)",
    fontSize: 14,
    lineHeight: 22,
    fontFamily: FontFamily.merriweather,
  },
  cite: {
    color: "rgba(255, 255, 255, 0.89)",
    fontSize: 14,
    fontFamily: FontFamily.merriweather,
    lineHeight: 22,
  },
};

// end of dark Mode post content
// **************************************************************************

const subscriptionInfo = StyleSheet.create({
  imageWrapper: {
    width: "100%",
    height: 350,
    // backgroundColor: '#eeeeee',
  },
});

const YesterdayModule = StyleSheet.create({
  block: {
    // position: 'relative',
    width: 1216,
    height: 416,
    left: 112,
    bottom: 397,
    marginTop: 400,
    // backgroundColor: 'linear-gradient(90.97deg, #6C7AF7 0%, #A97DF1 99.9%)'
    backgroundColor: "#6C7AF7",
  },

  readSmarter: {
    position: "absolute",
    width: 467,
    height: 86,
    left: 520,
    top: 66,

    fontFamily: "Abril Fatface",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 72,
    // lineHeight: "120%",
    /* or 86px */

    color: "#FFFFFF",
  },
  readSmarterSignup: {
    position: "absolute",
    width: 591,
    height: 72,
    left: 520,
    top: 164,
    fontFamily: "Matteo",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 24,
    // lineheight: "150%",
    color: "#FFFFFF",
  },
  inputBox: {
    // position: absolute,
    width: 415,
    height: 56,
    left: 520,
    bottom: 84,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderWidth: 1,
    borderColor: "#FFFFFF",
    // boxSizing: 'border-box',
    borderRadius: 4,
    marginTop: 310,
  },
  inputBoxPlaceholder: {
    // position: absolute;
    width: "auto",
    height: "auto",
    left: 536,
    bottom: 100,
    fontFamily: "Matteo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 40,
    // lineHeight: 40,
    /* identical to box height, or 150% */
    color: "#FFFFFF",
  },
  signupButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "16px 24px",
    position: "absolute",
    width: 168,
    height: 56,
    left: 943,
    bottom: 84,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
  },
  // don't stop reading module
  box: {
    // position: helpers.isWeb() ? 'fixed' : 'absolute',
    width: "auto",
    height: "auto",
    left: 0,
    bottom: 272,
    backgroundColor: "#FFFFFF",
    margin: "auto",
  },
  boxAuto: {
    // position: helpers.isWeb() ? 'fixed' : 'absolute',
    width: "auto",
    height: "auto",
    left: 0,
    bottom: 0,
    backgroundColor: "#FFFFFF",
    margin: "auto",
  },
});

const formStyle = StyleSheet.create({
  input: {
    height: 46,
    borderWidth: 1,
    borderColor: "#051462",
    paddingHorizontal: 8,
    paddingVertical: 8,
    fontSize: 14,
    fontFamily: FontFamily.light,
    letterSpacing: 0.5,
  },
  inputUnderline: {
    height: 40,
    borderBottomWidth: 1,
    borderBottomColor: "#BCBCBC",
    paddingHorizontal: 0,
    paddingVertical: 8,
    fontSize: 16,
    fontFamily: FontFamily.regular,
    color: "#000000",
  },
  inputBorderBox: {
    height: 40,
    borderWidth: 0.5,
    borderColor: "#C4C4C4",
    paddingHorizontal: 10,
    paddingVertical: 8,
    fontSize: 14,
    fontFamily: FontFamily.regular,
    color: "#000000",
    borderRadius: 3,
  },
  inputBox: {
    height: 53,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.25)",
    borderRadius: 1,
    paddingHorizontal: 0,
    paddingVertical: 8,
    fontSize: 18,
    lineHeight: 22,
    fontFamily: FontFamily.regular,
    color: "#000000",
    paddingHorizontal: 17,
  },
  inputBoxStyle: {
    height: 40,
    borderBottomWidth: 0.3,
    borderColor: "rgba(153, 153, 153, 0.5)",
    borderRadius: 1,
    paddingHorizontal: 0,
    paddingVertical: 4,
    fontSize: 18,
    lineHeight: 22,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "#000000",
    paddingHorizontal: 2,
  },
  inputBoxSmall: {
    height: 46,
    borderWidth: 1,
    borderColor: "rgba(5, 20, 98, 0.8)",
    borderRadius: 1,
    paddingHorizontal: 0,
    paddingVertical: 8,
    fontSize: 16,
    fontFamily: FontFamily.regular,
    color: "#000000",
    paddingHorizontal: 15,
    letterSpacing: 0.5,
  },
  inputBoxError: {
    borderColor: "#F02121",
  },
  inputBoxValid: {
    borderColor: "rgba(0, 0, 0, 0.7)",
  },
  inputIconWrap: {
    position: "relative",
  },
  showPassBtn: {
    position: "absolute",
    top: 7,
    right: 0,
    paddingLeft: 10,
    paddingRight: 0,
    paddingVertical: 11,
  },
  showPassBtnText: {
    fontSize: 12,
    lineHeight: 14,
    color: Colors.black,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    marginTop: 2,
  },
  footerText1: {
    fontSize: 12,
    lineHeight: 14.4,
    color: "rgba(98, 98, 98, 0.7)",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  footerText2: {
    fontSize: 12,
    lineHeight: 14,
    color: "rgba(98, 98, 98, 0.7)",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    textAlign: "center",
  },
  primayLinkBtn: {
    fontSize: 12,
    lineHeight: 14.4,
    color: Colors.brandColor1,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    // borderBottomColor: Colors.brandColor3,
    // borderBottomWidth: 1,
    // paddingBottom: 5,
    // marginBottom: 5,
    textDecorationLine: "underline",
  },
  linkBtn: {
    fontSize: 11,
    lineHeight: 16,
    color: Colors.primary,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    textAlign: "center",
  },
  linkBtnBorder: {
    // borderBottomColor: 'rgba(0, 0, 0, 0.7)',
    // borderBottomWidth: 1,
    // position: 'relative',
    // textDecorationLine: 'underline'
  },
  textArea: {
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: 2,
    paddingVertical: 15,
    paddingHorizontal: 18,
    fontSize: 14,
    color: "#000000",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  prefix: {
    paddingHorizontal: 10,
    position: "absolute",
    top: 11,
    left: 10,
    fontSize: 18,
    fontFamily: FontFamily.regular,
  },
  searchBox: {
    backgroundColor: helpers.isWeb()
      ? "linear-gradient(130.24deg, #6C7AF6 0%, #A97DF1 100%)"
      : "#6C7AF6",
  },
  applyCouponBtn: {
    fontSize: 13,
    lineHeight: 15,
    color: Colors.purple,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 2,
  },
});

const button = StyleSheet.create({
  primary: {
    backgroundColor: Colors.blue,
    paddingHorizontal: 10,
    height: 40,
  },
  primaryannualloggedout: {
    backgroundColor: "#ffffff",
    paddingHorizontal: 10,
    height: 40,
  },
  primaryText: {
    color: "#ffffff",
    fontSize: 16,
    fontFamily: FontFamily.merriweather,
    textAlign: "center",
    letterSpacing: 0.5,
  },
  primaryGradient: {
    backgroundColor: Colors.primary,
    borderRadius: 0,
    position: "relative",
    overflow: "hidden",
    height: 46,
    justifyContent: "center",
  },
  primaryGradientView: {
    backgroundColor: helpers.isDarkMode() ? "black" : "#ffffff",
    borderColor: "#907CFF",
    borderRadius: 0,
    borderWidth: 2,
    position: "relative",
    overflow: "hidden",
    height: 46,
    justifyContent: "center",
  },
  primaryGradientBtn: {
    backgroundColor: Colors.primary,
    borderRadius: 0,
    position: "relative",
    overflow: "hidden",
    // height: 46,
    justifyContent: "center",
  },
  primaryGradientText: {
    color: "#ffffff",
    fontSize: 17,
    fontFamily: FontFamily.medium,
    // fontWeight: FontWeight.semiBold,
    textAlign: "center",
    textTransform: "uppercase",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.5,
  },
  primaryGradientTextViewlogout: {
    color: "#3800CA",
    fontSize: 17,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.semiBold,
    textAlign: "center",
    textTransform: "uppercase",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.5,
  },
  primaryGradientTextView: {
    color: "#907CFF",
    fontSize: 17,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.semiBold,
    textAlign: "center",
    textTransform: "uppercase",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.5,
  },

  primaryGradientSmall: {
    backgroundColor: Colors.primary,
    borderRadius: 3,
    position: "relative",
    overflow: "hidden",
    height: 40,
    justifyContent: "center",
  },
  primaryGradientTextSmall: {
    color: "#ffffff",
    fontSize: 12,
    lineHeight: 14,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    textAlign: "center",
    textTransform: "uppercase",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.5,
  },
  outlineButton: {
    fontFamily: FontFamily.regular,
    fontWeight: 500,
    borderColor: "#7b8ffe",
    borderWidth: 0.75,
    color: "#7b8ffe",
    fontSize: 20,
    lineHeight: 41,
    borderRadius: 40,
    paddingHorizontal: 25,
    paddingVertical: 0,
    margin: 12,
  },
  primaryWhiteBack: {
    borderColor: Colors.primary,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderRadius: 3,
    position: "relative",
    overflow: "hidden",
    height: 40,
    justifyContent: "center",
  },
  primaryWhiteText: {
    color: Colors.primary,
    fontSize: 12,
    lineHeight: 14,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    textAlign: "center",
    textTransform: "uppercase",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.5,
  },
  outlineButtonPurple: {
    // border:"1px solid #7B8FFE",
    color: "#7B8FFE",
    fontSize: 16,
    lineHeight: 15,
    borderRadius: 25,
    paddingHorizontal: 20,
    paddingVertical: 10,
    margin: 10,
    //   display:"inline-block"
  },
  primaryOutline: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: Colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 2,
  },
  primaryOutlineFree: {
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: Colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 2,
  },
  primaryOutlineText: {
    color: Colors.primary,
    fontSize: 12,
    lineHeight: 15,
    fontFamily: FontFamily.medium,
    textAlign: "center",
    letterSpacing: 0.02,
    textTransform: "uppercase",
  },
  primaryOutlineRound: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: Colors.blue,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 100,
  },
  primaryOutlineRoundText: {
    color: Colors.blue,
    fontSize: 16,
    fontFamily: FontFamily.merriweather,
    textAlign: "center",
    letterSpacing: 0.5,
  },
  btnUnderPrimaryWrap: {
    paddingHorizontal: 10,
    alignItems: "center",
    marginTop: 13,
    marginBottom: 13,
  },
  // btnUnderlinePrimary: {
  //     borderBottomWidth: 1,
  //     paddingBottom: 0,
  //     borderBottomColor: '#6663FF',
  // },
  btnUnderlinePrimaryText: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 18,
    lineHeight: 25,
    textDecorationLine: "underline",
    color: "#6663FF",
  },
  btnGoogle: {
    borderWidth: 0.5,
    borderColor: "rgba(151, 151, 151, 0.6)",
    borderRadius: 2,
    backgroundColor: "#FFFFFF",
    height: 50,
    justifyContent: "center",
    paddingHorizontal: 12,
    width: "100%",
    //shadowColor: "rgba(151, 151, 151, 0.6)",
    //shadowOffset: {
    //width: 0,
    //height: 1,
    //},
    //shadowOpacity: 1,
    //shadowRadius: 2.00,
    //elevation: 1,
  },
  btnGoogleText: {
    color: "#000000",
    fontSize: 16,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    textAlign: "center",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.8,
  },
  btnFB: {
    borderWidth: 0.5,
    borderColor: "rgba(151, 151, 151, 0.6)",
    borderRadius: 2,
    backgroundColor: "#FFFFFF",
    height: 50,
    justifyContent: "center",
    paddingHorizontal: 12,
    width: "100%",
    //shadowColor: "rgba(151, 151, 151, 0.6)",
    //shadowOffset: {
    //width: 0,
    //height: 1,
    //},
    //shadowOpacity: 1,
    //shadowRadius: 2.00,
    //elevation: 1,
  },
  btnFBText: {
    color: "#000000",
    fontSize: 16,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    textAlign: "center",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.8,
  },
  btnEmail: {
    borderWidth: 1,
    borderColor: "rgba(151, 151, 151, 0.3)",
    borderRadius: 2,
    backgroundColor: "#FFFFFF",
    height: 50,
    justifyContent: "center",
    paddingHorizontal: 12,
    width: "100%",
    shadowColor: "rgba(0, 0, 0, 0.1)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 2.0,

    elevation: 1,
  },
  btnEmailText: {
    color: "#000000",
    fontSize: 16,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    textAlign: "center",
    position: "relative",
    zIndex: 2,
    letterSpacing: 0.8,
  },
  disableBtn: {
    backgroundColor: "#EAEAEA",
    paddingHorizontal: 10,
    height: 46,
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "center",
  },
  disableBtnText: {
    color: "#CACACA",
    fontSize: 17,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.regular,
    textAlign: "center",
    letterSpacing: helpers.isWeb() ? "0.05em" : 0.05,
  },
  btnDefaultText: {
    fontSize: 12,
    lineHeight: 14,
    fontFamily: FontFamily.regular,
    color: Colors.brandColor1,
    paddingTop: 8,
    paddingTop: 6,
    //borderBottomWidth: 1,
    //borderBottomColor: 'rgba(0, 0, 0, 0.25)',
    //letterSpacing: 0.8,
  },
  secondaryText: {
    color: Colors.brandColor1,
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    textAlign: "center",
    borderBottomColor: Colors.brandColor1,
    borderBottomWidth: 1,
    paddingBottom: 1,
    letterSpacing: 0.8,
  },
  secondaryOutline: {
    color: Colors.brandColor1,
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.semiBold,
    textAlign: "center",
    borderColor: Colors.brandColor1,
    borderWidth: 1,
    paddingHorizontal: 30,
    paddingVertical: 12,
    letterSpacing: 0.8,
    textTransform: "uppercase",
  },
  secondaryOutlineDesktop: {
    fontSize: 18,
    lineHeight: 22,
  },
  secondaryOutlineText: {
    color: Colors.brandColor1,
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    textAlign: "center",
    paddingTop: 12,
    letterSpacing: 0.8,
  },
  secondaryUnderline: {
    marginTop: 10,
    paddingVertical: 8,
  },
  secondaryUnderlineText: {
    color: Colors.brandColor1,
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    textAlign: "center",
    textDecorationLine: "underline",
  },
  primaryOutlineSmall: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: Colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  primaryOutlineTextSmall: {
    color: Colors.primary,
    fontSize: 14,
    lineHeight: 15,
    fontFamily: FontFamily.medium,
    textAlign: "center",
    letterSpacing: 0.02,
    textTransform: "uppercase",
  },
  primaryOutlineSmallDark: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: Colors.purpleBorder,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  primaryOutlineTextSmallDark: {
    color: Colors.purpleBorder,
    fontSize: 14,
    lineHeight: 15,
    fontFamily: FontFamily.medium,
    textAlign: "center",
    letterSpacing: 0.02,
    textTransform: "uppercase",
  },
  whiteButton: {
    fontFamily: FontFamily.medium,
    // height: 30,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 0.5,
    borderWidth: 1,
    borderColor: "white",
    width: "100%",
    marginTop: 20,
  },
  whiteButtonText: {
    color: "white",
    fontSize: 13,
    lineHeight: 15,
    letterSpacing: 0.8,
    paddingVertical: 7,
    paddingHorizontal: 20,
    textAlign: "center",
    width: "100%",
  },
  uploadButton: {
    height: 38,
    backgroundColor: "#C4C4C4",
    borderRadius: 2,
    borderWidth: 1,
    borderColor: "#C4C4C4",
    width: "100%",
    marginTop: 10,
  },
  uploadButtonText: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    color: "white",
    fontSize: 16,
    lineHeight: 37,
    letterSpacing: 0.3,
  },
});

const unlockBox = StyleSheet.create({
  wrapper: {
    backgroundColor: "#F3F3F3",
    paddingHorizontal: 15,
    paddingVertical: 12,
  },
  Title: {
    fontSize: FontSize.h4,
    color: "#000000",
    fontFamily: FontFamily.medium,
  },
  desc: {
    fontSize: FontSize.h5,
    color: "#656565",
    fontFamily: FontFamily.regular,
  },
  errorWrap: {
    backgroundColor: "#edc9c5",
    paddingHorizontal: 10,
    paddingVertical: 10,
    width: "100%",
    marginHorizontal: "auto",
  },
  successWrap: {
    backgroundColor: "#c5e2ed",
    paddingHorizontal: 10,
    paddingVertical: 10,
    width: "100%",
    marginHorizontal: "auto",
  },
  errorText: {
    color: "#F02121",
    fontSize: 14,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 5,
  },
  successText: {
    color: "#427eaa",
    fontSize: 14,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 5,
  },
  errorDesc: {
    fontSize: FontSize.h5,
    marginTop: 5,
  },
});

const testimonialStyl = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: "#000000",
    paddingHorizontal: 10,
    paddingVertical: 10,
    maxWidth: "100%",
    width: "100%",
  },
});
const subscribePlans = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  wrapperDesktop: {
    flexWrap: "nowrap",
  },
  wrapperBlock: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  wrapperBlockDesktop: {
    flexWrap: "nowrap",
  },
  planItemWrap: {
    paddingHorizontal: 0,
    width: "100%",
    marginBottom: 35,
  },
  planItemWrapDesktop: {
    paddingHorizontal: 10,
    width: "33.33%",
    marginBottom: 0,
  },
  planItemWrapPanel: {
    paddingHorizontal: 0,
    width: "100%",
    marginBottom: 35,
  },
  planItem: {
    flex: 1,
    width: "100%",
    borderWidth: 2,
    borderColor: Colors.primary,
    paddingTop: 50,
    paddingHorizontal: 25,
    paddingBottom: 25,
    marginBottom: 20,
  },
  mostViwed: helpers.isWeb()
    ? {
      textAlign: "center",
      fontFamily: FontFamily.medium,
      fontWeight: FontWeight.medium,
      fontSize: 14,
      lineHeight: 17,
      marginBottom: 0,
      paddingHorizontal: 30,
      paddingVertical: 10,
      backgroundColor: Colors.green,
      color: "#ffffff",
      textTransform: "uppercase",
      width: 170,
      position: "absolute",
      left: "50%",
      top: -70,
      transform: [{ translateX: "-50%" }],
    }
    : {
      textAlign: "center",
      fontFamily: FontFamily.medium,
      fontWeight: FontWeight.medium,
      fontSize: 14,
      lineHeight: 17,
      marginBottom: 0,
      paddingHorizontal: 30,
      paddingVertical: 10,
      backgroundColor: Colors.green,
      color: "#ffffff",
      textTransform: "uppercase",
      width: 190,
      position: "relative",
      left: 0,
      right: 0,
      top: -70,
    },
  planType: {
    textAlign: "center",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: FontSize.f20,
  },
  planTypeFree: {
    textAlign: "center",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.light,
  },
  planTypeDesktop: {
    fontSize: 30,
  },
  planTypePanel: {
    fontSize: FontSize.f20,
  },
  textUnderline: {
    maxWidth: 46,
    width: "100%",
    height: 2,
    borderTopWidth: 1,
    borderTopColor: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 8,
    marginBottom: 20,
  },
  textUnderlineFree: {
    maxWidth: 46,
    width: "100%",
    height: 2,
    borderTopWidth: 1,
    borderTopColor: "#fff",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 8,
    marginBottom: 20,
  },
  symbol: {
    fontSize: 30,
    lineHeight: 36,
    fontFamily: FontFamily.medium,
    color: Colors.brandColor1,
    paddingRight: 8,
  },
  trialPlanPrice: {
    textAlign: "center",
    fontSize: 32,
    lineHeight: 50,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: Colors.brandColor1,
    marginTop: 5,
  },
  planPrice: {
    textAlign: "center",
    fontSize: 42,
    lineHeight: 50,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: Colors.brandColor1,
    marginTop: 5,
  },
  planPriceMobile: {
    fontSize: 36,
    lineHeight: 43,
  },
  planDesc: {
    textAlign: "center",
    fontSize: FontSize.h5,
    lineHeight: 21,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 0,
    marginBottom: 10,
    opacity: 0.8,
  },
  planDescDesktop: {
    fontSize: 15,
    lineHeight: 24,
  },
  planDescPanel: {
    fontSize: FontSize.h5,
  },
  planDescPanelDesktop: {
    fontSize: 16,
    lineHeight: 24,
  },
  currentPlan: {
    fontSize: 15,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 0.32)",
  },
  currentPlanType: {
    fontSize: 18,
    lineHeight: 32,
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    color: "rgba(0, 0, 0, 0.32)",
  },
  PlanDescOdd: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 0.32)",
    marginHorizontal: 8,
  },
  PlanPriceOdd: {
    fontSize: 22,
    lineHeight: 26,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: "rgba(0, 0, 0, 0.32)",
  },
  PlanPriceSymbolOdd: {
    fontSize: 18,
    lineHeight: 22,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 0.32)",
  },
  currentPlanEven: {
    fontSize: 15,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 0.9)",
  },
  currentPlanTypeEven: {
    fontSize: 18,
    lineHeight: 32,
    fontFamily: FontFamily.merriweather,
    fontWeight: Platform.OS == "ios" ? FontWeight.regular : FontWeight.semiBold,
    color: "rgba(0, 0, 0, 0.9)",
  },
  PlanDescEven: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 0.9)",
    marginHorizontal: 8,
  },
  PlanPriceEven: {
    fontSize: 22,
    lineHeight: 26,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: "#FC876D",
    opacity: 0.85,
  },
  PlanPriceSymbolEven: {
    fontSize: 18,
    lineHeight: 22,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "#FC876D",
  },
  seprator: {
    borderTopWidth: 1,
    borderTopColor: "rgba(5, 20, 98, 0.3)",
  },
  cancelDesc: {
    fontSize: 14,
    lineHeight: 17,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 1)",
  },
  infoText: {
    fontSize: 13,
    lineHeight: 16,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.black85,
  },
  footertext: {
    fontSize: 12,
    lineHeight: 14,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0,0,0, 0.9)",
  },
  badgeAmount: {
    fontSize: 28,
    lineHeight: 34,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
  },
  badgeText: {
    fontSize: 12,
    lineHeight: 14,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  creditText: {
    fontSize: 20,
    lineHeight: 28,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.medium,
    color: "#c0c0c0",
    textDecorationLine: "line-through",
  },
  loaderTitle: {
    fontSize: 30,
    lineHeight: 54,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.medium,
    color: "#907CFF",
  },
  loaderSubTitle: {
    fontSize: 18,
    lineHeight: 25,
    fontWeight: FontWeight.light,
    fontFamily: FontFamily.medium,
    color: "#907CFF",
  },
  loaderDesc: {
    fontSize: 12,
    lineHeight: 17,
    fontWeight: FontWeight.light,
    fontFamily: FontFamily.medium,
    color: "#6A6A6A",
  },
  loaderClick: {
    fontWeight: FontWeight.semiBold,
    borderBottomColor: "#6A6A6A",
    borderBottomWidth: 1,
  },
  userName: {
    color: "#000000",
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.medium,
    fontSize: 20,
    lineHeight: 36,
  },
  planCancelText: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  planCancelTextBold: {
    fontWeight: FontWeight.semiBold,
  },
  planCancelTextTitle: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: FontWeight.light,
    fontFamily: FontFamily.medium,
  },
  payTitle: {
    fontSize: 20,
    lineHeight: 36,
    fontWeight: FontWeight.light,
    fontFamily: FontFamily.medium,
  },
  payTitleAmt: {
    lineHeight: 28,
  },
  paySubTitle: {
    lineHeight: 28,
  },
  payBaseLine: {
    borderBottomColor: "#BCBCBC",
    borderBottomWidth: 1,
  },
});

const appHeader = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 20,
    paddingVertical: 3,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
    zIndex: 999,
    backgroundColor: "#ffffff",

    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 1.0,

    elevation: 2,
  },
  trendingWrapper: {
    paddingHorizontal: 20,
    paddingVertical: 3,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
  },
  wrapperDesktop: {
    paddingVertical: 4,
  },
  menuIcon: {
    width: 23,
    height: 18,
  },
  searchIcon: {
    width: 18,
    height: 18,
  },
  logo: {
    width: 29,
    height: 29,
    position: "relative",
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  },
  menuWrapper: {
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 9999,
    paddingTop: 20,
    paddingHorizontal: 20,
    paddingBottom: 20,
    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 1.0,

    elevation: 2,
    overflow: helpers.isWeb() ? "auto" : "visible",
  },
  searchWrapper: {
    backgroundColor: "#ffffff",
    // position: 'absolute',
    // top: 0,
    left: 130,
    flexDirection: "row-reverse",
    // bottom: 0,
    // zIndex: 9999,
    // paddingTop: 20,
    // paddingHorizontal: 20,
    // paddingBottom: 20,
    // elevation: 2,
    // overflow: helpers.isWeb() ? 'auto' : 'visible'
  },
  menuWrapperDesktop: {
    position: helpers.isDesktop() ? "fixed" : "absolute",
    paddingHorizontal: 30,
  },
  menuItem: {
    marginVertical: 10,
  },
  menuItemText: {
    fontSize: 16,
    lineHeight: 27,
    color: Colors.black,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    textTransform: "uppercase",
  },
  menuItemTextDesktop: {
    fontSize: 18,
  },
  menuItemTextDesc: {
    fontSize: 13,
    lineHeight: 17,
    color: "#757575",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  menuItemTextDescDesktop: {
    fontSize: 14,
  },
  successBox: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: Colors.successBackground,
  },
  successBoxText: {
    fontSize: 18,
    color: Colors.success,
  },
  errorBox: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: Colors.errorBackground,
  },
  errorBoxText: {
    fontSize: 18,
    color: Colors.error,
  },
  paymentSuccess: {
    backgroundColor: Colors.green,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 28,
    paddingTop: 8,
    paddingBottom: 8,
  },
  paymentSuccessDesktop: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  paymentFailure: {
    backgroundColor: "#F7931E",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 28,
    paddingTop: 8,
    paddingBottom: 8,
  },
  paymentFailureDesktop: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  alertMsg: {
    fontSize: 20,
    lineHeight: 17,
    letterSpacing: helpers.isWeb() ? "0.03em" : 0.03,
    color: "white",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    paddingRight: 10,
    paddingBottom: 3,
  },
  alertMsgDesktop: {
    fontSize: 18,
    lineHeight: 30,
    paddingBottom: 0,
  },
  msgText: {
    fontSize: 14,
    lineHeight: 18,
    color: "rgba(255,255,255,0.95)",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginRight: 10,
    textDecorationLine: "underline",
  },
  msgTextDesktop: {
    fontSize: 16,
    lineHeight: 30,
    fontWeight: FontWeight.medium,
  },
  text: {
    fontSize: 14,
    fontFamily: FontFamily.regular,
    lineHeight: 27,
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "rgba(63, 63, 63, 0.8)",
    marginHorizontal: 12,
    marginTop: 1,
  },
});

const aboutAuthor = StyleSheet.create({
  wrapper: {
    // borderWidth: 1,
    // borderColor: Colors.brandColor1,
    // paddingTop: 40,
    // paddingBottom: 28,
    // paddingHorizontal: 30,
    position: "relative",
  },
  titleWrap: {
    // position: 'absolute',
    // top: -12.5,
    // left: 0,
    // right: 0,
    // alignItems: 'center',
  },
  wrapperTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    paddingHorizontal: 0,
    paddingVertical: 5,
    color: "#8A8A8A",
    marginBottom: 10,
  },
  wrapperTitleDesktop: {
    fontSize: 18,
    lineHeight: 27,
  },
  desc: {
    fontSize: 16,
    lineHeight: 24,
    color: "#000000",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.3,
  },
  title: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: "#000000",
    marginBottom: 8,
    letterSpacing: 0.3,
  },
  titleDesktop: {
    fontSize: 24,
    lineHeight: 26,
  },
});

const noteDiscloures = StyleSheet.create({
  sectionName: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: 16,
    lineHeight: 32,
    letterSpacing: 0.5,
    textAlign: "left",
    color: "#000000",
  },
});

const articleLists = StyleSheet.create({
  sectionTitle: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 22,
    lineHeight: 32,
    letterSpacing: 0.5,
    textAlign: "center",
    textTransform: "uppercase",
  },
  listWrap: {
    // flexDirection: 'row',
    // paddingVertical: 26,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: "rgba(5, 20, 98, 0.6)",
  },
  artliceThumb: {
    width: "100%",
    height: "auto",
    resizeMode: "cover",
  },
  articleTitle: {
    fontFamily: FontFamily.regular,
    fontWeight: "600",
    fontSize: 26,
    lineHeight: 34,
    color: "#000000",
    flex: 1,
    maxHeight: 68,
  },
  articleTitleMobile: {
    fontSize: 24,
  },
  articleDate: {
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: 13,
    lineHeight: 16,
    color: "#000000",
  },
  articleDateDesktop: {
    fontSize: 14,
    lineHeight: 19,
  },
  lineBreak: {
    width: 70,
    height: 1,
    backgroundColor: "#907CFF",
  },
  dotSeprator: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 10,
    marginLeft: 0,
  },
  authorTitle: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    marginRight: 10,
    fontSize: 14,
    lineHeight: 19,
    color: Colors.black,
  },
  badge: {
    width: 120,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: FontWeight.semiBold,
    fontFamily: FontFamily.medium,
    fontSize: 16,
    textTransform: "uppercase",
  },
  badgeMobile: {
    width: 120,
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: FontWeight.semiBold,
    fontFamily: FontFamily.medium,
    fontSize: 16,
    textTransform: "uppercase",
  },
  col33: {
    width: "85%",
    marginRight: 20,
    marginRight: 20,
  },
  col33Desktop: {
    width: "32%",
    marginRight: 20,
  },
});

const appStyles = StyleSheet.create({
  appBg: {
    backgroundColor: "#ffffff",
  },
  appContainer: {
    paddingTop: 0,
    flex: 1,
    backgroundColor: "#ffffff",
  },
  appBgDark: {
    backgroundColor: Colors.darkBlackColorBg,
  },
  appContainerDark: {
    backgroundColor: "#000000",
  },
  appContainerDesktop: {
    paddingTop: 54,
  },
});

const sliderstyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slideContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  slide1: {
    backgroundColor: "rgba(20,20,200,0.3)",
  },
  slide2: {
    backgroundColor: "rgba(20,200,20,0.3)",
  },
  slide3: {
    backgroundColor: "rgba(200,20,20,0.3)",
  },
});

const postActionBar = StyleSheet.create({
  wrapper: {
    // borderTopColor: 'rgba(5, 20, 98, 0.25)',
    borderTopWidth: 0,
    borderBottomColor: "rgba(5, 20, 98, 0.25)",
    borderBottomWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  actionBtn: {
    marginRight: 10,
    paddingVertical: 5,
  },
  fontBtn: {
    paddingLeft: 15,
    paddingVertical: 5,
  },
});

const actionBox = StyleSheet.create({
  box: {
    borderWidth: 2,
    borderTopWidth: 0,
    borderColor: "#3AACFF",
    padding: 25,
    paddingVertical: 25,
    backgroundColor: "#ffffff",
  },
  boxDesktop: {
    paddingVertical: 35,
  },
  title: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 21,
    textAlign: "center",
    color: "#000000",
    letterSpacing: 0.3,
  },
  titleDesktop: {
    fontSize: 28,
    lineHeight: 30,
  },
  Desc: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 14,
    lineHeight: 16,
    textAlign: "center",
    color: Colors.black85,
  },
  DescDesktop: {
    fontSize: 18,
    lineHeight: 25,
  },
  Strong: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    // fontSize: 13,
    // lineHeight: 16,
    fontSize: 14,
    lineHeight: 16,
    textAlign: "center",
    color: "#FC876D",
  },
  StrongDesktop: {
    fontSize: 18,
    lineHeight: 25,
  },
  footerText: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 14,
    lineHeight: 17,
    color: Colors.black,
  },
  footerTextDesktop: {
    fontSize: 18,
  },
  footerBtnText: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 14,
    lineHeight: 17,
    color: Colors.primary,
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  footerBtnTextDesktop: {
    fontSize: 16,
    lineHeight: 19,
  },
  footerBtnTextCancel: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 14,
    lineHeight: 19,
    color: "#FC876D",
    borderWidth: 0,
    textDecorationLine: "underline",
  },
  footerBtnTextCancelDesktop: {
    fontSize: 16,
  },
  loginText: {
    color: Colors.primary,
    borderBottomWidth: 2,
    borderBottomColor: Colors.primary,
    fontFamily: FontFamily.bold,
    fontWeight: FontWeight.bold,
    textTransform: "uppercase",
  },
  underline: {
    color: Colors.brandColor2,
    borderBottomColor: Colors.brandColor2,
    borderBottomWidth: 1,
    paddingBottom: 1,
  },
});

const signUpPage = StyleSheet.create({
  pageTitle: {
    color: "#000000",
    fontSize: 28,
    lineHeight: 37,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    textAlign: "center",
    letterSpacing: 0.5,
  },
  subTitle: {
    color: "#000000",
    fontSize: 26,
    lineHeight: 38,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    textAlign: "center",
  },
  orWrapper: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  sepratorLine: {
    height: 2,
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: "rgba(0, 0, 0, 0.3)",
    position: "absolute",
    top: 11,
  },
  orText: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 19,
    textAlign: "center",
    backgroundColor: "#ffffff",
    paddingHorizontal: 20,
    position: "relative",
    zIndex: 1,
  },
  planPrice: {
    fontFamily: FontFamily.abrilFatface,
    fontSize: 48,
    lineHeight: 65,
    letterSpacing: 0.5,
    color: "#A97DF1",
    marginBottom: 40,
    paddingLeft: 18,
  },
  planText: {
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    fontSize: 26,
    lineHeight: 35,
    letterSpacing: 0.5,
    color: "rgba(0, 0, 0, 0.9)",
  },
  infoText: {
    fontFamily: FontFamily.regular,
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 0.1,
    color: "rgba(0, 0, 0, 0.85)",
    paddingBottom: 15,
  },
  wrapper: {
    shadowColor: "rgba(0, 0, 0, 0.08)",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 1,
    shadowRadius: 18.0,

    elevation: 4,
  },
});

const referralSection = StyleSheet.create({
  subTitle: {
    fontSize: 14,
    color: Colors.black85,
    lineHeight: 17,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  desc: {
    fontSize: 13,
    color: Colors.black85,
    lineHeight: 16,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginTop: 15,
  },
});

const Messages = StyleSheet.create({
  errorText: {
    fontSize: 13,
    color: Colors.error,
    lineHeight: 16,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  successText: {
    fontSize: 13,
    color: Colors.success,
    lineHeight: 16,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
});

const joinUsInfo = StyleSheet.create({
  infoText: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.black,
  },
  pl_15: {
    paddingLeft: 15,
  },
  pr_15: {
    paddingRight: 15,
  },
  col_c_right: {
    paddingRight: 60,
  },
  col_c_left: {
    paddingLeft: 60,
  },
});

const exclusiveContent = StyleSheet.create({
  borderTop: {
    borderTopColor: "rgba(153, 153, 153, 0.6)",
    borderTopWidth: 1,
  },
  borderRight: {
    borderRightColor: "rgba(153, 153, 153, 0.6)",
    borderRightWidth: 1,
  },
});

const sliderStyle = StyleSheet.create({
  sliderOuterItem: {
    paddingTop: 0,
    paddingHorizontal: 0,
  },
  sliderOuterItemDesktop: {
    paddingHorizontal: 0,
  },
  sliderItem: {
    paddingTop: 37,
    paddingHorizontal: 0,
    paddingBottom: 50,
    position: "relative",
    minHeight: 230,
  },
  sliderItemDesktop: {
    paddingHorizontal: 25,
    paddingTop: 80,
    paddingBottom: 80,
    minHeight: 320,
  },
  sliderItem1: {
    paddingTop: 30,
    paddingHorizontal: 0,
    paddingBottom: 30,
    position: "relative",
    minHeight: 230,
  },
  sliderItemDesktop1: {
    paddingHorizontal: 25,
    paddingTop: 80,
    paddingBottom: 80,
    minHeight: 320,
  },
  desc: {
    fontFamily: FontFamily.regular,
    fontSize: 14,
    lineHeight: 19,
    color: Colors.black,
    marginBottom: 20,
    letterSpacing: 0.3,
  },
  descDesktop: {
    fontSize: 20,
    lineHeight: 32,
    marginBottom: 30,
  },
  name: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 13,
    lineHeight: 16,
    marginBottom: 2,
    letterSpacing: 0.3,
    color: "#7E30FF",
  },
  writerName: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 13,
    lineHeight: 16,
    marginBottom: 2,
    letterSpacing: 0.3,
    color: "#000",
  },
  nameDesktop: {
    fontSize: 22,
    lineHeight: 33,
  },
  writerDescription: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.3,
    color: "#000",
    marginLeft: 10,
  },
  position: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.3,
    color: "#7E30FF",
    marginLeft: 10,
  },
  positionDesktop: {
    fontSize: 22,
    lineHeight: 33,
  },
  userPicWrapper: {
    width: 36,
    height: 36,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: Colors.purple,
    overflow: "hidden",
  },
  userPicCenter: {
    position: "absolute",
    top: -18,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  quoteSymbol: {
    color: "#F0F0F0",
    fontSize: 90,
    lineHeight: 90,
    marginBottom: -40,
    fontFamily: FontFamily.bold,
    fontWeight: FontWeight.bold,
  },
  wrapper: {
    position: "relative",
    backgroundColor: "#fff",
  },
  dotWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 0,
    position: "relative",
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 100,
    backgroundColor: "transparent",
    marginHorizontal: 7,
    borderWidth: 1,
    borderColor: "#55C79A",
  },
  dotNews: {
    width: 10,
    height: 10,
    borderRadius: 100,
    backgroundColor: "transparent",
    marginHorizontal: 7,
    borderWidth: 1,
    borderColor: "#ECB731",
  },
  activeDot: {
    backgroundColor: "#55C79A",
  },
  activeDotNews: {
    backgroundColor: "#ECB731",
  },
  dotDark: {
    width: 10,
    height: 10,
    borderRadius: 100,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    marginHorizontal: 7,
    borderWidth: 1,
    borderColor: "#55C79A",
  },
  activeDotDark: {
    backgroundColor: "#55C79A",
  },
  greyDot: {
    width: 8,
    height: 8,
    borderRadius: 100,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    marginHorizontal: 7,
    borderWidth: 0.8,
    borderColor: "#6A6A6A",
  },
  greyActiveDot: {
    backgroundColor: "#6A6A6A",
  },
  arrowLeft: {
    position: "absolute",
    top: "50%",
    left: -20,
  },
  arrowRight: {
    position: "absolute",
    top: "50%",
    right: -20,
  },
  arrowBtn: {
    paddingHorizontal: 15,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: "#E8E8E8",
    borderRadius: 50,
    backgroundColor: "#ffffff",
  },
});

const article = StyleSheet.create({
  countWrap: {
    alignItems: "center",
    justifyContent: "center",
  },
  count: {
    fontSize: 15,
    lineHeight: 18,
    fontFamily: FontFamily.regular,
    letterSpacing: 0.3,
    textAlign: "center",
    color: Colors.black,
  },
  countDesktop: {
    fontSize: 16,
    lineHeight: 19,
  },
  countLabel: {
    fontSize: 12,
    lineHeight: 14,
    fontFamily: FontFamily.light,
    letterSpacing: 0.3,
    textAlign: "center",
    textTransform: "uppercase",
    color: Colors.black,
  },
  countLabelDesktop: {
    fontSize: 16,
    lineHeight: 19,
  },
  headerWrapper: {
    position: "relative",
  },
  sortbyWrap: {
    // position: 'absolute',
    // right: 0,
    // top: 7,
  },
  sortBtn: {
    paddingLeft: 10,
  },
  company: {
    color: "#907CFF",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 0.3,
  },
});

const pageCover = StyleSheet.create({
  title: {
    fontFamily: FontFamily.abrilFatface,
    fontSize: 34,
    lineHeight: 36,
    textAlign: "left",
    color: "#000",
  },
  titleDesktop: {
    fontSize: 50,
    textAlign: "center",
    lineHeight: 75,
  },
  content: {
    fontSize: 16,
    lineHeight: 30,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.regular,
    letterSpacing: 0.3,
    color: "#000000",
    textAlign: "center",
  },
  contentDesktop: {
    fontSize: 24,
    lineHeight: 38,
  },
});

const homeCover = StyleSheet.create({
  title: {
    fontFamily: FontFamily.abrilFatface,
    fontSize: 30,
    lineHeight: 36,
  },
  titleDesktop: {
    fontSize: 64,
    lineHeight: 71,
  },
  heading: {
    fontFamily: FontFamily.abrilFatface,
    fontSize: 30,
    lineHeight: 39,
    textAlign: "left",
    paddingTop: 10,
  },
  headingDesktop: {
    fontSize: 40,
    lineHeight: 50,
  },
  content: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.regular,
    letterSpacing: 0.3,
    color: "#000000",
    textAlign: "left",
  },
  contentDesktop: {
    fontSize: 20,
    lineHeight: 30,
  },
  strokedText: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    color: "#f8f8f8",
    textShadowColor: Colors.blue1,
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 2,
    paddingRight: 10,
  },
  secondText: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    color: Colors.green,
  },
});

const featuredArticle = StyleSheet.create({
  postDate: {
    fontSize: 16,
    lineHeight: 17,
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    color: "#000000",
    textAlign: "center",
  },
  postDateDesktop: {
    fontSize: 16,
    lineHeight: 19,
    textAlign: "left",
  },
  newslettermain: {
    fontSize: 15,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.semiBold,
    color: "#ECB731",
    textAlign: "center",
    marginTop: 0,
    marginBottom: "2px",
    lineHeight: helpers.isWeb() ? "140%" : 1.4,
  },
  newslettertitle: {
    fontSize: 35,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.semiBold,
    color: "#000000",
    textAlign: "center",
    marginTop: 0,
    marginBottom: "10px",
    lineHeight: helpers.isWeb() ? "140%" : 1.4,
  },
  title: {
    fontSize: 30,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.semiBold,
    color: "#000000",
    textAlign: "center",
    marginTop: 0,
    lineHeight: helpers.isWeb() ? "140%" : 1.4,
  },
  titleDesktop: {
    fontSize: 30,
    textAlign: "left",
  },
  excerpt: {
    fontSize: 18,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    lineHeight: 28,
    letterSpacing: 0.3,
    color: "#000000",
    marginTop: 15,
    textAlign: "center",
  },
  excerptDesktop: {
    fontSize: 18,
    lineHeight: 27,
    textAlign: "left",
  },
  lineBreak: {
    width: 70,
    height: 1,
    backgroundColor: "#907CFF",
  },
  dotSeprator: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 10,
  },
  borderLine: {
    borderWidth: 8,
    borderColor: Colors.green,
    height: "85%",
    position: "absolute",
    top: 125,
    left: 0,
    right: 0,
    width: "94%",
    zIndex: 1,
    margin: "auto",
  },
  borderLineDesktop: {
    height: 565,
    top: 45,
    left: 125,
    width: "86%",
  },
});

const articleItem = StyleSheet.create({
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: "rgba(5, 20, 98, 0.22)",
  },
  wrap: {
    // flexDirection: 'row',
    // paddingTop: 25,
    // paddingBottom: 25,
  },
  wrapAbove: {
    paddingTop: 25,
    paddingBottom: 10,
  },
  removeBorder: {
    borderTopWidth: 1,
    borderTopColor: "transparent",
  },
  colRight: {
    flex: 1,
    // paddingLeft: 25,
  },
  title: {
    color: "#000000",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 5,
  },
  titleDesktop: {
    fontSize: 26,
    lineHeight: 36,
  },
  desc: {
    color: "#000000",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 14,
    // lineHeight: 24,
    letterSpacing: 0.5,
  },
  postDate: {
    color: "#000000",
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: 13,
    lineHeight: 16,
  },
  author: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 14,
    lineHeight: 17,
    color: Colors.black,
  },
  date: {
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: 13,
    lineHeight: 16,
    color: Colors.black,
  },
  dateTop: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 15,
    lineHeight: 18,
    color: Colors.black,
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 5,
    marginTop: 2,
  },
  col33: {
    width: "85%",
    marginRight: 15,
  },
  col33Desktop: {
    width: "32%",
  },
  articleTitle: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    fontSize: 18,
    lineHeight: 24,
    color: "#000000",
    flex: 1,
    maxHeight: 90,
  },
  articleTitleDesktop: {
    fontSize: 28,
    lineHeight: 38,
  },
  authorTitle: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    marginRight: 10,
    fontSize: 14,
    lineHeight: 17,
    color: Colors.black,
  },
  authorTitleDesktop: {
    fontSize: 16,
    lineHeight: 20,
  },
  articleDate: {
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: 14,
    lineHeight: 17,
    color: "#000000",
  },
  articleDateDesktop: {
    fontSize: 16,
    lineHeight: 20,
  },
  lineBreak: {
    width: 70,
    height: 1,
    backgroundColor: "#907CFF",
  },
  featuredTitle: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    marginRight: 10,
    fontSize: 26,
    lineHeight: 19,
    color: Colors.black,
    textTransform: "uppercase",
  },
  col3: {
    width: "85%",
    paddingHorizontal: 16,
    // marginRight: 20,
    // marginRight: 20,
  },
  col3Desktop: {
    width: "33.33%",
    paddingHorizontal: 16,
  },
  col4: {
    width: "85%",
    marginRight: 20,
    marginRight: 20,
  },
  col4Desktop: {
    width: "23.80%",
    marginRight: 0,
  },
});

const authorItem = StyleSheet.create({
  wrap: {
    paddingTop: 26,
    paddingBottom: 26,
  },
  name: {
    fontSize: 20,
    lineHeight: 24,
    color: Colors.black,
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    marginBottom: 10,
  },
  position: {
    fontSize: 13,
    lineHeight: 16,
    color: Colors.black,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  location: {
    fontSize: 13,
    lineHeight: 16,
    color: "#6A6A6A",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  articleCount: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 0.7,
    color: Colors.black,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  articleCountLabel: {
    fontSize: 12,
    lineHeight: 12,
    letterSpacing: 0.1,
    color: Colors.black,
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    textTransform: "uppercase",
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 5,
    marginTop: 8,
  },
});

const writerSingle = StyleSheet.create({
  authorName: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    fontSize: 18,
    lineHeight: 34,
    textAlign: "center",
    color: Colors.black,
  },
  authorNameDesktop: {
    fontSize: 26,
  },
  position: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 15,
    lineHeight: 19,
    textAlign: "center",
    color: Colors.black,
  },
  location: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 15,
    lineHeight: 19,
    textAlign: "center",
    color: Colors.brandColor1,
  },
  articleCount: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 15,
    lineHeight: 19,
    textAlign: "center",
    color: Colors.black,
    letterSpacing: 0.7,
  },
  articleCountLabel: {
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: 14,
    textAlign: "center",
    color: Colors.black,
    textTransform: "uppercase",
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 12,
    marginTop: 2,
  },
  bioText: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: helpers.isWeb() ? "160%" : 1.6,
    textAlign: "center",
    color: Colors.black,
    letterSpacing: 1,
  },
  bioTextDesktop: {
    fontSize: 18,
  },
  followBadge: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    backgroundColor: Colors.brandColor1,
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: "#ffffff",
  },
});

const welcomeStyle = StyleSheet.create({
  pageTitle: {
    fontSize: 26,
    lineHeight: 38,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.brandColor3,
  },
  pageTitle2: {
    fontSize: 36,
    lineHeight: 52,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.brandColor3,
  },
  percetageText: {
    fontSize: 46,
    lineHeight: 62,
    opacity: 0.95,
    color: Colors.brandColor1,
    fontFamily: FontFamily.bold,
    fontWeight: FontWeight.bold,
  },
  percetage: {
    fontSize: 34,
    lineHeight: 62,
    opacity: 0.95,
    color: Colors.brandColor1,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  percetageOff: {
    fontSize: 36,
    lineHeight: 62,
    opacity: 0.95,
    color: Colors.brandColor1,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
});

const modalStyle = StyleSheet.create({
  backdropContainer: helpers.isWeb()
    ? {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.50)",
      zIndex: 9999,
      paddingVertical: 0,
      paddingHorizontal: 0,
      justifyContent: "center",
      width: "100%",
      alignItems: "center",
      userSelect: "unset",
    }
    : {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.50)",
      zIndex: 9999,
      paddingVertical: 0,
      paddingHorizontal: 0,
      justifyContent: "center",
      width: "100%",
      alignItems: "center",
    },
  backdropContainerDesktop: helpers.isWeb()
    ? {
      position: "fixed",
      userSelect: "none",
      paddingVertical: 50,
      paddingHorizontal: 20,
    }
    : {
      position: "absolute",
      paddingVertical: 50,
      paddingHorizontal: 20,
    },
  modalContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 0,
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    position: "relative",
    maxWidth: 450,
    width: "100%",
  },
  modalContainer500: {
    backgroundColor: "#ffffff",
    borderRadius: 0,
    paddingTop: 20,
    paddingBottom: 0,
    paddingHorizontal: 20,
    position: "relative",
    maxWidth: 500,
    width: "100%",
  },
  modalContainerMedium: {
    backgroundColor: "#ffffff",
    borderRadius: 0,
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
    position: "relative",
    maxWidth: 700,
    width: "100%",
  },
  modalContainerlarge: {
    // backgroundColor: '#ffffff',
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 20,
    position: "relative",
    maxWidth: 1070,
    width: "100%",
  },
  closeButton: {
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  sidebarModal: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    overflow: "hidden",
    borderLeftWidth: 1,
    borderColor: "#C4C4C4",
  },
  sidebarHeader: {
    fontSize: 18,
    lineHeight: 30,
    textTransform: "uppercase",
    paddingVertical: 7,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    height: 42,
  },
  sidebarHeaderDesktop: {
    fontSize: 18,
    lineHeight: 30,
    textTransform: "uppercase",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    paddingVertical: 20,
    height: 67,
  },
  sidebarSubHeader: {
    fontSize: 18,
    lineHeight: 30,
    textTransform: "uppercase",
    paddingVertical: 9,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    height: 46,
  },
  sidebarSubHeaderDesktop: {
    fontSize: 18,
    lineHeight: 30,
    textTransform: "uppercase",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    paddingVertical: 20,
    height: 67,
  },
  giftModalBg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.50)",
    zIndex: 9999,
    paddingVertical: 0,
    paddingHorizontal: 0,
    justifyContent: "center",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
});

const userAccount = StyleSheet.create({
  userWrapper: {
    paddingTop: 53,
    paddingBottom: 110,
    paddingHorizontal: 20,
    position: "relative",
  },
  userName: {
    fontSize: 36,
    lineHeight: 52,
    color: "#3AC067",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  userEmail: {
    fontSize: 18,
    lineHeight: 22,
    color: "#000000",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  editProfileBtn: {
    paddingVertical: 6,
    paddingHorizontal: 9,
    flexDirection: "row",
  },
  editProfileBtnText: {
    fontSize: 12,
    lineHeight: 14,
    color: "#FC876D",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    marginRight: 8,
  },
  userActivityWrapper: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#907CFF",
    backgroundColor: "#ffffff",
    borderRadius: 1,
    paddingVertical: 18,
    paddingHorizontal: 15,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 8.0,

    elevation: 2,
  },
  activityItem: {
    alignItems: "center",
    flex: 1,
  },
  activitylabel: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 12,
    lineHeight: 15,
    color: "#000000",
    marginBottom: 6,
    textAlign: "center",
  },
  activityValue: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 24,
    lineHeight: 29,
    color: "#000000",
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: 24,
    lineHeight: 35,
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    color: "#999999",
    letterSpacing: -0.5,
  },
  accountBox: {
    position: "relative",
    marginRight: 25,
  },
  accountBoxTitle: {
    fontSize: 15,
    lineHeight: 18,
    color: "#ffffff",
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.medium,
  },
  accountBoxDesc: {
    fontSize: 10,
    lineHeight: 12,
    color: "#ffffff",
    opacity: 0.8,
    fontWeight: FontWeight.medium,
    fontFamily: FontFamily.medium,
    letterSpacing: -0.01,
  },
  topContent: {
    alignItems: "center",
    borderWidth: 2,
    borderColor: Colors.primary,
    paddingVertical: 25,
  },
  bottomContent: {
    backgroundColor: Colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 14,
    position: "relative",
  },
  menuItemBorder: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: "rgba(5, 20, 98, 0.3)",
    borderBottomColor: "rgba(5, 20, 98, 0.3)",
    paddingVertical: 15,
  },
  menuItem: {
    borderTopWidth: 1,
    borderTopColor: "rgba(5, 20, 98, 0.3)",
    paddingTop: 20,
    paddingBottom: 17,
  },
  menuItemBtn: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItemBtnText: {
    fontSize: 18,
    lineHeight: 22,
    color: "#000000",
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
  },
  logoutBtn: {
    color: "#F02121",
  },
});

const stickyFooter = StyleSheet.create({
  wrapper: {
    backgroundColor: "#f1f1f1",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.04,
    shadowRadius: 1.0,
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.04)",
    elevation: 1,
  },
});

const wygSection = StyleSheet.create({
  Loader: {},
  LoaderWrapper: {
    marginBottom: 60,
  },
});

const readers = StyleSheet.create({
  LoaderWrapper: {
    marginBottom: 60,
  },
});

const accessContent = StyleSheet.create({
  LoaderWrapper: {
    marginBottom: 60,
  },
});

const textBlock = StyleSheet.create({
  LoaderWrapper: {
    marginBottom: 60,
  },
});

const articleLoader = StyleSheet.create({
  LoaderWrapper: {
    marginBottom: 40,
  },
});

const input = StyleSheet.create({
  Label: {
    fontSize: 14,
    color: "#999999",
    marginBottom: 7,
    fontFamily: FontFamily.regular,
  },
  Text: {
    fontSize: 18,
    color: "#000000",
    fontFamily: FontFamily.regular,
  },
  Wrapper: {
    paddingBottom: 25,
    paddingTop: 25,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: "rgba(153, 153, 153, 0.5)",
  },
  LabelLight: {
    fontSize: 12,
    color: "#4d4d4d",
    marginBottom: 7,
    fontFamily: FontFamily.regular,
    letterSpacing: helpers.isWeb() ? "0.005em" : 0.005,
  },
});

const giftCard = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: Colors.primary,
  },
});

const siteFooter = StyleSheet.create({
  wrapper: {
    backgroundColor: "#363839",
  },
  col_1: {
    width: "100%",
  },
  col_1Desktop: {
    width: "27%",
  },
  borderRightCol: {
    borderRightWidth: 0,
    borderRightColor: "#FFFFFF",
  },
  borderRightColDesktop: {
    borderRightWidth: 0.3,
  },
  menu: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: helpers.isDesktop() ? 50 : 0,
    // marginBottom: 25
  },
  menuDesktop: {},
  followTitle: {
    fontSize: 24,
    fontFamily: FontFamily.regular,
    //fontWeight: 400,
    color: "#C4C4C4",
    marginBottom: 10,
    lineHeight: 22,
  },
  menuTitle: {
    fontSize: 18,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    color: "#C4C4C4",
    marginBottom: 10,
    lineHeight: 22,
  },
  menuList: {
    flexDirection: "column",
    // alignItems: 'center',
    // flexWrap: 'wrap'
  },
  menuParent: {
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: 'center',
    // flexWrap: 'wrap'
  },
  menuListItem: {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.regular,
    letterSpacing: 0.1,
    marginBottom: 0,
    color: "#C4C4C4",
  },
  menuListItemDesktop: {
    fontSize: 16,
    lineHeight: 24,
  },
  dotSeprator: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#ffffff",
    marginHorizontal: 10,
  },
  footerTextLight: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.regular,
    color: "rgba(255,255,255,0.8)",
  },
  footerTextRegular: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.regular,
    color: "#ffffff",
  },
  footerLinks: {
    fontSize: 14,
    lineHeight: 17,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "#ffffff",
    textDecorationLine: "underline",
    textDecorationColor: "#ffffff",
  },
});

const articleItemHorizontal = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    paddingTop: 25,
    paddingBottom: 25,
  },
  noWrap: {
    paddingTop: 25,
    paddingBottom: 25,
  },
  wrapAbove: {
    paddingTop: 25,
    paddingBottom: 10,
  },
  colRight: {
    flex: 1,
    paddingLeft: 25,
    paddingRight: 20,
  },
  desc: {
    color: "#000000",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.3,
  },
  descDesktop: {
    fontSize: 16,
    lineHeight: 22,
  },
  dateTop: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    fontSize: 15,
    lineHeight: 18,
    color: Colors.black,
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 5,
    marginTop: 5,
    alignSelf: "center",
  },
  dotDesktop: {
    marginTop: 8,
  },
  articleTitle: {
    fontFamily: 'PlayfairDisplay-Bold',
    fontWeight: FontWeight.regular,
    fontSize: 26,
    lineHeight: 35,
    color: "#000000",
  },
  articleMTitle: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    marginRight: 0,
    fontSize: 26,
    lineHeight: 34,
    color: Colors.black,
  },
  authorTitle: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    marginRight: 0,
    fontSize: 14,
    lineHeight: 19,
    color: Colors.black,
  },
  authorTitleDesktop: {
    fontSize: 16,
  },
  articleDate: {
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: 14,
    lineHeight: 14,
    color: "#000000",
  },
  articleDateDesktop: {
    fontSize: 16,
    lineHeight: 19,
  },
  lineBreak: {
    width: 70,
    height: 1,
    backgroundColor: "#907CFF",
  },
  articleTitleApp: {
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    color: "#000000",
    fontSize: 16,
    lineHeight: 22,
  },
  articleDateApp: {
    fontFamily: FontFamily.light,
    fontWeight: FontWeight.light,
    fontSize: 10,
    lineHeight: 12,
    color: "#000000",
  },
  authorTitleApp: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    marginRight: 10,
    fontSize: 14,
    lineHeight: 19,
    color: Colors.black,
  },
});

const paymentStatusMsg = StyleSheet.create({
  container: {
    position: helpers.isWeb() ? "fixed" : "absolute",
    left: 0,
    right: 0,
    top: 53,
    width: "100%",
    paddingVertical: 30,
  },
  containerDesktop: {
    top: 68,
  },
  success: {
    backgroundColor: Colors.green,
    alignItems: "center",
    justifyContent: "center",
  },
  unsuccessful: {
    backgroundColor: "#FF8080",
    alignItems: "center",
    justifyContent: "center",
  },
  msgText: {
    color: "white",
    fontFamily: FontFamily.medium,
  },
});

const userProfile = StyleSheet.create({
  alertBox: {
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#E5707E",
    color: "#ffffff",
  },
  linkCard: {
    // border:"1px solid #F2F2F2",
    padding: 10,
    height: "100%",
  },
  userGreeting: {
    fontSize: 14,
    lineHeight: 17,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  userName: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    marginTop: 6,
    marginBottom: 12,
  },
  userEmail: {
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  count: {
    fontSize: 22,
    lineHeight: 26,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  counterText: {
    fontSize: 12,
    lineHeight: 14,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "#414141",
  },
  counterTextLower: {
    fontSize: 10,
    lineHeight: 12,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "#414141",
  },
  heading: {
    fontSize: 14,
    lineHeight: 16.8,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    color: "#999999",
    paddingBottom: 5,
  },
  profileTitle: {
    padding: 10,
  },
  planName: {
    fontSize: 16,
    // lineHeight: 22,
    lineHeight: 24,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    color: "#000",
    textTransform: "uppercase",
    paddingBottom: 10,
  },
});

const popularStories = StyleSheet.create({
  container: {
    borderWidth: 0.5,
    borderColor: "black",
  },
  heading: {
    fontSize: 20,
    lineHeight: 29,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    color: "#000",
    textTransform: "uppercase",
    textAlign: "left",
  },
  headingDesktop: {
    fontSize: 26,
    lineHeight: 36,
  },
  viewAllLink: {
    fontFamily: FontFamily.regular,
    textAlign: "center",
    color: "rgb(252, 135, 109)",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: 0.5,
  },
  viewAllLinkDesktop: {
    fontSize: 18,
  },
  text: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.regular,
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 100,
    backgroundColor: "#6A6A6A",
    marginHorizontal: 10,
  },
  imageBlock: {
    backgroundColor: "white",
    shadowColor: "rgba(0,0,0,0.3)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 18,
    elevation: 1,
    padding: 5,
    width: "100%",
  },
  imageTitle: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
  },
  imageTitleDesktop: {
    fontSize: 19,
    lineHeight: 34,
    fontWeight: FontWeight.regular,
  },
  articleCount: {
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
  },
  excerpt: {
    fontSize: 12,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    lineHeight: 22,
    letterSpacing: 0.3,
    color: "#000000",
    marginTop: 15,
    textAlign: "center",
  },
  excerptDesktop: {
    fontSize: 16,
    lineHeight: 26,
    textAlign: "left",
  },
});

const pageContent = StyleSheet.create({
  subTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    marginHorizontal: 20,
    letterSpacing: 0.3,
    color: "#000000",
  },
  subTitleDesktop: {
    fontSize: 24,
    lineHeight: 40,
    marginHorizontal: "auto",
  },
  sectionTitle: {
    fontSize: 20,
    lineHeight: 28,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.bold,
    color: "#000000",
  },
  sectionTitleDesktop: {
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.bold,
    fontSize: 34,
    lineHeight: 40,
    marginRight: 25,
  },
  text: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 26,
    letterSpacing: 0.3,
    marginBottom: 30,
    color: "#000000",
  },
  textDesktop: {
    fontSize: 24,
    lineHeight: 40,
  },
  ListText: {
    fontFamily: FontFamily.merriweather,
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.5,
    marginBottom: 25,
    color: "#000000",
  },
  ListTextDesktop: {
    fontSize: 22,
    lineHeight: 30,
  },
  number: {
    fontSize: 32,
    lineHeight: 43,
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    color: "#000000",
  },
  numberDesktop: {
    fontSize: 60,
    lineHeight: 80,
  },
});

const bannerStyles = StyleSheet.create({
  heading: {
    fontFamily: FontFamily.regular,
    color: Colors.lightWhite,
  },
  text: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    color: Colors.lightWhite,
    fontSize: 16,
  },
});
const tweetsContainer = {
  topBorder: {
    borderTopColor: "#907cff",
    borderTopWidth: 7,
  },
};

const investorCard = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  wrap: {
    paddingLeft: 17.5,
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    shadowColor: "rgba(12, 0, 75, 0.12)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 16,
    elevation: 2,
    flex: 1,
    justifyContent: "center",
    backgroundColor: "white",
  },
  cardApp: {
    backgroundColor: "white",
    padding: 10,
    elevation: 3,
    shadowColor: "rgba(12, 0, 75, 0.12)",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },
  wrapBorder: {
    borderLeftColor: "#A97DF1",
    borderLeftWidth: 5,
    paddingVertical: 20,
  },
  title: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    letterSpacing: 0.3,
    marginBottom: 0,
    fontSize: 24,
    marginBottom: 0,
  },
  designation: {
    fontSize: 18,
    lineHeight: 22,
    color: "#6A6A6A",
  },
});

const unlockBoxPost = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 30,
    paddingVertical: 60,
    position: "relative",
    borderWidth: 2,
  },
  text: {
    color: "#000000",
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    textAlign: "center",
  },
  bottomIcon: {
    position: "absolute",
    bottom: 0,
    right: 20,
  },
  topBox: {
    backgroundColor: "#ffffff",
    width: 66,
    height: 66,
    position: "absolute",
    top: -35,
    left: 0,
    right: 0,
    paddingVertical: 5,
    paddingHorizontal: 5,
  },
  topBoxMobile: {
    backgroundColor: "#ffffff",
    width: 66,
    height: 66,
    paddingVertical: 5,
    paddingHorizontal: 5,
    position: "absolute",
    top: -35,
    right: 0,
    left: "50%",
    textAlign: "center",
    marginHorizontal: "auto",
  },
  topBoxInner: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

const banner = StyleSheet.create({
  Wrapper: {
    backgroundColor: "#FFF4F1",
  },
});

const orderData = StyleSheet.create({
  name: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: "#000000",
  },
  price: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: "#000000",
  },
  date: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "#000000",
    fontSize: 14,
    lineHeight: 16,
  },
  payMethod: {
    fontSize: 15,
    lineHeight: 17,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 0.4)",
    paddingBottom: 20,
  },
});

const newsletter = StyleSheet.create({
  title: {
    fontSize: 18,
    lineHeight: 27,
    letterSpacing: 0.3,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.black,
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: Colors.black,
  },
  linkText: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 14,
    lineHeight: 17,
    textDecorationLine: "underline",
    textTransform: "uppercase",
    color: "#FC876D",
  },
  lineBreak: {
    width: 70,
    height: 1,
    backgroundColor: "#907CFF",
  },
  lineBreakwrite: {
    width: 120,
    height: 2,
    backgroundColor: "#000000",
  },
  wrapper: {
    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 1,
    paddingVertical: 30,
    marginHorizontal: 20,
  },
  appWrapper: {
    backgroundColor: "white",
    paddingVertical: 30,
    elevation: 4,
    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 6,
    marginHorizontal: 20,
  },
});

const editor = StyleSheet.create({
  title: {
    fontFamily: FontFamily.abrilFatface,
    marginBottom: 20,
  },
  designation: {
    color: "#6A6A6A",
    marginBottom: 20,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.3,
  },
  description: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.1,
  },
});

const accordion = StyleSheet.create({
  title: {
    paddingVertical: 15,
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: FontWeight.regular,
    fontFamily: FontFamily.regular,
  },
  titleActive: {
    paddingVertical: 15,
    color: "#000000",
    fontWeight: "600",
    fontFamily: helpers.isWeb() ? FontFamily.medium : FontFamily.bold,
  },
  content: {
    color: "#000000",
    fontWeight: "300",
    fontFamily: FontFamily.merriweather,
    paddingBottom: 15,
  },
});

const forgotPassword = StyleSheet.create({
  title: {
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.5,
  },
  text: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    color: "rgba(0, 0, 0, 0.7)",
  },
  link: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    color: "#FC876D",
    textDecorationLine: "underline",
  },
});

const list = StyleSheet.create({
  Wrap: {
    paddingHorizontal: 20,
  },
  item: {
    marginBottom: 30,
    paddingLeft: 0,
    flexDirection: "row",
  },
  Icon: {
    width: 5,
    height: 5,
    backgroundColor: "#000000",
    borderRadius: 10,
    marginRight: 20,
    marginTop: 9,
  },
  Text: {
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.5,
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    flex: 1,
  },
  TextDesktop: {
    fontSize: 20,
    lineHeight: 32,
    letterSpacing: 0.5,
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
  },
});

const teamSubscription = StyleSheet.create({
  value: {
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    fontSize: 30,
    lineHeight: 41,
    textAlign: "center",
    color: "#000000",
  },
  valueDesktop: {
    fontSize: 60,
    lineHeight: 81,
  },
  value1: {
    fontFamily: FontFamily.regular,
    fontWeight: "700",
    fontSize: 24,
    lineHeight: 31,
    color: "#000000",
  },
  valueDesktop1: {
    fontSize: 40,
    lineHeight: 64,
  },
  text: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 32,
    textAlign: "center",
    letterSpacing: 0.5,
    color: "rgba(0, 0, 0, 0.4)",
  },
  textDesktop: {
    fontSize: 20,
    lineHeight: 26,
  },
  googleText: {
    fontFamily: FontFamily.abrilFatface,
    fontWeight: FontWeight.regular,
    fontSize: 24,
    lineHeight: 32,
    alignItems: "center",
    color: "#000000",
  },
  googleTextDesktop: {
    fontSize: 34,
    lineHeight: 41,
  },
  planText: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: 26,
    color: "#8A8A8A",
  },
  planTextDesktop: {
    fontSize: 22,
    lineHeight: 44,
  },
  planValue: {
    fontFamily: FontFamily.merriweatherB,
    fontSize: 16,
    lineHeight: 26,
    color: "#4D4D4D",
  },
  planValueDesktop: {
    fontSize: 22,
    lineHeight: 44,
  },
  tableHead: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    fontSize: 14,
    lineHeight: 19,
    color: "#6A6A6A",
  },
  tableHeadDesktop: {
    fontSize: 16,
    lineHeight: 19,
  },
  tableValue: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    fontSize: 14,
    lineHeight: 19,
    color: "#000000",
    flex: 0.3,
  },
  tableValueDesktop: {
    fontSize: 22,
    lineHeight: 26,
  },
  addMemberText: {
    fontFamily: FontFamily.regular,
    fontSize: 16,
    lineHeight: 19,
    color: "rgba(0, 0, 0, 0.5)",
  },
  title: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.medium,
    color: "#000000",
  },
  noAccessText: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.medium,
    color: "rgba(0, 0, 0, 0.9)",
  },
  link: {
    color: "#FC876D",
    textDecorationLine: "underline",
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.3,
  },
  buttons: {
    fontSize: 14,
    lineHeight: 19,
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
  },
  wrapper: {
    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 6,
    elevation: 1,
    position: helpers.isWeb() ? "fixed" : "absolute",
    left: 0,
    right: 0,
    bottom: helpers.isWeb() ? 0 : 0,
    backgroundColor: "white",
    paddingHorizontal: 25,
    paddingVertical: 10,
  },
  arrow: {
    position: "absolute",
    right: 4,
    top: "40%",
    backgroundColor: "white",
    paddingRight: 8,
  },
  pickerStyles: {
    borderWidth: 1,
    borderColor: "#55C79A",
    paddingVertical: 4,
    paddingHorizontal: 15,
    fontSize: 17,
    lineHeight: 27,
    fontFamily: FontFamily.regular,
    fontWeight: "600",
    color: "#55C79A",
    height: 40,
    backgroundColor: "transparent",
  },
  pickerStylesDebit: {
    borderColor: "#FC876D",
    color: "#FC876D",
  },
});

const darkModeSwitch = StyleSheet.create({
  blockWrapper: {
    backgroundColor: "#012137",
    borderRadius: 4,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  blockText: {
    fontFamily: FontFamily.medium,
    fontWeight: FontWeight.semiBold,
    letterSpacing: 0.3,
    color: "rgba(255, 255, 255, 0.89)",
    marginBottom: 5,
  },
  blockSubText: {
    fontFamily: FontFamily.merriweather,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.3,
    color: "rgba(255, 255, 255, 0.89)",
  },
  textBlock: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.regular,
    letterSpacing: 0.3,
    color: "rgba(255, 255, 255, 0.89)",
  },
  close: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    letterSpacing: 0.3,
    color: "rgba(255, 255, 255, 0.6)",
  },
  enable: {
    fontFamily: FontFamily.regular,
    fontWeight: FontWeight.semiBold,
    letterSpacing: 0.3,
    color: "#FC876D",
  },
});

export {
  Colors,
  activenews,
  FontFamily,
  Widgets,
  Flex,
  postSingle,
  Padding,
  Margin,
  Helpers,
  postContentClasses,
  postContentClassesDarkMode,
  postContentTags,
  postContentTagsDarkMode,
  PostList,
  postExcerptTags,
  postExcerptTagsDarkMode,
  postExcerptClasses,
  postExcerptClassesDarkMode,
  postTags,
  Typo,
  subscriptionInfo,
  formStyle,
  button,
  subscribePlans,
  testimonialStyl,
  unlockBox,
  appHeader,
  aboutAuthor,
  Badges,
  articleLists,
  noteDiscloures,
  appStyles,
  postActionBar,
  actionBox,
  postNoteTags,
  postNoteTagsDarkMode,
  postNoteClasses,
  postNoteClassesDarkMode,
  signUpPage,
  referralSection,
  Messages,
  joinUsInfo,
  exclusiveContent,
  sliderStyle,
  article,
  pageCover,
  featuredArticle,
  articleItem,
  authorItem,
  writerSingle,
  welcomeStyle,
  modalStyle,
  userAccount,
  input,
  FontWeight,
  stickyFooter,
  wygSection,
  readers,
  accessContent,
  textBlock,
  articleLoader,
  giftCard,
  siteFooter,
  articleItemHorizontal,
  paymentStatusMsg,
  userProfile,
  popularStories,
  homeCover,
  tweetsContainer,
  pageContent,
  investorCard,
  unlockBoxPost,
  bannerStyles,
  banner,
  orderData,
  newsletter,
  editor,
  accordion,
  forgotPassword,
  list,
  teamSubscription,
  dynamicPageClasses,
  dynamicPageClassesDarkMode,
  dynamicPageTags,
  darkModeSwitch,
  sliderstyles,
  dynamicPageTagsDarkMode,
  YesterdayModule,
};
