import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  postActionBar,
  articleItemHorizontal,
  FontFamily,
  articleLists,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";
import PremiumTag from "../premium-tag";

const likeIcon = getImagePath("icons/like-icon.png");
const likeIconSelected = getImagePath("icons/like-icon-selected.png");
const bookmarkIcon = getImagePath("icons/bookmark.png");
const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");
// const premiumTag = getImagePath("img/premium-tag.png");

export class ArticleTopStories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeSecondAuthor: false,
    };
    this.isUserSubscriptionActive = localStorage.getItem(
      "isUserSubscriptionActive"
    );
    this.userSubscription = localStorage.getItem("userSubscription");
  }

  componentDidMount = () => { };

  handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.data.title);
    }
  };

  render() {
    const {
      single,
      freeUser,
      all,
      home,
      mainIndex,
      data,
      viewArticle,
      viewAuthor,
      userStoryAction,
      applyStoryActions,
      userCredits,
      writers,
      archive,
      hometrendingstory,
      hideDate,
      darkMode,
    } = this.props;

    let btnClick,
      authorBtnClick,
      author2BtnClick,
      btnClickBookmark,
      btnClickLike;
    if (helpers.isWeb()) {
      btnClick = {
        to: {
          pathname: "/" + data.category.slug + "/" + data.slug,
          postData: data,
        },
      };
      authorBtnClick = {
        to: "/writers/" + data.author[0].slug,
      };
      if (data.author[1]) {
        author2BtnClick = {
          to: "/writers/" + data.author[1].slug,
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
    } else {
      btnClick = {
        onPress: () => viewArticle(data.category.slug, data.slug, data),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author[0].slug);
        },
      };
      if (data.author[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
    }
    let btnCategoryClick = {
      to: "/category/" + data.category.slug,
    };
    return (
      <View style={[helpers.isDesktop() && { marginRight: 20 }]}>
        {/* <TouchableOpacityLink
                    {...btnClick} handleClick={() => this.handleClick()}> */}
        <View style={[writers && Margin.mb_2]}>
          {userCredits && (
            <View style={[articleItemHorizontal.wrapAbove]}>
              <Text
                style={[
                  articleItemHorizontal.dateTop,
                  darkMode && { color: "rgba(255,255,255,0.6)" },
                ]}
              >
                {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
              </Text>
            </View>
          )}

          <View
            style={[
              helpers.isDesktop() && !all && { flexDirection: "column" },
              helpers.isDesktop() && { marginHorizontal: all ? 40 : 20 },
            ]}
          >
            <View
              style={[
                all ? articleItemHorizontal.wrap : articleItemHorizontal.noWrap,
                !helpers.isDesktop() && { alignItems: "flex-start" },
                userCredits && { paddingTop: 0 },
                home && { paddingTop: 0 },
              ]}
            >
              {!all && (
                <TouchableOpacityLink
                  {...btnCategoryClick}
                  style={{ marginBottom: 10 }}
                >
                  <Text
                    style={[
                      articleLists.badge,
                      {
                        width: 150,
                        marginBottom: helpers.isDesktop() ? 15 : 10,
                        marginLeft: helpers.isDesktop() ? 0 : 20,
                        marginTop: helpers.isDesktop() ? 0 : 40,
                        textAlign: "left",
                        fontFamily: FontFamily.regular,
                        color:
                          data.category.name === "Business"
                            ? "#2CB680"
                            : data.category.name === "Chaos"
                              ? "#907cff"
                              : data.category.name === "Internet"
                                ? "#FC876D"
                                : "#ECB731",
                        fontSize: helpers.isDesktop() ? 18 : 13,
                        lineHeight: helpers.isDesktop() ? 22 : 16,
                        letterSpacing: helpers.isDesktop() ? 1 : 0.5,
                        fontFamily: 'HaasGrotesk-Medium',
                      },
                      !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                      darkMode && { color: "#5263F4" },
                    ]}
                  >
                    {data.category.name}
                  </Text>
                </TouchableOpacityLink>
              )}
              <TouchableOpacityLink
                style={
                  !helpers.isDesktop()
                    ? {
                      width: "100%",
                      maxWidth: "500px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }
                    : null
                }
                {...btnClick}
                handleClick={() => this.handleClick()}
              >
                <View style={helpers.isDesktop() && !all && Flex.row}>
                  {!writers ? (
                    <View style={[Margin.mb_0]}>
                      {all ? (
                        <View>
                          <CustomImage
                            source={data.featured_image.url}
                            require={false}
                            style={{ width: 150, height: 100 }}
                            webStyle={{
                              maxWidth: "100%",
                              width: helpers.isDesktop() ? 485 : 350,
                              height: home
                                ? helpers.isDesktop()
                                  ? 441
                                  : 200
                                : helpers.isDesktop()
                                  ? 441
                                  : 71,
                              objectFit: "cover",
                              marginBottom: helpers.isDesktop() ? 24 : 12,
                              marginLeft: helpers.isDesktop() ? "0px" : 0,
                            }}
                            resizeMode={"cover"}
                            altText={
                              data.featured_image.alt &&
                                data.featured_image.alt != ""
                                ? data.featured_image.alt
                                : data.title
                                  ? data.title
                                  : data.story?.title
                                    ? data.story.title
                                    : data.featured_image.title
                            }
                          />
                          {/* {(this.isUserSubscriptionActive == "0" ||
                            this.isUserSubscriptionActive == null) && (
                            <>{!data.is_free && <PremiumTag />}</>
                          )} */}
                          {/* {freeUser &&
                                                        <CustomImage
                                                            source={require("../../../../assets/img/premium-tag.png")}
                                                            require={false}
                                                            style={{ width: 140, height: 30 }}
                                                            webStyle={{
                                                                position: 'absolute', width: helpers.isDesktop() ? 140 : 100, height: home ? (helpers.isDesktop() ? 30 : 20) : helpers.isDesktop() ? 441 : 71, left: 642,
                                                                top: 15, objectFit: 'cover'
                                                            }}
                                                            resizeMode={'cover'}
                                                            altText={(data.featured_image.alt && data.featured_image.alt != '') ? data.featured_image.alt : (data.title ? data.title : (data.story?.title ? data.story.title : data.featured_image.title))}
                                                        />
                                                    } */}
                        </View>
                      ) : !all ? (
                        <View>
                          <CustomImage
                            source={data.featured_image.url}
                            require={false}
                            style={{
                              width: Dimensions.get("window").width,
                              height: 180,
                            }}
                            webStyle={{
                              width: helpers.isDesktop() ? 350 : "100%",
                              height: home
                                ? helpers.isDesktop()
                                  ? 200
                                  : "auto"
                                : helpers.isDesktop()
                                  ? 200
                                  : 71,
                              objectFit: "cover",
                            }}
                            resizeMode={"cover"}
                            altText={
                              data.featured_image.alt &&
                                data.featured_image.alt != ""
                                ? data.featured_image.alt
                                : data.title
                                  ? data.title
                                  : data.story?.title
                                    ? data.story.title
                                    : data.featured_image.title
                            }
                          />
                          {/* {freeUser &&

                                                            <CustomImage
                                                                source={require("../../../../assets/img/premium-tag.png")}
                                                                require={false}
                                                                style={{ width: 140, height: 30 }}
                                                                webStyle={{
                                                                    position: 'absolute', left:helpers.isDesktop() ?230:270, top: 15, width: helpers.isDesktop() ? 120 : 80, height: home ? (helpers.isDesktop() ? 25 : 18) : helpers.isDesktop() ? 441 : 71,
                                                                    objectFit: 'cover'
                                                                }}
                                                                resizeMode={'cover'}
                                                                altText={(data.featured_image.alt && data.featured_image.alt != '') ? data.featured_image.alt : (data.title ? data.title : (data.story?.title ? data.story.title : data.featured_image.title))}
                                                            />
                                                        } */}
                          {/* {(this.isUserSubscriptionActive == "0" ||
                            this.isUserSubscriptionActive == null) && (
                              <>{!data.is_free && <PremiumTag />}</>
                            )} */}
                        </View>
                      ) : (
                        <View>
                          <CustomImage
                            source={data.featured_image.url}
                            require={false}
                            style={{ width: 150, height: 100 }}
                            webStyle={{
                              width: helpers.isDesktop() ? 300 : 150,
                              height: home
                                ? helpers.isDesktop()
                                  ? 200
                                  : 100
                                : helpers.isDesktop()
                                  ? 180
                                  : 71,
                              objectFit: "cover",
                            }}
                            resizeMode={"cover"}
                            altText={
                              data.featured_image.alt &&
                                data.featured_image.alt != ""
                                ? data.featured_image.alt
                                : data.title
                                  ? data.title
                                  : data.story?.title
                                    ? data.story.title
                                    : data.featured_image.title
                            }
                          />
                          {/* {freeUser &&

                                                            <CustomImage
                                                                source={require("../../../../assets/img/premium-tag.png")}
                                                                require={false}
                                                                style={{ width: 140, height: 30 }}
                                                                webStyle={{
                                                                    position: 'absolute', left: helpers.isDesktop?230:270, top: 15, width: helpers.isDesktop() ? 120 : 80, height: home ? (helpers.isDesktop() ? 25 : 18) : helpers.isDesktop() ? 441 : 71,
                                                                    objectFit: 'cover'
                                                                }}
                                                                resizeMode={'cover'}
                                                                altText={(data.featured_image.alt && data.featured_image.alt != '') ? data.featured_image.alt : (data.title ? data.title : (data.story?.title ? data.story.title : data.featured_image.title))}
                                                            />
                                                        } */}
                          {/* {(this.isUserSubscriptionActive == "0" ||
                            this.isUserSubscriptionActive == null) && (
                              <>{!data.is_free && <PremiumTag />}</>
                            )} */}
                        </View>
                      )}
                    </View>
                  ) : (
                    <View style={[Margin.mb_0]}>
                      <CustomImage
                        source={data.featured_image.url}
                        require={false}
                        style={{ width: 150, height: 100 }}
                        webStyle={{
                          width: helpers.isDesktop() ? 300 : 125,
                          height: helpers.isDesktop() ? 168 : 71,
                          objectFit: "cover",
                        }}
                        resizeMode={"cover"}
                        altText={
                          data.featured_image.alt &&
                            data.featured_image.alt != ""
                            ? data.featured_image.alt
                            : data.title
                              ? data.title
                              : data.story?.title
                                ? data.story.title
                                : data.featured_image.title
                        }
                      />
                    </View>
                  )}
                  {userStoryAction && (
                    <View style={[Flex.row, Margin.mt_1]}>
                      <TouchableOpacity
                        {...btnClickLike}
                        style={[postActionBar.actionBtn, { marginRight: 3 }]}
                      >
                        <CustomImage
                          source={
                            data.user_story_action &&
                              data.user_story_action.like
                              ? likeIconSelected
                              : likeIcon
                          }
                          require={true}
                          style={{ width: 15, height: 13 }}
                          webStyle={{ width: 15, height: 13 }}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        {...btnClickBookmark}
                        style={[postActionBar.actionBtn]}
                      >
                        <CustomImage
                          source={
                            data.user_story_action &&
                              data.user_story_action.bookmark
                              ? bookmarkIconSelected
                              : bookmarkIcon
                          }
                          require={true}
                          style={{ width: 20, height: 20 }}
                          webStyle={{
                            width: helpers.isDesktop() ? 26 : 21,
                            height: helpers.isDesktop() ? 25 : "auto",
                            marginLeft: -5,
                            marginRight: -5,
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </TouchableOpacityLink>
              <View
                style={[
                  !all && { flexDirection: "row" },
                  helpers.isDesktop() && all && articleItemHorizontal.colRight,
                  all && Padding.pl_2,
                  { maxWidth: all ? 332 : helpers.isDesktop() ? 350 : 500 },
                  !helpers.isDesktop() && {
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                  !helpers.isDesktop() && { paddingRight: 20, paddingLeft: 20 },
                  Flex.column,
                ]}
              >
                {all && (
                  <TouchableOpacityLink {...btnCategoryClick}>
                    <Text
                      style={[
                        helpers.isDesktop()
                          ? articleLists.badge
                          : articleLists.badgeMobile,
                        {
                          width: 150,
                          marginBottom: helpers.isDesktop() ? 15 : 10,
                          textAlign: "left",
                          fontFamily: FontFamily.regular,
                          color:
                            data.category.name === "Business"
                              ? "#2CB680"
                              : data.category.name === "Chaos"
                                ? "#907cff"
                                : data.category.name === "Internet"
                                  ? "#FC876D"
                                  : "#ECB731",
                          fontSize: helpers.isDesktop() ? 18 : 13,
                          lineHeight: helpers.isDesktop() ? 22 : 16,
                          letterSpacing: helpers.isDesktop() ? 1 : 0.5,
                          fontFamily: 'HaasGrotesk-Medium',
                        },
                        !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                        darkMode && { color: "#5263F4" },
                      ]}
                    >
                      {data.category.name}
                    </Text>
                  </TouchableOpacityLink>
                )}
                <br />
                <TouchableOpacityLink
                  style={{
                    marginTop: "-5px",
                    marginLeft: helpers.isDesktop() ? 0 : 0,
                  }}
                  {...btnClick}
                  handleClick={() => this.handleClick()}
                >
                  <Text
                    style={[
                      articleItemHorizontal.articleTitle,
                      Margin.mb_1,
                      !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                      home && {
                        fontWeight: "400",
                        fontSize: helpers.isDesktop() ? 30 : 16,
                        lineHeight: helpers.isDesktop() ? 39 : 22,
                        fontFamily: FontFamily.abrilFatface,
                      },
                      !helpers.isDesktop() && { fontSize: 26 },
                      !helpers.isDesktop() && { lineHeight: 38 },
                      writers && {
                        fontSize: helpers.isDesktop() ? 26 : 16,
                        lineHeight: helpers.isDesktop() ? 34 : 22,
                      },
                      !helpers.isWeb() && { fontFamily: FontFamily.medium },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {data.title}
                  </Text>
                  <View></View>
                  <br />
                  <Text
                    style={[
                      articleItemHorizontal.articleTitle,
                      Margin.mb_1,
                      !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                      home && {
                        fontWeight: "400",
                        fontSize: helpers.isDesktop() ? 18 : 12,
                        fontFamily: FontFamily.regular,
                        letterSpacing: 0.03,
                        lineHeight: helpers.isDesktop() ? 27 : 22,
                      },
                      !helpers.isDesktop() && { fontSize: 14 },
                      !helpers.isDesktop() && { lineHeight: 22 },
                      writers && {
                        fontSize: helpers.isDesktop() ? 18 : 12,
                        lineHeight: helpers.isDesktop() ? 34 : 22,
                      },
                      !helpers.isWeb() && { fontFamily: FontFamily.bold },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {data.strapline}
                  </Text>
                </TouchableOpacityLink>
                {userCredits ? (
                  <>
                    {data.credits && (
                      <View style={[Flex.row]}>
                        <Text style={postSingle.creditBox}>
                          {data.credits.unlock} Credits
                        </Text>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {/* {(!home && helpers.isDesktop()) &&
                                                <Text style={[articleItemHorizontal.desc, helpers.isDesktop() && articleItemHorizontal.descDesktop, writers && { fontSize: 14, lineHeight: 18 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>{(data.strapline != '') ? data.strapline : helpers.trimWord(data.excerpt)}</Text>
                                            } */}
                    <View
                      style={{
                        width: "100px",
                        height: 1,
                        backgroundColor: "#907CFF",
                        marginTop: "16px",
                        marginBottom: "25px",
                        marginLeft: helpers.isDesktop() ? 0 : 0,
                      }}
                    ></View>
                    <View
                      style={[
                        {
                          marginLeft: helpers.isDesktop() ? 0 : 0,
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginTop: 0,
                          alignItems: "baseline",
                        },
                        Platform.OS == "ios" && { alignItems: "center" },
                      ]}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          flexShrink: 1,
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        <TouchableOpacityLink {...authorBtnClick}>
                          <View
                            style={[
                              Flex.row,
                              Flex.alignCenter,
                              { marginRight: 15 },
                            ]}
                          >
                            <View
                              style={{
                                width: 40,
                                height: 40,
                                overflow: "hidden",
                                marginRight: 10,
                                borderRadius: 40,
                                padding: 3,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#FC876D",
                              }}
                            >
                              <View
                                style={{
                                  width: 34,
                                  height: 34,
                                  overflow: "hidden",
                                  borderRadius: 36,
                                }}
                              >
                                {
                                  <Image
                                    style={{
                                      height: 34,
                                      width: 34,
                                      borderRadius: 20,
                                    }}
                                    source={{
                                      uri: data.author[0].profile_image,
                                    }}
                                  />
                                }
                              </View>
                            </View>
                            <Text
                              style={[
                                {
                                  fontWeight: "400",
                                  fontSize: helpers.isDesktop() ? 16 : 14,
                                  lineHeight: helpers.isDesktop() ? 19 : 22,
                                },
                                darkMode && { color: "rgba(255,255,255,0.87)" },
                                { fontFamily: FontFamily.regular },
                              ]}
                            >
                              {data.author[0].name}
                            </Text>
                          </View>
                        </TouchableOpacityLink>

                        <TouchableOpacityLink {...author2BtnClick}>
                          {this.state.seeSecondAuthor && data.author[1] && (
                            <View
                              style={[
                                Flex.row,
                                Flex.alignCenter,
                                { marginRight: 15 },
                              ]}
                            >
                              <View
                                style={{
                                  width: 40,
                                  height: 40,
                                  overflow: "hidden",
                                  marginRight: 10,
                                  borderRadius: 40,
                                  padding: 3,
                                  borderStyle: "solid",
                                  borderWidth: 1,
                                  borderColor: "#FC876D",
                                }}
                              >
                                <View
                                  style={{
                                    width: 34,
                                    height: 34,
                                    overflow: "hidden",
                                    borderRadius: 36,
                                  }}
                                >
                                  <CustomImage
                                    source={data.author[1].profile_image}
                                    require={true}
                                  />
                                </View>
                              </View>
                              <Text
                                style={[
                                  {
                                    fontWeight: "400",
                                    fontSize: helpers.isDesktop() ? 16 : 16,
                                    lineHeight: helpers.isDesktop() ? 19 : 22,
                                  },
                                  darkMode && {
                                    color: "rgba(255,255,255,0.87)",
                                  },
                                  { fontFamily: FontFamily.regular },
                                ]}
                              >
                                {data.author[1].name}
                              </Text>
                            </View>
                          )}
                        </TouchableOpacityLink>

                        {data.author.length > 1 && !this.state.seeSecondAuthor && (
                          <TouchableOpacity
                            onPress={() =>
                              this.setState({ seeSecondAuthor: true })
                            }
                          >
                            {helpers.isWeb() ? (
                              <View
                                style={[
                                  {
                                    borderStyle: "solid",
                                    fontFamily: FontFamily.regular,
                                    fontSize: helpers.isDesktop() ? 16 : 16,
                                    lineHeight: helpers.isDesktop() ? 19 : 22,
                                    borderWidth: 1,
                                    backgroundColor: "#F3F5FF",
                                    borderColor: "#907CFF",
                                    width: 28,
                                    height: 28,
                                    borderRadius: 28,
                                    marginRight: 5,
                                  },
                                  Flex.alignCenter,
                                  Flex.row,
                                  Flex.justfiyCenter,
                                ]}
                              >
                                +1
                              </View>
                            ) : (
                              <Text
                                style={[
                                  {
                                    borderStyle: "solid",
                                    fontFamily: FontFamily.regular,
                                    fontSize: helpers.isDesktop() ? 16 : 16,
                                    lineHeight: helpers.isDesktop() ? 19 : 22,
                                    borderWidth: 1,
                                    backgroundColor: "#F3F5FF",
                                    borderColor: "#907CFF",
                                    width: 28,
                                    height: 28,
                                    borderRadius: 28,
                                    marginRight: 5,
                                  },
                                  Flex.alignCenter,
                                  Flex.row,
                                  Flex.justfiyCenter,
                                ]}
                              >
                                +1
                              </Text>
                            )}
                          </TouchableOpacity>
                        )}
                        {!hideDate && (
                          <Text
                            style={[
                              articleItemHorizontal.articleDate,
                              helpers.isDesktop() &&
                              articleItemHorizontal.articleDateDesktop,
                              !helpers.isWeb() &&
                              articleItemHorizontal.articleDateApp,
                              writers && {
                                fontSize: helpers.isDesktop() ? 14 : 10,
                                lineHeight: helpers.isDesktop() ? 19 : 12,
                              },
                              home &&
                              !helpers.isDesktop() && {
                                fontSize: 11,
                                lineHeight: 13,
                              },
                              darkMode && { color: "rgba(255,255,255,0.6)" },
                            ]}
                          >
                            {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format(
                              "DD MMMM, YYYY"
                            )}
                          </Text>
                        )}

                        {/* {!hideDate && (
                          <View
                            style={[
                              articleItemHorizontal.dot,
                              helpers.isDesktop() &&
                              articleItemHorizontal.dotDesktop,
                              home && {
                                marginTop: 2,
                                position: "relative",
                                top: helpers.isDesktop() ? -2.7 : -2,
                              },
                              !helpers.isWeb() && { top: -4, left: 0, top: -5 },
                            ]}
                          ></View>
                        )} */}
                      </View>
                    </View>
                    {/* {!hideDate && <Text style={[articleItemHorizontal.articleDate, helpers.isDesktop() && articleItemHorizontal.articleDateDesktop, !helpers.isWeb() && articleItemHorizontal.articleDateApp, writers && { fontSize: helpers.isDesktop() ? 14 : 10, lineHeight: helpers.isDesktop() ? 19 : 12 }, home && !helpers.isDesktop() && { fontSize: 11, lineHeight: 13 }, darkMode && { color: 'rgba(255,255,255,0.6)' }]}>{moment(data.published_date).format("DD MMMM, YYYY")}</Text>} */}
                  </>
                )}
              </View>
            </View>
          </View>
        </View>
        {/* </TouchableOpacityLink> */}
      </View>
    );
  }
}

export default ArticleTopStories;
