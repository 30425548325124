import React, { Component } from "react";
import { View } from "react-native";
import ReactHtmlParser from "react-html-parser";
import RelatedStoryNudge from "../related-story-nudge";

export class PostContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { data, darkMode, dynamicPage, relatedNudgeData, storyType } = this.props;
    console.log("Debugging post-content: >", relatedNudgeData)
    const briefPostData = relatedNudgeData && relatedNudgeData["short_posts"] ? relatedNudgeData["short_posts"][relatedNudgeData["short_posts"].length - 1] : null
    const yesterdayPostData = relatedNudgeData && relatedNudgeData["yesterday_posts"] ? relatedNudgeData["yesterday_posts"][relatedNudgeData["yesterday_posts"].length - 1] : null
    const longreadPostData = relatedNudgeData && relatedNudgeData["regular_posts"] ? relatedNudgeData["regular_posts"][relatedNudgeData["regular_posts"].length - 1] : null

    // Split the content by paragraphs
    let paragraphs = data.split(/(<p[^>]*>.*?<\/p>)/g);

    // Insert RelatedStoryNudge after the 2nd and 4th paragraphs
    if (paragraphs.length > 8) {
      paragraphs.splice(8, 0, '<div id="nudge4"></div>');
    }
    if (paragraphs.length > 4) {
      paragraphs.splice(4, 0, '<div id="nudge2"></div>');
    }

    // Combine the paragraphs back into a single HTML string
    const modifiedData = paragraphs.join('');

    return (
      <View style={{ flex: 1 }}>
        <div
          className={
            "postsingle-content postsingle-bodyfont" +
            (darkMode ? " dark-mode" : "") +
            (dynamicPage ? " postsingle-dynamic-page-content" : "")
          }
        >
          {ReactHtmlParser(modifiedData, {
            transform: (node) => {
              if (node.name === "div" && node.attribs && node.attribs.id === "nudge2") {
                console.log("Debugging post-content explainer: ", yesterdayPostData)
                return storyType === "longread" && yesterdayPostData ? <RelatedStoryNudge {...yesterdayPostData} type={"NEWS EXPLAINER"} key="nudge2" /> 
                :
                storyType === "explainer" && longreadPostData ? <RelatedStoryNudge {...longreadPostData} type={"LONGREAD"} key="nudge2" />   : 
                null;
              }
              if (node.name === "div" && node.attribs && node.attribs.id === "nudge4") {
                console.log("Debugging post-content brief: ", briefPostData)
                return briefPostData ? <RelatedStoryNudge {...briefPostData} type={"NEWS BRIEF"} key="nudge4" /> : null;
              }
              return undefined;
            },
          })}
        </div>
      </View>
    );
  }
}

export default PostContent;

