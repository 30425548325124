import React, { Component } from "react";
import {
  ScrollView,
  Text,
  View,
  findNodeHandle,
  ActivityIndicator,
  Modal,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
  TextInput,
  Linking
} from "react-native";
import ContentLoader from "react-native-easy-content-loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppFooter from "../../components/app-footer";
import AppHeader from "../../components/app-header";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import Banner from "../../components/banner";
import HomeArchievedContent from "../../components/home-archived-content/index-pricing";
import WritersSlider from "../../components/home-news-letters/WritersSlider";
import HomeReadersQuote from "../../components/home-readers-quote";
import OurJournalism from "../../components/ourJournalism";
import PageBreak from "../../components/page-break";
import Seo from "../../components/seo-meta";
import ViewPlans from "../../components/view-plans";
import SubscriptionPricingPlan from "../../components/subscription-pricing-plan";
import WhyTMCSection from "../../components/why-choose-tmc";
import HomePopularCategories from "../../components/home-popular-categories";
import ThankYouComponent from "../../components/customer-thank-you";
import * as Yup from "yup";

import {
  appStyles,
  article,
  button,
  Colors,
  Flex,
  FontFamily,
  Helpers,
  Margin,
  Padding,
  pageContent,
  Typo,
} from "../../styles/appStyles";
import apiHelpers from "../../utils/api-helpers";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import { Mixpanel } from "../../utils/mixpanel";
import seoObj from "../../utils/seo-helper";
import moment, { months } from "moment";
import CustomImage from "../../components/custom-image";
import { BENEFITS_OF_READING_TMC, COLLEGES_THAT_READ_US, FREQUENTLY_ASKED_QUESTIONS, PRICING_PLANS, TMC_PLANS, WHAT_OUR_READERS_SAY, WHO_READS_TMC } from "../../utils/constants";
import Button from "../../components/button";
import Swiper from "react-native-web-swiper";
import VimeoVideo from "../../components/vimeo-video";
import CheckoutModal from "../../components/checkout-modal/index.web";
import Vimeo from 'react-vimeo';
import { ChargebeeController } from "../../utils/chargebee";
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import { TestimonialCarousel } from "../../components/testimonial-carousel/index.web";
import { PhoneNumberUtil } from "google-libphonenumber";
import CustomPicker from '../../components/custom-picker/index'
import CustomCountryPicker from '../../components/custom-country-picker/index'
import ButtonGradient from "../../components/button-gradient";
import { Formik } from 'formik'
import { stubTrue } from "lodash";

const { width } = Dimensions.get('window');

const phoneUtil = PhoneNumberUtil.getInstance();



// import './vimeoStyles.css'


// const SubscriptionPricingPlan = React.lazy(() =>
//   import("../../components/subscription-pricing-plan")
// );

const cookies = new Cookies();
const elementBG = getImagePath("img/welcome_bg.png");
const laptop = getImagePath("img/laptop.png");
const checkIcon = getImagePath("icons/check.png");
const heroBannerPricing = getImagePath("img/hero-banner-pricing.png");
const greenTick = getImagePath("icons/green-tick.png");
const whySubscribeBg = getImagePath("img/why-subscribe-bg.png");
const spotifyLogo = getImagePath("img/spotify-img.png");
const corporateImg = getImagePath("img/corporate-img.png");
const studentImg = getImagePath("img/student-img.png");
const startReadingIcon = getImagePath("icons/start-reading.png");
const purchaseSeatsIcon = getImagePath("icons/purchase-seat.png");
const inviteMembersCorporateIcon = getImagePath("icons/invite-members-corporate.png");
const calloutIllustration = getImagePath("img/student-callout.png");
const teacherUniversityPlan = getImagePath("img/teacher-university-plan.png");
const friendsOfTMC = getImagePath("img/friends-of-tmc.png");
const pressFreedomIndex = getImagePath("img/press-freedom-index.jpg");
const tmcSupporters = getImagePath("img/tmc-supporters.png");
const iimAhmedabadLogo = getImagePath("icons/iim-ahmedabad-logo.png");
const iimAmritsarLogo = getImagePath("icons/iim-amritsar-logo.png");
const iimKozikhodeLogo = getImagePath("icons/iim-kozikhode-logo.png");
const iimIndore = getImagePath("icons/iim-indore-logo.png");
const isbLogo = getImagePath("icons/isb-logo.png");
const iitDelhiLogo = getImagePath("icons/iit-delhi-logo.png");
const corporateImgMob = getImagePath("img/corporate-img-mob.png");
const studentImgMob = getImagePath("img/student-img-mob.png");
const teacherImgMob = getImagePath("img/teacher-img-mob.png");
const friendsOfTMCImgMob = getImagePath("img/friends-of-tmc-img-mob.png");
const studentRefundMob = getImagePath("img/student-refund-mob.png");

const videoId = "889599819";
const videoIdUrl =
  "https://player.vimeo.com/video/889599819";

const pricingHeroTopMob = getImagePath("img/pricing-hero-top-mob.png")
const pricingHeroBottomMob = getImagePath("img/pricing-hero-bottom-mob.png")
const pricingHeroBgMob = getImagePath("img/pricing-hero-bg-mob.png")
const faqIconClosed = getImagePath("icons/faq-icon.svg")
const faqIconOpened = getImagePath("icons/faq-icon-opened.svg")
const heroBannerMob = getImagePath("img/hero-banner-mob.png")
const lockIcon = getImagePath("icons/lock-icon.svg")
const lockIconDark = getImagePath("icons/lock-icon-dark.svg")
const darkTick = getImagePath("icons/dark-tick.svg")
const upgradeHeroBanner = getImagePath("img/upgrade-hero-banner.png")
const upgradeHeroBannerMob = getImagePath("img/upgrade-hero-banner-mob.png")
const friendsOfTMCIcon = getImagePath("icons/friends-of-tmc-icon.svg")
const friendsOfTMCIconDark = getImagePath("icons/friends-of-tmc-icon-dark.svg")
const closeIcon = getImagePath("icons/close-icon.png")
const whatsappIcon = getImagePath('icons/whatsapp-icon.svg')
const crossIconRed = getImagePath("icons/cross-icon-red.png");

const SupportWidget = () => {
  return <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', rowGap: 5 }}>
    <View><Text style={{ fontSize: 24, fontWeight: 500, color: '#051462', borderBottomWidth: 1 }}>Need help?</Text></View>
    <View style={{ marginTop: 5 }}><Text>Email at:</Text></View>
    <View><Text onPress={() => Linking.openURL('mailto:support@themorningcontext.com')} style={{ fontWeight: 700, textDecorationLine: 'underline' }}>support@themorningcontext.com</Text></View>
    <TouchableOpacity onPress={() => Linking.openURL('https://wa.me/919819358263')} style={{ marginTop: 5, borderRadius: 20, backgroundColor: '#25D366', paddingVertical: 10, paddingHorizontal: 15 }}>
      <View style={{ display: 'flex', flexDirection: 'row', columnGap: 5, justifyContent: 'center', alignItems: 'center' }}>
        <CustomImage
          source={whatsappIcon}
          style={{ width: 10, height: 10, objectFit: 'contain' }}
          webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
          className={'whatsapp-icon'}
          altText={name}
        />
        <Text selectable={false}>Whatsapp</Text>
      </View>
    </TouchableOpacity>
  </View>
}

export class Subscription extends Component {
  constructor(props) {
    super(props);
    let routePath = this.props?.route;
    let selectedRouteIndex = 0
    if (routePath === '/') {
      selectedRouteIndex = 0;
    } else if (routePath === 'friends-of-tmc') {
      selectedRouteIndex = 4
    } else if (routePath === 'corporate') {
      selectedRouteIndex = 1
    } else if (routePath === 'student') {
      selectedRouteIndex = 2
    } else if (routePath === 'university') {
      selectedRouteIndex = 3

    }
    this.sliderRef = React.createRef();
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    this.state = {
      token: tokenSet ? token : false,
      pageLoader: this.props.postData ? false : true,
      showErrorModal: false,
      enableUpgradeModal: false,
      enableCancelSubModal: false,
      plansData: false,
      userSubscription: false,
      teamSubscription: false,
      activeSubscription: false,
      country: false,
      pricingPlanRefreshKey: false,
      bannerTitle: "Become a TMC subscriber",
      plansTitle: "Choose your subscription",
      archivedStories: [],
      writers: false,
      planCheckoutLoaderState: false,
      showContactUsModal: false,
      selectedPlanIdx: selectedRouteIndex,
      previousPlanIdx: -1,
      hoverTabIdx: -1,
      FAQCollapsedIndices: [0],
      sliderRefPosition: 0,
      sliderRefWidth: 1000,
      selectedSubscribtionID: '2-year-plan-inr',
      selectedSubscribtion: { id: '2-year-plan-inr', billing_enitity_type: "plan", price: 5500 },
      country: 'IN',
      email: '',
      quantity: 5,
      showCheckoutOptions: false,
      paymentMethod: '',
      bankName: '',
      showCheckoutModal: false,
      plans: [],
      features: [],
      selectedPlan: [
        {
          "id": "2-year-plan-inr",
          "name": "2 Year Plan",
          "tmc_group": "individual",
          "billing_enitity_type": "plan",
          "invoice_name": "2 Year Plan",
          "description": {
            "access_desc": [
              "Read our Daily Edition shipped every morning at 6 AM IST, 12:30 AM UTC",
              "Access close to 400 plus stories every year",
              "Complete access to our archive of 1000 plus stories",
              "Gift 25 stories a year to your colleagues, friends and family",
              "Join our Slack and Telegram channel community",
              "Read on our Android and iOS app",
              "Cancel anytime"
            ],
            "is_annual": true,
            "monthly_price": 266,
            "full_access": true
          },
          "currency_code": "INR",
          "price": 6402,
          "tax_exclusive_price": 6402,
          "chargebee_price": "640200",
          "is_recurring": true,
          "is_popular": false,
          "recommended_plan": true,
          "discount": {}
        }
      ],
      currentPlan: [],
      isPlanChanged: false,
      planList: [],
      name: '',
      businessEmail: '',
      phoneNumber: '',
      countryCode: '',
      jobTitle: '',
      company: '',
      requirements: '',
      locationInfo: null,
      corporateSelectedSeats: 5,
      totalAmount: 2999,
      breakupDifference: 0,
      enableLoader: false,
      selectedIndividualPlanIdx: 0,
      individualPlanData: [],
      showThankYouComponent: false,
      corporate_price_without_discount: 14750,
      isButtonClicked: false,
      hasUserMovedOverseas: false,
      chargebeePlatform: '',
      overseasShiftedUsersPlanData: [
        {
          "id": "2-year-plan-in-usd",
          "name": "2 Year Plan",
          "tmc_group": "individual",
          "billing_enitity_type": "plan",
          "invoice_name": "2 Year Plan",
          "description": {
            "access_desc": [
              "Read everything we publish for two years",
              "Gift two monthly subscriptions to friends",
              "Gift 3 subscriber only stories to friends every month",
              "Comment on stories and be part of the subscriber community",
              "Privilege seats for subscriber only events",
              "Access to all our archives of 1000+ stories",
              "Read on our Android & iOS app",
              "Cancel or upgrade anytime",
              "Save over 10% compared to the one year subscription"
            ],
            "is_annual": true,
            "full_access": true,
            "display_name": "Ace",
            "monthly_price": 7.29,
            "remark": "SAVE OVER 40% YEARLY"
          },
          "currency_code": "USD",
          "price": 175,
          "tax_exclusive_price": 175,
          "chargebee_price": "17500",
          "is_recurring": true,
          "is_popular": false,
          "recommended_plan": true,
          "discount": {}
        },
        {
          "id": "annual-plan-in-usd",
          "name": "Annual Plan",
          "tmc_group": "individual",
          "billing_enitity_type": "plan",
          "invoice_name": "Annual Plan",
          "description": {
            "access_desc": [
              "Read everything we publish for two years",
              "Gift two monthly subscriptions to friends",
              "Gift 3 subscriber only stories to friends every month",
              "Comment on stories and be part of the subscriber community",
              "Access to all our archives of 1000+ stories",
              "Read on our Android & iOS app",
              "Cancel or upgrade anytime"
            ],
            "is_annual": true,
            "full_access": true,
            "display_name": "Smart",
            "monthly_price": 8.25,
            "remark": "MOST POPULAR"
          },
          "currency_code": "USD",
          "price": 99,
          "tax_exclusive_price": 99,
          "chargebee_price": "9900",
          "is_recurring": true,
          "is_popular": true,
          "recommended_plan": true,
          "discount": {}
        },
        {
          "id": "monthly-plan-in-usd",
          "name": "Monthly Plan",
          "tmc_group": "individual",
          "billing_enitity_type": "plan",
          "invoice_name": "Monthly Plan",
          "description": {
            "access_desc": [
              "Read 50 stories every month",
              "Read on our Android & iOS app",
              "Cancel or upgrade anytime"
            ],
            "is_annual": false,
            "full_access": true,
            "display_name": "Starter"
          },
          "currency_code": "USD",
          "price": 9.99,
          "tax_exclusive_price": 9.99,
          "chargebee_price": "999",
          "is_recurring": true,
          "is_popular": false,
          "recommended_plan": true,
          "discount": {}
        }

      ]
    };

    this.subscriptionLayout = 0;
  }

  setPlanButtonLoaderState = (state) => {
    this.setState({
      planCheckoutLoaderState: state,
    });
  };

  getPlanButtonLoaderState = () => {
    return this.state.planCheckoutLoaderState;
  };


  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentDidMount = () => {
    // Get the current URL
    const currentUrl = window.location.href;
    const requiredPlanIdx = currentUrl.includes('idx=') ? parseInt(currentUrl.split('idx=')?.pop()) : this.state.selectedPlanIdx
    this.setState({ selectedPlanIdx: parseInt(requiredPlanIdx) })
    setTimeout(() => {
      let location = window.location.search;
      if (helpers.isDesktop() && location === "?header=true") {
        window.scrollTo({
          top: 300,
          left: 300,
          behavior: "instant",
        });
      }
    }, 1000);
    const { token } = this.state;
    if (token) {
      this.getUserInfo();
    }
    if (this.state.token) {
      this.getUserInfo();
      ChargebeeController.activatedController().then((cb_handle) => {
        let countryInfo = null;
        if (
          cb_handle?.instance?.billingInfo?.billing_platform ===
          "chargebee_india"
        ) {
          countryInfo = {
            code: "IN",
            name: "India",
          };
          this.setState({ chargebeePlatform: "chargebee_india" })
        } else if (cb_handle?.instance?.billingInfo) {
          countryInfo = {
            code: "US",
            name: "USA",
          };
          this.setState({ chargebeePlatform: "chargebee_worldwide" })
          if (!helpers.isDesktop()) {
            this.setState({ selectedSubscribtionID: '2-year-plan-usd' })
          }
        }
        this.checkIfUserHasMovedOverseas()

        if (countryInfo) {
          console.log("Debug: cb_country_code: ", countryInfo)
          this.setState({
            locationInfo: { countryInfo: countryInfo },
          });

        }
      }).finally(() => {
        if (this.state.selectedPlanIdx === 0 && !helpers.isDesktop()) {
          console.log("Location debug: 1")
          this.getIndividualPlanData()
        } else {
          this.getPlansData()
        }
      });
    } else {
      this.getLocationInfo();
    }
    this.getStaticContent();
    this.getWriters();
    this.getArchivedStories();
    if (!this.state.custom_user_id) {
      this.setAuthToken()
    }

    if (this.state.showContactUsModal) {
      document.body.style.overflow = 'hidden';
    }
    if (this.state.selectedPlanIdx === 1 && this.state.previousPlanIdx !== 1 && this.state.previousPlanIdx !== -1) {
      this.setState({ previousPlanIdx: 1 })
      setTimeout(() => this.getEstimate(), 0)
    }
  };

  componentDidUpdate = (prevProps) => {
    let webView = helpers.isWeb();
    let token = webView
      ? this.props.auth.fetchToken
      : this.props.user?.userData?.data?.token;
    if (
      (!(
        this.props.searchQuery &&
        (this.props.searchQuery.includes("subscribe=") ||
          this.props.searchQuery.includes("buy-credits=true"))
      ) &&
        ((token && token != this.state.token) ||
          ((token == undefined || token == "") &&
            this.state.token != false))) ||
      (!helpers.isWeb() &&
        prevProps.app.refreshScreen != this.props.app.refreshScreen)
    ) {
      this.setState(
        {
          token: token == undefined || token == "" ? false : token,
          pageLoader: true,
        },
        () => {
          if (this.state.token) {
            this.getUserInfo(true);
          }
        }
      );
    }

    if (this.state.showContactUsModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
    if (!this.state.token && !this.state.locationInfo) {
      this.getLocationInfo()
    } else if (this.state.selectedPlanIdx === 0 && !helpers.isDesktop()) {
      this.getIndividualPlanData()
    } else {
      this.getPlansData()
    }
    // if(this.isSubscriptionUnderDunning() && this.state.userSubscription?.plan){

    // }
    // this.checkIfUserHasMovedOverseas()
    if (this.state.hasUserMovedOverseas) {
      console.log("Debug: user has moved overseas. Showing overseas plans!")
    }
  };

  getIndividualPlanData = (profileData) => {
    console.log("ESTIMATE PREV:", this.state.previousPlanIdx, " and ", this.state.selectedIndividualPlanIdx)
    console.log("Location debug: 2", this.state.locationInfo)
    if (this.state.previousPlanIdx === this.state.selectedPlanIdx || !this.state.locationInfo) return
    else this.setState({ previousPlanIdx: this.state.selectedPlanIdx })
    const { token } = this.state;
    let endpoint = `/plans/individual/IN`;
    if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN") {
      endpoint = `/plans/individual/${this.state.locationInfo?.countryInfo?.code}`
    }
    let body = {
      filters: {
        tmc_group: ["individual"]
      },
      rank_sort: "DESC"
    }

    if (profileData && profileData?.subscription?.plan) {
      body.filters.plan_gte = profileData.subscription.plan.id;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.plans) {
          console.log("Individual plans data: ", res)
          this.setState({
            individualPlanData: res,
            pageLoader: false
          });
        } else {
          console.log('individual Error 1=>>', res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log('individual Error 2=>>', error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });

  }

  getLocationInfo = () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
            setTimeout(() => {
              if (this.state.selectedPlanIdx === 0 && !helpers.isDesktop()) {
                this.getIndividualPlanData()
              } else {
                this.getPlansData()
              }
            }, 0)
            if (res.data?.countryInfo?.code !== 'IN') {
              this.setState({
                selectedSubscribtionID: '2-year-plan-usd',
                selectedSubscribtion: {
                  id: '2-year-plan-usd',
                  billing_enitity_type: "plan",
                  price: 175
                }
              })
            }
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  };

  checkIfUserHasMovedOverseas = async () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            if (res.data?.countryInfo?.code !== 'IN' && this.state.locationInfo?.countryInfo?.code == 'IN') {
              this.setState({ hasUserMovedOverseas: true })
              console.log("Debug: User moved overseas")
            } else {
              console.log("Debug: ", res.data?.countryInfo?.code, this.state.locationInfo?.countryInfo?.code)
            }
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  }


  getPlansData = (profileData) => {
    if (this.state.previousPlanIdx === this.state.selectedPlanIdx) return;
    else if (this.state.selectedPlanIdx === 1 && this.state.previousPlanIdx !== 1) {
      this.setState({ previousPlanIdx: 1 })
      setTimeout(() => this.getEstimate(), 0)
    } else this.setState({ previousPlanIdx: this.state.selectedPlanIdx })
    console.log("data obtained")
    let endpoint = '/plans/'
    const { token } = this.state
    if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN") {
      endpoint += "US"
    } else {
      endpoint += "IN"
    }

    let filters = {};
    if (this.state.selectedPlanIdx === 0) {
      /**
       * Filters for individual plans
       */
      filters = {
        "filters": {
          "tmc_group": [
            "individual"
          ]
        }
      }

    } else if (this.state.selectedPlanIdx === 1) {
      /**
       * Filters for corporate plans
       */
      filters = {
        "filters": {
          "tmc_group": [
            "team"
          ]
        }
      }
    } else if (this.state.selectedPlanIdx === 2) {
      /**
       * Filters for students plans
       */
      filters = {
        "filters": {
          "tmc_group": [
            "student"
          ]
        }
      }
    } else if (this.state.selectedPlanIdx === 3) {
      /**
       * Filters for college/university plans
       */
      console.log("College / University filter to be added")
    } else if (this.state.selectedPlanIdx === 4) {
      /**
       * Filters for Friends of TMC plan
      */
      console.log("Friends of TMC filter to be added")

    }
    apiHelpers
      .apiRequest(endpoint, filters, "POST", true, token)
      .then((res) => {
        if (res.success) {
          console.log('User info RSE', res);
          console.log("User info: ", {
            userSubscription: this.state.userSubscription,
            teamSubscription: this.state.teamSubscription,
            activeSubscription: this.state.activeSubscription,
            bannerTitle: this.state.activeSubscription
              ? "Thanks for being a TMC subscriber!"
              : "Become a TMC subscriber",
            plansTitle: this.state.activeSubscription
              ? "Active subscription plans"
              : "Choose your subscription",
            userProfile: this.state.userProfile
          })
          this.setState({
            plansData: (profileData && profileData?.team_subscription?.is_team_member) ? { individual: [] } : res.data.plans,
            country: res.data.country,
            pageLoader: false,
            previousPlanIdx: this.state.selectedPlanIdx
          });
        } else {
          console.log("data obtained error")
          console.log('Error=>>', res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("data obtained error 2", error)
        console.log('Error=>>', error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });

  }

  showActivePlan = () => {
    if (!this.state.activeSubscription) return <></>
    return <View style={{ display: 'flex', alignItems: 'center', width: '30%', rowGap: 10, backgroundColor: '#FFF3E3', borderColor: '#E7D6C9', borderWidth: 1 }}>
      <View style={{ marginTop: 20 }}>
        <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{this.state.userSubscription?.plan?.description?.display_name?.toUpperCase()}</Text>
      </View>
      <View>
        {this.state.userSubscription?.plan?.price ?
          <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>{this.state.userSubscription?.plan?.currency_code === 'INR' ? `₹${this.state.userSubscription?.plan?.price}` : `$${this.state.userSubscription?.plan?.price}`}</Text>
          : null
        }
      </View>
      <View>
        {this.state.userSubscription?.plan?.price ?
          <Text style={{ color: '#051462', fontWeight: 500, fontSize: 14 }}>At {this.state.userSubscription?.plan?.currency_code !== 'INR' ? `$${this.state.userSubscription?.plan?.description?.monthly_price || parseFloat(this.state.userSubscription?.plan?.chargebee_price) / 100}` : `₹${this.state.userSubscription?.plan?.description?.monthly_price || parseInt(this.state.userSubscription?.plan?.chargebee_price) / 100}`} / month</Text>
          : null
        }
      </View>
      <View style={{ width: '80%', display: 'flex', flexDirection: 'column', columnGap: 10, justifyContent: 'center', rowGap: 10, marginTop: this.state.userSubscription?.plan?.price > 0 ? 30 : 0 }}>
        {this.state.userSubscription?.plan?.description?.access_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
          <CustomImage
            source={greenTick}
            style={{ width: 10, height: 10, objectFit: 'contain' }}
            webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
            className={'green-tick-icon'}
            altText={name}
          />
          <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
        </View>)}
      </View>
      <TouchableOpacity onPress={() => {
        if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined) {
          this.setState({
            selectedSubscribtion: {
              id: this.state.userSubscription?.plan?.id,
              billing_enitity_type: this.state.userSubscription?.plan?.billing_enitity_type,
              price: this.state.userSubscription?.plan?.price
            },
            selectedSubscribtionID: this.state.userSubscription?.plan?.id
          })
          setTimeout(() => this.proceedToCheckoutScreen(), 0)
        }
      }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#051462' }}>
        <Text style={{ fontSize: 14, fontWeight: 700, color: 'white' }}>{this.isSubscriptionUnderDunning() &&
          this.state.userSubscription?.due_since !== undefined ? "RENEW" : "YOUR CURRENT PLAN"}</Text>
      </TouchableOpacity>
      <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', marginBottom: 10 }}>
        <Text style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>Your current plan will expire on {moment(this.state.userSubscription?.next_billing_at || this.state.userSubscription?.end_date).format('Do MMMM YYYY')}</Text>
      </View>
    </View>
  }

  showActivePlanMob = () => {
    if (!this.state.activeSubscription) return <></>
    return <View style={{ display: 'flex', alignItems: 'center', width: '90%', rowGap: 10, backgroundColor: '#FFF3E3', borderColor: '#E7D6C9', borderWidth: 1 }}>
      <View style={{ marginTop: 20 }}>
        <Text style={{ color: '#051462', fontWeight: 600, fontSize: 24, textAlign: 'center' }}>{this.state.userSubscription?.plan?.description?.display_name?.toUpperCase()}</Text>
      </View>
      <View style={{ display: this.state.userSubscription?.plan?.price > 0 ? 'flex' : 'none' }}>
        <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
          {this.state.userSubscription?.plan?.currency_code === 'INR'
            ? `₹${this.state.userSubscription?.plan?.price}`
            : `$${this.state.userSubscription?.plan?.price}`}
        </Text>
      </View>
      <View style={{ display: this.state.userSubscription?.plan?.price > 0 ? 'flex' : 'none' }}>
        <Text style={{ color: '#051462', fontWeight: 500, fontSize: 14 }}>
          At {this.state.userSubscription?.plan?.currency_code !== 'INR'
            ? `$${this.state.userSubscription?.plan?.description?.monthly_price || parseFloat(this.state.userSubscription?.plan?.chargebee_price) / 100}`
            : `₹${this.state.userSubscription?.plan?.description?.monthly_price || parseInt(this.state.userSubscription?.plan?.chargebee_price) / 100}`} / month
        </Text>
      </View>
      <View style={{ width: '80%', display: 'flex', flexDirection: 'column', columnGap: 10, justifyContent: 'center', rowGap: 10, marginTop: this.state.userSubscription?.plan?.price > 0 ? 30 : 0 }}>
        {this.state.userSubscription?.plan?.description?.access_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
          <CustomImage
            source={greenTick}
            style={{ width: 10, height: 10, objectFit: 'contain' }}
            webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
            className={'green-tick-icon'}
            altText={name}
          />
          <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
        </View>)}
      </View>
      <TouchableOpacity onPress={() => {
        if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined) {
          this.setState({
            selectedSubscribtion: {
              id: this.state.userSubscription?.plan?.id,
              billing_enitity_type: this.state.userSubscription?.plan?.billing_enitity_type,
              price: this.state.userSubscription?.plan?.price
            },
            selectedSubscribtionID: this.state.userSubscription?.plan?.id
          })
          setTimeout(() => this.proceedToCheckoutScreen(), 0)
        }
      }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#051462' }}>
        <Text style={{ fontSize: 14, fontWeight: 700, color: 'white' }}>{this.isSubscriptionUnderDunning() &&
          this.state.userSubscription?.due_since !== undefined ? "RENEW" : "YOUR CURRENT PLAN"}</Text>
      </TouchableOpacity>
      <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', marginBottom: 10 }}>
        <Text style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
          Your current plan will expire on {moment(this.state.userSubscription?.next_billing_at || this.state.userSubscription?.end_date).format('Do MMMM YYYY')}
        </Text>
      </View>
    </View>
  }

  refreshPage = () => {
    if (!this.state.token) {
      this.getUserInfo();
    }
  };

  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      }
    }
  };

  segregateWriters = (writerList) => {
    let writers = [],
      contributors = [];
    writerList.map((writer) => {
      if (writer.roles.includes("contributor")) {
        contributors.push(writer);
      } else {
        writers.push(writer);
      }
    });
    return { writers: writers };
  };

  getWriters = () => {
    this.setState({ showErrorModal: false });
    const { token } = this.state;
    let endpoint = `/authors`;
    let body = {
      // limit: 3,
      sort_on: "name",
      sort_by: "asc",
      filters: [{ key: "roles", value: ["editor"] }],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "author_found") {
          // this.setState({ writers: res.data.author });
          let allWriters = this.segregateWriters(res.data.author);
          this.setState({
            // writers: allWriters['writers'],
            writers: allWriters.writers,
            contributorData: allWriters["contributors"],
          });
        } else {
          // this.setState({ showErrorModal: true, pageLoader: false });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        // this.setState({ showErrorModal: true, pageLoader: false });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getUserInfo = async (scroll) => {
    this.scrollToTop(scroll);
    const { token } = this.state;
    let endpoint = `/profile`;
    let body = {
      request_group: ["subscription", "profile"],
    };
    await apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
          return false;
        }
        let userSubscription =
          !res.data.subscription ||
            JSON.stringify(res.data.subscription) == "{}"
            ? false
            : (res.data.subscription || res.data.team_subscription);
        let teamSubscription =
          !res.data.team_subscription ||
            JSON.stringify(res.data.team_subscription) == "{}"
            ? false
            : res.data.team_subscription;
        let activeSubscription =
          (userSubscription && userSubscription.status != "expired") ||
            (teamSubscription &&
              teamSubscription.is_team_member &&
              teamSubscription.status != "expired")
            ? true
            : false;
        if (res.success) {
          this.setState({
            userSubscription: userSubscription ? userSubscription : JSON.stringify(res.data.subscription) == "{}" ? res.data.team_subscription : false,
            teamSubscription: teamSubscription,
            activeSubscription: activeSubscription,
            bannerTitle: activeSubscription
              ? "Thanks for being a TMC subscriber!"
              : "Become a TMC subscriber",
            plansTitle: activeSubscription
              ? "Active subscription plans"
              : "Choose your subscription",
            userProfile: res.data
          });
          console.log("User info: ", {
            userSubscription: userSubscription,
            teamSubscription: teamSubscription,
            activeSubscription: activeSubscription,
            bannerTitle: activeSubscription
              ? "Thanks for being a TMC subscriber!"
              : "Become a TMC subscriber",
            plansTitle: activeSubscription
              ? "Active subscription plans"
              : "Choose your subscription",
            userProfile: res.data
          })
          // this.getSubscriptionPlans(res.data);
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  /**
   * Subscribe to plan
   */

  handleRenew = () => {
    ChargebeeController.activatedController().then(
      (cb_handle) => {
        cb_handle.hosted_page.collect_now();
      }
    );
  }


  // pushPurchasePlanInteractionEventToDataLayer = (subscription) => {
  //   const dataLayer = window.dataLayer || [];
  //   dataLayer.push({
  //     event: "purchase_plan_interaction",
  //     custom_user_id: this.state.custom_user_id || "NA",
  //     user_status: this.state.custom_user_id ? "logged in " : "guest",
  //     plan_price: subscription.price,
  //     plan_name: subscription.name?.toLowerCase(),
  //     // CTA_text: this.state.userSubscription ? "upgrade Plan" : "select Plan",
  //     scroll_depth: helpers.getScrollDepth(),
  //   });
  // };

  // subscribeToPlan = (subscribtion) => {
  //   console.log("subscribtion:", subscribtion);
  //   this.pushPurchasePlanInteractionEventToDataLayer(subscribtion)
  //   try {
  //     console.log("try");
  //     window.webengage.track("Click Select Plan", {
  //       "Type of User": this.state.token
  //         ? this.state.userSubscription
  //           ? "Paid"
  //           : "Free"
  //         : "non-logged in",
  //       "Plan Name": subscribtion?.name,
  //       "Component Name": "SubscriptionPricingPlan",
  //     });
  //     console.log("success");
  //   } catch (err) {}

  //   this.props.showSubscriptionPlan(
  //     subscribtion?.tmc_group == "credit" ? "credits" : subscribtion.id,
  //     subscribtion,
  //     this.state.country
  //   );
  // };

  handleLayout = (event) => {
    console.log("Position: ", event.nativeEvent.layout)
    this.setState({ sliderRefPosition: event.nativeEvent.layout.x })
    this.setState({ sliderRefWidth: event.nativeEvent.layout.width })
    // Additional logic with x, y, width, height if needed
  };

  closeSideBar = () => {
    let pushProp = JSON.parse(JSON.stringify(window.location));
    pushProp.hash = "";
    this.props.history.push(pushProp);
  };

  isSubscriptionUnderDunning() {
    let { userSubscription } = this.state;
    if (userSubscription?.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  openSubscriptionPlan = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity,
    themeData,
    paymentConfig = {},
    couponId = null
  ) => {
    this.setState({
      showSubscriptionPlanModal: true,
      selectedSubscribtionID: subscribtionID,
      paymentConfig: paymentConfig,
      couponId: couponId,
      selectedSubscribtion: selectedSubscribtion,
      country: country,
      subscribeEmail: email ? email : "",
      quantity: quantity,
      themeData: themeData,
    });
    if (
      !window.location.search.includes("subscribe=") &&
      !window.location.search.includes("buy-credits=true")
    ) {
      let pushProp = JSON.parse(JSON.stringify(window.location));
      let searchArr = window.location.search.replace("?", "").split("&");
      let queryParam =
        subscribtionID == "credits"
          ? "buy-credits=true"
          : subscribtionID == "renew-plan" ||
            subscribtionID == "renew-team-plan" ||
            selectedSubscribtion.tmc_group != "team"
            ? `subscribe=${subscribtionID}`
            : `idx=1&subscribe=${subscribtionID}&quantity=${quantity}`;
      if (searchArr.length > 0 && searchArr[0] != "") {
        pushProp.search += "&" + queryParam;
      } else {
        pushProp.search = "?" + queryParam;
      }
      this.props.history.push(pushProp);
    }

    if (subscribtionID === "team-plan-4-inr" && !window.location.search.includes("quantity=")) {
      let pushProp = JSON.parse(JSON.stringify(window.location));
      let searchArr = window.location.search.replace("?", "").split("&");
      let queryParam = `idx=1&subscribe=${subscribtionID}&quantity=${quantity}`;
      pushProp.search = "?" + queryParam;
      this.props.history.replace(pushProp);
      window.location.reload()
    }
  };

  // selectPlanData = (params) => {
  //   let _dd = [];
  //   if (params.plan_name !== "Free") {
  //     _dd = this.props.data.filter((d, i) => d.id === params.id);
  //   } else {
  //     _dd = this.props.data.filter((d, i) => d.tmc_group === "free");
  //   }
  //   this.setState({
  //     selectedPlan: _dd,
  //     isPlanChanged: params.id !== this.state.currentPlan[0].id ? true : false,
  //   });
  // };


  renderFAQText = (ans) => {
    if (ans === 'Our Android App is here.') {
      return <Text>Our Android App is{" "}
        <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.app.themorningcontext', '_blank')}>
          here
        </Text>
      </Text>
    } else if (ans === 'Our iOS App is here.') {
      return <Text>Our iOS App is{" "}
        <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL('https://apps.apple.com/in/app/the-morning-context/id1551672701', '_blank')}>
          here
        </Text>
      </Text>
    } else if (ans.includes('newsletters@themorningcontext.com')) {
      return <Text> {ans.split('newsletters@themorningcontext.com')[0]} {" "} <Text onPress={() => Linking.openURL('mailto:newsletter@themorningcontext.com')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>newsletters@themorningcontext.com</Text>{" "}{ans.split('newsletters@themorningcontext.com')[1]}</Text>
    } else if (ans.includes('support@themorningcontext.com')) {
      return <Text>{this.renderFAQText(ans.split('support@themorningcontext.com')[0])}{" "}<Text onPress={() => Linking.openURL('mailto:support@themorningcontext.com')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>support@themorningcontext.com</Text>{" "}{ans.split('support@themorningcontext.com')[1]}</Text>
    }
    else if (ans.includes('priya@mailtmc.com')) {
      return <Text> {ans.split('priya@mailtmc.com')[0]} {" "} <Text onPress={() => Linking.openURL('mailto:priya@mailtmc.com')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>priya@mailtmc.com</Text>{" "}{ans.split('priya@mailtmc.com')[1]}</Text>
    } else if (ans.includes("Fixed Plan:")) {
      return <Text><Text style={{ fontWeight: 600 }}>Fixed Plan: </Text>{ans.split('Fixed Plan:').pop()} </Text>
    } else if (ans.includes("Engaged Plan:")) {
      return <Text><Text style={{ fontWeight: 600 }}>Engaged Plan: </Text>{ans.split('Engaged Plan:').pop()} </Text>
    } else if (ans.includes('subscription page')) {
      return <Text>{ans.split('subscription page')[0]}{" "}<Text onPress={() => Linking.openURL('/pricing?idx=1')} style={{ textDecorationLine: 'underline', fontWeight: 700 }}>subscription page</Text>{" "}{ans.split('subscription page')[1]}</Text>
    }
    else return <Text>{ans}</Text>
  }

  openGiftCardModal = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    story = null,
    giftCredits
  ) => {
    this.setState({
      showGiftCardModal: true,
      subscriptionData: {
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        userProfile: this.state.userProfile,
        story,
        giftCredits,
      },
    });
  };


  _showSubscriptionPlan = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity = 1,
    themeData = null,
    paymentConfig = {}
  ) => {
    const urlParams = helpers.getURLParamDict(window.location.href);

    const couponId = urlParams.coupon_ids;

    if (!helpers.isDesktop()) {
      this.closeSideBar();
    }

    // this.props.setCheckoutLoaderState(false);

    if (this.state.token && this.state.userProfile == false) {
      this.getUserInfo(true, {
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        quantity,
      });
      return false;
    }
    if (subscribtionID == "renew-plan") {
      if (
        JSON.stringify(this.state.userProfile.subscription) != "{}" &&
        this.state.userProfile.subscription.can_renew
      ) {
        this.openSubscriptionPlan(
          subscribtionID,
          this.state.userProfile.subscription.plan,
          country,
          paymentConfig
        );
      }
      return false;
    }
    if (subscribtionID == "renew-team-plan") {
      if (
        JSON.stringify(this.state.userProfile.team_subscription) != "{}" &&
        this.state.userProfile.team_subscription.can_renew &&
        this.state.userProfile.team_subscription.is_team_owner
      ) {
        this.openSubscriptionPlan(
          subscribtionID,
          this.state.userProfile.team_subscription.plan,
          country
        ),
          paymentConfig;
      }
      return false;
    }
    if (
      subscribtionID == "credits" &&
      (this.state.userProfile?.subscription?.disable_buy_credits ||
        (this.state.userProfile?.team_subscription?.is_team_member &&
          this.state.userProfile?.team_subscription?.disable_buy_credits))
    ) {
      return false;
    }
    if (
      selectedSubscribtion &&
      selectedSubscribtion.tmc_group == "team" &&
      parseInt(quantity) < 2
    ) {
      return false;
    }

    if (window && window.ReactNativeWebView && !themeData) {
      try {
        themeData = cookies.get("themeData");
        if (typeof themeData == "string") {
          themeData = JSON.parse(themeData);
        }
      } catch (error) {
        this.setState({ themeDataError: error });
      }
    }
    if (
      selectedSubscribtion &&
      (selectedSubscribtion.tmc_group == "gift_plan" ||
        selectedSubscribtion.tmc_group == "gift_credit") &&
      !themeData
    ) {
      this.openGiftCardModal(
        subscribtionID,
        selectedSubscribtion,
        country,
        email
      );
      return false;
    }
    this.openSubscriptionPlan(
      subscribtionID,
      selectedSubscribtion,
      country,
      email,
      quantity,
      themeData,
      paymentConfig,
      couponId
    );
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() - 1);
      cookies.set("token", "", { path: "/", expires: cookieDate });
      this.props.navigateToScreen("/sign-in?redirect=/pricing");
    } else {
      this.props.removeUserToken();
      this.props.navigation.navigate("/sign-in", {
        redirect: "subscriptions",
        verifyRedirect: "/pricing",
      });
    }
  };

  abortRequest = () => {
    if (this.controller) {
      this.controller.abort();
    }
  }

  getEstimate = () => {
    this.abortRequest();
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    let signal = this.signal;

    const { planData, token } = this.props;
    let { quantity: corporateSelectedSeats } = this.state;
    if (corporateSelectedSeats < 5) {
      return
    }
    let endpoint = `/subscription-estimate`;
    let body = {
      plan_id: this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? 'team-plan-4-usd' : 'team-plan-4-inr',
      // For prod team-plan-4-inr
      quantity: corporateSelectedSeats
    }
    this.setState({ enableLoader: true })
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token, signal)
      .then((res) => {
        if (res.success && res.code == 'subscription_estimate') {
          this.setState({
            totalAmount: res.data.amount_due,
            breakupDifference: res.data.credits_applied,
            enableLoader: false,
            corporate_price_without_discount: res.data.individual_plan_amount
          })
          console.log("Corporate plan data: ", {
            totalAmount: res.data.amount_due,
            breakupDifference: res.data.credits_applied,
            enableLoader: false
          })
        } else {
          this.setState({ enableLoader: false })
          console.log('ResError=>>', res);
        }
      })
      .catch((error) => {
        console.log('Error=>>', error);
        if (error && error.aborted) {
          // Do nothing
        }
        else {
          this.setState({ enableLoader: false })
        }
      });
  }

  closeContactUsForm = () => {
    this.setState({ showContactUsModal: false, showThankYouComponent: false })
  }

  goToLogin = () => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/sign-in?redirect=/pricing");
    } else {
      this.props.navigation.navigate("/sign-in", {
        redirect: "subscriptions",
        verifyRedirect: "/pricing",
      });
    }
  };


  renderMobileView = () => {
    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      businessEmail: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
      phoneNumber: Yup.string()
        .test("is-number", "Invalid phone number", (value) => !isNaN(value))
        .required("Phone number is required"),
      jobTitle: Yup.string().required("Job Title is required"),
      company: Yup.string().required("Company is required"),
      requirements: Yup.string().notRequired(),
      countryCode: Yup.string().required("Please select a option"),
    });
    return (
      <View style={{ display: 'flex', width: '100%', backgroundColor: '#FFF9F1' }}>
        {this.state.showContactUsModal ?
          <Modal transparent animationType="slide" visible={this.state.showContactUsModal} onRequestClose={() => this.closeContactUsForm()}>
            <TouchableWithoutFeedback onPress={() => {
              console.log("Clicked 1")
              this.closeContactUsForm()
            }} activeOpacity={1}>
              <View style={{ display: 'flex', alignItems: 'center', height: '100vh', width: '100vw', zIndex: -10, backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <TouchableWithoutFeedback onPress={(e) => {
                  e.preventDefault()
                }} >
                  {this.state.showThankYouComponent ?
                    <View style={{ display: 'flex', alignItems: 'center', height: 700, width: '80vw', backgroundColor: 'white', position: 'absolute', top: '10%' }}>
                      <ThankYouComponent closeThankYouModal={this.closeContactUsForm} />
                    </View>
                    :
                    <View style={{ display: 'flex', alignItems: 'center', height: 700, width: '80vw', backgroundColor: 'white', position: 'absolute', top: '10%' }}>
                      <View onPress={(e) => {
                        e.preventDefault()
                      }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 30, flexDirection: 'row' }}>
                        <Text style={{ fontFamily: 'PlayfairDisplay-Bold', fontSize: 34, fontWeight: 700, color: '#051462' }}>
                          Contact Us
                        </Text>
                        <TouchableWithoutFeedback onPress={() => this.closeContactUsForm()}>
                          <CustomImage
                            source={closeIcon}
                            style={{ width: 10, height: 10, objectFit: 'contain' }}
                            webStyle={{ width: 15, height: 15, objectFit: 'contain', position: 'relative', bottom: '55%', left: '15%' }}
                            className={'green-tick-icon'}
                            altText={name}
                          />
                        </TouchableWithoutFeedback>
                      </View>
                      <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', alignSelf: 'center', marginTop: 15 }}>
                        <Text style={{ textAlign: 'center', fontSize: 19 }}>Please enter your information below and we will contact you shortly</Text>
                      </View>
                      <Formik
                        initialValues={{
                          name: "",
                          lastName: "",
                          businessEmail: "",
                          phoneNumber: "",
                          jobTitle: "",
                          company: "",
                          requirements: "",
                          countryCode: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                          console.log("Values =", values);
                          const data = {
                            name: values.name,
                            email: values.businessEmail,
                            phone:
                              "+" +
                              phoneUtil.getCountryCodeForRegion(values.countryCode) +
                              values.phoneNumber,
                            position: values.jobTitle,
                            job_title: values.jobTitle,
                            company: values.company,
                            requirements: values.requirements,
                            university: values.company,
                          };
                          console.log("Data =", data);
                          this.onSubmitCustomerForm(data);
                        }}
                      >
                        {(props) => (
                          <View style={styles.container}>
                            {/* <View style={styles.formRow}> */}
                            <View style={styles.formItem}>
                              <TextInput
                                placeholder="Name*"
                                onChangeText={props.handleChange("name")}
                                onBlur={props.handleBlur("name")}
                                value={props.values.name}
                                style={[
                                  styles.input,
                                  { borderBottom: "1px solid #bbbbbb" },
                                ]}
                              />
                              {props.errors.name && props.touched.name && (
                                <Text style={styles.formError}>{props.errors.name}</Text>
                              )}
                            </View>

                            <View style={styles.formItem}>
                              <TextInput
                                placeholder="Business Email*"
                                onChangeText={props.handleChange("businessEmail")}
                                onBlur={props.handleBlur("businessEmail")}
                                value={props.values.businessEmail}
                                style={[
                                  styles.input,
                                  { borderBottom: "1px solid #bbbbbb" },
                                ]}
                              />
                              {props.errors.businessEmail &&
                                props.touched.businessEmail && (
                                  <Text style={styles.formError}>
                                    {props.errors.businessEmail}
                                  </Text>
                                )}
                            </View>
                            {/* </View> */}
                            {/* <View style={styles.formRow}> */}
                            <View style={styles.formItem}>
                              <TextInput
                                placeholder="Phone Number*"
                                onChangeText={props.handleChange("phoneNumber")}
                                onBlur={props.handleBlur("phoneNumber")}
                                value={props.values.phoneNumber}
                                style={[
                                  styles.input,
                                  { borderBottom: "1px solid #bbbbbb" },
                                ]}
                              />
                              {props.errors.phoneNumber && props.touched.phoneNumber && (
                                <Text style={styles.formError}>
                                  {props.errors.phoneNumber}
                                </Text>
                              )}
                            </View>
                            <View style={styles.formItem}>
                              <CustomCountryPicker
                                name="countryCode"
                                selectedValue={props.values.countryCode}
                                field={{
                                  value: props.values.countryCode,
                                  error: props.errors.countryCode,
                                  touched: props.touched.countryCode,
                                }}
                                onChange={(value) => {
                                  props.setFieldValue("countryCode", value);
                                }}
                              />
                              {props.errors.countryCode && props.touched.countryCode && (
                                <Text style={styles.formError}>
                                  {props.errors.countryCode}
                                </Text>
                              )}
                            </View>
                            {/* </View> */}
                            <View
                              style={{
                                zIndex: -5,
                              }}
                            >
                              {/* <View style={styles.formRow}> */}
                              <View style={styles.formItem}>
                                <TextInput
                                  placeholder={this.state.selectedPlanIdx === 1 ? "Job Title*" : "Position*"}
                                  onChangeText={props.handleChange("jobTitle")}
                                  onBlur={props.handleBlur("jobTitle")}
                                  value={props.values.jobTitle}
                                  style={[
                                    styles.input,
                                    { borderBottom: "1px solid #bbbbbb" },
                                  ]}
                                />
                                {props.errors.jobTitle && props.touched.jobTitle && (
                                  <Text style={styles.formError}>
                                    {props.errors.jobTitle}
                                  </Text>
                                )}
                              </View>
                              <View style={styles.formItem}>
                                <TextInput
                                  placeholder={this.state.selectedPlanIdx === 1 ? "Company*" : "University*"}
                                  onChangeText={props.handleChange("company")}
                                  onBlur={props.handleBlur("company")}
                                  value={props.values.company}
                                  style={[
                                    styles.input,
                                    { borderBottom: "1px solid #bbbbbb" },
                                  ]}
                                />
                                {props.errors.company && props.touched.company && (
                                  <Text style={styles.formError}>
                                    {props.errors.company}
                                  </Text>
                                )}
                              </View>
                              {/* </View> */}
                              {/* <View style={styles.formRow}> */}
                              <View style={styles.formItem}>
                                {/* <CustomPicker
                                name="requirements"
                                selectedValue={props.values.requirements}
                                field={{
                                  value: props.values.requirements,
                                  error: props.errors.requirements,
                                  touched: props.touched.requirements,
                                }}
                                onChange={(value) => {
                                  props.setFieldValue("requirements", value);
                                }}
                              /> */}
                                <TextInput
                                  placeholder={"Requirements"}
                                  onChangeText={props.handleChange("requirements")}
                                  onBlur={props.handleBlur("requirements")}
                                  value={props.values.requirements}
                                  style={[
                                    styles.input,
                                    { borderBottom: "1px solid #bbbbbb" },
                                  ]}
                                />
                                {props.errors.requirements && props.touched.requirements ? (
                                  <Text style={styles.formError}>
                                    {props.errors.requirements}
                                  </Text>
                                ) : null}
                              </View>
                              <View style={styles.formItem}></View>
                              {/* </View> */}
                              <View
                                style={{
                                  zIndex: -5,
                                }}
                              >
                                <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                  <Button
                                    title={'SUBMIT'}
                                    rootStyle={{
                                      btnWrapper: [button.primary,
                                      { height: 36, cursor: "pointer", marginTop: 25, width: '80%', zIndex: -5, alignItems: 'center', justifyContent: 'center' },
                                      ],
                                      btnText: [button.primaryText,
                                      { fontWeight: "500", paddingHorizontal: 15, fontSize: 17 },
                                      ],
                                    }}
                                    onClick={() => {
                                      props.handleSubmit();
                                    }}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                      </Formik>
                    </View>}
                </TouchableWithoutFeedback>
              </View>

            </TouchableWithoutFeedback>
          </Modal> :
          null}
        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <CustomImage
            source={this.state.activeSubscription ? upgradeHeroBannerMob : heroBannerMob}
            style={{ width: '100%', height: 'auto' }}
            webStyle={{ width: '100%', height: 'auto', zIndex: 1 }}
            className={'pricing-page-bg'}
            altText={name}
          />
        </View>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <View
            style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', backgroundColor: '#1E1B4B', height: 50, borderRadius: '30px', paddingLeft: 5, position: 'sticky', top: 0, marginHorizontal: 30, marginTop: '5vh' }}>
            {PRICING_PLANS.map((plan, idx) =>
              <TouchableOpacity
                style={{ minWidth: 200, display: 'flex', flexDirection: 'row', cursor: 'pointer', backgroundColor: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? 'white' : 'transparent', borderRadius: 30, height: '85%', zIndex: 1, alignItems: 'center', justifyContent: 'center' }}
                onPress={() => {
                  this.setState({ selectedPlanIdx: idx })
                  if (idx === 1) {
                    this.props.navigateToScreen('/pricing/corporate')
                  } else if (idx === 2) {
                    this.props.navigateToScreen('/pricing/student')
                  } else if (idx === 3) {
                    this.props.navigateToScreen('/pricing/university')
                  } else if (idx === 4) {
                    this.props.navigateToScreen('/pricing/friends-of-tmc')
                  } else if (idx === 0) {
                    this.props.navigateToScreen("/pricing/individual")
                  }
                  this.setState({ FAQCollapsedIndices: [0] })
                }}
                onMouseEnter={() => {
                  this.setState({ hoverTabIdx: idx })
                  console.log("Focussed: ", idx)
                }}
                onMouseLeave={() => this.setState({ hoverTabIdx: -1 })}
              >
                {idx === 4 && <CustomImage
                  source={this.state.selectedPlanIdx === idx || this.state.hoverTabIdx === idx ? friendsOfTMCIconDark : friendsOfTMCIcon}
                  style={{ width: 10, height: 10, objectFit: 'contain' }}
                  webStyle={{ width: 24, height: 24, objectFit: 'contain' }}
                  className={'green-tick-icon'}
                  altText={name}
                />}
                <Text
                  style={{ color: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? '#1E1B4B' : 'white', textAlign: 'center', fontWeight: 600, zIndex: 1, fontSize: 16, paddingHorizontal: 5 }}
                >
                  {plan}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </ScrollView>
        {this.state.selectedPlanIdx === 0 &&
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 40 }}>
            <View style={{ width: '80%', display: 'flex', flexDirection: 'column', columnGap: '5%', justifyContent: 'center', rowGap: 10 }}>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
                <CustomImage
                  source={greenTick}
                  style={{ width: 10, height: 10, objectFit: 'contain' }}
                  webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                  className={'green-tick-icon'}
                  altText={name}
                />
                <Text style={{ fontSize: 18, fontWeight: 500, color: '#051462' }}>Pick your plan</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
                <CustomImage
                  source={greenTick}
                  style={{ width: 10, height: 10 }}
                  webStyle={{ width: 15, height: 15 }}
                  className={'green-tick-icon'}
                  altText={name}
                />
                <Text style={{ fontSize: 18, fontWeight: 500, color: '#051462' }}>Transparent pricing</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
                <CustomImage
                  source={greenTick}
                  style={{ width: 10, height: 10 }}
                  webStyle={{ width: 15, height: 15 }}
                  className={'green-tick-icon'}
                  altText={name}
                />
                <Text style={{ fontSize: 18, fontWeight: 500, color: '#051462' }}>Change or cancel anytime</Text>
              </View>
            </View>
            {/* Individual pricing table */}
            {this.state.userSubscription?.plan?.tmc_group !== 'exclusive' && this.state.userSubscription?.plan?.id !== 'institution-plan-inr' ? <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '10vh' }}>
              <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', rowGap: '3vh' }}>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  {this.state.individualPlanData?.plans && Object.keys(this.state.individualPlanData?.plans)?.filter((plan) => {
                    if (this.state.chargebeePlatform === 'chargebee_worldwide' && plan.includes("-in-usd")) {
                      return false
                    }else if(!this.state.token && plan.includes("in-usd")){
                      return false
                    }
                    if (!this.state.activeSubscription) return true;
                    const currentPlanID = this.state.userSubscription?.plan?.id;
                    if (!currentPlanID || currentPlanID.includes('monthly-plan')) {
                      return true
                    } else if (currentPlanID.includes('annual-plan') && plan.includes('monthly-plan')) {
                      return false;
                    } else if (currentPlanID.includes('2-year-plan') && (plan.includes('monthly-plan') || plan.includes('annual-plan'))) {
                      return false;
                    }
                    return true;
                  })?.map((plan) => <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 10, fontWeight: 500, textAlign: 'center', height: '3vh', marginBottom: '-2vh' }}>{this.state.individualPlanData?.plans[plan]["remark"]}</Text>
                  </View>)}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  {this.state.individualPlanData?.plans && Object.keys(this.state.individualPlanData?.plans)?.filter((plan) => {
                    if (this.state.chargebeePlatform === 'chargebee_worldwide' && plan.includes("-in-usd")) {
                      return false
                    }else if(!this.state.token && plan.includes("in-usd")){
                      return false
                    }
                    if (!this.state.activeSubscription) return true;
                    const currentPlanID = this.state.userSubscription?.plan?.id;
                    if (!currentPlanID || currentPlanID.includes('monthly-plan')) {
                      return true
                    } else if (currentPlanID.includes('annual-plan') && plan.includes('monthly-plan')) {
                      return false;
                    } else if (currentPlanID.includes('2-year-plan') && (plan.includes('monthly-plan') || plan.includes('annual-plan'))) {
                      return false;
                    }
                    return true;
                  })?.map((plan, idx) => <TouchableWithoutFeedback onPress={() => {
                    console.log("Clicked here")
                    this.setState({ selectedIndividualPlanIdx: idx })
                    this.setState({ selectedSubscribtion: { id: (this.state.hasUserMovedOverseas && !this.state.activeSubscription) ? (idx === 0 && '2-year-plan-iin-usd') || (idx === 1 && 'annual-plan-in-usd') || (idx === 2 && 'monthly-plan-in-usd') : plan, billing_enitity_type: this.state.individualPlanData?.plans[plan]["billing_entity_type"], price: this.state.individualPlanData?.plans[plan]["amount"] }, selectedSubscribtionID: plan })
                  }}>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: this.state.selectedIndividualPlanIdx === idx ? '#128D5C' : 'rgba(18, 141, 92, 0.70)', paddingHorizontal: '1vw', paddingVertical: '1vh', width: '30%' }}>
                      <View>
                        <Text style={{ color: 'white', fontSize: 16, fontWeight: 700, textAlign: 'center' }}>{this.state.individualPlanData?.plans[plan]["display_name"]?.toUpperCase()}</Text>
                      </View>
                      <View>
                        <Text style={{ color: 'white', fontSize: 12, fontWeight: 400, textAlign: 'center' }}>{this.state.individualPlanData?.plans[plan]["plan_name"]}</Text>
                      </View>
                      {this.state.selectedIndividualPlanIdx === idx && <View style={{ position: 'relative', backgroundColor: '#128D5C', transform: 'rotate(45deg)', top: '1.5vh', width: '1.5vh', height: '1.5vh' }} />}
                    </View>
                  </TouchableWithoutFeedback>)}
                </View>
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Subscription price
                  </Text>
                  {/* <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    (Offer ends 29th February at 11:59 PM {this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? "PST" : "IST"})
                  </Text> */}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', width: '90%' }}>
                  {this.state.individualPlanData?.plans && Object.keys(this.state.individualPlanData?.plans)?.filter((plan) => {
                    if (this.state.chargebeePlatform === 'chargebee_worldwide' && plan.includes("-in-usd")) {
                      return false
                    }else if(!this.state.token && plan.includes("in-usd")){
                      return false
                    }
                    if (!this.state.activeSubscription) return true;
                    const currentPlanID = this.state.userSubscription?.plan?.id;
                    if (!currentPlanID || currentPlanID.includes('monthly-plan')) {
                      return true
                    } else if (currentPlanID.includes('annual-plan') && plan.includes('monthly-plan')) {
                      return false;
                    } else if (currentPlanID.includes('2-year-plan') && (plan.includes('monthly-plan') || plan.includes('annual-plan'))) {
                      return false;
                    }
                    return true;
                  })?.map((plan, idx) => <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ fontSize: 14, fontWeight: 500, textAlign: 'center', color: this.state.selectedIndividualPlanIdx === idx ? '#128D5C' : '#1E1B4B' }}>
                      {this.state.individualPlanData?.plans[plan]["display_name"] === "Smart" ?
                        `${this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!this.state.activeSubscription && this.state.hasUserMovedOverseas) ?
                          `$8.25 / month`
                          : `₹249 / month`
                        }` : this.state.individualPlanData?.plans[plan]["display_name"] === "Ace" ?
                          `${this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!this.state.activeSubscription && this.state.hasUserMovedOverseas) ?
                            `$7.29 / month`
                            : `₹225 / month`
                          }` :
                          `${this.state.individualPlanData?.plans[plan]["display_name"] === "Starter"
                            && this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!this.state.activeSubscription && this.state.hasUserMovedOverseas) ?
                            `$9.99 / month`
                            : `₹499 / month`}
                          `.trim()}
                    </Text>
                    <Text style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', color: this.state.selectedIndividualPlanIdx === idx ? '#128D5C' : '#1E1B4B' }}>
                      {this.state.individualPlanData?.plans[plan]["display_name"] === "Smart" ?
                        <Text>{this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!this.state.activeSubscription && this.state.hasUserMovedOverseas) ?
                          <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>$125</Text> $99 now for annual access</Text>
                          : <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>₹3,449</Text> ₹2,999 now for annual access</Text>
                        }</Text> : this.state.individualPlanData?.plans[plan]["display_name"] === "Ace" ?
                          <Text>{this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!this.state.activeSubscription && this.state.hasUserMovedOverseas) ?
                            <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>$250</Text> $175 now for annual access</Text>
                            : <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>₹6,898</Text> ₹5,500 now for annual access</Text>
                          }</Text> :
                          `${this.state.individualPlanData?.plans[plan]["display_name"] === "Starter" ?
                            `Billed every month`
                            : `Billed every month`}
                          `}
                    </Text>
                  </View>)}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Access to stories
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 0 ? '#128D5C' : '#1E1B4B' }}>
                      Everything we publish for two years
                    </Text>
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 1 ? '#128D5C' : '#1E1B4B' }}>
                      Everything we publish for one year
                    </Text>
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 2 ? '#128D5C' : '#1E1B4B' }}>
                      50 stories every month
                    </Text>
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Archive Access
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 0 ? '#128D5C' : '#1E1B4B' }}>
                      Unlimited access to our archives of 1000+ stories
                    </Text>
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 1 ? '#128D5C' : '#1E1B4B' }}>
                      Limited access to archives for one year
                    </Text>
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) &&
                      <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomImage
                          source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                          style={{ width: 10, height: 10, objectFit: 'contain' }}
                          webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                          className={'green-tick-icon'}
                          altText={name}
                        />
                      </View>}
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Monthly subscriptions you can gift to friends
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ fontSize: 14, color: '#1E1B4B', fontWeight: 500, textAlign: 'center', color: this.state.selectedIndividualPlanIdx === 0 ? '#128D5C' : '#1E1B4B' }}>2</Text>
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Gift 3 subscriber only stories to friends monthly
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 0 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Comment on stories and be part of the subscriber community
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 0 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Privilege seats for subscriber only events
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 0 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !this.state.activeSubscription || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <Button
                  loader={this.state.isButtonClicked}
                  title={this.state.activeSubscription &&
                    ((this.state.userSubscription?.plan?.description?.display_name === "Ace" && this.state.selectedIndividualPlanIdx === 0) ||
                      (this.state.userSubscription?.plan?.description?.display_name === "Smart" && this.state.selectedIndividualPlanIdx === 1) ||
                      (this.state.userSubscription?.plan?.tmc_group === "individual" && this.state.selectedIndividualPlanIdx === 2 && !this.state.userSubscription?.plan?.description?.is_annual))
                    ? "CURRENT PLAN" : this.isSubscriptionUnderDunning() &&
                      this.state.userSubscription?.due_since !== undefined && ((this.state.userSubscription?.plan?.description?.display_name === "Ace" && this.state.selectedIndividualPlanIdx === 0) ||
                        (this.state.userSubscription?.plan?.description?.display_name === "Smart" && this.state.selectedIndividualPlanIdx === 1) ||
                        (this.state.userSubscription?.plan?.tmc_group === "individual" && this.state.selectedIndividualPlanIdx === 2 && !this.state.userSubscription?.plan?.description?.is_annual)) ? "RENEW" :
                      this.state.activeSubscription ? "UPGRADE" : "SUBSCRIBE"}
                  rootStyle={{
                    btnWrapper: [
                      true
                        ? button.primary
                        : button.primary,
                      { height: 36, cursor: "pointer", marginTop: 25, width: '80%', zIndex: -5, alignItems: 'center', justifyContent: 'center', opacity: ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null, backgroundColor: '#1E1B4B' },
                    ],
                    btnText: [
                      true
                        ? button.primaryText
                        : button.primaryText,
                      { fontWeight: "700", paddingHorizontal: 15, fontSize: 17, fontFamily: "HaasGrotesk-Medium" },
                    ],

                  }}
                  onClick={() => {
                    if (this.state.isButtonClicked) return
                    this.setState({ isButtonClicked: true })
                    if (this.state.selectedIndividualPlanIdx === 0 && !helpers.isDesktop() && this.state.selectedPlanIdx === 0 && !this.state.selectedSubscribtionID.includes('2-year-plan')) {
                      this.setState(
                        {
                          selectedSubscribtionID:
                            this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                              '2-year-plan-usd' :
                              `2-year-plan-inr`,
                          selectedSubscribtion: {
                            id: this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                              '2-year-plan-usd' :
                              `2-year-plan-inr`,
                            billing_enitity_type: "plan",
                            price: 5500
                          },
                        },
                        () => {
                          this.proceedToCheckoutScreen()
                        }
                      )
                      return
                    }
                    if (!this.state.token) {
                      let selectedPlanHere = '2-year-plan-inr'
                      if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code === "IN") {
                        if (this.state.selectedIndividualPlanIdx === 0) {
                          selectedPlanHere = '2-year-plan-inr'
                        } else if (this.state.selectedIndividualPlanIdx === 1) {
                          selectedPlanHere = 'annual-plan-in'
                        } else {
                          selectedPlanHere = 'monthly-plan-inr'
                        }
                      } else {
                        if (this.state.selectedIndividualPlanIdx === 0) {
                          selectedPlanHere = '2-year-plan-usd'
                        } else if (this.state.selectedIndividualPlanIdx === 1) {
                          selectedPlanHere = 'annual-plan-usd'
                        } else {
                          selectedPlanHere = 'monthly-plan-usd'
                        }
                      }
                      this.props.navigateToScreen(
                        `/sign-in?redirect=/pricing&redirect-params=subscribe=${selectedPlanHere}&quantity=1`
                      );
                      return
                    }
                    if (['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                      return
                    }
                    if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'individual') {
                      if ((this.state.userSubscription?.plan?.id.includes('annual-plan') && this.state.selectedIndividualPlanIdx === 1)) {
                        this.handleRenew()
                        return
                      }
                      if ((this.state.userSubscription?.plan?.id.includes('2-year-plan') && this.state.selectedIndividualPlanIdx === 0)) {
                        this.handleRenew()
                        return
                      }
                      if ((this.state.userSubscription?.plan?.id.includes('monthly-plan') && this.state.selectedIndividualPlanIdx === 2)) {
                        this.handleRenew()
                        return
                      }
                      if (this.isSubscriptionUnderDunning() &&
                        this.state.userSubscription?.due_since !== undefined) {
                        return
                      }
                      this.setState({
                        selectedSubscribtion: {
                          id: this.state.userSubscription?.plan?.id,
                          billing_enitity_type: this.state.userSubscription?.plan?.billing_enitity_type,
                          price: this.state.userSubscription?.plan?.price
                        },
                        selectedSubscribtionID: this.state.userSubscription?.plan?.id
                      })
                      setTimeout(() => this.proceedToCheckoutScreen(), 0)
                      return
                    }
                    setTimeout(() => this.proceedToCheckoutScreen(), 0)
                  }}
                />
              </View>
            </View> :
              <View style={{ marginTop: 20, display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                {this.showActivePlanMob()}
              </View>
            }
            {/* Individual pricing table ends here */}
          </View>}
        {this.state.selectedPlanIdx === 0 && <View style={{ marginTop: 30 }}>
          <SupportWidget />
        </View>}
        {
          this.state.selectedPlanIdx === 0 &&
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 60 }}>
            <CustomImage
              source={whySubscribeBg}
              style={{ width: '100%', height: 'auto' }}
              webStyle={{ width: '100%', height: 'auto' }}
              className={'why-subscribe-the-morning-context'}
              altText={name}
            />
            <View style={{ position: 'relative', bottom: 50, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1 }}>
              <Text style={{ color: '#051462', fontWeight: 700, fontSize: 34, fontFamily: 'PlayfairDisplay-Bold', lineHeight: '150%', textAlign: 'center', width: '80%' }}>
                Why read The Morning Context?
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'relative', bottom: 50, rowGap: 30 }}>
              <View style={{ display: 'flex', width: '85%', justifyContent: 'center', alignItems: 'center', rowGap: 10, marginTop: 30 }}>
                <View style={{ width: '100%', display: 'flex' }}>
                  <Text style={{ fontSize: 24, fontWeight: 700, color: '#1E1B4B' }}>Original Journalism</Text>
                </View>
                <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                  Gain access to top-notch reporting, analysis, and opinion written by experienced journalists and subject matter experts.
                </Text>
              </View>
              <View style={{ display: 'flex', width: '85%', justifyContent: 'center', alignItems: 'center', rowGap: 10 }}>
                <View style={{ width: '100%', display: 'flex' }}>
                  <Text style={{ fontSize: 24, fontWeight: 700, color: '#1E1B4B' }}>Detailed Perspectives</Text>
                </View>
                <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                  Our editorial team is committed to quality journalism over quantity. Our detailed, longform approach to writing and explainers helps you understand complex subjects.
                </Text>
              </View>
              <View style={{ display: 'flex', width: '85%', justifyContent: 'center', alignItems: 'center', rowGap: 10 }}>
                <View style={{ width: '100%', display: 'flex' }}>
                  <Text style={{ fontSize: 24, fontWeight: 700, color: '#1E1B4B' }}>No Clickbait, No Ads</Text>
                </View>
                <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                  Unlike many other news sources, we prioritize quality over clickbait headlines or intrusive advertisements. Your reading experience will be uninterrupted and focused on what truly matters.
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <View style={{ display: 'flex', width: '85%', rowGap: 10 }}>
                <View style={{ display: 'flex', width: '100%' }}>
                  <Text style={{ fontSize: 34, fontWeight: 700, fontFamily: 'PlayfairDisplay-Bold', textAlign: 'center', color: '#051462' }}>
                    Benefits of reading The Morning Context
                  </Text>
                </View>
                <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                  <Text style={{ fontSize: 16 }}>
                    When you choose The Morning Context as your daily source of financial news and analysis, you unlock a host of benefits that you won’t find anywhere else. Here's what you can expect:
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', width: '85%', rowGap: 10, marginTop: 40 }}>
                {BENEFITS_OF_READING_TMC.map((benefit) =>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', columnGap: 5 }}>
                    <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%' }}>✓</Text>
                    <Text>
                      <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%', color: '#1E1B4B' }}>{benefit.title + " "}</Text>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>{benefit.description}</Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 100 }}>
              <View style={{ display: 'flex', width: '80%', rowGap: 10 }}>
                <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={{ fontSize: 34, fontWeight: 700, fontFamily: 'PlayfairDisplay-Bold', textAlign: 'center', color: '#051462' }}>
                    Who reads The Morning Context?
                  </Text>
                </View>
                <View style={{ display: 'flex', width: '100%', marginTop: 20 }}>
                  <Text style={{ fontSize: 16, width: '85%' }}>
                    The Morning Context is trusted and read by a diverse and engaged audience. Our readers come from various backgrounds and professions, including:
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', width: '85%', rowGap: 10, marginTop: 40 }}>
                {WHO_READS_TMC.map((benefit) =>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', columnGap: 5 }}>
                    <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%' }}>✓</Text>
                    <Text>
                      <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%', color: '#1E1B4B' }}>{benefit.title + " "}</Text>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>{benefit.description}</Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        }
        {
          this.state.selectedPlanIdx === 1 &&
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '5vh' }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '90%' }}>
              <Text style={{ fontFamily: 'PlayfairDisplay-Bold', fontSize: 32, color: '#051462' }}>Power your people</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '90%', marginTop: '2vh' }}>
              <Text style={{ fontSize: 20, lineHeight: '150%' }}>90% of our readers say they subscribe to The Morning Context because it helps them work better. Whether they are running their own business, consulting, or working with startups or large corporations, in finance or fin-tech, marketing, government policy, venture funds or retail investors, academia and even students, our stories add value to their work. It helps them understand companies and their strategies, what they are getting right or wrong, so that they can bring this knowledge into their own work.</Text>
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CustomImage
                source={corporateImgMob}
                style={{ width: '100%', height: 'auto', marginTop: 80 }}
                webStyle={{ width: '100%', height: 'auto', marginTop: 80 }}
                className={'corporate-banner-img'}
                altText={name}
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", marginVertical: 40, alignItems: 'center', width: '100%', rowGap: '5vh' }}>
              {this.state.plansData?.team?.map((plan) => <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '85%', minHeight: 400 }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description.display_name}</Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 18, fontWeight: 500 }}>Purchase plan for a fixed number of seats</Text>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column-reverse', rowGap: '2vh' }}>
                  <View style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                    <View style={{ display: 'flex', width: '90%' }}>
                      <Text style={{ fontSize: 16, fontWeight: 500 }}>Enter number of seats</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, paddingVertical: 10, alignItems: 'center', width: '90%', borderWidth: 1, borderColor: '#1E1B4B', }}>
                      <TouchableOpacity onPress={() => {
                        this.setState({ quantity: this.state.quantity <= 5 ? 5 : this.state.quantity - 1 })
                        setTimeout(() => this.getEstimate(), 0)
                      }}>
                        <Text style={{ fontWeight: 500, fontSize: 18, alignSelf: 'flex-start' }}>-</Text>
                      </TouchableOpacity>
                      <TextInput
                        style={{ fontWeight: 500, fontSize: 18, alignSelf: 'center', width: '50%', textAlign: 'center' }}
                        value={this.state.quantity}
                        onChange={(e) => {
                          const { value } = e.target
                          if (parseInt(value) === NaN) return
                          this.setState({ quantity: value ? parseInt(value) : value })
                          setTimeout(() => this.getEstimate(), 0)
                        }}
                        keyboardType="numeric"
                      />
                      <TouchableOpacity onPress={() => {
                        this.setState({ quantity: this.state.quantity + 1 })
                        setTimeout(() => this.getEstimate(), 0)
                      }}>
                        <Text style={{ fontWeight: 500, fontSize: 18, alignSelf: 'flex-end' }}>+</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={{ display: 'flex', width: '90%' }}>
                    <Text style={{ fontSize: 38, fontWeight: 700, color: '#051462' }}>
                      {this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? '$' : '₹'}{this.state.totalAmount}
                      {/* <Text style={{ textDecorationLine: 'line-through', fontSize: 18, fontWeight: 400, opacity: 0.6, verticalAlign: 'top' }}>
                        {this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? '$' : '₹'}{this.state.corporate_price_without_discount}
                      </Text> */}
                    </Text>
                    <Text style={{ fontSize: 12, fontWeight: 400, opacity: 0.6 }}>Billed yearly</Text>
                  </View>
                </View>
                {this.state.quantity < 5 ?
                  <View>
                    <Text style={{ fontSize: 14, color: '#F02121' }}>*Minimum number of seats is 5</Text>
                  </View> : null}
                <TouchableOpacity onPress={() => {
                  if (this.state.quantity < 5) return
                  if (this.state.isButtonClicked) return
                  this.setState({ isButtonClicked: true })
                  if (!this.state.token) {
                    this.props.navigateToScreen(
                      `/sign-in?redirect=/pricing&redirect-params=subscribe=${this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                        'team-plan-4-usd'
                        :
                        'team-plan-4-inr'
                      }&quantity=${this.state.quantity}`
                    );
                    return
                  }
                  if (['friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                    return
                  }
                  if (this.state.userSubscription?.plan?.tmc_group === 'team') {
                    Linking.openURL(`${window.location.origin}/my-team`, '_blank')
                    return
                  }
                  if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'team') {
                    this.handleRenew()
                    return
                  }
                  if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN") {
                    this.setState({ selectedSubscribtion: { id: 'team-plan-4-usd', billing_enitity_type: 'plan', price: this.state.totalAmount }, selectedSubscribtionID: 'team-plan-4-usd' })
                  } else {
                    this.setState({ selectedSubscribtion: { id: 'team-plan-4-inr', billing_enitity_type: 'plan', price: this.state.totalAmount }, selectedSubscribtionID: 'team-plan-4-inr' })
                  }
                  setTimeout(() => this.proceedToCheckoutScreen(), 0)
                  console.log("Clicked!!!")
                }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B', opacity: ['friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null }}>
                  <Text style={{ textAlign: 'center', fontWeight: 500, fontSize: 17, color: 'white' }}>{this.state.userSubscription?.plan?.tmc_group === 'team' ?
                    this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined ?
                      "RENEW" :
                      'MANAGE TEAM' : "PROCEED TO CHECKOUT"}</Text>
                </TouchableOpacity>
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                {plan.description.owner_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>{desc}</Text>
                </View>)}
                <View style={{ display: 'flex', flexDirection: 'row', columnGap: 5, marginTop: 20 }}>
                  <Text style={{ fontSize: 16, fontWeight: 500 }}>Need help?</Text>
                  <TouchableOpacity onPress={() => {
                    this.setState({ showContactUsModal: true })
                  }}>
                    <Text style={{ fontSize: 16, fontWeight: 500, textDecorationLine: 'underline' }}>Contact us</Text>
                  </TouchableOpacity>
                </View>
              </View>)}
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '85%' }}>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '60%', height: 30, bottom: 35, alignSelf: 'center' }}>
                  <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>FREE 7-DAY TRIAL</Text>
                </View>
                <View style={{ position: 'relative', bottom: 50, display: 'flex', rowGap: 20 }}>
                  <View>
                    <View>
                      <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>Engaged</Text>
                    </View>
                    <View style={{ display: 'flex', width: '90%', marginTop: '1vh', flexDirection: 'column' }}>
                      <View style={{ flex: 1 }}><Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%', fontWeight: 500 }}>Purchase plan for the whole organization.</Text></View>
                      <Text />
                      <View><Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%', fontWeight: 500 }}>Pay only for engaged readers, the rest read free.</Text></View>
                    </View>
                  </View>
                  <View style={{ display: 'flex', height: '3vh' }}>
                    {/* <View>
                    <Text style={{ color: '#051462', fontSize: 40, fontWeight: 800 }}>₹3,201</Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 14 }}>At ₹267/month, billed yearly</Text>
                  </View> */}
                  </View>
                  <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B' }}>
                    <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                  </TouchableOpacity>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 40 }}>
              <View style={{ display: 'flex', width: '85%' }}><Text style={{ fontFamily: 'PlayfairDisplay-Bold', fontSize: 34, color: '#051462' }}>How fixed plan subscription works?</Text></View>
              <View style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', paddingHorizontal: '7.5%' }}>
                <View style={{ display: 'flex', width: '90%', marginTop: 40, rowGap: 10, marginBottom: '5vh' }}>
                  <View style={{ display: 'flex' }}>
                    <CustomImage
                      source={purchaseSeatsIcon}
                      style={{ width: 'auto', height: 40 }}
                      webStyle={{ width: 40, height: 40 }}
                      className={'purchase-seats-icon'}
                      altText={name}
                    />
                  </View>
                  <View><Text style={{ fontWeight: 600, fontSize: 18 }}>Purchase Seats</Text></View>
                  <View><Text style={{ fontSize: 16 }}>Purchase plan for a fixed number of seats. The number of seats can be increased during the term of the subscription, or upon renewal.</Text></View>
                </View>
                <View style={{ display: 'flex', width: '90%', marginTop: 10, rowGap: 10, marginBottom: '5vh' }}>
                  <View style={{ display: 'flex' }}>
                    <CustomImage
                      source={inviteMembersCorporateIcon}
                      style={{ width: 'auto', height: 40 }}
                      webStyle={{ width: 40, height: 40 }}
                      className={'purchase-seats-icon'}
                      altText={name}
                    />
                  </View>
                  <View><Text style={{ fontWeight: 600, fontSize: 18 }}>Invite Members</Text></View>
                  <View><Text style={{ fontSize: 16 }}>Post-purchase you can access the admin panel and invite members from your organization</Text></View>
                </View>
                <View style={{ display: 'flex', width: '90%', marginTop: 10, rowGap: 10, marginBottom: '5vh' }}>
                  <View style={{ display: 'flex' }}>
                    <CustomImage
                      source={startReadingIcon}
                      style={{ width: 'auto', height: 40 }}
                      webStyle={{ width: 28.47, height: 40 }}
                      className={'purchase-seats-icon'}
                      altText={name}
                    />
                  </View>
                  <View><Text style={{ fontWeight: 600, fontSize: 18 }}>Start Reading</Text></View>
                  <View><Text style={{ fontSize: 16 }}>Members can accept the invite, sign in and have unfettered access to everything The Morning Context publishes.</Text></View>
                </View>
              </View>
            </View>
          </View>
        }

        {
          this.state.selectedPlanIdx === 2 &&
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <View style={{ display: 'flex', width: '100%', paddingLeft: '10%', marginBottom: 40, marginTop: 60 }}>
              <Text style={{ width: '90%', fontSize: 24, lineHeight: '150%' }}>
                More than 5,000 students read The Morning Context, either through a subscription from their University or through their own individual subscription. Students enjoy a special discount compared to our standard individual subscription.
              </Text>
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CustomImage
                source={studentImgMob}
                style={{ width: '100%', height: 'auto', marginTop: 40 }}
                webStyle={{ width: '100%', height: 'auto', marginTop: 40 }}
                className={'corporate-banner-img'}
                altText={name}
              />
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CustomImage
                source={studentRefundMob}
                style={{ width: '80%', height: 'auto', marginTop: 40 }}
                webStyle={{ width: '80%', height: 'auto', marginTop: 40 }}
                className={'corporate-banner-img'}
                altText={name}
              />
            </View>
            {this.state.userSubscription?.plan?.tmc_group && <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: 'rgb(255, 61, 0)', padding: 10, width: '80%', marginTop: 60 }}>
              <Text style={{ fontSize: 16, color: 'rgb(255, 61, 0)', width: '80%', textAlign: 'center' }}>
                You cannot purchase a student subscription as you are already subscribed to {this.state.userSubscription?.plan?.tmc_group} plan
              </Text>
            </View>}
            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginVertical: 40, marginTop: 80, rowGap: '5vh' }}>
              {this.state.plansData?.student?.filter((plan) => {
                if (this.state.userSubscription?.plan?.tmc_group !== 'student') {
                  return true
                }
                return parseInt(plan.chargebee_price) > this.state.userSubscription.subscribed_plan_amount
              }).map((plan) => <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '85%', minHeight: 400 }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description.display_name}</Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 18 }}>
                      {plan.description.is_annual ? "Annual plan" : "Monthly plan"}
                    </Text>
                  </View>
                </View>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
                      {`${plan.currency_code === "USD" ?
                        `$${plan.description.monthly_price} / month`
                        : `₹${plan.description.monthly_price} / month`
                        }`}
                    </Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 14 }}>
                      {`Billed ${plan.currency_code === "USD" ? `$${parseFloat(plan.chargebee_price) / 100}` : `₹${parseFloat(plan.chargebee_price) / 100}`} now for annual access`}
                    </Text>
                  </View>
                </View>
                <Button
                  title={this.state.activeSubscription && this.state.userSubscription?.plan?.tmc_group === 'student' ? "YOUR CURRENT PLAN" :
                    this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'student' ?
                      "RENEW" :
                      "SUBSCRIBE NOW"}
                  loader={this.state.isButtonClicked}
                  rootStyle={{
                    btnWrapper: [button.primary,
                    { height: 36, cursor: "pointer", marginTop: 25, width: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1E1B4B', opacity: this.state.activeSubscription && this.state.userSubscription?.plan?.tmc_group === 'student' ? 1 : this.state.activeSubscription || this.state.isButtonClicked ? 0.5 : 1, marginTop: 15 },
                    ],
                    btnText: [button.primaryText,
                    { textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white', paddingHorizontal: 15, fontSize: 17, fontFamily: "HaasGrotesk-Medium" },
                    ],

                  }}
                  onClick={() => {
                    if (this.state.isButtonClicked) return
                    this.setState({ isButtonClicked: true })
                    if (!this.state.token) {
                      this.props.navigateToScreen(
                        `/sign-in?redirect=/pricing&redirect-params=subscribe=${plan.id}&quantity=1`
                      );
                      return
                    }
                    if (this.state.userSubscription?.plan?.tmc_group === 'student') {
                      if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'student') {
                        this.handleRenew()
                        return
                      }
                    }
                    if (this.state.activeSubscription) {
                      return
                    }
                    this.setState({ selectedSubscribtion: { id: plan.id, billing_enitity_type: plan.billing_enitity_type, price: plan.chargebee_price }, selectedSubscribtionID: plan.id })
                    setTimeout(() => this.proceedToCheckoutScreen(), 0);
                  }}
                />
                {this.state.userSubscription?.plan?.tmc_group === 'student' && <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                  <Text style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>Your current plan will expire on {moment(this.state.userSubscription?.next_billing_at).format('Do MMMM YYYY')}</Text>
                </View>}
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                {plan.description.access_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>{desc}</Text>
                </View>)}
              </View>)}
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '85%' }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>Dedicated plan for your college</Text>
                  </View>
                  <View style={{ display: 'flex', width: '90%', marginTop: 10 }}>
                    <Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%', fontWeight: 500 }}>If you're a faculty member seeking a tailored plan for your campus, please contact us. We'll reach out to you personally to discuss and provide the perfect plan to meet your specific requirements.</Text>
                  </View>
                </View>
                <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B' }}>
                  <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                </TouchableOpacity>
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                </View>
              </View>
            </View>
          </View>
        }

        {
          this.state.selectedPlanIdx === 3 &&
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <View style={{ display: 'flex', width: '100%', paddingLeft: '10%', marginTop: 60 }}>
              <View style={{ display: 'flex', width: '90%', }}>
                <Text style={{ fontSize: 24, lineHeight: '150%' }}>Several amongst the top 50 management colleges and universities in India, including some of the top universities in the US, UK and Southeast Asia have a subscription of The Morning Context. Faculty and students value The Morning Context’s journalism to get a true and deep perspective on the Indian business, technology and economic landscape. Our stories help in stimulating classroom discussions, case studies and faculty research.</Text>
              </View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CustomImage
                source={teacherImgMob}
                style={{ width: '100%', height: 'auto', marginTop: 40 }}
                webStyle={{ width: '100%', height: 'auto', marginTop: 40 }}
                className={'corporate-banner-img'}
                altText={name}
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginVertical: 40, marginTop: 40, rowGap: '5vh' }}>
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '85%', minHeight: 400 }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>Teacher</Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 18, fontWeight: 500 }}>Full access to archives</Text>
                  </View>
                </View>
                <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B', marginTop: '5vh' }}>
                  <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                </TouchableOpacity>
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                </View>
              </View>
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '85%' }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>University/Librarian</Text>
                  </View>
                  <View style={{ display: 'flex', width: '80%', marginTop: 10 }}>
                    <Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%', fontWeight: 500 }}>Purchase subscription for the entire college or specific departments</Text>
                  </View>
                </View>
                <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B' }}>
                  <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                </TouchableOpacity>
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                </View>
              </View>
            </View>
          </View>
        }
        {(this.state.selectedPlanIdx === 1 || this.state.selectedPlanIdx === 2 || this.state.selectedPlanIdx === 3) && <View style={{ marginTop: 30 }}>
          <SupportWidget />
        </View>}
        {
          (this.state.selectedPlanIdx === 2 || this.state.selectedPlanIdx === 3) &&
          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 60, rowGap: '5vh' }}>
            <View style={{ display: 'flex', width: '100%' }}>
              <View style={{ display: 'flex', width: '70%', marginLeft: '5vw' }}>
                <Text style={{ fontSize: 34, fontFamily: 'PlayfairDisplay-Bold', color: '#051462' }}>Colleges that read us</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                <Text></Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '90%', }}>
              <ScrollView horizontal style={{ display: 'flex', flexDirection: 'row', columnGap: 20 }} onLayout={this.handleLayout} ref={this.sliderRef} showsHorizontalScrollIndicator={false} scrollEnabled={false}>
                <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                  <CustomImage
                    source={iimAhmedabadLogo}
                    style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Ahmedabad</Text>
                </View>
                <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                  <CustomImage
                    source={iimAmritsarLogo}
                    style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Amritsar</Text>
                </View>
                <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                  <CustomImage
                    source={iimKozikhodeLogo}
                    style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Kozikhode</Text>
                </View>
                <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                  <CustomImage
                    source={iitDelhiLogo}
                    style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIT Delhi</Text>
                </View>
                <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                  <CustomImage
                    source={iimIndore}
                    style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Indore</Text>
                </View>
                <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                  <CustomImage
                    source={isbLogo}
                    style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10, maxWidth: 175, objectFit: 'contain' }}
                    webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10, maxWidth: 175, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>Indian School of Business</Text>
                </View>
              </ScrollView>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', columnGap: 30, marginTop: 20, marginLeft: '5vw', alignSelf: 'flex-start' }}>
              <TouchableOpacity style={{ borderWidth: 1, paddingHorizontal: 10 }} onPress={() => {
                console.log("Position :", this.state.sliderRefPosition)
                this.setState({ sliderRefPosition: Math.max(0, this.state.sliderRefPosition - 400) })
                console.log("Position :", this.state.sliderRefPosition)
                this.sliderRef.current.scrollTo({ x: this.state.sliderRefPosition - 400 })
              }}>
                <Text style={{ fontSize: 24 }}>←</Text>
              </TouchableOpacity>
              <TouchableOpacity style={{ borderWidth: 1, paddingHorizontal: 10 }} onPress={() => {
                console.log("Position :", this.state.sliderRefPosition)
                this.setState({ sliderRefPosition: Math.min(this.state.sliderRefPosition + 400, this.state.sliderRefWidth) })
                console.log("Position :", this.state.sliderRefPosition)
                this.sliderRef.current.scrollTo({ x: this.state.sliderRefPosition + 400 })
              }}>
                <Text style={{ fontSize: 24 }}>→</Text>
              </TouchableOpacity>
            </View>
          </View>
        }
        {
          this.state.selectedPlanIdx === 4 &&
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'relative', bottom: '15vh', zIndex: -1 }}>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CustomImage
                source={friendsOfTMCImgMob}
                style={{ width: '100%', height: 'auto', marginTop: 40 }}
                webStyle={{ width: '100%', height: 'auto', marginTop: 40 }}
                className={'corporate-banner-img'}
                altText={name}
              />
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#292929', width: '100%' }}>
              <View style={{ display: 'flex', width: '95%' }}>
                <Text style={{ color: 'white', fontSize: 34, fontWeight: 600, fontFamily: 'PlayfairDisplay-Bold', textAlign: 'center' }}>
                  Friends of The Morning Context
                </Text>
              </View>
              <View style={{ display: 'flex', width: '95%', marginTop: 40 }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: 18 }}>
                  The Morning Context was founded with the mission India needs a media company which holds companies and people in power to account, in the world of technology and business. Our thesis has been that business news faces three challenges:
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center', marginTop: 40, rowGap: '3vh' }}>
                <View style={{ display: 'flex', width: '95%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', columnGap: 10 }}>
                  <Text style={{ fontSize: 48, color: 'white', fontWeight: 900, fontSize: 52, alignSelf: 'flex-start' }}>1</Text>
                  <Text style={{ color: 'white', fontSize: 19, lineHeight: '150%' }}>Traditional media churns out hundreds of articles everyday, mostly commodity stuff.</Text>
                </View>

                <View style={{ display: 'flex', width: '95%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', columnGap: 10 }}>
                  <Text style={{ fontSize: 48, color: 'white', fontWeight: 900, fontSize: 52, alignSelf: 'flex-start' }}>2</Text>
                  <Text style={{ color: 'white', fontSize: 19, lineHeight: '150%' }}>Advertising based business models, including media companies which double up as content studios for companies, are very often compromised by vested interests.</Text>
                </View>
                <View style={{ display: 'flex', width: '95%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', columnGap: 10 }}>
                  <Text style={{ fontSize: 48, color: 'white', fontWeight: 900, fontSize: 52, alignSelf: 'flex-start' }}>3</Text>
                  <Text style={{ color: 'white', fontSize: 19, lineHeight: '150%' }}>Social media has created echo chambers and disinformation, a bubble that needs piercing.</Text>
                </View>
              </View>
              <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 40, marginBottom: 80, rowGap: '5vh' }}>
                <View style={{ display: 'flex', width: '95%' }}>
                  <Text style={{ color: 'white', fontSize: 19 }}>It does not help that India ranks 161 out of 180 countries on the Press Freedom Index, an abysmal rank.</Text>
                  <CustomImage
                    source={pressFreedomIndex}
                    style={{ width: '100%', height: 'auto', marginTop: 20 }}
                    webStyle={{ width: '100%', height: 'auto', marginTop: 20 }}
                    className={'press-freedom-index'}
                    altText={name}
                  />
                </View>
                <View style={{ display: 'flex', width: '95%' }}>
                  <Text style={{ color: 'white', fontSize: 19 }}>Friends of The Morning Context is a community of supporters who share our values and want us and our brand of journalism to succeed.</Text>
                  <CustomImage
                    source={tmcSupporters}
                    style={{ width: '100%', height: 'auto', marginTop: 20 }}
                    webStyle={{ width: '100%', height: 'auto', marginTop: 20 }}
                    className={'TMC-Supporters'}
                    altText={name}
                  />
                </View>
              </View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '5vh' }}>
              <View style={{ display: 'flex', width: '90%' }}>
                <Text style={{ fontSize: 34, fontWeight: 600, fontFamily: 'PlayfairDisplay-Bold', color: '#051462' }}>
                  Support Us
                </Text>
              </View>
              <View style={{ display: 'flex', width: '90%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#FFF3E3', marginTop: 40, paddingVertical: 20 }}>
                <View style={{ display: 'flex', width: '90%', rowGap: 5 }}>
                  <View><Text style={{ fontFamily: 'Matteo-medium', fontSize: 32, fontWeight: 600, color: '#1E1B4B' }}>Friends of The Morning Context</Text></View>
                  <View><Text style={{ fontSize: 18, color: '#1E1B4B', fontWeight: 500 }}>One time payment</Text></View>
                  <View><Text style={{ fontSize: 38, fontWeight: 700, marginTop: 5, color: '#1E1B4B' }}>
                    {this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? '$1,999' : '₹1,49,000'}
                  </Text></View>
                  <View style={{ display: 'flex', width: '90%' }}>
                    <Button
                      title={this.state.userSubscription?.plan?.tmc_group === 'friends-of-tmc' ? "YOUR CURRENT PLAN" : this.state.activeSubscription ? "UPGRADE" : "PROCEED TO CHECKOUT"}
                      rootStyle={{
                        btnWrapper: [button.primary,
                        { height: 36, cursor: "pointer", marginTop: 25, width: '100%', alignItems: 'center', justifyContent: 'center', opacity: ['team', 'friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null },
                        ],
                        btnText: [button.primaryText,
                        { fontWeight: "500", paddingHorizontal: 15, fontSize: 17 },
                        ],

                      }}
                      onClick={() => {
                        if (this.state.isButtonClicked) return
                        this.setState({ isButtonClicked: true })
                        if (!this.state.token) {
                          this.props.navigateToScreen(
                            `/sign-in?redirect=/pricing&redirect-params=subscribe=${this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                              TMC_PLANS[8].plan_id
                              :
                              TMC_PLANS[4].plan_id
                            }&quantity=1`
                          );
                          return
                        }
                        if (['team', 'friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                          return
                        }
                        if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN") {
                          this.setState({ selectedSubscribtion: { id: TMC_PLANS[8].plan_id, billing_enitity_type: 'plan', price: TMC_PLANS[8].price }, selectedSubscribtionID: TMC_PLANS[8].plan_id })
                          setTimeout(() => this.proceedToCheckoutScreen(), 0)
                        } else {
                          this.setState({ selectedSubscribtion: { id: TMC_PLANS[4].plan_id, billing_enitity_type: 'plan', price: TMC_PLANS[4].price }, selectedSubscribtionID: TMC_PLANS[4].plan_id })
                          setTimeout(() => this.proceedToCheckoutScreen(), 0)
                        }
                      }}
                    />
                  </View>
                </View>
                <View style={{ display: 'flex', width: '90%', rowGap: 10, marginTop: '2vh' }}>
                  <View><Text style={{ fontWeight: 600, fontSize: 16 }}>What's Included</Text></View>
                  <View style={{ display: 'flex', rowGap: 5 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Lifetime subscription to The Morning Context</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Invite to an exclusive Friends of The Morning Context event once a year with the founding team</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Privilege seats for subscriber only events</Text>
                    </View>
                    {/* <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Gift five annual subscriptions to friends and family</Text>
                    </View> */}
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Gift 3 subscriber only stories to friends every month</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Access to all our archives of 1000+ stories</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Read on our Android & iOS app</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Cancel or upgrade anytime</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        }
        {this.state.selectedPlanIdx !== 4 && <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#FFF3E3', marginTop: 40, paddingVertical: 20 }}>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
            <Text style={{ fontSize: 34, fontFamily: 'PlayfairDisplay-Regular', fontWeight: 700, color: '#051462', textAlign: 'center' }}>Hear from our community</Text>
          </View>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', borderColor: '#E7D6C9', height: 300, objectFit: 'cover', width: '90%', marginTop: 40 }}>
            <View style={[{ width: '100%', height: '100%', objectFit: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }]}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/DVMpRRPLW3k?si=BqaDJ1I1jNf32EYa"
                title="The Morning Context"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </View>
          </View>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 40 }}>
            <TestimonialCarousel />
          </View>
        </View>}
        {(this.state.selectedPlanIdx === 4) && <View style={{ marginTop: 30, position: 'relative', bottom: 120 }}>
          <SupportWidget />
        </View>}
        <View style={[{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: this.state.selectedPlanIdx === 4 ? null : 100, marginBottom: 100 }, this.state.selectedPlanIdx === 4 ? { position: 'relative', bottom: 80 } : {}]}>
          <View style={{ display: 'flex', width: '90%', alignSelf: 'center', justifyContent: 'flex-start' }}>
            <Text style={{ fontSize: 34, fontFamily: 'PlayfairDisplay-Bold', fontWeight: 600, color: '#051462' }}>Frequently Asked Questions</Text>
            {FREQUENTLY_ASKED_QUESTIONS[this.state.selectedPlanIdx].FAQs.map((faq, index) => {
              if (typeof faq.answer === 'object') {
                return <TouchableOpacity onPress={() => {
                  if (this.state.FAQCollapsedIndices.includes(index)) {
                    this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                  } else {
                    this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                  }
                }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                    <Text style={{ fontSize: 20, fontWeight: 600, width: '90%', fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                    <CustomImage
                      source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {this.state.FAQCollapsedIndices.includes(index) &&
                    <View>
                      <Text style={{ fontSize: 18, marginTop: 20, display: 'flex', flexDirection: 'column', rowGap: 10, lineHeight: '150%', width: '90%', fontFamily: 'HaasGrotesk-Light' }}>
                        {faq.answer.map((ans) => {
                          return this.renderFAQText(ans)
                        })}
                      </Text>
                    </View>
                  }
                  <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                </TouchableOpacity>
              } else
                return <TouchableOpacity onPress={() => {
                  if (this.state.FAQCollapsedIndices.includes(index)) {
                    this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                  } else {
                    this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                  }
                }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                    <Text style={{ fontSize: 20, fontWeight: 600, width: '90%', fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                    <CustomImage
                      source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {this.state.FAQCollapsedIndices.includes(index) &&
                    <View>
                      <Text style={{ fontSize: 18, marginTop: 20, lineHeight: '150%', width: '90%', fontFamily: 'HaasGrotesk-Light' }}>
                        {faq.answer === 'Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out here.' ?
                          <Text>Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out{" "}
                            <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL(`${window.location.origin}/pricing`, '_blank')}>
                              here
                            </Text>
                          </Text>
                          :
                          this.renderFAQText(faq.answer)}
                      </Text>
                    </View>
                  }
                  <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                </TouchableOpacity>
            })}
          </View>
        </View>
      </View>
    )
  }

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  getStaticContent = () => {
    const { token } = this.state;
    let endpoint = `/static-content`;
    let body = {
      content: ["readers_quotes"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "content_found") {
          this.setState({ staticContent: res.data });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  viewAuthorDetails = (Slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + Slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: Slug });
    }
  };

  navigateToTag = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/tags/" + slug);
    } else {
      this.props.navigation.navigate("tags-archive", { archiveSlug: slug });
    }
  };

  subscribeToPlan = (subscribtionId, subscribtion, country) => {
    try {
      if (subscribtion.tmc_group == "individual") {
        Mixpanel.track("click_select_plan_btn", {
          plan: subscribtion.name,
          price: subscribtion.price,
          discount:
            subscribtion.discount && subscribtion.discount.amount
              ? "yes"
              : "no",
        });
      } else if (subscribtion.tmc_group == "credit") {
        Mixpanel.track("click_privilege_credits_btn", {
          price: subscribtion.price,
          discount:
            subscribtion.discount && subscribtion.discount.amount
              ? "yes"
              : "no",
        });
      }
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    console.log("Here ----->>>>>", "Showing the subscription plan from the refs header")
    this.refs.header.showSubscriptionPlan(
      subscribtionId,
      subscribtion,
      country
    );
  };

  isSubscriptionUnderDunning() {
    let { userSubscription } = this.state;
    if (userSubscription?.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  goToSignUp = (email) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(
        "/sign-up?redirect=/pricing" + (email ? "&email=" + email : "")
      );
    } else {
      this.props.navigation.navigate("/sign-up", {
        redirect: "/pricing",
        verifyRedirect: "/pricing",
        email: email,
      });
    }
  };

  trackReadTerms = () => {
    // try {
    //     if(helpers.isWeb()) {
    //         Mixpanel.track('click_read_tc');
    //     }
    // }
    // catch(error) {
    //     console.log('mix panel error =>', error);
    // }
  };

  trackThemeClick = (theme) => {
    // try {
    //     if(helpers.isWeb()) {
    //         Mixpanel.track('click_theme', { theme : theme });
    //     }
    // }
    // catch(error) {
    //     console.log('mix panel error =>', error);
    // }
  };

  renderPopularCategory() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let sectionTitle = {
      title: "Get all the news from our categories",
    };
    return (
      <HomePopularCategories
        data={sectionTitle}
        trackThemeClick={(theme) => this.trackThemeClick(theme)}
        getTagClick={(slug) => this.navigateToTag(slug)}
        darkMode={darkMode}
      />
    );
  }

  goToSignUpEmail = (email) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign me up",
      header_title: "become a TMC subscriber",
      CTA_position: "top",
      article_name: "NA",
      signup_method: "NA",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      "/pricing" +
      "&providerHint=tmc-passwordless" +
      (email ? "&emailHint=" + email : "")
    );
  };
  goToGoogleLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with google",
      header_title: "become a TMC subscriber",
      CTA_position: "top",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      "/pricing" +
      "&providerHint=google"
    );
  };

  goToGoogleLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with google",
      header_title: "become a TMC subscriber",
      CTA_position: "top",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      "/newsletters" +
      "&providerHint=google"
    );
  };

  getValueOfQuantityFromRequirement = (requirements) => {
    if (requirements.includes("and above")) {
      const lower = parseInt(requirements.split(" ")[0]);
      const upper = null;
      return { lower, upper };
    } else {
      const [lower, upper] = requirements.split("-").map((x) => parseInt(x));
      return { lower, upper: upper + 1 };
    }
  };

  mostPopularPlanTag = () => {
    return <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1E1B4B', position: 'relative', width: '60%', height: 30, bottom: 35, alignSelf: 'center' }}>
      <Text style={{ fontSize: 14, color: 'white', fontWeight: 500 }}>MOST POPULAR</Text>
    </View>
  }

  onSubmitCustomerForm = (data) => {
    let endpoint = "/forms/contact-us/";
    if (this.state.selectedPlanIdx === 1) {
      /**
       * Corporate enquiry
       */
      endpoint += "corporate/"
    } else if (this.state.selectedPlanIdx === 2) {
      endpoint += "student/"
    } else if (this.state.selectedPlanIdx === 3) {
      endpoint += "university/"
    }
    apiHelpers
      .apiRequest(endpoint, data, "POST", true, false)
      .then((res) => {
        if (res.status == "success") {
          // this.setState({ isCustomerDataSubmitted: true });
          console.log("Success")
          this.setState({ showThankYouComponent: true })
        }
      })
      .catch((error) => {
        // this.setState({ isServerSideError: true });

        const res = error?.data;

        const errorMessage = Object.values(res).join(",");
        // this.setState({ serverSideErrorMessage: errorMessage });
        console.error(error);
      });
  };

  addSlideContent = (item, index) => {

    return (
      <View style={{ display: 'flex', alignItems: 'center', width: '60%', height: '300', borderWidth: 1, alignSelf: 'flex-end' }}>
        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFF3E3', height: 200, width: 200 }}>
          <CustomImage
            source={corporateImg}
            style={{ width: '100%', height: 'auto', marginTop: 80 }}
            webStyle={{ width: '100%', height: 'auto', marginTop: 80 }}
            className={'corporate-banner-img'}
            altText={name}
          />
          <Text>{item.name}</Text>
        </View>
      </View>
    );
  };

  renderPageContent = () => {
    const {
      plansData,
      userSubscription,
      teamSubscription,
      activeSubscription,
      country,
      pricingPlanRefreshKey,
      staticContent,
      writerData,
      bannerTitle,
      plansTitle,
      token,
      creditPlan,
    } = this.state;

    let webView = helpers.isWeb();
    let sectionTitle = {
      title: "Get all the news from our categories",
    };

    let btnClickTerms, btnClickStudents;
    const { navigation } = this.props;
    if (helpers.isWeb()) {
      btnClickTerms = {
        to: "/terms-and-conditions",
      };
    } else {
      btnClickTerms = {
        onPress: () => navigation.navigate("/terms-and-conditions"),
      };
    }

    if (helpers.isWeb()) {
      btnClickStudents = {
        to: "/student-subscription",
      };
    } else {
      btnClickStudents = {
        onPress: () => navigation.navigate("/student-subscription"),
      };
    }



    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      businessEmail: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
      phoneNumber: Yup.string()
        .test("is-number", "Invalid phone number", (value) => !isNaN(value))
        .required("Phone number is required"),
      jobTitle: Yup.string().required("Job Title is required"),
      company: Yup.string().required("Company is required"),
      requirements: Yup.string().notRequired(),
      countryCode: Yup.string().required("Please select a option"),
    });

    let subInfoBox = {
      title: "Get upto date with the latest stories",
      subtitle: "Subscribe to TMC now",
      btnText: "SUBSCRIBE",
    };
    if (activeSubscription) {
      subInfoBox.subtitle = null;
      subInfoBox.btnText = "VIEW LATEST STORIES";
    }
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      helpers.isDesktop() ?
        <View style={{ backgroundColor: '#FFF9F1' }}>
          {/* Contact Us Modal (Refactor and create a separate component for the same) */}
          {this.state.showContactUsModal ?
            <Modal transparent animationType="slide" visible={this.state.showContactUsModal} onRequestClose={() => this.closeContactUsForm()}>
              <TouchableWithoutFeedback onPress={() => {
                this.closeContactUsForm()
              }} activeOpacity={1}>
                <View style={{ display: 'flex', alignItems: 'center', height: '100vh', width: '100vw', zIndex: -10, backgroundColor: 'rgba(0,0,0,0.5)' }}>
                  <TouchableWithoutFeedback onPress={(e) => {
                    e.preventDefault()
                  }} >
                    {this.state.showThankYouComponent ?
                      <View style={{ display: 'flex', alignItems: 'center', height: '75%', width: 500, backgroundColor: 'white', position: 'absolute', top: '15%', left: '30%' }}>
                        <ThankYouComponent closeThankYouModal={this.closeContactUsForm} />
                      </View>
                      :
                      <View style={{ display: 'flex', alignItems: 'center', height: '75%', width: 500, backgroundColor: 'white', position: 'absolute', top: '15%', left: '30%' }}>
                        <View onPress={(e) => {
                          e.preventDefault()
                        }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20, flexDirection: 'row' }}>
                          <Text style={{ fontFamily: 'PlayfairDisplay-Bold', fontSize: 34, fontWeight: 700, color: '#051462' }}>
                            Contact Us
                          </Text>
                          <TouchableWithoutFeedback onPress={() => this.closeContactUsForm()}>
                            <CustomImage
                              source={closeIcon}
                              style={{ width: 10, height: 10, objectFit: 'contain' }}
                              webStyle={{ width: 15, height: 15, objectFit: 'contain', position: 'relative', bottom: '45%', left: '25%' }}
                              className={'green-tick-icon'}
                              altText={name}
                            />
                          </TouchableWithoutFeedback>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', alignSelf: 'center', marginTop: 10 }}>
                          <Text style={{ textAlign: 'center', fontSize: 19 }}>Please enter your information below and we will contact you shortly</Text>
                        </View>
                        <Formik
                          initialValues={{
                            name: "",
                            lastName: "",
                            businessEmail: "",
                            phoneNumber: "",
                            jobTitle: "",
                            company: "",
                            requirements: "",
                            countryCode: "",
                          }}
                          validationSchema={validationSchema}
                          onSubmit={(values) => {
                            console.log("Values =", values);
                            const data = {
                              name: values.name,
                              email: values.businessEmail,
                              phone:
                                "+" +
                                phoneUtil.getCountryCodeForRegion(values.countryCode) +
                                values.phoneNumber,
                              position: values.jobTitle,
                              job_title: values.jobTitle,
                              company: values.company,
                              requirements: values.requirements,
                              university: values.company
                            };
                            console.log("Data =", data);
                            this.onSubmitCustomerForm(data);
                          }}
                        >
                          {(props) => (
                            <View style={styles.container}>
                              {/* <View style={styles.formRow}> */}
                              <View style={styles.formItem}>
                                <TextInput
                                  placeholder="Name*"
                                  onChangeText={props.handleChange("name")}
                                  onBlur={props.handleBlur("name")}
                                  value={props.values.name}
                                  style={[
                                    styles.input,
                                    { borderBottom: "1px solid #bbbbbb" },
                                  ]}
                                />
                                {props.errors.name && props.touched.name && (
                                  <Text style={styles.formError}>{props.errors.name}</Text>
                                )}
                              </View>

                              <View style={styles.formItem}>
                                <TextInput
                                  placeholder="Business Email*"
                                  onChangeText={props.handleChange("businessEmail")}
                                  onBlur={props.handleBlur("businessEmail")}
                                  value={props.values.businessEmail}
                                  style={[
                                    styles.input,
                                    { borderBottom: "1px solid #bbbbbb" },
                                  ]}
                                />
                                {props.errors.businessEmail &&
                                  props.touched.businessEmail && (
                                    <Text style={styles.formError}>
                                      {props.errors.businessEmail}
                                    </Text>
                                  )}
                              </View>
                              {/* </View> */}
                              {/* <View style={styles.formRow}> */}
                              <View style={styles.formItem}>
                                <TextInput
                                  placeholder="Phone Number*"
                                  onChangeText={props.handleChange("phoneNumber")}
                                  onBlur={props.handleBlur("phoneNumber")}
                                  value={props.values.phoneNumber}
                                  style={[
                                    styles.input,
                                    { borderBottom: "1px solid #bbbbbb" },
                                  ]}
                                />
                                {props.errors.phoneNumber && props.touched.phoneNumber && (
                                  <Text style={styles.formError}>
                                    {props.errors.phoneNumber}
                                  </Text>
                                )}
                              </View>
                              <View style={styles.formItem}>
                                <CustomCountryPicker
                                  name="countryCode"
                                  selectedValue={props.values.countryCode}
                                  field={{
                                    value: props.values.countryCode,
                                    error: props.errors.countryCode,
                                    touched: props.touched.countryCode,
                                  }}
                                  onChange={(value) => {
                                    props.setFieldValue("countryCode", value);
                                  }}
                                />
                                {props.errors.countryCode && props.touched.countryCode && (
                                  <Text style={styles.formError}>
                                    {props.errors.countryCode}
                                  </Text>
                                )}
                              </View>
                              {/* </View> */}
                              <View
                                style={{
                                  zIndex: -5,
                                }}
                              >
                                {/* <View style={styles.formRow}> */}
                                <View style={styles.formItem}>
                                  <TextInput
                                    placeholder={this.state.selectedPlanIdx === 1 ? "Job Title*" : "Position*"}
                                    onChangeText={props.handleChange("jobTitle")}
                                    onBlur={props.handleBlur("jobTitle")}
                                    value={props.values.jobTitle}
                                    style={[
                                      styles.input,
                                      { borderBottom: "1px solid #bbbbbb" },
                                    ]}
                                  />
                                  {props.errors.jobTitle && props.touched.jobTitle && (
                                    <Text style={styles.formError}>
                                      {props.errors.jobTitle}
                                    </Text>
                                  )}
                                </View>
                                <View style={styles.formItem}>
                                  <TextInput
                                    placeholder={this.state.selectedPlanIdx === 1 ? "Company*" : "University*"}
                                    onChangeText={props.handleChange("company")}
                                    onBlur={props.handleBlur("company")}
                                    value={props.values.company}
                                    style={[
                                      styles.input,
                                      { borderBottom: "1px solid #bbbbbb" },
                                    ]}
                                  />
                                  {props.errors.company && props.touched.company && (
                                    <Text style={styles.formError}>
                                      {props.errors.company}
                                    </Text>
                                  )}
                                </View>
                                <View style={styles.formItem}>
                                  {/* <CustomPicker
                                  name="requirements"
                                  selectedValue={props.values.requirements}
                                  field={{
                                    value: props.values.requirements,
                                    error: props.errors.requirements,
                                    touched: props.touched.requirements,
                                  }}
                                  onChange={(value) => {
                                    props.setFieldValue("requirements", value);
                                  }}
                                /> */}
                                  <TextInput
                                    placeholder={"Requirements"}
                                    onChangeText={props.handleChange("requirements")}
                                    onBlur={props.handleBlur("requirements")}
                                    value={props.values.requirements}
                                    style={[
                                      styles.input,
                                      { borderBottom: "1px solid #bbbbbb" },
                                    ]}
                                  />
                                  {props.errors.requirements && props.touched.requirements ? (
                                    <Text style={styles.formError}>
                                      {props.errors.requirements}
                                    </Text>
                                  ) : null}
                                </View>
                                <View style={styles.formItem}></View>
                                <View
                                  style={{
                                    zIndex: -5,
                                  }}
                                >
                                  <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                      title={'SUBMIT'}
                                      rootStyle={{
                                        btnWrapper: [
                                          darkMode
                                            ? button.primary
                                            : button.primary,
                                          { height: 36, cursor: "pointer", marginTop: 25, width: '80%', zIndex: -5, alignItems: 'center', justifyContent: 'center' },
                                        ],
                                        btnText: [
                                          darkMode
                                            ? button.primaryText
                                            : button.primaryText,
                                          { fontWeight: "500", paddingHorizontal: 15, fontSize: 17 },
                                        ],

                                      }}
                                      onClick={() => {
                                        props.handleSubmit();
                                      }}
                                    />
                                  </View>
                                </View>
                              </View>
                            </View>
                          )}
                        </Formik>
                      </View>}
                  </TouchableWithoutFeedback>
                </View>

              </TouchableWithoutFeedback>
            </Modal> :
            null}
          <View>
            <CustomImage
              source={this.state.activeSubscription ? upgradeHeroBanner : heroBannerPricing}
              style={{ width: '100%', height: 'auto' }}
              webStyle={{ width: '100%', height: 'auto' }}
              className={'hero-banner-img'}
              altText={name}
            />
          </View>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginVertical: 20 }}>
            <View
              style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', backgroundColor: '#1E1B4B', width: '80%', height: 60, borderRadius: '30px', paddingLeft: 5, position: 'sticky', top: 0 }}>
              {PRICING_PLANS.map((plan, idx) =>
                <TouchableOpacity
                  style={{ minWidth: 200, display: 'flex', flexDirection: 'row', cursor: 'pointer', backgroundColor: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? 'white' : 'transparent', borderRadius: 30, height: '85%', zIndex: 1, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => {
                    this.setState({ selectedPlanIdx: idx })
                    if (idx === 1) {
                      this.props.navigateToScreen('/pricing/corporate')
                    } else if (idx === 2) {
                      this.props.navigateToScreen('/pricing/student')
                    } else if (idx === 3) {
                      this.props.navigateToScreen('/pricing/university')
                    } else if (idx === 4) {
                      this.props.navigateToScreen('/pricing/friends-of-tmc')
                    } else if (idx === 0) {
                      this.props.navigateToScreen("/pricing/individual")
                    }
                    this.setState({ FAQCollapsedIndices: [0] })
                  }}
                  onMouseEnter={() => {
                    this.setState({ hoverTabIdx: idx })
                    console.log("Focussed: ", idx)
                  }}
                  onMouseLeave={() => this.setState({ hoverTabIdx: -1 })}
                >
                  {idx === 4 && <CustomImage
                    source={this.state.selectedPlanIdx === idx || this.state.hoverTabIdx === idx ? friendsOfTMCIconDark : friendsOfTMCIcon}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 24, height: 24, objectFit: 'contain' }}
                    className={'friends-of-tmc-icon'}
                    altText={name}
                  />}
                  <Text
                    style={{ color: (idx === this.state.selectedPlanIdx || idx === this.state.hoverTabIdx) ? '#1E1B4B' : 'white', textAlign: 'center', fontWeight: 600, zIndex: 1, fontSize: 18, paddingHorizontal: 5 }}
                  >
                    {plan}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
          {/* Corporate plan */}
          {this.state.selectedPlanIdx === 1 && <> <View style={{ display: 'flex', justifyContent: 'center', width: '100%', rowGap: 20, marginTop: 40, marginLeft: '10%' }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
              <Text style={{ fontFamily: 'PlayfairDisplay-Bold', fontSize: 40, color: '#051462' }}>Power your people</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '60%' }}>
              <Text style={{ fontSize: 24, lineHeight: '150%' }}>90% of our readers say they subscribe to The Morning Context because it helps them work better. Whether they are running their own business, consulting, or working with startups or large corporations, in finance or fin-tech, marketing, government policy, venture funds or retail investors, academia and even students, our stories add value to their work. It helps them understand companies and their strategies, what they are getting right or wrong, so that they can bring this knowledge into their own work.</Text>
            </View>
          </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CustomImage
                source={corporateImg}
                style={{ width: '100%', height: 'auto', marginTop: 80 }}
                webStyle={{ width: '100%', height: 'auto', marginTop: 80 }}
                className={'corporate-banner-img'}
                altText={name}
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginVertical: 40, columnGap: 80 }}>
              {this.state.plansData.team?.map((plan) => <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '35%', minHeight: 400 }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description.display_name}</Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 18, fontWeight: 500 }}>{plan.description.access_desc[0]}</Text>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <View style={{ display: 'flex', flexDirection: 'column', width: '50%', rowGap: 5 }}>
                    <View>
                      <Text style={{ fontSize: 16, fontWeight: 500 }}>Enter number of seats</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, paddingVertical: 10, alignItems: 'center', width: '70%', borderWidth: 1, borderColor: '#1E1B4B', }}>
                      <TouchableOpacity onPress={() => {
                        this.setState({ quantity: this.state.quantity <= 5 ? 5 : this.state.quantity - 1 })
                        setTimeout(() => this.getEstimate(), 0)
                      }}>
                        <Text style={{ fontWeight: 500, fontSize: 18, alignSelf: 'flex-start' }}>-</Text>
                      </TouchableOpacity>
                      <TextInput
                        style={{ fontWeight: 500, fontSize: 18, alignSelf: 'center', width: '50%', textAlign: 'center' }}
                        value={this.state.quantity}
                        onChange={(e) => {
                          const { value } = e.target
                          if (parseInt(value) === NaN) return
                          this.setState({ quantity: value ? parseInt(value) : value })
                          setTimeout(() => this.getEstimate(), 0)
                        }}
                        keyboardType="numeric"
                      />
                      <TouchableOpacity onPress={() => {
                        this.setState({ quantity: this.state.quantity + 1 })
                        setTimeout(() => this.getEstimate(), 0)
                      }}>
                        <Text style={{ fontWeight: 500, fontSize: 18, alignSelf: 'flex-end' }}>+</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                    <Text style={{ fontSize: 38, fontWeight: 700, color: '#051462' }}>{this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? '$' : '₹'}{this.state.totalAmount}
                      {/* <Text style={{ textDecorationLine: 'line-through', fontSize: 18, fontWeight: 400, opacity: 0.6, verticalAlign: 'top' }}>
                        {this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? '$' : '₹'}{this.state.corporate_price_without_discount}
                      </Text> */}
                    </Text>
                    <Text style={{ fontSize: 12, fontWeight: 400, opacity: 0.6 }}>Billed yearly</Text>
                  </View>
                </View>
                {this.state.quantity < 5 ?
                  <View>
                    <Text style={{ fontSize: 14, color: '#F02121' }}>*Minimum number of seats is 5</Text>
                  </View> : null}
                <TouchableOpacity onPress={() => {
                  if (this.state.quantity < 5) return
                  if (this.state.isButtonClicked) return
                  this.setState({ isButtonClicked: true })
                  if (!this.state.token) {
                    this.props.navigateToScreen(
                      `/sign-in?redirect=/pricing&redirect-params=subscribe=${this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                        'team-plan-4-usd'
                        :
                        'team-plan-4-inr'
                      }&quantity=${this.state.quantity}`
                    );
                    return
                  }
                  if (this.state.userSubscription?.plan?.tmc_group === 'team') {
                    Linking.openURL(`${window.location.origin}/my-team`, '_blank')
                    return
                  } else if (this.state.userSubscription?.plan?.tmc_group === 'friends-of-tmc') {
                    return
                  }
                  if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'team') {
                    this.handleRenew()
                    return
                  }
                  if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN") {
                    this.setState({ selectedSubscribtion: { id: 'team-plan-4-usd', billing_enitity_type: 'plan', price: this.state.totalAmount }, selectedSubscribtionID: 'team-plan-4-usd' })
                  } else {
                    this.setState({ selectedSubscribtion: { id: 'team-plan-4-inr', billing_enitity_type: 'plan', price: this.state.totalAmount }, selectedSubscribtionID: 'team-plan-4-inr' })
                  }
                  setTimeout(() => this.proceedToCheckoutScreen(), 0)
                }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B', opacity: this.state.userSubscription?.plan?.tmc_group === 'friends-of-tmc' || this.state.isButtonClicked ? 0.5 : null }}>
                  <Text style={{ textAlign: 'center', fontWeight: 500, fontSize: 17, color: 'white' }}>
                    {this.state.userSubscription?.plan?.tmc_group === 'team' ? this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined ? "RENEW" : 'MANAGE TEAM' : "PROCEED TO CHECKOUT"}
                  </Text>
                </TouchableOpacity>
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                {
                  plan.description.owner_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>{desc}</Text>
                  </View>)
                }
                <View style={{ display: 'flex', flexDirection: 'row', columnGap: 5, marginTop: 20 }}>
                  <Text style={{ fontSize: 16, fontWeight: 500 }}>Need help?</Text>
                  <TouchableOpacity onPress={() => {
                    this.setState({ showContactUsModal: true })
                  }}>
                    <Text style={{ fontSize: 16, fontWeight: 500, textDecorationLine: 'underline' }}>Contact us</Text>
                  </TouchableOpacity>
                </View>
              </View>)}
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '35%' }}>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '30%', height: 30, bottom: 35, alignSelf: 'center' }}>
                  <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>FREE 7-DAY TRIAL</Text>
                </View>
                <View style={{ position: 'relative', bottom: 50, display: 'flex', rowGap: 20 }}>
                  <View>
                    <View>
                      <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>Engaged</Text>
                    </View>
                    <View style={{ display: 'flex', width: '90%', marginTop: '1vh', flexDirection: 'column' }}>
                      <View style={{ flex: 1 }}><Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%', fontWeight: 500 }}>Purchase plan for the whole organization.</Text></View>
                      <Text />
                      <View><Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%', fontWeight: 500 }}>Pay only for engaged readers, the rest read free.</Text></View>
                    </View>
                  </View>
                  <View style={{ display: 'flex', height: 32 }}>
                    {/* <View>
                    <Text style={{ color: '#051462', fontSize: 40, fontWeight: 800 }}>₹3,201</Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 14 }}>At ₹267/month, billed yearly</Text>
                  </View> */}
                  </View>
                  <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B' }}>
                    <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                  </TouchableOpacity>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 40 }}>
              <View style={{ display: 'flex', width: '80%' }}><Text style={{ fontFamily: 'PlayfairDisplay-Bold', fontSize: 34, color: '#051462' }}>How fixed plan subscription works?</Text></View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingHorizontal: '10%' }}>
                <View style={{ display: 'flex', width: '30%', marginTop: 40, rowGap: 10, marginBottom: 80 }}>
                  <View style={{ display: 'flex' }}>
                    <CustomImage
                      source={purchaseSeatsIcon}
                      style={{ width: 'auto', height: 40 }}
                      webStyle={{ width: 40, height: 40 }}
                      className={'purchase-seats-icon'}
                      altText={name}
                    />
                  </View>
                  <View><Text style={{ fontWeight: 600, fontSize: 18 }}>Purchase Seats</Text></View>
                  <View><Text style={{ fontSize: 16 }}>Purchase plan for a fixed number of seats. The number of seats can be increased during the term of the subscription, or upon renewal.</Text></View>
                </View>
                <View style={{ display: 'flex', width: '30%', marginTop: 40, rowGap: 10, marginBottom: 80 }}>
                  <View style={{ display: 'flex' }}>
                    <CustomImage
                      source={inviteMembersCorporateIcon}
                      style={{ width: 'auto', height: 40 }}
                      webStyle={{ width: 40, height: 40 }}
                      className={'purchase-seats-icon'}
                      altText={name}
                    />
                  </View>
                  <View><Text style={{ fontWeight: 600, fontSize: 18 }}>Invite Members</Text></View>
                  <View><Text style={{ fontSize: 16 }}>Post-purchase you can access the admin panel and invite members from your organization</Text></View>
                </View>
                <View style={{ display: 'flex', width: '30%', marginTop: 40, rowGap: 10, marginBottom: 80 }}>
                  <View style={{ display: 'flex' }}>
                    <CustomImage
                      source={startReadingIcon}
                      style={{ width: 'auto', height: 40 }}
                      webStyle={{ width: 28.47, height: 40 }}
                      className={'purchase-seats-icon'}
                      altText={name}
                    />
                  </View>
                  <View><Text style={{ fontWeight: 600, fontSize: 18 }}>Start Reading</Text></View>
                  <View><Text style={{ fontSize: 16 }}>Members can accept the invite, sign in and have unfettered access to everything The Morning Context publishes.</Text></View>
                </View>
              </View>
            </View> </>}
          {/* Corporate plan ends here */}
          {/* Student plan starts here */}
          {this.state.selectedPlanIdx === 2 && <>
            <View style={{ display: 'flex', width: '100%', paddingLeft: '10%', marginBottom: 40, marginTop: 60 }}>
              <Text style={{ width: '70%', fontSize: 24, lineHeight: '150%' }}>
                More than 5,000 students read The Morning Context, either through a subscription from their University or through their own individual subscription. Students enjoy a special discount compared to our standard individual subscription.
              </Text>
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginVertical: 80 }}>
              <CustomImage
                source={studentImg}
                style={{ width: '100%', height: 'auto' }}
                webStyle={{ width: '100%', height: 'auto' }}
                className={'student-plan-img'}
                altText={name}
              />
            </View>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <View style={{ display: 'flex', width: '80%' }}>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', zIndex: -1, backgroundColor: '#FFF3E3' }}>
                  <CustomImage
                    source={calloutIllustration}
                    style={{ width: '100%', height: 'auto', backgroundColor: '#FFF3E3' }}
                    webStyle={{ width: '100%', height: 'auto', backgroundColor: '#FFF3E3' }}
                    className={'student-plan-img'}
                    altText={name}
                  />
                </View>
                {/* <View style={{ display: 'flex', width: '40%', zIndex: 1, position: 'relative', bottom: 200 }}>
                <Text>We do not offer refunds if your student ID verification fails</Text>
                <Text>You will need to submit a valid ID which specifically identifies you as a student:</Text>
                <Text>The name on this ID and details of your subscription purchased need to match.</Text>
                <Text>Student IDs of minors who are below 18 years is not acceptable.</Text>
                <Text>This discounted plan is designed for the benefit of genuine students.</Text>
              </View> */}
              </View>
              {this.state.userSubscription?.plan?.tmc_group && <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: 'rgb(255, 61, 0)', padding: 10, width: '60%', marginTop: 60 }}>
                <Text style={{ fontSize: 16, color: 'rgb(255, 61, 0)', width: '80%', textAlign: 'center' }}>
                  You cannot purchase a student subscription as you are already subscribed to {this.state.userSubscription?.plan?.tmc_group} plan
                </Text>
              </View>}
            </View> </>}
          {this.state.selectedPlanIdx === 2 &&
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginVertical: 40, marginTop: 80, columnGap: 80 }}>
              {this.state.plansData?.student?.filter((plan) => {
                if (this.state.userSubscription?.plan?.tmc_group !== 'student') {
                  return true
                }
                return parseInt(plan.chargebee_price) > this.state.userSubscription.subscribed_plan_amount
              }).map((plan) => <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '35%', minHeight: 400 }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description?.display_name}</Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 18 }}>{plan.description.is_annual ? "Annual plan" : "Monthly plan"}</Text>
                  </View>
                </View>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
                      {`${plan.currency_code === "USD" ?
                        `$${plan.description.monthly_price} / month`
                        : `₹${plan.description.monthly_price} / month`
                        }`}
                      {/* <Text style={{ textDecorationLine: 'line-through', fontSize: 18, fontWeight: 400, opacity: 0.6, verticalAlign: 'top' }}>₹3,201</Text>*/}
                    </Text>
                  </View>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 14 }}>
                      {`Billed ${plan.currency_code === "USD" ? `$${parseFloat(plan.chargebee_price) / 100}` : `₹${parseFloat(plan.chargebee_price) / 100}`} now for annual access`}
                    </Text>
                  </View>
                </View>
                <Button
                  title={this.state.activeSubscription && this.state.userSubscription?.plan?.tmc_group === 'student' ? "YOUR CURRENT PLAN" :
                    this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'student' ?
                      "RENEW" :
                      "SUBSCRIBE NOW"}
                  loader={this.state.isButtonClicked}
                  rootStyle={{
                    btnWrapper: [button.primary,
                    { height: 36, cursor: "pointer", marginTop: 25, width: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1E1B4B', opacity: this.state.activeSubscription && this.state.userSubscription?.plan?.tmc_group === 'student' ? 1 : this.state.activeSubscription || this.state.isButtonClicked ? 0.5 : 1, marginTop: 15 },
                    ],
                    btnText: [button.primaryText,
                    { textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white', paddingHorizontal: 15, fontSize: 17, fontFamily: "HaasGrotesk-Medium" },
                    ],

                  }}
                  onClick={() => {
                    if (this.state.isButtonClicked) return
                    this.setState({ isButtonClicked: true })
                    if (!this.state.token) {
                      this.props.navigateToScreen(
                        `/sign-in?redirect=/pricing&redirect-params=subscribe=${plan.id}&quantity=1`
                      );
                      return
                    }
                    if (this.state.userSubscription?.plan?.tmc_group === 'student') {
                      if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'student') {
                        this.handleRenew()
                        return
                      }
                    }
                    if (this.state.activeSubscription) {
                      return
                    }
                    this.setState({ selectedSubscribtion: { id: plan.id, billing_enitity_type: plan.billing_enitity_type, price: plan.chargebee_price }, selectedSubscribtionID: plan.id })
                    setTimeout(() => this.proceedToCheckoutScreen(), 0);
                  }}
                />
                {this.state.userSubscription?.plan?.tmc_group === 'student' && <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                  <Text style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>Your current plan will expire on {moment(this.state.userSubscription?.next_billing_at).format('Do MMMM YYYY')}</Text>
                </View>}
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                {plan.description.access_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>{desc}</Text>
                </View>)}
              </View>)}
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '35%' }}>
                <View>
                  <View>
                    <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>Dedicated plan for your college</Text>
                  </View>
                  <View style={{ display: 'flex', width: '90%', marginTop: 10 }}>
                    <Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%' }}>If you're a faculty member seeking a tailored plan for your campus, please contact us. We'll reach out to you personally to discuss and provide the perfect plan to meet your specific requirements.</Text>
                  </View>
                </View>
                {/* <View>
                <View>
                  <Text style={{ color: '#051462', fontSize: 24, fontWeight: 800 }}>₹3,201</Text>
                </View>
                <View>
                  <Text style={{ color: '#051462', fontSize: 10 }}>At ₹267/month, billed yearly</Text>
                </View>
              </View> */}
                <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B' }}>
                  <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                </TouchableOpacity>
                <View>
                  <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                  <CustomImage
                    source={greenTick}
                    style={{ width: 10, height: 10, objectFit: 'contain' }}
                    webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                    className={'green-tick-icon'}
                    altText={name}
                  />
                  <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                </View>
              </View>
            </View>}
          {/* Student plan ends here */}
          {/* Teacher/University plan starts here */}
          {this.state.selectedPlanIdx === 3 &&
            <View style={{ display: 'flex', width: '100%', paddingLeft: '10%', marginTop: 60 }}>
              <View style={{ display: 'flex', width: '70%', }}>
                <Text style={{ fontSize: 24, lineHeight: '150%' }}>Several amongst the top 50 management colleges and universities in India, including some of the top universities in the US, UK and Southeast Asia have a subscription of The Morning Context. Faculty and students value The Morning Context’s journalism to get a true and deep perspective on the Indian business, technology and economic landscape. Our stories help in stimulating classroom discussions, case studies and faculty research.</Text>
              </View>
            </View>}
          {this.state.selectedPlanIdx === 3 &&
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 80 }}>
              <CustomImage
                source={teacherUniversityPlan}
                style={{ width: '100%', height: 'auto' }}
                webStyle={{ width: '100%', height: 'auto' }}
                className={'teacher-university-plan-img'}
                altText={name}
              />
            </View>
          }
          {
            this.state.selectedPlanIdx === 3 &&
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginVertical: 40, marginTop: 80, columnGap: 80 }}>
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '35%', minHeight: 400 }}>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '30%', height: 30, bottom: 35, alignSelf: 'center' }}>
                  <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>FREE 7-DAY TRIAL</Text>
                </View>
                <View style={{ position: 'relative', bottom: 50, display: 'flex', rowGap: 20 }}>
                  <View style={{ marginBottom: 30 }}>
                    <View>
                      <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>Teacher</Text>
                    </View>
                    <View>
                      <Text style={{ color: '#051462', fontSize: 18 }}>Full access to archives</Text>
                    </View>
                  </View>
                  <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B', marginTop: 10 }}>
                    <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                  </TouchableOpacity>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                  </View>
                </View>
              </View>
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 15, width: '35%' }}>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '30%', height: 30, bottom: 35, alignSelf: 'center' }}>
                  <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>FREE 7-DAY TRIAL</Text>
                </View>
                <View style={{ position: 'relative', bottom: 50, display: 'flex', rowGap: 20 }}>
                  <View>
                    <View>
                      <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>University/Librarian</Text>
                    </View>
                    <View style={{ display: 'flex', width: '80%', marginTop: 10 }}>
                      <Text style={{ color: '#051462', fontSize: 18, lineHeight: '150%' }}>Purchase subscription for the entire college or specific departments</Text>
                    </View>
                  </View>
                  <TouchableOpacity onPress={() => this.setState({ showContactUsModal: true })} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: '#1E1B4B' }}>
                    <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>CONTACT US</Text>
                  </TouchableOpacity>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Admin dashboard</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Unrestricted access</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16 }}>Exclusive events and benefits</Text>
                  </View>
                </View>
              </View>
            </View>
          }
          {/* Teacher/University plan ends here */}
          {/* Friends of TMC starts here */}
          {
            this.state.selectedPlanIdx === 4 &&
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'relative', bottom: 120, zIndex: -1 }}>
              <CustomImage
                source={friendsOfTMC}
                style={{ width: '100%', height: 'auto' }}
                webStyle={{ width: '100%', height: 'auto' }}
                className={'friends-of-tmc'}
                altText={name}
              />
            </View>
          }
          {
            this.state.selectedPlanIdx === 4 &&
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#292929', position: 'relative', bottom: 120, paddingTop: 80 }}>
              <View style={{ display: 'flex', width: '60%' }}>
                <Text style={{ color: 'white', fontSize: 34, fontWeight: 600, fontFamily: 'PlayfairDisplay-Bold', textAlign: 'center' }}>
                  Friends of The Morning Context
                </Text>
              </View>
              <View style={{ display: 'flex', width: '60%', marginTop: 40 }}>
                <Text style={{ color: 'white', textAlign: 'center', fontSize: 18 }}>
                  The Morning Context was founded with the mission India needs a media company which holds companies and people in power to account, in the world of technology and business. Our thesis has been that business news faces three challenges:
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingHorizontal: '10%', marginTop: 40 }}>
                <View style={{ display: 'flex', width: '22%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', columnGap: 10 }}>
                  <Text style={{ fontSize: 48, color: 'white', fontWeight: 900 }}>1</Text>
                  <Text style={{ color: 'white' }}>Traditional media churns out hundreds of articles everyday, mostly commodity stuff.</Text>
                </View>

                <View style={{ display: 'flex', width: '22%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', columnGap: 10 }}>
                  <Text style={{ fontSize: 48, color: 'white', fontWeight: 900 }}>2</Text>
                  <Text style={{ color: 'white' }}>Advertising based business models, including media companies which double up as content studios for companies, are very often compromised by vested interests.</Text>
                </View>
                <View style={{ display: 'flex', width: '22%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', columnGap: 10 }}>
                  <Text style={{ fontSize: 48, color: 'white', fontWeight: 900 }}>3</Text>
                  <Text style={{ color: 'white' }}>Social media has created echo chambers and disinformation, a bubble that needs piercing.</Text>
                </View>
              </View>
              <View style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingHorizontal: '10%', marginTop: 40, marginBottom: 80 }}>
                <View style={{ display: 'flex', width: '45%' }}>
                  <Text style={{ color: 'white' }}>It does not help that India ranks 161 out of 180 countries on the Press Freedom Index, an abysmal rank.</Text>
                  <CustomImage
                    source={pressFreedomIndex}
                    style={{ width: '100%', height: 'auto', marginTop: 20 }}
                    webStyle={{ width: '100%', height: 'auto', marginTop: 20 }}
                    className={'press-freedom-index'}
                    altText={name}
                  />
                </View>
                <View style={{ display: 'flex', width: '45%' }}>
                  <Text style={{ color: 'white' }}>Friends of The Morning Context is a community of supporters who share our values and want us and our brand of journalism to succeed.</Text>
                  <CustomImage
                    source={tmcSupporters}
                    style={{ width: '100%', height: 'auto', marginTop: 20 }}
                    webStyle={{ width: '100%', height: 'auto', marginTop: 20 }}
                    className={'TMC-Supporters'}
                    altText={name}
                  />
                </View>
              </View>
            </View>
          }
          {
            this.state.selectedPlanIdx === 4 &&
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <View style={{ display: 'flex', width: '80%' }}>
                <Text style={{ fontSize: 34, fontWeight: 600, fontFamily: 'PlayfairDisplay-Bold', color: '#051462' }}>
                  Support Us
                </Text>
              </View>
              <View style={{ display: 'flex', width: '80%', justifyContent: 'center', flexDirection: 'row', backgroundColor: '#FFF3E3', marginTop: 40, paddingVertical: 32 }}>
                <View style={{ display: 'flex', width: '45%', rowGap: 5 }}>
                  <View><Text style={{ fontFamily: 'Matteo-medium', fontSize: 32, fontWeight: 700, color: '#1E1B4B' }}>Friends of The Morning Context</Text></View>
                  <View><Text style={{ fontSize: 18, color: '#1E1B4B', fontWeight: 500 }}>One time payment</Text></View>
                  <View><Text style={{ fontSize: 38, fontWeight: 700, marginTop: 24, color: '#1E1B4B' }}>
                    {this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? '$1,999' : '₹1,49,000'}
                  </Text></View>
                  <View style={{ display: 'flex', width: '80%', marginTop: 'auto' }}>
                    <Button
                      title={this.state.userSubscription?.plan?.tmc_group === 'friends-of-tmc' ? "YOUR CURRENT PLAN" : this.state.activeSubscription ? "UPGRADE" : "PROCEED TO CHECKOUT"}
                      rootStyle={{
                        btnWrapper: [
                          darkMode
                            ? button.primary
                            : button.primary,
                          { height: 36, cursor: "pointer", marginTop: 25, width: '80%', alignItems: 'center', justifyContent: 'center', opacity: ['team', 'friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null },
                        ],
                        btnText: [
                          darkMode
                            ? button.primaryText
                            : button.primaryText,
                          { fontWeight: "500", paddingHorizontal: 15, fontSize: 17 },
                        ],

                      }}
                      onClick={() => {
                        if (this.state.isButtonClicked) return
                        this.setState({ isButtonClicked: true })
                        if (!this.state.token) {
                          this.props.navigateToScreen(
                            `/sign-in?redirect=/pricing&redirect-params=subscribe=${this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                              TMC_PLANS[8].plan_id
                              :
                              TMC_PLANS[4].plan_id
                            }&quantity=1`
                          );
                          return
                        }
                        if (['team', 'friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group)) return
                        if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN") {
                          this.setState({ selectedSubscribtion: { id: TMC_PLANS[8].plan_id, billing_enitity_type: 'plan', price: TMC_PLANS[8].price }, selectedSubscribtionID: TMC_PLANS[8].plan_id })
                          setTimeout(() => this.proceedToCheckoutScreen(), 0)
                        } else {
                          this.setState({ selectedSubscribtion: { id: TMC_PLANS[4].plan_id, billing_enitity_type: 'plan', price: TMC_PLANS[4].price }, selectedSubscribtionID: TMC_PLANS[4].plan_id })
                          setTimeout(() => this.proceedToCheckoutScreen(), 0)
                        }
                      }}
                    />
                  </View>
                </View>
                <View style={{ display: 'flex', width: '45%', rowGap: 20 }}>
                  <View><Text style={{ fontWeight: 500, fontSize: 16 }}>What's Included</Text></View>
                  <View style={{ display: 'flex', rowGap: 20 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Lifetime subscription to The Morning Context</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Invite to an exclusive Friends of The Morning Context event once a year with the founding team</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Privilege seats for subscriber only events</Text>
                    </View>
                    {/* <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Gift five annual subscriptions to friends and family</Text>
                    </View> */}
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Gift 3 subscriber only stories to friends every month</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Access to all our archives of 1000+ stories</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Read on our Android & iOS app</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ fontSize: 16, fontWeight: 400, lineHeight: '150%' }}>Cancel or upgrade anytime</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          }
          {/* Friends of TMC ends here */}
          {/* Carousel built from scratch */}
          {
            (this.state.selectedPlanIdx === 2 || this.state.selectedPlanIdx === 3) &&
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 60 }}>
              <View style={{ display: 'flex', width: '40%' }}>
                <View style={{ display: 'flex', width: '40%', marginLeft: '30%' }}>
                  <Text style={{ fontSize: 34, fontFamily: 'PlayfairDisplay-Bold', color: '#051462' }}>Colleges that read us</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', columnGap: 30, marginLeft: '30%', marginTop: 20 }}>
                  <TouchableOpacity style={{ borderWidth: 1, paddingHorizontal: 10 }} onPress={() => {
                    console.log("Position :", this.state.sliderRefPosition)
                    this.setState({ sliderRefPosition: Math.max(0, this.state.sliderRefPosition - 400) })
                    console.log("Position :", this.state.sliderRefPosition)
                    this.sliderRef.current.scrollTo({ x: this.state.sliderRefPosition - 400 })
                  }}>
                    <Text style={{ fontSize: 24 }}>←</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={{ borderWidth: 1, paddingHorizontal: 10 }} onPress={() => {
                    console.log("Position :", this.state.sliderRefPosition)
                    this.setState({ sliderRefPosition: Math.min(this.state.sliderRefPosition + 400, this.state.sliderRefWidth) })
                    console.log("Position :", this.state.sliderRefPosition)
                    this.sliderRef.current.scrollTo({ x: this.state.sliderRefPosition + 400 })
                  }}>
                    <Text style={{ fontSize: 24 }}>→</Text>
                  </TouchableOpacity>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                  <Text></Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '60%', }}>
                <ScrollView horizontal style={{ display: 'flex', flexDirection: 'row', columnGap: 20 }} onLayout={this.handleLayout} ref={this.sliderRef} showsHorizontalScrollIndicator={false} scrollEnabled={false}>
                  <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                    <CustomImage
                      source={iimAhmedabadLogo}
                      style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Ahmedabad</Text>
                  </View>
                  <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                    <CustomImage
                      source={iimAmritsarLogo}
                      style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Amritsar</Text>
                  </View>
                  <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                    <CustomImage
                      source={iimKozikhodeLogo}
                      style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Kozikhode</Text>
                  </View>
                  <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                    <CustomImage
                      source={iitDelhiLogo}
                      style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIT Delhi</Text>
                  </View>
                  <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                    <CustomImage
                      source={iimIndore}
                      style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10 }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>IIM Indore</Text>
                  </View>
                  <View style={{ display: 'flex', width: 200, height: 200, marginRight: 20, backgroundColor: '#FFF3E3' }}>
                    <CustomImage
                      source={isbLogo}
                      style={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10, maxWidth: 175, objectFit: 'contain' }}
                      webStyle={{ width: 'auto', height: 100, alignSelf: 'center', marginTop: 10, maxWidth: 175, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 600, marginTop: 30 }}>Indian School of Business</Text>
                  </View>
                </ScrollView>
              </View>
            </View>
          }
          {/* Carousel built from scratch ends here */}
          {/* Carousel ends here */}

          {this.state.selectedPlanIdx === 0 && <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 40 }}>
            <View style={{ width: '80%', display: 'flex', flexDirection: 'row', columnGap: '5%' }}>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <CustomImage
                  source={greenTick}
                  style={{ width: 10, height: 10, objectFit: 'contain' }}
                  webStyle={{ width: 15, height: 15, objectFit: 'contain', marginRight: 5 }}
                  className={'green-tick-icon'}
                  altText={name}
                />
                <Text style={{ fontSize: 18, fontWeight: 500, color: '#051462' }}>Pick your plan</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <CustomImage
                  source={greenTick}
                  style={{ width: 10, height: 10 }}
                  webStyle={{ width: 15, height: 15, marginRight: 5, objectFit: 'contain' }}
                  className={'green-tick-icon'}
                  altText={name}
                />
                <Text style={{ fontSize: 18, fontWeight: 500, color: '#051462' }}>Transparent pricing</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <CustomImage
                  source={greenTick}
                  style={{ width: 10, height: 10 }}
                  webStyle={{ width: 15, height: 15, marginRight: 5, objectFit: 'contain' }}
                  className={'green-tick-icon'}
                  altText={name}
                />
                <Text style={{ fontSize: 18, fontWeight: 500, color: '#051462' }}>Change or cancel anytime</Text>
              </View>
            </View>
          </View>}
          {this.state.selectedPlanIdx === 0 && (!this.state.hasUserMovedOverseas || this.state.activeSubscription) && <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginVertical: 40, columnGap: 40 }}>
            {/* Plans array starts here */}
            {
              this.state.activeSubscription && (this.state.userSubscription?.plan?.tmc_group === "individual" || this.state.userSubscription?.plan?.tmc_group === "exclusive") ?
                this.showActivePlan() : <></>
            }
            {
              this.state.plansData && this.state.plansData.individual?.filter((plan) => {
                if (!this.state.activeSubscription) return true
                if (['team', 'friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group)) return true
                console.log("User info plans", parseInt(plan.chargebee_price) / 100, " and ", this.state.userSubscription.subscribed_plan_amount)
                if (this.state.activeSubscription && this.state.hasUserMovedOverseas) return false
                if (this.state.userSubscription?.plan?.id == 'institution-plan-inr') return false
                if (this.state.userSubscription?.plan?.tmc_group === 'exclusive') return false
                return parseInt(plan.chargebee_price) > parseInt(this.state.userSubscription?.plan?.chargebee_price)
              }).map((plan) =>
                <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 20, width: '27%', minHeight: 400, borderColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : '#E7D6C9', borderWidth: 1 }}>
                  {plan.description.remark ? plan.description.remark === "MOST POPULAR" ? this.mostPopularPlanTag() : <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '60%', height: 30, bottom: 35, alignSelf: 'center' }}>
                    <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>{plan.description.remark}</Text>
                  </View> : <></>}
                  <View style={plan.description.remark ? { position: 'relative', bottom: 50, display: 'flex', rowGap: 20 } : { display: 'flex', rowGap: 20, }}>
                    <View>
                      <View>
                        <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description?.display_name?.toUpperCase()}</Text>
                      </View>
                      <View>
                        <Text style={{ color: '#051462', fontSize: 18 }}>{plan.name}</Text>
                      </View>
                    </View>
                    <View>
                      <View>
                        <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
                          {plan.description.is_annual ?
                            `${plan.currency_code === "USD" ?
                              `$${plan.description.monthly_price} / month`
                              : `₹${plan.description.monthly_price} / month`
                            }` :
                            `${plan.currency_code === "USD" ?
                              `$${parseFloat(plan.chargebee_price) / 100} / month`
                              : `₹${parseInt(plan.chargebee_price) / 100} / month`}
                          `.trim()}
                        </Text>
                      </View>
                      <View>
                        <Text style={{ color: '#051462', fontSize: 14 }}>
                          {plan.description.is_annual ?
                            plan.description.display_name === "Ace" ?
                              <Text>Billed {plan.currency_code === "USD" ? <Text> <Text style={{ textDecorationLine: 'line-through' }}>$250</Text> ${parseFloat(plan.chargebee_price) / 100} </Text> :
                                <Text> <Text style={{ textDecorationLine: 'line-through' }}>₹6898</Text> ₹{parseFloat(plan.chargebee_price) / 100} </Text>} now for two year access</Text>
                              :
                              <Text>Billed {plan.currency_code === "USD" ? <Text><Text style={{ textDecorationLine: 'line-through' }}>$125</Text> ${parseFloat(plan.chargebee_price) / 100}</Text> :
                                <Text><Text style={{ textDecorationLine: 'line-through' }}>₹3449</Text> ₹{parseFloat(plan.chargebee_price) / 100}</Text>} now for annual access</Text>
                            :
                            <Text>Billed every month</Text>}
                        </Text>
                        {/* {plan.description.is_annual ?
                          <Text style={{ fontWeight: 'bold', color: '#117A51' }}>Ends 29th February at 11:59 PM {plan.currency_code === "USD" ? "PST" : "IST"}</Text>
                          :
                          null} */}
                      </View>
                    </View>
                    <TouchableOpacity onPress={() => {
                      if (this.state.isButtonClicked) return
                      this.setState({ isButtonClicked: true })
                      if (!this.state.token) {
                        this.props.navigateToScreen(
                          `/sign-in?redirect=/pricing&redirect-params=subscribe=${plan.id}&quantity=1`
                        );
                        return
                      }
                      /**
                       * Function to open chargebee box
                       */
                      if (['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                        return
                      }
                      if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id) {
                        /**
                         * Renew the current plan
                         */

                        this.handleRenew()
                        return
                      }
                      this.setState({ selectedSubscribtion: { id: plan.id, billing_enitity_type: plan.billing_enitity_type, price: plan.chargebee_price }, selectedSubscribtionID: plan.id })
                      setTimeout(() => this.proceedToCheckoutScreen(), 0)
                    }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : null, opacity: ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null }}>
                      <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: plan.description.remark && plan.description.remark === "MOST POPULAR" ? 'white' : 'black' }}>
                        {this.state.activeSubscription ? "UPGRADE" : this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id ? "RENEW" : "SELECT PLAN"}
                      </Text>
                    </TouchableOpacity>
                    <View>
                      <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                    </View>
                    {plan.description.access_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
                    </View>)}
                    {plan.description?.limitations_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                      <CustomImage
                        source={crossIconRed}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
                    </View>)}
                  </View>
                </View>)
            }
            {/* Plans array ends here */}
          </View>}
          {
            this.state.selectedPlanIdx === 0 && this.state.hasUserMovedOverseas && !this.state.activeSubscription ? <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginVertical: 40, columnGap: 40 }}>
              {/* Plans array starts here */}
              {
                this.state.overseasShiftedUsersPlanData && this.state.overseasShiftedUsersPlanData?.map((plan) => {
                  console.log("Debug overseas plans: ", plan)
                  return <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 20, width: '27%', minHeight: 400, borderColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : '#E7D6C9', borderWidth: 1 }}>
                    {plan.description.remark ? plan.description.remark === "MOST POPULAR" ? this.mostPopularPlanTag() : <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '60%', height: 30, bottom: 35, alignSelf: 'center' }}>
                      <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>{plan.description.remark}</Text>
                    </View> : <></>}
                    <View style={plan.description.remark ? { position: 'relative', bottom: 50, display: 'flex', rowGap: 20 } : { display: 'flex', rowGap: 20, }}>
                      <View>
                        <View>
                          <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description?.display_name?.toUpperCase()}</Text>
                        </View>
                        <View>
                          <Text style={{ color: '#051462', fontSize: 18 }}>{plan.name}</Text>
                        </View>
                      </View>
                      <View>
                        <View>
                          <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
                            {plan.description.is_annual ?
                              `${plan.currency_code === "USD" ?
                                `$${plan.description.monthly_price} / month`
                                : `₹${plan.description.monthly_price} / month`
                              }` :
                              `${plan.currency_code === "USD" ?
                                `$${parseFloat(plan.chargebee_price) / 100} / month`
                                : `₹${parseInt(plan.chargebee_price) / 100} / month`}
                          `.trim()}
                          </Text>
                        </View>
                        <View>
                          <Text style={{ color: '#051462', fontSize: 14 }}>
                            {plan.description.is_annual ?
                              plan.description.display_name === "Ace" ?
                                <Text>Billed {plan.currency_code === "USD" ? <Text> <Text style={{ textDecorationLine: 'line-through' }}>$250</Text> ${parseFloat(plan.chargebee_price) / 100} </Text> :
                                  <Text> <Text style={{ textDecorationLine: 'line-through' }}>₹6898</Text> ₹{parseFloat(plan.chargebee_price) / 100} </Text>} now for two year access</Text>
                                :
                                <Text>Billed {plan.currency_code === "USD" ? <Text><Text style={{ textDecorationLine: 'line-through' }}>$125</Text> ${parseFloat(plan.chargebee_price) / 100}</Text> :
                                  <Text><Text style={{ textDecorationLine: 'line-through' }}>₹3449</Text> ₹{parseFloat(plan.chargebee_price) / 100}</Text>} now for annual access</Text>
                              :
                              <Text>Billed every month</Text>}
                          </Text>
                          {/* {plan.description.is_annual ?
                            <Text style={{ fontWeight: 'bold', color: '#117A51' }}>Ends 29th February at 11:59 PM {plan.currency_code === "USD" ? "PST" : "IST"}</Text>
                            :
                            null} */}
                        </View>
                      </View>
                      <TouchableOpacity onPress={() => {
                        if (this.state.isButtonClicked) return
                        this.setState({ isButtonClicked: true })
                        if (!this.state.token) {
                          this.props.navigateToScreen(
                            `/sign-in?redirect=/pricing&redirect-params=subscribe=${plan.id}&quantity=1`
                          );
                          return
                        }
                        /**
                         * Function to open chargebee box
                         */
                        if (['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                          return
                        }
                        if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id) {
                          /**
                           * Renew the current plan
                           */

                          this.handleRenew()
                          return
                        }
                        this.setState({ selectedSubscribtion: { id: plan.id, billing_enitity_type: plan.billing_enitity_type, price: plan.chargebee_price }, selectedSubscribtionID: plan.id })
                        setTimeout(() => this.proceedToCheckoutScreen(), 0)
                      }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : null, opacity: ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null }}>
                        <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: plan.description.remark && plan.description.remark === "MOST POPULAR" ? 'white' : 'black' }}>
                          {this.state.activeSubscription ? "UPGRADE" : this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id ? "RENEW" : "SELECT PLAN"}
                        </Text>
                      </TouchableOpacity>
                      <View>
                        <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                      </View>
                      {plan.description.access_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                        <CustomImage
                          source={greenTick}
                          style={{ width: 10, height: 10, objectFit: 'contain' }}
                          webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                          className={'green-tick-icon'}
                          altText={name}
                        />
                        <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
                      </View>)}
                    </View>
                  </View>
                })
              }
              {/* Plans array ends here */}
            </View> : null
          }
          {/* Sinch integration */}
          {/* <a href="https://www.messengerpeople.com/">Hello</a>
          <script
            src="https://cdn.messengerpeople.com/widget.chip.js"
            data-widget="mpWidget"
            data-id="168876597c4cb721979.49895164"
          >Widget</script> */}
          {<SupportWidget />}
          {/* Why subscribe to the morning context section */}
          {this.state.selectedPlanIdx === 0 && <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
            <View style={{ display: 'flex', width: '100%', objectFit: 'cover' }}>
              <CustomImage
                source={whySubscribeBg}
                style={{ width: '100%', height: 'auto', zIndex: 0 }}
                webStyle={{ width: '100%', height: 'auto', zIndex: 0 }}
                className={'why-subscribe-img'}
                altText={name}
              />
            </View>
            <View style={{ position: 'relative', bottom: 300, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', zIndex: 1 }}>
              <Text style={{ color: '#292929', fontWeight: 700, fontSize: 34, fontFamily: 'PlayfairDisplay-Bold' }}>
                Why Subscribe to The Morning Context?
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start', width: '100%', position: 'relative', bottom: 200 }}>
              <View style={{ display: 'flex', width: '25%', justifyContent: 'center', alignItems: 'center', rowGap: 10 }}>
                <View style={{ width: '100%', display: 'flex' }}>
                  <Text style={{ fontSize: 24, fontWeight: 700, color: '#1E1B4B' }}>Original Journalism</Text>
                </View>
                <Text style={{ fontSize: 16 }}>
                  Gain access to top-notch reporting, analysis, and opinion written by experienced journalists and subject matter experts.
                </Text>
              </View>
              <View style={{ display: 'flex', width: '25%', justifyContent: 'center', alignItems: 'center', rowGap: 10 }}>
                <View style={{ width: '100%', display: 'flex' }}>
                  <Text style={{ fontSize: 24, fontWeight: 700, color: '#1E1B4B' }}>Detailed Perspectives</Text>
                </View>
                <Text style={{ fontSize: 16 }}>
                  Our editorial team is committed to quality journalism over quantity. Our detailed, longform approach to writing and explainers helps you understand complex subjects.
                </Text>
              </View>
              <View style={{ display: 'flex', width: '25%', justifyContent: 'center', alignItems: 'center', rowGap: 10 }}>
                <View style={{ width: '100%', display: 'flex' }}>
                  <Text style={{ fontSize: 24, fontWeight: 700, color: '#1E1B4B' }}>No Clickbait, No Ads</Text>
                </View>
                <Text style={{ fontSize: 16 }}>
                  Unlike many other news sources, we prioritize quality over clickbait headlines or intrusive advertisements. Your reading experience will be uninterrupted and focused on what truly matters.
                </Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
              <View style={{ display: 'flex', width: '40%', rowGap: 10 }}>
                <View style={{ display: 'flex', width: '100%' }}>
                  <Text style={{ fontSize: 34, fontWeight: 700, fontFamily: 'PlayfairDisplay-Bold', color: '#051462' }}>
                    Benefits of reading The Morning Context
                  </Text>
                </View>
                <View style={{ display: 'flex', width: '100%' }}>
                  <Text style={{ fontSize: 16 }}>
                    When you choose The Morning Context as your daily source of financial news and analysis, you unlock a host of benefits that you won’t find anywhere else. Here's what you can expect:
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', width: '40%', rowGap: 10 }}>
                {BENEFITS_OF_READING_TMC.map((benefit) =>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', columnGap: 5 }}>
                    <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%' }}>✓</Text>
                    <Text>
                      <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%', color: '#1E1B4B' }}>{benefit.title}</Text>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>{benefit.description}</Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around', marginTop: 100 }}>
              <View style={{ display: 'flex', width: '40%', rowGap: 10 }}>
                <View style={{ display: 'flex', width: '100%' }}>
                  <Text style={{ fontSize: 34, fontWeight: 700, fontFamily: 'PlayfairDisplay-Bold', color: '#051462' }}>
                    Who reads The Morning Context?
                  </Text>
                </View>
                <View style={{ display: 'flex', width: '100%' }}>
                  <Text style={{ fontSize: 16 }}>
                    The Morning Context is trusted and read by a diverse and engaged audience. Our readers come from various backgrounds and professions, including:
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', width: '40%', rowGap: 10 }}>
                {WHO_READS_TMC.map((benefit) =>
                  <View style={{ display: 'flex', flexDirection: 'row', width: '100%', columnGap: 5 }}>
                    <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%' }}>✓</Text>
                    <Text>
                      <Text style={{ fontWeight: 700, fontSize: 16, lineHeight: '150%', color: '#1E1B4B' }}>{benefit.title}</Text>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>{benefit.description}</Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
            {/* Hear from our community */}
          </View>}
          {this.state.selectedPlanIdx !== 4 && <View style={{ backgroundColor: '#FFF3E3', width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40 }}>
            <View style={{ paddingVertical: 40, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 80 }}>
              <Text style={{ fontSize: 34, fontWeight: 600, fontFamily: 'PlayfairDisplay-Regular' }}>Hear from our community</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', marginBottom: 60, columnGap: 20 }}>
              <View style={{ display: 'flex', width: '60%', rowGap: 20 }}>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', paddingLeft: 40, columnGap: 20 }}>
                  <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                    <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                      <CustomImage
                        source={WHAT_OUR_READERS_SAY[0].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'spotify-img'}
                        altText={name}
                      />
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[0].review}
                      </Text>
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[0].name}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[0].designation}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[0].organization}</View>
                  </View>
                  <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                    <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                      <CustomImage
                        source={WHAT_OUR_READERS_SAY[1].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                      />
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[1].review}
                      </Text>
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[1].name}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[1].designation}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[1].organization}</View>
                  </View>
                </View>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', borderColor: '#E7D6C9', height: 400, objectFit: 'cover', }}>
                  <View style={[{ width: '88%', height: '100%', objectFit: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }]}>
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/DVMpRRPLW3k?si=BqaDJ1I1jNf32EYa"
                      title="The Morning Context"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    />
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', paddingLeft: 40, columnGap: 20 }}>
                  <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                    <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                      <CustomImage
                        source={WHAT_OUR_READERS_SAY[3].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'spotify-img'}
                        altText={name}
                      />
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[3].review}
                      </Text>
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[3].name}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[3].designation}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[3].organization}</View>
                  </View>
                  <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                    <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                      <CustomImage
                        source={WHAT_OUR_READERS_SAY[4].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                      />
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[4].review}
                      </Text>
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[4].name}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[4].designation}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[4].organization}</View>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', paddingLeft: 40, columnGap: 20 }}>
                  <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                    <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                      <CustomImage
                        source={WHAT_OUR_READERS_SAY[7].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'spotify-img'}
                        altText={name}
                      />
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[7].review}
                      </Text>
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[7].name}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[7].designation}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[7].organization}</View>
                  </View>
                  <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                    <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                      <CustomImage
                        source={WHAT_OUR_READERS_SAY[6].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                      />
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                      <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[6].review}
                      </Text>
                    </View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[6].name}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[6].designation}</View>
                    <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[6].organization}</View>
                  </View>
                </View>
              </View>
              <View style={{ display: 'flex', alignItems: 'flex-start', rowGap: 20, width: '25%', marginLeft: '0%' }}>
                <View style={{ display: 'flex', alignItems: 'center', width: '90%', minHeight: 400, borderWidth: 1, height: 600, borderColor: '#E7D6C9' }}>
                  <View style={{ display: 'flex', width: '100%', height: 160, backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={WHAT_OUR_READERS_SAY[2].logo}
                      style={{ width: 111, height: 'auto' }}
                      webStyle={{ width: 111, height: 'auto' }}
                      className={'times-internet-img'}
                      altText={name}
                    />
                  </View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                      {WHAT_OUR_READERS_SAY[2].review}
                    </Text>
                  </View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 25 }}>{WHAT_OUR_READERS_SAY[2].name}</View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[2].designation}</View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[2].organization}</View>
                </View>

                <View style={{ display: 'flex', alignItems: 'center', width: '90%', minHeight: 200, borderWidth: 1, height: 200, borderColor: '#E7D6C9' }}>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                      {WHAT_OUR_READERS_SAY[8].review}
                    </Text>
                  </View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 5 }}>{WHAT_OUR_READERS_SAY[8].name}</View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[8].designation}</View>
                </View>

                <View style={{ display: 'flex', alignItems: 'center', width: '90%', minHeight: 400, borderWidth: 1, height: 400, borderColor: '#E7D6C9' }}>
                  <View style={{ display: 'flex', width: '100%', height: 160, backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={WHAT_OUR_READERS_SAY[5].logo}
                      style={{ width: 111, height: 'auto' }}
                      webStyle={{ width: 111, height: 'auto' }}
                      className={'times-internet-img'}
                      altText={name}
                    />
                  </View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                      {WHAT_OUR_READERS_SAY[5].review}
                    </Text>
                  </View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 20 }}>{WHAT_OUR_READERS_SAY[5].name}</View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[5].designation}</View>
                  <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[5].organization}</View>
                </View>
              </View>
            </View>
          </View>}
          {/* FAQs ssection */}
          <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 100, marginBottom: 100 }}>
            <View style={{ display: 'flex', width: '60%', alignSelf: 'center', justifyContent: 'flex-start' }}>
              <Text style={{ fontSize: 24, fontFamily: 'PlayfairDisplay-Bold', fontWeight: 600, color: '#051462' }}>Frequently Asked Questions</Text>
              {FREQUENTLY_ASKED_QUESTIONS[this.state.selectedPlanIdx].FAQs.map((faq, index) => {
                if (typeof faq.answer === 'object') {
                  return <TouchableOpacity onPress={() => {
                    if (this.state.FAQCollapsedIndices.includes(index)) {
                      this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                    } else {
                      this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                    }
                  }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                      <Text style={{ fontSize: 20, fontWeight: 600, fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                      <CustomImage
                        source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                    </View>
                    {this.state.FAQCollapsedIndices.includes(index) &&
                      <View>
                        <Text style={{ fontSize: 18, marginTop: 20, display: 'flex', flexDirection: 'column', rowGap: 10, lineHeight: '150%', fontFamily: 'HaasGrotesk-Light' }}>
                          {faq.answer.map((ans) => {
                            return this.renderFAQText(ans)
                          })}
                        </Text>
                      </View>
                    }
                    <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                  </TouchableOpacity>
                } else
                  return <TouchableOpacity onPress={() => {
                    if (this.state.FAQCollapsedIndices.includes(index)) {
                      this.setState({ FAQCollapsedIndices: this.state.FAQCollapsedIndices.filter((item) => item !== index) })
                    } else {
                      this.setState({ FAQCollapsedIndices: [...this.state.FAQCollapsedIndices, index] })
                    }
                  }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                      <Text style={{ fontSize: 20, fontWeight: 600, fontFamily: 'HaasGrotesk-Regular' }}>{faq.question}</Text>
                      <CustomImage
                        source={this.state.FAQCollapsedIndices.includes(index) ? faqIconOpened : faqIconClosed}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                    </View>
                    {this.state.FAQCollapsedIndices.includes(index) &&
                      <View>
                        <Text style={{ fontSize: 18, marginTop: 20, lineHeight: '150%', fontFamily: 'HaasGrotesk-Light' }}>
                          {faq.answer === 'Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out here.' ?
                            <Text>Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out{" "}
                              <Text style={{ fontWeight: 600, textDecorationLine: 'underline' }} onPress={() => Linking.openURL(`${window.location.origin}/pricing`, '_blank')}>
                                here
                              </Text>
                            </Text>
                            :
                            this.renderFAQText(faq.answer)}
                        </Text>
                      </View>
                    }
                    <View style={{ width: '100%', borderBottomWidth: 1, borderBottomColor: '#E7D6C9', marginTop: 20 }} />
                  </TouchableOpacity>
              })}
            </View>
          </View>
        </View >
        :
        <>{this.renderMobileView()}</>
    );
  };
  renderWriters = () => {
    let { homeContent } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let data = this.state.writers;
    let data1 = [];
    let data2 = [];
    let data3 = [];
    let data4 = [],
      data5 = [],
      data6 = [];
    Array.from(data)
      .slice(0, 3)
      .map((item, index) => {
        data1.push(item);
      });
    Array.from(data)
      .slice(3, 6)
      .map((item, index) => {
        data2.push(item);
      });
    Array.from(data)
      .slice(6, 9)
      .map((item, index) => {
        data3.push(item);
      });
    Array.from(data)
      .slice(9, 12)
      .map((item, index) => {
        data4.push(item);
      });
    Array.from(data)
      .slice(12, 15)
      .map((item, index) => {
        data5.push(item);
      });
    Array.from(data)
      .slice(15, 18)
      .map((item, index) => {
        data6.push(item);
      });
    return (
      <WritersSlider
        categorySlug={"writers"}
        data={data}
        data1={data1}
        data2={data2}
        data3={data3}
        data4={data4}
        data5={data5}
        data6={data6}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        getNewsletterClick={(slug) => this.viewAuthor(slug)}
        home={true}
        darkMode={darkMode}
        userProfile={this.state.userProfile}
      />
    );
  };
  renderArchivedStories = () => {
    let { archivedStories } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <HomeArchievedContent
        data={archivedStories}
        show={true}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        navigateWeb={(slug) => this.props.navigateToScreen(slug)}
        navigateApp={(slug, params) =>
          this.props.navigation.navigate(slug, params)
        }
        trackSignUp={(email, position) => {
          this.trackSignUp(email, position);
        }}
        trackStoryClick={(title, data, position) => {
          this.trackStoryClick(
            "click_starter_collection",
            title,
            data,
            position
          );
        }}
        darkMode={darkMode}
      />
    );
  };
  getArchivedStories = (excludeData = []) => {
    this.setState({
      showErrorModal: false,
    });
    let excludeList = excludeData.map((td) => {
      return td.id;
    });
    // const { token, whatsTrendingContent, latestStories, featuredStory, premiumStories, userStarterCollection } = this.state;
    // let excludeList = [];
    // if (latestStories) {
    //     excludeList = latestStories.map((ls) => {
    //         return ls.id;
    //     })
    // }
    // if (featuredStory) {
    //     excludeList.push(featuredStory.id);
    // }
    // if (whatsTrendingContent) {
    //     excludeList.push(whatsTrendingContent.id);
    // }

    let endpoint = `/stories`;
    let body = {
      limit: 1,
      exclude: excludeList,
      sort_on: "date",
      sort_by: "desc",
      // filters: [{ key: "single_category", value: true }]
      filters: [
        { key: "is_privileged", value: false },
        { key: "single_category", value: true },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            archivedStories: res.data.story,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  navigateScreen = (pushProp) => {
    if (helpers.isWeb()) {
      this.props.history.push(pushProp);
    } else {
      this.props.navigation.navigate(pushProp);
    }
  };

  renderReadersQuotes() {
    let { staticContent } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <HomeReadersQuote
        data={staticContent.readers_quotes}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        subscriptionQuotes={true}
        darkMode={darkMode}
        pricing={true}
      />
    );
  }

  handleInfoBoxClick = () => {
    let { activeSubscription } = this.state;
    if (activeSubscription) {
      if (helpers.isWeb()) {
        this.props.navigateToScreen("/all-stories");
      } else {
        this.props.navigation.navigate("/all-stories", { categorySlug: "all" });
      }
    } else {
      this.scrollToSection(this.subscriptionLayout);
      try {
        if (helpers.isWeb()) {
          Mixpanel.track("click_subscribe_btn");
        }
      } catch (error) {
        console.log("mix panel error =>", error);
      }
    }
  };

  /*
      showSubscriptionPlan wrapper function
  */

  showChargbeePage = (callback, ...params) => {
    if (callback) callback(...params);
  };

  CB__HostedPage = (
    actionType,
    existingSubscriptionId,
    selectedSubscribtionSlug,
    quantity,
    hostedObjectParams = null
  ) => {
    let shouldUpdateUrl =
      ["/pricing", "/team-subscription", "/student-subscription"].includes(
        window.location.pathname
      ) && helpers.isDesktop()
        ? true
        : false;
    const urlParams = helpers.getURLParamDict(window.location.href);
    const couponIdList = urlParams.coupon_ids
      ? urlParams.coupon_ids.split(",")
      : [];

    const extraParams = {
      coupon_ids: couponIdList,
    };

    ChargebeeController.activatedController().then((cb_handle) => {
      if (cb_handle?.instance?.siteConfig?.enableRedirectMode === true) {
        window.history.pushState(
          { path: `${window.location.pathname}${window.location.hash}` },
          "",
          `${window.location.pathname}${window.location.hash}`
        );
      }

      if (actionType == "checkout_new") {
        cb_handle.hosted_page.checkout_new(
          {
            plan_id: selectedSubscribtionSlug,
            plan_quantity: selectedSubscribtionSlug.includes('team-plan') ? quantity : 1,
            ...extraParams,
          },
          {},
          shouldUpdateUrl
        );
      } else if (actionType == "collect_now") {
        cb_handle.hosted_page.collect_now();
      } else if (actionType == "checkout_existing") {
        cb_handle.hosted_page.checkout_existing(
          { ...hostedObjectParams, ...extraParams },
          {},
          shouldUpdateUrl
        );
      }
    });

    return;
  };

  showSubscriptionOptionsCore = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity = 1,
    themeData = null,
    paymentMethod = null,
    bankName = null
  ) => {
    const { token } = this.state;
    if (!token) {
      this._showSubscriptionPlan(
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        quantity,
        themeData,
        {}
      );
      return;
    }

    const urlParams = helpers.getURLParamDict(window.location.href);
    const forcePaymentGateway = urlParams.force_payment_gateway;
    const forceCheckoutType = urlParams.force_checkout_type;

    let endpoint = `/get-checkout-options`;

    let body = {
      planName: selectedSubscribtion.id,
      force_payment_gateway: forcePaymentGateway,
      force_checkout_type: forceCheckoutType,
      paymentMethod: paymentMethod,
      bankName: bankName,
    };
    try {
      console.log("try");
      window.webengage.track("Purchase Initiated", {
        "Type of subscription": selectedSubscribtion?.id,
        Amount: selectedSubscribtion?.price,
      });
      console.log("Purchase Initiated", {
        "Type of subscription": selectedSubscribtion?.id,
        Amount: selectedSubscribtion?.price,
      });
    } catch (err) { }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code === "access_denied") {
          this._showSubscriptionPlan(
            subscribtionID,
            selectedSubscribtion,
            country,
            email,
            quantity,
            themeData,
            {}
          );
          return;
        }
        if (!res.success || res.code !== "checkout_options") {
          console.log(
            "Assert failed (showSubscriptionPlan:/get-checkout-options):",
            res.success,
            res.code
          );
          return false;
        }
        const paymentConfig = res.data;

        /*
            Case 1: upgrade
        */
        console.log("Here ---->>>>>", "case 1 upgrade", { paymentConfig }, this.state.userProfile?.subscription?.subscription_id &&
          selectedSubscribtion.billing_enitity_type === "plan")
        if (
          this.state.userProfile?.subscription?.subscription_id &&
          selectedSubscribtion.billing_enitity_type === "plan"
        ) {
          let existingSubscriptionId =
            this.state.userProfile.subscription.subscription_id;
          if (paymentConfig.checkoutType === "CB") {
            // // apply only for advance renewal condition
            // let advance_renew = {
            //     terms_to_charge: 1,
            //     // force_term_reset: true,
            //     invoice_immediately: true,
            //     subscription: {
            //         plan_quantity: quantity,
            //     },
            // }

            let upgrade_plan = {
              subscription: {
                plan_id: selectedSubscribtion.id,
                plan_quantity: quantity,
              },
            };

            this.showChargbeePage(
              this.CB__HostedPage,
              "checkout_existing",
              existingSubscriptionId,
              selectedSubscribtion.id,
              quantity,
              upgrade_plan
            );
          } else {
            this._showSubscriptionPlan(
              subscribtionID,
              selectedSubscribtion,
              country,
              email,
              quantity,
              themeData,
              paymentConfig
            );
          }
        } else if (paymentConfig.checkoutType === "CB") {
          /*
              Case 2: new sub checkout using CB
          */
          console.log("Here ---->>>>>", "case 2 new sub checkout using CB")

          this.showChargbeePage(
            this.CB__HostedPage,
            "checkout_new",
            null,
            selectedSubscribtion.id,
            quantity
          );
        } else if (paymentConfig.checkoutType === "TMC") {
          /*
              Case 3: new sub checkout using TMC
          */
          console.log("Here ---->>>>>", "case 3 new sub checkout using TMC")
          this._showSubscriptionPlan(
            subscribtionID,
            selectedSubscribtion,
            country,
            email,
            quantity,
            themeData,
            paymentConfig
          );
        }
      })
      .catch((error) => {
        console.log("Error-catch(showPayment:/get-checkout-options):", error);
      });
  };

  proceedToCheckoutScreen = (paymentMode, bankName) => {

    this.setState({ showCheckoutOptions: false });
    this.setState({ paymentMethod: paymentMode, bankName: bankName });
    // Passing paymentMode and bankName to this API because the set state
    // does not happen immediately so we can't be sure if the values will be set
    console.log(
      "Here --->>>>",
      "setting the show checkout options to false",
      " setting payment method and bank name and sub id:::::::",
      this.state.selectedSubscribtionID,
      this.state.selectedSubscribtion,
      this.state.country,
      this.state.email,
      this.state.quantity,
      this.state.themeData,
      paymentMode,
      bankName
    )
    this.showSubscriptionOptionsCore(
      this.state.selectedSubscribtionID,
      this.state.selectedSubscribtion,
      this.state.country,
      this.state.email,
      this.state.selectedSubscribtionID.includes('team-plan') ? this.state.quantity : 1,
      this.state.themeData,
      paymentMode,
      bankName
    );
    setTimeout(() => this.setState({ isButtonClicked: false }), 8000)
  };

  scrollToSection = (position) => {
    let offsetPosition = position;
    if (helpers.isWeb()) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      this.appscrollview.scrollTo(offsetPosition);
    }
  };

  renderWebPage = () => {
    return <>{this.renderPageContent()}</>;
  };

  renderAppPage = () => {
    return (
      <>
        <ScrollView
          style={{ flex: 1 }}
          ref={(ref) => (this.appscrollview = ref)}
        >
          {this.renderPageContent()}
        </ScrollView>
      </>
    );
  };

  render() {
    const {
      pageLoader,
      showErrorModal,
      enableUpgradeModal,
      enableCancelSubModal,
    } = this.state;
    console.log("Plan list---->>>>>", this.state.planList)
    console.log("PROPS: ", this.props)
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/pricing",
      native: { redirect: "/pricing", verifyRedirect: "/pricing" },
    };
    let darkMode = false;
    console.log("PROPS ---->>>>>", this.props)
    console.log("STATES ---->>>>>", this.state)
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj["subscriptions"]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/pricing"
        />
        {this.state.showCheckoutModal &&
          <CheckoutModal
            proceedToCheckoutScreen={this.proceedToCheckoutScreen}
            setCheckoutLoaderState={(loaderState) =>
              this.setPlanButtonLoaderState(loaderState)
            }
            closeModal={() => {
              this.setState({ showCheckoutModal: false })
            }}
          />
        }
        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          refreshPage={() => this.refreshPage()}
          isPricing={true}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          setCheckoutLoaderState={(loaderState) =>
            this.setPlanButtonLoaderState(loaderState)
          }
          getCheckoutLoaderState={this.getPlanButtonLoaderState}
        />
        {pageLoader
          ? this.showPageLoader()
          : webView
            ? this.renderWebPage()
            : this.renderAppPage()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          Breadcrumbs={Breadcrumbs}
        />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    padding: helpers.isDesktop() ? 20 : 11,
    width: '90%'
  },
  formRow: {
    marginVertical: 14,
    display: "flex",
    flexDirection: helpers.isDesktop() ? "row" : "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    columnGap: "20px",
    rowGap: "20px",
  },
  formRowleft: {
    marginVertical: 14,
    display: "flex",
    flexDirection: helpers.isDesktop() ? "row" : "column",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    columnGap: "20px",
    rowGap: "20px",
  },
  formSingleRow: {
    marginVertical: 14,
    display: "flex",
    flexDirection: helpers.isDesktop() ? "row" : "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "start",
    columnGap: "20px",
    rowGap: "20px",
  },
  formLabel: {
    fontWeight: "bold",
  },
  formInput: {
    borderWidth: 1,
    borderColor: "gray",
    padding: 5,
    flex: 1,
  },
  formItem: {
    // flexDirection: "column",
    // justifyContent: "space-between",
    // marginVertical: 10,
    width: helpers.isDesktop() ? "100%" : "100%",
    position: "relative",
    marginVertical: helpers.isDesktop() ? 8 : 12
  },
  formPicker: {
    borderWidth: 1,
    borderColor: "gray",
  },
  parentContainer: {
    margin: "0 auto",
    height: helpers.isDesktop() ? "auto" : "auto",
    width: helpers.isDesktop() ? "50%" : "96%",
    padding: helpers.isDesktop() ? 20 : 11,
    backgroundColor: "#ffffff",
    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
    position: "relative",
  },
  formTitle: {
    fontFamily: "Merriweather-Regular",
    fontSize: helpers.isDesktop() ? "20px" : "16px",
    fontWeight: "400",
    color: "rgb(0, 0, 0)",
    textAlign: "center",
    marginTop: 12,
  },
  input: {
    fontSize: "16px",
    lineHeight: "32px",
    fontFamily: "Matteo-Regular",
  },
  formSelect: {
    fontSize: "16px",
    lineHeight: "32px",
    fontFamily: "Matteo-Regular",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  formError: {
    color: "#fc0808",
    position: "absolute",
    bottom: "-18px",
    fontFamily: "Matteo-Regular",
    fontSize: "13px",
  },
  formmainError: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#bd0707",
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  formmainerrorText: {
    color: "#ffffff",
    fontSize: "14px",
    lineHeight: "18px",
    fontFamily: "Matteo-Regular",
  },
});

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(Subscription));

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Individual Subscription",
  },
];
