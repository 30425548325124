import { useEffect, useState } from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { helpers } from "../../utils/helpers"
import ScrollContainerView from "../scroll-container-view"
import ArticleItemHorizontal from "../article-item-horizontal"
import ArticleTopStories from "../article-item-vertical/index-top-stories";
import { Flex, FontFamily, Margin, articleItem, articleItemHorizontal, button } from "../../styles/appStyles.web"
import apiHelpers from "../../utils/api-helpers"
import TouchableOpacityLink from "../touchable-opacity-link"
import CustomImage from "../custom-image"
import LatestStories from "../latest-stories"


const MoreStoriesSection = (props) => {
    const { category = 'business', story_type = 'regular', token = false, story_id = 0, isExplainer = false } = props
    const [moreStoriesData, setMoreStoriesData] = useState([])
    const [loading, setLoading] = useState(false)
    const [seeSecondAuthor, setSeeSecondAuthor] = useState([])

    useEffect(() => {
        fetchMoreStories()
    }, [])

    const fetchMoreStories = () => {
        setLoading(true)
        let endpoint = `/stories`;
        let body = {
            limit: 6,
            page: 1,
            sort_on: "date",
            sort_by: "desc",
            exclude: [story_id], //Should be ID of current story
            filters: [
                {
                    key: "category",
                    value: [category],
                },
                {
                    key: "type",
                    value: [story_type]
                }
            ],
        };
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == "story_found") {
                    setLoading(false)
                    setMoreStoriesData(res.data.story)
                } else {
                    console.log("Error=>>", JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log("Error=>> down", JSON.stringify(error));
            });
    }

    const viewArticle = (catSlug, slug, postData) => {
        const { viewArticle } = props;
        viewArticle(catSlug, slug, postData)
    }

    const viewAuthor = (slug) => {
        const { viewAuthor } = props;
        viewAuthor(slug)
    }

    const viewCategory = (slug) => {
        const { viewCategory } = props;
        viewCategory(slug)
    }

    const viewStory = (slug) => {
        const { viewStory } = props;
        viewStory(slug)
    }

    const setSecondAuth = (index) => {
        let isAuthorEnable = [...seeSecondAuthor];
        isAuthorEnable[index] = true;
        setSeeSecondAuthor(isAuthorEnable)
    };
    let btnClickCategory = null

    let newsletter = {
        name: ''
    }

    return <View style={styles.wrapper}>
        <View style={styles.sectionHeading}>
            <View style={styles.separator} />
            <Text style={styles.moreInCategoryText}>More in <Text style={{ textTransform: 'capitalize' }}>{category}</Text></Text>
            <View style={styles.separator} />
        </View>
        <View style={styles.relatedCardsContainer}>
            <View style={[Margin.mt_3, Margin.mb_4]}>
                {helpers.isDesktop() ?
                    <View style={[Flex.column]}>
                        {moreStoriesData && moreStoriesData.reduce((rows, item, index) => {
                            if (index % 2 === 0) {
                                rows.push([]);
                            }
                            rows[rows.length - 1].push(item);
                            return rows;
                        }, []).map((row, rowIndex) => (
                            <View key={rowIndex} style={[Flex.row, Margin.mb_2, { justifyContent: 'space-between' }]}>
                                {row.map((item, index) => {
                                    let btnClickCategory, btnClick, authorBtnClick, author2BtnClick;
                                    if (helpers.isWeb()) {
                                        btnClickCategory = {
                                            to: "/category/" + item.category.slug
                                        };
                                        btnClick = {
                                            to: isExplainer ? "/" + item.category.slug + "/" + item.slug : item.slug
                                        }
                                        authorBtnClick = {
                                            to: "/writers/" + item.author[0].slug,
                                        };
                                        if (item.author[1]) {
                                            author2BtnClick = {
                                                to: "/writers/" + item.author[1].slug,
                                            };
                                        }
                                    } else {
                                        btnClickCategory = {
                                            onPress: () => viewCategory(item.category.slug)
                                        };
                                        btnClick = {
                                            onPress: () => viewStory(item.slug)
                                        }
                                        authorBtnClick = {
                                            onPress: (event) => {
                                                event.stopPropagation();
                                                viewAuthor(item.author[0].slug);
                                            },
                                        };
                                        if (item.author[1]) {
                                            author2BtnClick = {
                                                onPress: (event) => {
                                                    event.stopPropagation();
                                                    viewAuthor(item.author[1].slug);
                                                },
                                            };
                                        }
                                    }
                                    return (
                                        <View
                                            style={{
                                                paddingHorizontal: helpers.isDesktop() ? 16 : 0,
                                                width: helpers.isDesktop() ? "50%" : "100%",
                                                columnGap: '2vw'
                                            }}
                                        >
                                            <View
                                                style={{
                                                    marginBottom: helpers.isDesktop() ? 35 : 26,
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    columnGap: '1vw'
                                                }}
                                            >
                                                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '60%' }}>
                                                    <TouchableOpacityLink
                                                        {...btnClickCategory}
                                                    >
                                                        <Text
                                                            style={[
                                                                {
                                                                    display: "block",
                                                                    marginBottom: helpers.isDesktop() ? 8 : 5,
                                                                    textAlign: "left",
                                                                    textTransform: "uppercase",
                                                                    fontFamily: "HaasGrotesk-Medium",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "600",
                                                                    color:
                                                                        item.category.name === "Business"
                                                                            ? "#2CB680"
                                                                            : item.category.name === "Chaos"
                                                                                ? "#907cff"
                                                                                : item.category.name === "Internet"
                                                                                    ? "#FC876D"
                                                                                    : "#ECB731",
                                                                    fontSize: helpers.isDesktop() ? 19 : 13,
                                                                    lineHeight: helpers.isDesktop() ? 22.8 : 16,
                                                                    letterSpacing: helpers.isDesktop() ? 1 : 0.5,
                                                                    fontFamily: "HaasGrotesk-Medium",
                                                                },
                                                                !helpers.isWeb() && {
                                                                    fontFamily: FontFamily.regular,
                                                                },
                                                                false && { color: "#5263F4" },
                                                                {
                                                                    fontFamily: "HaasGrotesk-Medium",
                                                                    fontSize: 19,
                                                                    fontWeight: 500,
                                                                    letterSpacing: 1,
                                                                    textTransform: 'uppercase',
                                                                }
                                                            ]}
                                                        >
                                                            {item?.newsletter?.name ?? item?.category?.name}
                                                        </Text>
                                                    </TouchableOpacityLink>
                                                    <TouchableOpacityLink
                                                        {...btnClick}
                                                        style={{ width: !helpers.isDesktop() && "100%" }}
                                                    >
                                                        <View
                                                            style={{
                                                                marginBottom: helpers.isDesktop() ? 16 : 8,
                                                            }}
                                                        >
                                                            <CustomImage
                                                                source={item?.featured_image?.url}
                                                                require={false}
                                                                style={{
                                                                    width: 280,
                                                                    height: helpers.isDesktop() ? 179 : 180,
                                                                    objectFit: "cover",
                                                                }}
                                                                webStyle={{
                                                                    width: 280,
                                                                    height: helpers.isDesktop() ? 179 : 180,
                                                                    objectFit: "cover",
                                                                }}
                                                                resizeMode={"cover"}
                                                                altText={"mng"}
                                                            />
                                                        </View>
                                                    </TouchableOpacityLink>
                                                </View>
                                                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                                    <TouchableOpacityLink
                                                        {...btnClick}
                                                    >
                                                        <Text
                                                            accessibilityRole="header"
                                                            aria-level={"3"}
                                                            style={[
                                                                articleItemHorizontal.articleTitle,
                                                                !helpers.isWeb() &&
                                                                articleItemHorizontal.articleTitleApp,
                                                                {
                                                                    display: "block",
                                                                    color: "#292929",
                                                                    fontWeight: "700",
                                                                    fontFamily: "PlayfairDisplay-Bold",
                                                                    fontSize: helpers.isDesktop() ? 24 : 24,
                                                                    lineHeight: helpers.isDesktop() ? 36 : 36,
                                                                    marginBottom: 16,
                                                                    maxHeight: "108px", // Limit height for 2 lines (lineHeight * 2)
                                                                    overflow: "hidden", // Hide overflow
                                                                    display: "-webkit-box", // Required for multi-line ellipsis
                                                                    WebkitLineClamp: 3, // Limit to 2 lines
                                                                    WebkitBoxOrient: "vertical", // Ensure proper orientation for clamping
                                                                    textOverflow: "ellipsis", // Show ellipsis at the end
                                                                },
                                                            ]}
                                                            numberOfLines={3} // Limit to 2 lines
                                                            ellipsizeMode="tail" // Truncate with ellipsis if it exceeds the limit
                                                        >
                                                            {item.title}
                                                        </Text>
                                                    </TouchableOpacityLink>
                                                    {/* </View> */}

                                                    <View
                                                        style={{
                                                            width: 100,
                                                            height: 1,
                                                            backgroundColor: "#907CFF",
                                                            marginTop: helpers.isDesktop() ? 16 : 8,
                                                            marginBottom: helpers.isDesktop() ? 18 : 10,
                                                            alignSelf: 'flex-start'
                                                        }}
                                                    ></View>
                                                    <View
                                                        style={[
                                                            Flex.row,
                                                            Flex.alignCenter,
                                                            { width: "100%", flexWrap: "wrap" },
                                                        ]}
                                                    >
                                                        <TouchableOpacityLink
                                                            {...authorBtnClick}
                                                        >
                                                            <View
                                                                style={[
                                                                    Flex.row,
                                                                    Flex.alignCenter,
                                                                    { marginRight: 15 },
                                                                ]}
                                                            >
                                                                <View
                                                                    style={{
                                                                        width: 34,
                                                                        height: 34,
                                                                        overflow: "hidden",
                                                                        marginRight: 10,
                                                                        borderRadius: "50%",
                                                                        padding: 1,
                                                                        borderStyle: "solid",
                                                                        borderWidth: 1,
                                                                        borderColor: "#FC876D",
                                                                    }}
                                                                >
                                                                    <View
                                                                        style={{
                                                                            width: 30,
                                                                            height: 30,
                                                                            overflow: "hidden",
                                                                            borderRadius: "50%",
                                                                        }}
                                                                    >
                                                                        <CustomImage
                                                                            source={item.author[0].profile_image}
                                                                            require={true}
                                                                        />
                                                                    </View>
                                                                </View>
                                                                <Text
                                                                    style={[
                                                                        {
                                                                            fontWeight: "400",
                                                                            fontSize: helpers.isDesktop() ? 16 : 16,
                                                                            lineHeight: helpers.isDesktop()
                                                                                ? 19
                                                                                : 22,
                                                                        },
                                                                        false && {
                                                                            color: "rgba(255,255,255,0.87)",
                                                                        },
                                                                        { fontFamily: FontFamily.regular },
                                                                    ]}
                                                                >
                                                                    {item.author[0].name}
                                                                </Text>
                                                            </View>
                                                            {seeSecondAuthor[index] && item.author[1] && (
                                                                <TouchableOpacityLink
                                                                    {...author2BtnClick}
                                                                >
                                                                    <View
                                                                        style={[
                                                                            Flex.row,
                                                                            Flex.alignCenter,
                                                                            { marginRight: 15 },
                                                                        ]}
                                                                    >
                                                                        <View
                                                                            style={{
                                                                                width: 34,
                                                                                height: 34,
                                                                                overflow: "hidden",
                                                                                marginRight: 12,
                                                                                borderRadius: "50%",
                                                                                padding: 1,
                                                                                borderStyle: "solid",
                                                                                borderWidth: "0.45px",
                                                                                borderColor: "#FC876D",
                                                                            }}
                                                                        >
                                                                            <View
                                                                                style={{
                                                                                    width: 30,
                                                                                    height: 30,
                                                                                    overflow: "hidden",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                            >
                                                                                <CustomImage
                                                                                    source={
                                                                                        item.author[1].profile_image
                                                                                    }
                                                                                    require={true}
                                                                                />
                                                                            </View>
                                                                        </View>
                                                                        <Text
                                                                            style={[
                                                                                {
                                                                                    fontWeight: "500",
                                                                                    fontSize: helpers.isDesktop()
                                                                                        ? 16
                                                                                        : 14,
                                                                                    lineHeight: helpers.isDesktop()
                                                                                        ? 19
                                                                                        : 16.8,
                                                                                },
                                                                                false && {
                                                                                    color: "rgba(255,255,255,0.87)",
                                                                                },
                                                                                { fontFamily: FontFamily.regular },
                                                                            ]}
                                                                        >
                                                                            {item.author[1].name}
                                                                        </Text>
                                                                    </View>
                                                                </TouchableOpacityLink>
                                                            )}
                                                        </TouchableOpacityLink>
                                                        {item.author.length > 1 &&
                                                            !seeSecondAuthor[index] && (
                                                                <TouchableOpacity
                                                                    onPress={() => {
                                                                        setSecondAuth(index);
                                                                    }}
                                                                >
                                                                    <View
                                                                        style={[
                                                                            {
                                                                                borderStyle: "solid",
                                                                                fontFamily: FontFamily.regular,
                                                                                fontSize: helpers.isDesktop()
                                                                                    ? 16
                                                                                    : 16,
                                                                                lineHeight: helpers.isDesktop()
                                                                                    ? 19
                                                                                    : 22,
                                                                                borderWidth: 1,
                                                                                backgroundColor: "#F3F5FF",
                                                                                borderColor: "#907CFF",
                                                                                width: 28,
                                                                                height: 28,
                                                                                borderRadius: 28,
                                                                            },
                                                                            Flex.alignCenter,
                                                                            Flex.row,
                                                                            Flex.justfiyCenter,
                                                                        ]}
                                                                    >
                                                                        +1
                                                                    </View>
                                                                </TouchableOpacity>
                                                            )}
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    );
                                })}
                            </View>
                        ))}
                    </View>

                    :
                    <View style={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                        <React.Suspense fallback={<Text>Loadig...</Text>}>
                            <>
                                {moreStoriesData.length > 0 ? (
                                    <LatestStories
                                        data={moreStoriesData.filter(
                                            (story) => !story.newsletter
                                        )}
                                        viewArticle={(catSlug, slug, postData) =>
                                            viewArticle(catSlug, slug, postData)
                                        }
                                        viewAuthor={(slug) => viewAuthor(slug)}
                                        viewCategory={(slug) => viewCategory(slug)}
                                        viewCompany={(slug) => viewCompany(slug)}
                                        trackStoryClick={(title, data, position) => {
                                            /*this.trackStoryClick('click_top_stories', title, data, position)*/
                                        }}
                                        darkMode={false}
                                        navigateToScreen={props.navigateToScreen}
                                        viewAllInCategory={(slug) =>
                                            props.navigation.navigate("categories", {
                                                archiveType: "category",
                                                archiveSlug: slug,
                                            })
                                        }
                                        moreStoriesSection={true}
                                    />
                                ) : null}
                            </>
                        </React.Suspense>
                    </View>
                }
            </View>
        </View>
    </View >
}

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    sectionHeading: {
        display: 'flex',
        width: '90%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        alignSelf: 'center'
    },
    separator: {
        width: helpers.isDesktop() ? '35%' : null,
        borderBottomWidth: 1,
        opacity: 0.6
    },
    moreInCategoryText: {
        color: '#292929',
        textAlign: 'center',
        fontFamily: "PlayfairDisplay-Bold",
        fontSize: 34,
        fontWeight: 700,
        lineHeight: '150%',
        textDecorationLine: 'underline'
    },
    relatedCardsContainer: {
        display: 'flex',
        width: helpers.isDesktop() ? '90%' : '100%',
        alignSelf: 'center',
    },
})

export default MoreStoriesSection