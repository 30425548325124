import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  postActionBar,
  articleItemHorizontal,
  articleItem,
  articleLists,
  FontFamily,
  FontWeight,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import apiHelpers from "../../utils/api-helpers";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";
import { Mixpanel } from "../../utils/mixpanel";
import PremiumTag from "../premium-tag";

const likeIcon = getImagePath("icons/like-icon.png");
const likeIconSelected = getImagePath("icons/like-icon-selected.png");
const bookmarkIcon = getImagePath("icons/bookmark.png");
const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");
const removeIcon = getImagePath("icons/removeIcon.png");
const removeIconBlue = getImagePath("icons/removeIconBlue.png");
const authorImage = getImagePath("img/userpic.png");
import AuthenticationWrapper from "../authentication-wrapper";

export class ArticleItemHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readingList: [],
      seeSecondAuthor: false,
      homesection: this.props?.homesection ? this.props.homesection : false,
      custom_user_id: false
    };
    this.isUserSubscriptionActive = localStorage.getItem(
      "isUserSubscriptionActive"
    );
    this.userSubscription = localStorage.getItem("userSubscription");
  }

  componentDidMount = () => {
    // call this api when this.props.slider is undefined or false
    if (!this.props.slider) this.getUserReadingList();
    if(this.props.token && !this.state.userProfile) this.getUserInfo();
    // this.isUserSubscriptionActive = localStorage.getItem(
    //   "isUserSubscriptionActive"
    // );
    // this.userSubscription = localStorage.getItem("userSubscription");
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
  };

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.data.title);
    }
  };

  trackCateoryClick = (item) => {
    // try {
    //     if (this.props.exclusiveHome) {
    //         Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: 'home_exclusive_content', section_value: { title: item.name, slug: item.slug } });
    //     }
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };
  abortRequest = () => {
    if (this.controller) {
      this.controller.abort();
    }
  };

  applyStoryActions = (id, type, value, index) => {
    const { token } = this.props;
    if (token) {
      this.abortRequest();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      let signal = this.signal;

      let endpoint = `/user-story-action`;
      let body = {
        story_id: id,
        type: type,
        value: value,
      };

      let freeStoryList = this.props.freeStoryList;
      freeStoryList[index].is_bookmarked = !freeStoryList[index].is_bookmarked;
      this.setState({ freeStoryList: freeStoryList });
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token, signal)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            let freeStoryList = this.props.data;
            freeStoryList[index].is_bookmarked = res.data.bookmark;
            this.setState({ freeStoryList: freeStoryList });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }

            try {
              if (type == "bookmark" && value === true) {
                Mixpanel.track("clicked_bookmark", {
                  story_title: freeStoryList[index].title,
                  story_slug: freeStoryList[index].slug,
                });
              } else if (type == "like" && value === true) {
                Mixpanel.track("clicked_like", {
                  story_title: freeStoryList[index].title,
                  story_slug: freeStoryList[index].slug,
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }

            this.props.refreshScreen(!this.props.app.refreshScreen);
          } else {
            console.log("Error=>>", JSON.stringify(res));
            let freeStoryList = this.state.freeStoryList;
            freeStoryList[index].is_bookmarked =
              !freeStoryList[index].is_bookmarked;
            this.setState({ freeStoryList: freeStoryList });
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          if (error && error.aborted) {
            // Do nothing
          } else {
            let freeStoryList = this.state.freeStoryList;
            freeStoryList[index].is_bookmarked =
              !freeStoryList[index].is_bookmarked;
            this.setState({ freeStoryList: freeStoryList });
          }
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          `/sign-in?redirect=${
            this.state.redirectSlugs.web
          }&redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`
        );
      } else {
        this.props.navigation.navigate("/sign-in", {
          ...this.state.redirectSlugs.native,
          verifyRedirect:
            this.state.redirectSlugs.native.verifyRedirect +
            `?redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`,
          "redirect-params": `id=${id}+action=bookmark+value=${true}+index=${index}`,
        });
      }
    }
  };
  getUserInfo = () => {
    const { token } = this.props;
    let endpoint = `/profile`;
    let body = { request_group: ["profile"] };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
        } else if (res.success) {
          this.setState({ userProfile: res.data });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        resolve(false);
        // this.setState({ showErrorModal: true, pageLoader: false });
      });
  };
  getUserReadingList = () => {
    this.setState({
      showErrorModal: false,
    });
    // const { token } = this.state;
    const token = this.props.token;

    let endpoint = `/user-action-story-list`;
    let body = {
      type: "bookmark",
      limit: 10,
      page: 1,
    };
    // apiHelpers
    //     .apiRequest(endpoint, body, "POST", true, token)
    //     .then((res) => {
    //         if (res.success && res.code == 'story_found') {
    //           let readingList=res.data.story;
    //          console.log('ugyfh');
    //           for (let index = 0; index < readingList.length; index++) {
    //              let readingListId=[];
    //             const element = readingList[index];
    //             readingListId.push(element.id);
    //             console.log(readingListId,"readingListid");

    //           }

    //             this.setState({
    //                 readingList: res.data.story,
    //             },()=>{
    //                 console.log(this.state.readingList,'readinglikst');
    //             });
    //         } else {
    //             console.log('Error=>>', JSON.stringify(res));
    //         }
    //     })
    //     .catch((error) => {
    //         console.log('Error=>> down', JSON.stringify(error));
    //     });
  };
  applyStoryActions = (id, type, value, index) => {
    const { token } = this.props;
    if (token) {
      this.abortRequest();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      let signal = this.signal;

      let endpoint = `/user-story-action`;
      let body = {
        story_id: id,
        type: type,
        value: value,
      };

      let freeStoryList = this.props.freeStoryList;
      freeStoryList[index].is_bookmarked = !freeStoryList[index].is_bookmarked;
      this.setState({ freeStoryList: freeStoryList });
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token, signal)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            let freeStoryList = this.props.data;
            freeStoryList[index].is_bookmarked = res.data.bookmark;
            this.setState({ freeStoryList: freeStoryList });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }

            try {
              if (type == "bookmark" && value === true) {
                Mixpanel.track("clicked_bookmark", {
                  story_title: freeStoryList[index].title,
                  story_slug: freeStoryList[index].slug,
                });
              } else if (type == "like" && value === true) {
                Mixpanel.track("clicked_like", {
                  story_title: freeStoryList[index].title,
                  story_slug: freeStoryList[index].slug,
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }

            this.props.refreshScreen(!this.props.app.refreshScreen);
          } else {
            console.log("Error=>>", JSON.stringify(res));
            let freeStoryList = this.state.freeStoryList;
            freeStoryList[index].is_bookmarked =
              !freeStoryList[index].is_bookmarked;
            this.setState({ freeStoryList: freeStoryList });
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          if (error && error.aborted) {
            // Do nothing
          } else {
            let freeStoryList = this.state.freeStoryList;
            freeStoryList[index].is_bookmarked =
              !freeStoryList[index].is_bookmarked;
            this.setState({ freeStoryList: freeStoryList });
          }
        });
    }
    // else {
    //   if (helpers.isWeb()) {
    //     this.props.navigateToScreen(`/sign-in?redirect=${this.state.redirectSlugs.web}&redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`)
    //   } else {
    //     this.props.navigation.navigate('/sign-in', { ...this.state.redirectSlugs.native, verifyRedirect: this.state.redirectSlugs.native.verifyRedirect + `?redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`, 'redirect-params': `id=${id}+action=bookmark+value=${true}+index=${index}` });
    //   }
    // }
  };

  pushNewsletterCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "newsletter_category_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      newsletter_section: this.props.sectionName || "NA",
      newsletter_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushArticleCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_category_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      article_section: this.props.sectionName || "NA",
      article_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  render() {
    const {
      single,
      home,
      homepage,
      data,
      viewCategory,
      viewArticle,
      viewAuthor,
      userStoryAction,
      applyStoryActions,
      userCredits,
      category,
      archive,
      exclusiveHome,
      elmsecond,
      readingList,
      slider,
      readingListBtnClick,
      singleNewsletter,
      listingStory,
      hideDate,
      index,
      latestStory,
      darkMode,
      homeRecentEdition,
      getNewsletterClick,
      hideBookMark,
      title,
      isStoryPage = false,
      isStoryMobView = false,
    } = this.props;
    // console.log('title::', title)

    let btnClick,
      authorBtnClick,
      author2BtnClick,
      btnClickBookmark,
      btnClickLike,
      btnCategoryClick;
    let is_shorts = false;
    is_shorts = data?.types && data.types.filter((d) => d.slug == "short");
    if (helpers.isWeb()) {
      if (this.state.seeSecondAuthor === false) {
        btnClick = {
          to: {
            pathname: "/" + data.category.slug + "/" + data.slug,
            postData: data,
          },
        };
      }
      authorBtnClick = {
        to: "/writers/" + data.author[0].slug,
      };
      if (data.author[1]) {
        author2BtnClick = {
          to: "/writers/" + data.author[1].slug,
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          this.applyStoryActions(
            Number(data.id),
            "bookmark",
            data.is_bookmarked ? false : true,
            index
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          this.applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like
              ? false
              : true,
            index
          );
        },
      };
     
      const typesArray = data?.types?.map((item) => {
        return item.name.toLowerCase();
      });

      btnCategoryClick = {
        to: data?.newsletter?.slug
          ? "/newsletters/" + data?.newsletter?.slug
          : "/category/" + data?.category?.slug,
        handleClick: data?.newsletter?.name
          ? () =>
              this.pushNewsletterCategoryClickToDataLayer(
                data?.newsletter?.slug,
                typesArray.join(",")
              )
          : () =>
              this.pushArticleCategoryClickToDataLayer(
                data?.category?.slug,
                typesArray.join(",")
              ),
      };

    } else {
      btnClick = {
        onPress: () => viewArticle(data.category.slug, data.slug, data),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author[0].slug);
        },
      };
      if (data.author[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
      btnCategoryClick = {
        onPress: () =>
          data?.newsletter.slug
            ? getNewsletterClick(data.newsletter?.slug)
            : viewCategory(data.category.slug),
      };
    }
    return (
      <>
        {/* <TouchableOpacityLink
          {...btnClick}
          handleClick={() => this.handleClick()}
        > */}
        <View style={[!archive && Flex.fill]}>
          {userCredits && (
            <View style={[articleItemHorizontal.wrapAbove]}>
              <Text
                style={[
                  articleItemHorizontal.dateTop,
                  darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                ]}
              >
                {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
              </Text>
            </View>
          )}
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {!homeRecentEdition && (
              <TouchableOpacityLink {...btnCategoryClick}>
                {!exclusiveHome && !slider && (
                  <Text
                    style={[
                      articleLists.badge,
                      {
                        display: "block",
                        width: 250,
                        marginBottom: helpers.isDesktop() ? 8 : 8,
                        textAlign: "left",
                        fontFamily: FontFamily.regular,
                        color:
                          title == "shorts" ||
                          (is_shorts !== false && is_shorts.length > 0)
                            ? "#000"
                            : data?.category?.name === "Business"
                            ? "#2CB680"
                            : data?.category?.name === "Internet"
                            ? "#FC876D"
                            : "#907cff",
                        fontSize: helpers.isDesktop() ? 19 : 13,
                        lineHeight: helpers.isDesktop() ? 23 : 16,
                        letterSpacing: helpers.isDesktop() ? 1 : 1,
                        paddingHorizontal: 0,
                        fontFamily: 'HaasGrotesk-Medium',
                      },
                      // !helpers.isDesktop() && { paddingLeft: 0 },
                      !helpers.isWeb() && { fontFamily: FontFamily.regular },
                      darkMode && { color: "#5263F4" },
                      this.state.homesection && {
                        paddingHorizontal: helpers.isDesktop() ? 0 : 20,
                      },
                    ]}
                  >
                    {title == "shorts"
                      ? "News Brief"
                      : title == "startercollection" || title == "allstories"
                      ? this.props.newsletter && data?.newsletter?.name
                        ? null
                        : is_shorts !== false && is_shorts.length > 0
                        ? "News Brief"
                        : data.category.name
                      : this.props.newsletter && data?.newsletter?.name
                      ? data.newsletter.name
                      : data.category.name}
                    {/* {!this.props.newsletter && data?.category?.name} */}

                    {/* {this.props.newsletter && data?.newsletter?.name} */}
                  </Text>
                )}
              </TouchableOpacityLink>
            )}
            {/* {!helpers.isDesktop() && !slider && !hideBookMark && (
              <TouchableOpacity
                {...btnClickBookmark}
                // style={[postActionBar.actionBtn]}
              >
                <CustomImage
                  source={
                    data.is_bookmarked ? bookmarkIconSelected : bookmarkIcon
                  }
                  require={true}
                  style={{ width: 15, height: 21 }}
                  webStyle={{ width: 15, height: 21 }}
                />
              </TouchableOpacity>
            )} */}
          </View>
          <View
            style={[
              Flex.column,
              helpers.isDesktop() && archive && Flex.row,
              archive && { marginBottom: helpers.isDesktop() ? 40 : 26 },
              userCredits && { paddingTop: 0 },
              homepage && {
                paddingHorizontal: helpers.isDesktop() ? 0 : 20,
                paddingBottom: helpers.isDesktop() ? 0 : 26,
              },
              {
                marginHorizontal: helpers.isDesktop() ? "-16px" : "-20px",
              },
            ]}
          >
            <TouchableOpacityLink
              {...btnClick}
              handleClick={() => this.handleClick()}
              style={{
                paddingHorizontal: 16,
              }}
            >
              <View
                style={[exclusiveHome && helpers.isDesktop() && Margin.mb_3]}
              >
                {!exclusiveHome && data.featured_image && (
                  <View
                    style={[
                      Margin.mb_0,
                      { paddingHorizontal: helpers.isDesktop() ? 16 : 20 },
                    ]}
                  >
                    {!archive ? (
                      <CustomImage
                        source={data.featured_image.url}
                        require={false}
                        style={{
                          width: "100%",
                          height: helpers.isDesktop() ? 200 : 210,
                        }}
                        webStyle={{
                          width: "100%",
                          height: home ? 180 : helpers.isDesktop() ? 205 : 210,
                          objectFit: "cover",
                        }}
                        resizeMode={"cover"}
                        altText={
                          data.featured_image.alt &&
                          data.featured_image.alt != ""
                            ? data.featured_image.alt
                            : data.title
                            ? data.title
                            : data.story?.title
                            ? data.story.title
                            : data.featured_image.title
                        }
                      />
                    ) : (
                      <CustomImage
                        source={data.featured_image.url}
                        require={false}
                        style={{ width: 125, height: 71 }}
                        webStyle={{
                          width: listingStory
                            ? helpers.isDesktop()
                              ? 300
                              : "100%"
                            : helpers.isDesktop()
                            ? 300
                            : 140,
                          height: listingStory
                            ? helpers.isDesktop()
                              ? 168
                              : 180
                            : helpers.isDesktop()
                            ? 168
                            : 95,
                          objectFit: "cover",
                          marginBottom: helpers.isDesktop() ? 0 : 8,
                        }}
                        resizeMode={"cover"}
                        altText={
                          data.featured_image.alt &&
                          data.featured_image.alt != ""
                            ? data.featured_image.alt
                            : data.title
                            ? data.title
                            : data.story?.title
                            ? data.story.title
                            : data.featured_image.title
                        }
                      />
                    )}
                    {/* {
                        !helpers.isDesktop() &&   ( data.newsletter.slug=='one-less-thing-to-worry-about'||data.newsletter.slug=='friction'|| data.newsletter.slug=='things-change'||data.newsletter.slug=='oversize')&&
                            <View style={{ position: 'absolute', top: helpers.isDesktop()? 10:10,  right: 0, width:'35%',height:20, backgroundColor: '#0077C8' }}>
<Text style={{fontFamily: FontFamily.abrilFatface,color:'#ffffff',textAlign:'center',letterSpacing:1}}>Premium</Text>
                            </View>
                        }
                     {
                        helpers.isDesktop() &&   ( data.newsletter.slug=='one-less-thing-to-worry-about'||data.newsletter.slug=='friction'||data.newsletter.slug=='things-change'||data.newsletter.slug=='oversize')&&
                            <View style={{ position: 'absolute', top: helpers.isDesktop()? 10:50,  right: 0, width:'25%',height:20, backgroundColor: '#0077C8' }}>
<Text style={{fontFamily: FontFamily.abrilFatface,color:'#ffffff',textAlign:'center',letterSpacing:1}}>Premium des</Text>
                            </View>
                        } */}
                    {/* {this.isUserSubscriptionActive == "0" ||
                    this.isUserSubscriptionActive == null ? (
                      <>{!data.is_free && <PremiumTag />}</>
                    ) : null} */}
                    {readingList && !helpers.isDesktop() && (
                      <TouchableOpacity
                        {...readingListBtnClick}
                        disabled={data.disableRemoveBtn}
                      >
                        <View
                          style={[
                            Flex.row,
                            Flex.fill,
                            Flex.alignEnd,
                            Padding.pb_1,
                            Margin.mt_2,
                            Flex.justfiyCenter,
                          ]}
                        >
                          <CustomImage
                            source={darkMode ? removeIconBlue : removeIcon}
                            require={true}
                            style={{ height: 22, width: 22 }}
                            webStyle={{ height: 22, width: 22 }}
                            resizeMode={"contain"}
                          />
                          <Text
                            style={{
                              fontSize: 14,
                              lineHeight: 17,
                              color: "#5F5F5F",
                              fontFamily: FontFamily.regular,
                              fontWeight: FontWeight.regular,
                              marginLeft: 5,
                            }}
                          >
                            Remove
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
                {exclusiveHome && data.featured_image && (
                  <View>
                    {helpers.isDesktop() && false && (
                      <TouchableOpacityLink
                        {...btnCategoryClick}
                        handleClick={() => this.trackCateoryClick(data)}
                      >
                        {/* <View style={[{ position: 'absolute', left: 0, right: 0, alignItems: 'center' }, !elmsecond && { bottom: 0 }, elmsecond && { top: 0 }]}> */}
                        <Text
                          style={[
                            articleLists.badge,
                            {
                              marginBottom: helpers.isDesktop() ? 15 : 10,
                              textAlign: "center",
                              fontFamily: helpers.isWeb()
                                ? FontFamily.regular
                                : FontFamily.semiBold,
                              color: darkMode
                                ? "rgba(255,255,255,0.87)"
                                : "#2CB680",
                              paddingVertical: 5,
                            },
                            single
                              ? { color: darkMode ? "white" : "#FC876D" }
                              : elmsecond
                              ? { color: darkMode ? "white" : "#2CB680" }
                              : { color: darkMode ? "white" : "#907CFF" },
                          ]}
                        >
                          {data.category.name}
                        </Text>
                        {/* </View> */}
                      </TouchableOpacityLink>
                    )}
                    <CustomImage
                      source={data.featured_image.url}
                      require={false}
                      style={{
                        width: "100%",
                        height: helpers.isDesktop() ? 200 : 210,
                      }}
                      webStyle={{
                        width: "100%",
                        height: elmsecond
                          ? 200
                          : helpers.isDesktop()
                          ? 200
                          : "auto",
                        objectFit: "cover",
                        marginTop: 10,
                        alignSelf: "center",
                      }}
                      resizeMode={"cover"}
                      altText={
                        data.featured_image.alt && data.featured_image.alt != ""
                          ? data.featured_image.alt
                          : data.title
                          ? data.title
                          : data.story?.title
                          ? data.story.title
                          : data.featured_image.title
                      }
                    />
                    {/* <View style={{ position: 'absolute', top: helpers.isDesktop()? 10:50,  right: 0}}>
                        <Image style={{width:85,height:25}} source={require("../../../../assets/img/premium-tag.png")} />
                     {/* <Text style={{fontFamily: FontFamily.abrilFatface,color:'#ffffff',textAlign:'center',letterSpacing:1}}>Premium</Text> */}
                    {/* </View>  */}
                    {/* {this.isUserSubscriptionActive == "0" ||
                    this.isUserSubscriptionActive == null ? (
                      <>{!data.is_free && <PremiumTag />}</>
                    ) : null} */}
                  </View>
                )}
                {/* {!helpers.isDesktop() &&
                  <View style={[Flex.row, Margin.mt_1]}>
                    <TouchableOpacity
                      {...btnClickLike}
                      style={[postActionBar.actionBtn, { marginRight: 3 }]}
                    >
                      <CustomImage
                        source={
                          data.user_story_action && data.user_story_action.like
                            ? likeIconSelected
                            : likeIcon
                        }
                        require={true}
                        style={{ width: 15, height: 13 }}
                        webStyle={{ width: 15, height: 13 }}
                      />
                    </TouchableOpacity>
                  </View>
                } */}
                {/* {(exclusiveHome && helpers.isDesktop()) && <TouchableOpacityLink {...btnCategoryClick} handleClick={() => this.trackCateoryClick(data)}>
                                    <View style={[{ position: 'absolute', left: 0, right: 0, alignItems: 'center' }, !elmsecond && { bottom: -30 }, elmsecond && { top: -15 }]}>
                                        <Text style={[articleLists.badge, { marginBottom: helpers.isDesktop() ? 15 : 10, textAlign: 'center', fontFamily: helpers.isWeb() ? FontFamily.regular : FontFamily.semiBold, backgroundColor: darkMode ? '#5263F4' : '#907cff', color: darkMode ? 'rgba(255,255,255,0.87)' : 'white', borderWidth: 1, borderColor: darkMode ? 'rgba(255,255,255,0.6)' : 'white', paddingVertical: 5 }, elmsecond && { backgroundColor: darkMode ? '#1E1E1E' : 'white', borderColor: darkMode ? '#5263F4' : '#907cff', color: darkMode ? '#5263F4' : '#907cff' }]}>{data.category.name}</Text>
                                    </View>
                                </TouchableOpacityLink>} */}
              </View>
            </TouchableOpacityLink>
            <View
              style={[
                // helpers.isDesktop() && archive && Padding.pl_2,
                // helpers.isDesktop() && Padding.pr_2,
                // !helpers.isDesktop() && Padding.pt_2,
                // !data.is_free && !helpers.isDesktop()
                //   ? { paddingLeft: 20, paddingRight: 20 }
                //   : { paddingLeft: 0, paddingRight: 0 },
                { paddingHorizontal: helpers.isDesktop() ? 16 : 20 },
                archive && Flex.fill,
                elmsecond && {
                  paddingHorizontal: helpers.isDesktop() ? 0 : 20,
                },
                this.state.homesection && {
                  paddingHorizontal: helpers.isDesktop() ? 16 : 20,
                },
              ]}
            >
              {homeRecentEdition && (
                <TouchableOpacityLink {...btnCategoryClick}>
                  {!exclusiveHome && !slider && (
                    <Text
                      style={[
                        articleLists.badge,
                        {
                          marginBottom: helpers.isDesktop() ? 8 : 8,
                          textAlign: "left",
                          fontFamily: FontFamily.regular,
                          color:
                            data?.category?.name === "Business"
                              ? "#2CB680"
                              : data?.category?.name === "Internet"
                              ? "#FC876D"
                              : "#907cff",
                          fontSize: helpers.isDesktop() ? 19 : 13,
                          lineHeight: helpers.isDesktop() ? 22.8 : 15.6,
                          letterSpacing: helpers.isDesktop() ? 1 : 1,
                        fontFamily: 'HaasGrotesk-Medium',
                        },
                        !helpers.isWeb() && { fontFamily: FontFamily.regular },
                        darkMode && { color: "#5263F4" },
                      ]}
                    >
                      {!this.props.newsletter && data?.category?.name}

                      {this.props.newsletter && data?.newsletter?.name}
                    </Text>
                  )}
                </TouchableOpacityLink>
              )}
              <TouchableOpacityLink
                {...btnClick}
                handleClick={() => this.handleClick()}
              >
                <Text
                  accessibilityRole="header"
                  aria-level={archive ? "3" : "4"}
                  style={[
                    home && {
                      fontSize: 30,
                      lineHeight: 42,
                    },
                    articleItemHorizontal.articleTitle,
                    !archive && {
                      marginTop: helpers.isDesktop() ? 16 : 8,
                      fontFamily: "PlayfairDisplay-Bold",
                      fontWeight: 700,
                    },
                    archive && Margin.mb_2,
                    !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                    home && {
                      fontWeight: helpers.isDesktop() ? "600" : "700",
                      fontFamily: helpers.isDesktop()
                        ? "PlayfairDisplay-Regular"
                        : "PlayfairDisplay-Bold",
                      fontSize: helpers.isDesktop() ? 30 : 24,
                      lineHeight: 32,
                    },
                    homepage && {
                      fontWeight: "600",
                      fontFamily: "PlayfairDisplay-Bold",
                      fontSize: helpers.isDesktop() ? 24 : 24,
                      lineHeight: helpers.isDesktop() ? 35.52 : 34,
                    },
                    exclusiveHome && {
                      fontSize: 26,
                      fontWeight: "400",
                      fontFamily: "PlayfairDisplay-Regular",
                      marginBottom: helpers.isDesktop() ? 16 : 10,
                      lineHeight: 38,
                      marginTop: helpers.isDesktop() ? -15 : 0,
                    },
                    readingList && {
                      height: "auto",
                      marginBottom: 0,
                      fontSize: helpers.isDesktop() ? 26 : 16,
                    },
                    archive && {
                      fontFamily: "PlayfairDisplay-Bold",
                      height: "auto",
                      marginBottom: 6,
                      fontWeight: "700",
                      fontSize: helpers.isDesktop() ? 26 : 18,
                      lineHeight: helpers.isDesktop() ? 36 : 24,
                    },
                    category && {
                      fontSize: helpers.isDesktop() ? 30 : 24,
                      lineHeight: helpers.isDesktop() ? 42 : 34,
                      fontWeight: "600",
                    },
                    // homepage && { height: helpers.isDesktop() ? 84 : 70 },
                    !helpers.isWeb() && { fontFamily: "PlayfairDisplay-Bold", fontWeight: 700 },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                  numberOfLines={2}
                >
                  {data.title}
                </Text>

                {archive && (
                  <Text
                    accessibilityRole="header"
                    aria-level="4"
                    style={[
                      articleItemHorizontal.desc,
                      helpers.isDesktop() && articleItemHorizontal.descDesktop,
                      { marginBottom: helpers.isDesktop() ? 0 : 8 },
                      {
                        maxWidth: helpers.isDesktop() ? 700 : "100%",
                        color: "#292929",
                      },
                      homepage && { fontFamily: FontFamily.regular },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    {data.strapline != ""
                      ? data.strapline
                      : helpers.trimWord(data.excerpt)}
                  </Text>
                )}
                {exclusiveHome && (
                  <Text
                    style={[
                      articleItemHorizontal.desc,
                      helpers.isDesktop() && articleItemHorizontal.descDesktop,
                      {
                        marginBottom: helpers.isDesktop() ? 0 : 8,
                        fontSize: helpers.isDesktop() ? 18 : 14,
                        lineHeight: helpers.isDesktop() ? 27 : 22,
                      },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                    numberOfLines={4}
                  >
                    {data.strapline != ""
                      ? data.strapline
                      : helpers.trimWord(data.excerpt)}
                  </Text>
                )}
              </TouchableOpacityLink>
              {userCredits ? (
                <>
                  {data.credits && (
                    <View style={[Flex.row]}>
                      <Text style={postSingle.creditBox}>
                        {data.credits.unlock} Credits
                      </Text>
                    </View>
                  )}
                </>
              ) : (
                <>
                  {!homepage && (
                    <View
                      style={[
                        articleItemHorizontal.lineBreak,
                        Margin.mt_1,
                        Margin.mb_1,
                        helpers.isDesktop() && [Margin.mb_1, Margin.mt_1],
                      ]}
                    ></View>
                  )}

                  {homepage && (
                    <Text
                      style={[
                        articleItemHorizontal.desc,
                        helpers.isDesktop() &&
                          articleItemHorizontal.descDesktop,
                        {
                          marginTop: helpers.isDesktop() ? 16 : 8,
                          marginBottom: helpers.isDesktop() ? 0 : 8,
                          fontFamily: FontFamily.regular,
                          color: "#292929",
                        },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      {data.strapline != ""
                        ? data.strapline
                        : helpers.trimWord(data.excerpt)}
                    </Text>
                  )}

                  {homepage && (
                    <View
                      style={[
                        articleItemHorizontal.lineBreak,
                        Margin.mt_0,
                        Margin.mb_1,
                        helpers.isDesktop() && {
                          marginTop: 16,
                          marginBottom: 18,
                        },
                      ]}
                    ></View>
                  )}

                  <View
                    style={[
                      {
                        flexDirection: helpers.isDesktop() ? "row" : "row",
                        flexWrap: "wrap",
                        marginTop: 0,
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginBottom: 0,
                        minHeight: 28,
                      },
                      helpers.isWeb() && { justifyContent: "flex-start" },
                      exclusiveHome && {
                        alignItems: helpers.isDesktop() ? "center" : "center",
                      },
                      homepage && {},
                      !helpers.isDesktop() && {
                        flexFlow: "row",
                        alignItems: "center",
                      },
                      !helpers.isDesktop() && home && { alignItems: "center" },
                      !helpers.isWeb() && { alignItems: "center" },
                    ]}
                  >
                    {!singleNewsletter && (
                      <TouchableOpacityLink
                        {...authorBtnClick}
                        style={{
                          marginRight: 15,
                        }}
                      >
                        <View style={[Flex.row, Flex.alignCenter]}>
                          {exclusiveHome && (
                            <View
                              style={[
                                postSingle.authorPicWrap,
                                {
                                  width: 34,
                                  height: 34,
                                  marginRight: 12,
                                  alignSelf: "center",
                                },
                                !helpers.isWeb() && home && { marginRight: 0 },
                              ]}
                            >
                              <CustomImage
                                source={data.author[0].profile_image}
                                require={false}
                                webStyle={{
                                  width: 32,
                                  height: 32,
                                  borderRadius: 100,
                                }}
                                resizeMode={"contain"}
                                style={{
                                  width: 32,
                                  height: 32,
                                  borderRadius: 100,
                                }}
                                altText={data.author[0].name}
                              />
                            </View>
                          )}
                          {homepage && (
                            <View
                              style={[
                                postSingle.authorPicWrap,
                                { width: 34, height: 34, marginRight: 12 },
                                !helpers.isWeb() && home && { marginRight: 0 },
                              ]}
                            >
                              <CustomImage
                                source={data.author[0].profile_image}
                                require={false}
                                webStyle={{
                                  width: 32,
                                  height: 32,
                                  borderRadius: 100,
                                }}
                                resizeMode={"contain"}
                                style={{
                                  width: 32,
                                  height: 32,
                                  borderRadius: 100,
                                }}
                                altText={data.author[0].name}
                              />
                            </View>
                          )}
                          <Text
                            style={[
                              articleItemHorizontal.authorTitle,
                              helpers.isDesktop() &&
                                articleItemHorizontal.authorTitleDesktop,
                              !helpers.isWeb() &&
                                articleItemHorizontal.authorTitleApp,
                              { fontWeight: "500" },
                              homepage && {
                                fontSize: helpers.isDesktop() ? 18.5 : 14,
                                lineHeight: helpers.isDesktop() ? 22.2 : 16.8,
                              },
                              !helpers.isWeb() && {
                                marginRight: 0,
                                marginTop: 1,
                              },
                              !helpers.isWeb() & category && { marginTop: 2 },
                              !helpers.isWeb() &&
                                listingStory && {
                                  fontSize: 14,
                                  lineHeight: 19,
                                },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                            ]}
                          >
                            {data.author[0].name}{" "}
                          </Text>
                        </View>
                      </TouchableOpacityLink>
                    )}
                    {data.author &&
                      data.author.length > 1 &&
                      !this.state.seeSecondAuthor && (
                        <TouchableOpacity
                          onPress={() =>
                            this.setState({ seeSecondAuthor: true })
                          }
                        >
                          <View
                            style={[
                              {
                                borderStyle: "solid",
                                fontFamily: FontFamily.regular,
                                fontSize: helpers.isDesktop() ? 16 : 16,
                                lineHeight: helpers.isDesktop() ? 19 : 22,
                                borderWidth: 1,
                                backgroundColor: "#F3F5FF",
                                borderColor: "#907CFF",
                                width: 28,
                                height: 28,
                                borderRadius: 28,
                                marginLeft: helpers.isDesktop() ? 10 : 0,
                                marginRight: helpers.isDesktop() ? 10 : 0,
                              },
                              Flex.alignCenter,
                              Flex.row,
                              Flex.justfiyCenter,
                            ]}
                          >
                            +1
                          </View>
                        </TouchableOpacity>
                      )}
                    <TouchableOpacityLink {...author2BtnClick}>
                      {this.state.seeSecondAuthor && data.author[1] && (
                        <View
                          style={[
                            Flex.row,
                            Flex.alignCenter,
                            { marginRight: 15 },
                          ]}
                        >
                          {exclusiveHome && (
                            <View
                              style={{
                                width: 34,
                                height: 34,
                                overflow: "hidden",
                                marginRight: 10,
                                borderRadius: 40,
                                padding: 1,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#FC876D",
                              }}
                            >
                              <View
                                style={{
                                  width: 30,
                                  height: 30,
                                  overflow: "hidden",
                                  borderRadius: 100,
                                }}
                              >
                                <CustomImage
                                  source={data.author[1].profile_image}
                                  require={true}
                                />
                              </View>
                            </View>
                          )}
                          {/* </View>
                      }
                      {this.state.seeSecondAuthor && data.author[1] &&  */}
                          {/* <View style={[Flex.row, Flex.alignCenter,{marginRight:15}]}> */}
                          <Text
                            style={[
                              articleItemHorizontal.authorTitle,
                              helpers.isDesktop() &&
                                articleItemHorizontal.authorTitleDesktop,
                              !helpers.isWeb() &&
                                articleItemHorizontal.authorTitleApp,
                              { fontWeight: "500" },
                              homepage && {
                                fontSize: helpers.isDesktop() ? 18.5 : 14,
                                lineHeight: helpers.isDesktop() ? 22.2 : 16.8,
                              },
                              !helpers.isWeb() && {
                                marginRight: 0,
                                marginTop: 1,
                              },
                              !helpers.isWeb() & category && { marginTop: 2 },
                              !helpers.isWeb() &&
                                listingStory && {
                                  fontSize: 14,
                                  lineHeight: 19,
                                },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                              !exclusiveHome &&
                                helpers.isDesktop() && {
                                  marginTop: 0,
                                  marginLeft: 5,
                                },
                            ]}
                          >
                            {data.author[1].name}
                          </Text>
                        </View>
                      )}
                    </TouchableOpacityLink>

                    {/* {!singleNewsletter && data.author[1] && (
                        <>
                          <Text
                            style={[
                              articleItemHorizontal.authorTitle,
                              helpers.isDesktop() &&
                              articleItemHorizontal.authorTitleDesktop,
                              !helpers.isWeb() &&
                              articleItemHorizontal.authorTitleApp,
                              articleItemHorizontal.date,
                              {
                                marginTop: 2,
                                fontFamily: FontFamily.light,
                                marginBottom: 5,
                              },
                              homepage && {
                                fontSize: helpers.isDesktop() ? 18.5 : 14,
                                lineHeight: helpers.isDesktop() ? 22.2 : 16.8,
                              },
                              exclusiveHome && {
                                paddingHorizontal: helpers.isDesktop() ? 4 : 6,
                                marginBottom: helpers.isDesktop() ? 9 : 5,
                                marginTop: helpers.isDesktop() ? 2 : 1,
                              },
                              !helpers.isWeb() && {
                                marginRight: 0,
                                marginTop: 0,
                                marginBottom: 0,
                                lineHeight: 14,
                              },
                              !helpers.isWeb() &&
                              exclusiveHome && { lineHeight: 14 },
                              !helpers.isWeb() &&
                              category && { lineHeight: 16 },
                              !helpers.isWeb() &&
                              listingStory && {
                                fontSize: 14,
                                lineHeight: 14,
                              },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                            ]}
                          >
                            {" "}
                            and{" "}
                          </Text>
                          <TouchableOpacityLink {...author2BtnClick}>
                            <View
                              style={[
                                Flex.row,
                                Flex.alignCenter,
                                { marginBottom: 5 },
                              ]}
                            >
                              {exclusiveHome && (
                                <View
                                  style={[
                                    postSingle.authorPicWrap,
                                    { width: 35, height: 35, marginRight: 12 },
                                    !helpers.isWeb() &&
                                    home && { marginRight: 0 },
                                  ]}
                                >
                                  <CustomImage
                                    source={data.author[1].profile_image}
                                    require={false}
                                    webStyle={{
                                      width: 31,
                                      height: 31,
                                      borderRadius: 100,
                                    }}
                                    resizeMode={"contain"}
                                    style={{
                                      width: 31,
                                      height: 31,
                                      borderRadius: 100,
                                    }}
                                    altText={data.author[1].name}
                                  />
                                </View>
                              )}
                              {homepage && (
                                <View
                                  style={[
                                    postSingle.authorPicWrap,
                                    { width: 35, height: 35, marginRight: 12 },
                                    !helpers.isWeb() &&
                                    home && { marginRight: 0 },
                                  ]}
                                >
                                  <CustomImage
                                    source={data.author[1].profile_image}
                                    require={false}
                                    webStyle={{
                                      width: 31,
                                      height: 31,
                                      borderRadius: 100,
                                    }}
                                    resizeMode={"contain"}
                                    style={{
                                      width: 31,
                                      height: 31,
                                      borderRadius: 100,
                                    }}
                                    altText={data.author[1].name}
                                  />
                                </View>
                              )}
                              <Text
                                style={[
                                  articleItemHorizontal.authorTitle,
                                  helpers.isDesktop() &&
                                  articleItemHorizontal.authorTitleDesktop,
                                  !helpers.isWeb() &&
                                  articleItemHorizontal.authorTitleApp,
                                  { fontWeight: "500" },
                                  homepage && {
                                    fontSize: helpers.isDesktop() ? 18.5 : 14,
                                    lineHeight: helpers.isDesktop()
                                      ? 22.2
                                      : 16.8,
                                  },
                                  !helpers.isWeb() && { marginRight: 0 },
                                  !helpers.isWeb() & category && {
                                    marginTop: 2,
                                  },
                                  !helpers.isWeb() &&
                                  listingStory && {
                                    fontSize: 14,
                                    lineHeight: 19,
                                  },
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {data.author[1].name}
                              </Text>
                            </View>
                          </TouchableOpacityLink>
                        </>
                      )} */}
                    {/* {!singleNewsletter && hideDate && helpers.isDesktop() && (
                      <View
                        style={[
                          articleItemHorizontal.dot,
                          helpers.isDesktop() &&
                          articleItemHorizontal.dotDesktop,
                          home && {
                            marginTop: helpers.isDesktop() ? 10 : 3,
                            position: "relative",
                            top: helpers.isDesktop() ? -3 : -2,
                          },
                          homepage && {
                            marginTop: helpers.isDesktop() ? 0 : 3,
                            position: "relative",
                            top: -3,
                          },
                          exclusiveHome && {
                            position: "relative",
                            top: helpers.isDesktop() ? -6 : -2,
                          },
                          category && {
                            position: "relative",
                            top: helpers.isDesktop() ? -3 : -3,
                          },
                          archive && {
                            position: "relative",
                            top: helpers.isDesktop() ? -3 : -3,
                          },
                          !helpers.isWeb() && { marginBottom: 0 },
                        ]}
                      ></View>
                    )} */}
                    <View
                      style={
                        (helpers.isDesktop && { alignSelf: "center" },
                        !helpers.isDesktop && { alignSelf: "flex-start" })
                      }
                    >
                      <Text
                        style={[
                          articleItemHorizontal.articleDate,
                          helpers.isDesktop() &&
                            articleItemHorizontal.articleDateDesktop,
                          !helpers.isWeb() &&
                            articleItemHorizontal.articleDateApp,
                          { fontWeight: "300", color: "#292929" },
                          !helpers.isDesktop() && { fontSize: 12 },
                          homepage && { color: "#5A5A5A" },
                          singleNewsletter && { color: "#292929" },
                          {
                            marginBottom: 0,
                            marginLeft: helpers.isDesktop() ? 0 : 0,
                          },
                          exclusiveHome &&
                            !helpers.isDesktop() && { marginBottom: 0 },
                          !helpers.isWeb() && { marginBottom: 0 },
                          darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                        ]}
                      >
                        {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                      </Text>
                    </View>
                  </View>
                </>
              )}
              {readingList && helpers.isDesktop() && (
                <TouchableOpacity
                  {...readingListBtnClick}
                  disabled={data.disableRemoveBtn}
                >
                  <View
                    style={[
                      Flex.row,
                      Flex.fill,
                      Flex.alignEnd,
                      Padding.pb_1,
                      { marginTop: 18 },
                    ]}
                  >
                    <CustomImage
                      source={darkMode ? removeIconBlue : removeIcon}
                      require={true}
                      style={{ height: 22, width: 22 }}
                      webStyle={{
                        height: 22,
                        width: 22,
                        objectFit: "contain",
                      }}
                      resizeMode={"contain"}
                    />
                    <Text
                      style={{
                        fontSize: 14,
                        lineHeight: 17,
                        color: "#5F5F5F",
                        fontFamily: FontFamily.regular,
                        fontWeight: FontWeight.regular,
                        marginLeft: 10,
                      }}
                    >
                      Remove
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
        {/* </TouchableOpacityLink> */}
      </>
    );
  }
}

export default AuthenticationWrapper(ArticleItemHorizontal);
