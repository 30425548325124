import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  button,
  article,
  Padding,
  pageContent,
  pageCover,
  Colors,
  articleLists,
  articleItemHorizontal,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import Button from "../button";
import ButtonGradient from "../button-gradient";
import ScrollContainerView from "../scroll-container-view";
import { CardView } from "react-native-simple-card-view";
import moment from "moment";
import Swiper from "react-native-web-swiper";
import {
  renderBorderColor,
  categoryImage,
  paddingCategory,
} from "../../screens/yesterday/renderBorderColor";
import SectionTitle from "../../components/section-title";
import { HomeYesterdayStory } from "../../components/home-yesterday-story";

// const banner = getImagePath('img/banner-slide.png');

export class TodayEdition extends Component {
  constructor(props) {
    super(props);
    this.TodayStoryData = [];
    this.state = {
      TodayStoryData: [],
      seeSecondAuthor: [],
      title: `Today's Edition`,
    };
  }

  componentDidMount = () => {
    const { data: rawData } = this.props;
    const data = rawData.map((d)=>{
      const {published_date} = d
      const published_date_ist = moment(published_date).add(5, 'hours').add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss")
      return {...d, published_date: published_date_ist}
    })
    const today = moment(new Date().toISOString()).add(5, 'hours').add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss");
    let current_time_stamp = Date.parse(today);
    console.log("Debug:", data)
    // Filtering todays date record only from Total array of data
    const filterByExpiration = data.filter(
      ({ published_date }) =>
        published_date.split(" ")[0] == today.split("T")[0]
    );
    data &&
      data.map((item, index) => {
        let published_date = new Date(item.published_date.replace(/-/g, "/"));
        var next_day = new Date(
          new Date(published_date).setDate(published_date.getDate() + 1)
        );
        next_day.setHours(7, 56, 0);
        let nextday_time_stamp = Date.parse(next_day);

        // Checking if todays record available. show the data
        if (
          filterByExpiration.length &&
          item.published_date.split(" ")[0] == today.split("T")[0]
        ) {
          this.TodayStoryData.push(item);
        }

        // Checking, if todays record not available, show the previous record until 7:55AM
        if (
          filterByExpiration.length === 0 &&
          current_time_stamp < nextday_time_stamp
        ) {
          this.TodayStoryData.push(item);
        }
      });
    // If story is not published after 7.55AM
    if (this.TodayStoryData.length === 0) {
      this.TodayStoryData = data;
      this.setState({ title: "Recent Edition" });
    }
    let isAuthorEnable = [];
    for (let i = 0; i < this.TodayStoryData.length; i++) {
      isAuthorEnable[i] = false;
    }

    this.setState(
      {
        TodayStoryData: [...this.TodayStoryData, 
          ...(this.props.breakingNewsData.length ? this.props.breakingNewsData : []), 
          ...(this.props.yesterdayStories.length ? this.props.yesterdayStories.map((story)=>helpers.formatNewsExplainer(story)) : []) ],
        seeSecondAuthor: isAuthorEnable,
      },
      () => {
        console.log("TodayStoryData:", this.TodayStoryData);
      }
    );
  };

  pushNewsletterCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "newsletter_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      newsletter_section: this.state.title,
      newsletter_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushArticleCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: this.state.title,
      article_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: this.state.title?.toLowerCase(),
      newsletter_section: "NA",
      CTA_text: "last week's stories",
      CTA_position: "top",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  setSecondAuth = (index) => {
    let isAuthorEnable = [...this.state.seeSecondAuthor];
    isAuthorEnable[index] = true;
    this.setState({ seeSecondAuthor: isAuthorEnable });
  };

  renderYesterdayStory(darkMode) {
    return (
      <View
        style={{
          width: helpers.isDesktop() ? "calc(100% - 832px)" : "100%",
          marginTop: helpers.isDesktop() ? 0 : 30,
          marginLeft: helpers.isDesktop() ? 32 : 0,
        }}
      >
        <HomeYesterdayStory
          token={this.props.token}
          custom_user_id={this.props.custom_user_id}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) => this.props.navigateApp(slug, params)}
          darkMode={darkMode}
        />
      </View>
    );
  }

  isPublishedInLast24Hours = (publishDate) => {
    const publishMoment = moment(publishDate).utcOffset("+05:30")
    const now = moment().utcOffset("+05:30")

    const differenceInHours = now.diff(publishMoment, 'hours')
    return differenceInHours <= 24
  }

  render() {
    let webView = helpers.isWeb();
    const { data, darkMode, viewAuthor } = this.props;
    let { seeSecondAuthor, TodayStoryData } = this.state;
    let viewAllBtn;
    if (helpers.isWeb()) {
      viewAllBtn = {
        to: "/all-stories",
      };
    } else {
      viewAllBtn = {
        onPress: () => this.props.navigateApp("/all-stories"),
      };
    }

    return (
      <>
        <SectionTitle
          app={this.props.app}
          title={this.state.title}
          style={{ backgroundColor: "#FFF9F1" }}
        />
        <View
          style={{
            backgroundColor: "#FFF9F1",
            paddingBottom: "40px",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: helpers.isDesktop() ? "row" : "column",
              alignItems: "flex-start",
              maxWidth: "1216px",
              paddingLeft: helpers.isDesktop() ? "16px" : "20px",
              paddingRight: helpers.isDesktop() ? "16px" : "20px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <View style={{ width: helpers.isDesktop() ? "800px" : "100%" }}>
              {TodayStoryData &&
                TodayStoryData.slice(0, 1).map((item, index) => {
                  let btnClick,
                    authorBtnClick,
                    author2BtnClick,
                    btnClickBookmark,
                    btnClickLike;
                  if (helpers.isWeb()) {
                    btnClick = {
                      to: {
                        pathname: "/" + item.category.slug + "/" + item.slug,
                        postData: item,
                      },
                    };
                    authorBtnClick = {
                      to: "/writers/" + item.author[0].slug,
                    };
                    if (item.author[1]) {
                      author2BtnClick = {
                        to: "/writers/" + item.author[1].slug,
                      };
                    }
                  } else {
                    btnClick = {
                      onPress: () =>
                        viewArticle(item.category.slug, item.slug, item),
                    };
                    authorBtnClick = {
                      onPress: (event) => {
                        event.stopPropagation();
                        viewAuthor(item.author[0].slug);
                      },
                    };
                    if (item.author[1]) {
                      author2BtnClick = {
                        onPress: (event) => {
                          event.stopPropagation();
                          viewAuthor(item.author[1].slug);
                        },
                      };
                    }
                    btnClickBookmark = {
                      onPress: (event) => {
                        event.stopPropagation();
                        applyStoryActions(
                          Number(item.id),
                          "bookmark",
                          item.user_story_action &&
                            item.user_story_action.bookmark
                            ? false
                            : true
                        );
                      },
                    };
                    btnClickLike = {
                      onPress: (event) => {
                        event.stopPropagation();
                        applyStoryActions(
                          Number(item.id),
                          "like",
                          item.user_story_action && item.user_story_action.like
                            ? false
                            : true
                        );
                      },
                    };
                  }

                  const typesArray = item?.types?.map((item) => {
                    return item.name.toLowerCase();
                  });
            

                  let btnCategoryClick = {
                    to: item?.newsletter?.name
                      ? "/newsletters/" + item?.newsletter?.slug
                      : "/category/" + item?.category?.slug,
                    handleClick: item?.newsletter?.name
                      ? () =>
                          this.pushNewsletterCategoryClickToDataLayer(
                            item?.newsletter?.slug,
                            typesArray.join(",")
                          )
                      : () =>
                          this.pushArticleCategoryClickToDataLayer(
                            item?.category?.slug,
                            typesArray.join(",")
                          ),
                  };

                  return (
                    <View
                      key={`todaynews_${index}`}
                      style={[
                        helpers.isDesktop() ? Flex.row : Flex.column,
                        Flex.FlexWrap,
                        {
                          width: "100%",
                          marginBottom: helpers.isDesktop() ? 35 : 26,
                        },
                        !helpers.isDesktop() && {
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingTop: 15,
                          paddingBottom: helpers.isDesktop()
                            ? this.props.isBannerShown
                              ? 0
                              : 15
                            : 0,
                        },
                        helpers.isDesktop() && { padding: 0 },
                      ]}
                    >
                      <View
                        style={{
                          // maxWidth: helpers.isDesktop() ? "46%" : "100%",
                          width: "100%",
                          marginBottom: helpers.isDesktop() ? 16 : 8,
                        }}
                      >
                        <TouchableOpacityLink {...btnCategoryClick}>
                          <Text
                            style={[
                              {
                                display: "block",
                                marginBottom: 8,
                                textAlign: "left",
                                textTransform: "uppercase",
                                fontFamily: FontFamily.regular,
                                fontStyle: "normal",
                                fontWeight: "600",
                                color:
                                  item.category.name === "Business"
                                    ? "#2CB680"
                                    : item.category.name === "Chaos"
                                    ? "#907cff"
                                    : item.category.name === "Internet"
                                    ? "#FC876D"
                                    : "#ECB731",
                                fontSize: helpers.isDesktop() ? 19 : 13,
                                lineHeight: helpers.isDesktop() ? 22.8 : 16,
                                letterSpacing: helpers.isDesktop() ? 1 : 0.5,
                                fontFamily: "HaasGrotesk-Medium",
                              },
                              !helpers.isWeb() && {
                                fontFamily: FontFamily.regular,
                              },
                              darkMode && { color: "#5263F4" },
                            ]}
                          >
                            {item?.newsletter?.name ?? item?.category?.name}
                          </Text>
                        </TouchableOpacityLink>
                        <TouchableOpacityLink {...btnClick}>
                          <CustomImage
                            source={item?.featured_image?.url}
                            require={true}
                            resizeMode={"cover"}
                            style={{
                              // width: "100%",
                              width: 800,
                              maxWidth: "100%",
                              height: helpers.isDesktop() ? 400 : 210,
                              objectFit: "cover",
                            }}
                            webStyle={{
                              maxWidth: "100%",
                              width: 800,
                              height: helpers.isDesktop() ? 400 : 210,
                              margin: 0,
                              objectFit: "cover",
                            }}
                          />
                        </TouchableOpacityLink>
                      </View>

                      <View
                        style={[
                          {
                            width: "100%",
                          },
                        ]}
                      >
                        <TouchableOpacityLink {...btnClick}>
                          <Text
                            style={[
                              {
                                display: "block",
                                fontWeight: "700",
                                fontFamily: "PlayfairDisplay-Bold",
                                fontSize: helpers.isDesktop() ? 30 : 24,
                                lineHeight: helpers.isDesktop() ? 42 : 35.52,
                              },
                              darkMode && { color: "#292929" },
                              {
                                marginBottom: helpers.isDesktop() ? 16 : 8,
                              },
                            ]}
                          >
                            {item.title}
                          </Text>
                        </TouchableOpacityLink>
                        <TouchableOpacityLink {...btnClick}>
                          <Text
                            style={[
                              {
                                fontWeight: "400",
                                fontSize: helpers.isDesktop() ? 18 : 14,
                                lineHeight: helpers.isDesktop() ? 27 : 21,
                              },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                              { fontFamily: FontFamily.regular },
                            ]}
                          >
                            {item.strapline}
                          </Text>
                        </TouchableOpacityLink>
                        <View
                          style={{
                            width: 100,
                            height: 1,
                            backgroundColor: "#907CFF",
                            marginTop: helpers.isDesktop() ? 16 : 8,
                            marginBottom: helpers.isDesktop() ? 18 : 10,
                          }}
                        ></View>
                        <View
                          style={[
                            Flex.row,
                            Flex.alignCenter,
                            { width: "100%", flexWrap: "wrap" },
                          ]}
                        >
                          <TouchableOpacityLink {...authorBtnClick}>
                            <View
                              style={[
                                Flex.row,
                                Flex.alignCenter,
                                { marginRight: 15 },
                              ]}
                            >
                              <View
                                style={{
                                  width: 34,
                                  height: 34,
                                  overflow: "hidden",
                                  marginRight: 12,
                                  borderRadius: "50%",
                                  padding: 1,
                                  borderStyle: "solid",
                                  borderWidth: "0.45px",
                                  borderColor: "#FC876D",
                                }}
                              >
                                <View
                                  style={{
                                    width: 30,
                                    height: 30,
                                    overflow: "hidden",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <CustomImage
                                    source={item.author[0].profile_image}
                                    require={true}
                                  />
                                </View>
                              </View>
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    fontSize: helpers.isDesktop() ? 16 : 14,
                                    lineHeight: helpers.isDesktop() ? 19 : 16.8,
                                  },
                                  darkMode && {
                                    color: "rgba(255,255,255,0.87)",
                                  },
                                  { fontFamily: FontFamily.regular },
                                ]}
                              >
                                {item.author[0].name}
                              </Text>
                            </View>
                          </TouchableOpacityLink>
                          {seeSecondAuthor[index] && item.author[1] && (
                            <TouchableOpacityLink {...author2BtnClick}>
                              <View
                                style={[
                                  Flex.row,
                                  Flex.alignCenter,
                                  { marginRight: 15 },
                                ]}
                              >
                                <View
                                  style={{
                                    width: 34,
                                    height: 34,
                                    overflow: "hidden",
                                    marginRight: 12,
                                    borderRadius: "50%",
                                    padding: 1,
                                    borderStyle: "solid",
                                    borderWidth: "0.45px",
                                    borderColor: "#FC876D",
                                  }}
                                >
                                  <View
                                    style={{
                                      width: 30,
                                      height: 30,
                                      overflow: "hidden",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <CustomImage
                                      source={item.author[1].profile_image}
                                      require={true}
                                    />
                                  </View>
                                </View>
                                <Text
                                  style={[
                                    {
                                      fontWeight: "500",
                                      fontSize: helpers.isDesktop() ? 16 : 14,
                                      lineHeight: helpers.isDesktop()
                                        ? 19
                                        : 16.8,
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                    { fontFamily: FontFamily.regular },
                                  ]}
                                >
                                  {item.author[1].name}
                                </Text>
                              </View>
                            </TouchableOpacityLink>
                          )}
                          {item.author.length > 1 &&
                            !seeSecondAuthor[index] && (
                              <TouchableOpacity
                                onPress={() => {
                                  this.setSecondAuth(index);
                                }}
                              >
                                <View
                                  style={[
                                    {
                                      borderStyle: "solid",
                                      fontFamily: FontFamily.regular,
                                      fontSize: 16,
                                      lineHeight: 19,
                                      borderWidth: 1,
                                      backgroundColor: "#F3F5FF",
                                      borderColor: "#907CFF",
                                      width: 24,
                                      height: 24,
                                      borderRadius: "50%",
                                    },
                                    Flex.alignCenter,
                                    Flex.row,
                                    Flex.justfiyCenter,
                                  ]}
                                >
                                  +1
                                </View>
                              </TouchableOpacity>
                            )}
                        </View>
                      </View>
                    </View>
                  );
                })}
              {/* for 3 story */}
              {TodayStoryData && TodayStoryData.length == 3 && (
                <View
                  style={{
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    margin: helpers.isDesktop() ? -16 : 0,
                  }}
                >
                  {TodayStoryData.slice(1, 3).map((item, index) => {
                    let btnClick,
                      authorBtnClick,
                      author2BtnClick,
                      btnClickBookmark,
                      btnClickLike;
                    if (helpers.isWeb()) {
                      btnClick = {
                        to: {
                          pathname: "/" + item?.category?.slug + "/" + item?.slug,
                          postData: item,
                        },
                      };
                      authorBtnClick = {
                        to: "/writers/" + item?.author[0]?.slug,
                      };
                      if (item?.author[1]) {
                        author2BtnClick = {
                          to: "/writers/" + item?.author[1]?.slug,
                        };
                      }
                    } else {
                      btnClick = {
                        onPress: () =>
                          viewArticle(item?.category?.slug, item?.slug, item),
                      };
                      authorBtnClick = {
                        onPress: (event) => {
                          event.stopPropagation();
                          viewAuthor(item.author[0].slug);
                        },
                      };
                      if (item.author[1]) {
                        author2BtnClick = {
                          onPress: (event) => {
                            event.stopPropagation();
                            viewAuthor(item.author[1].slug);
                          },
                        };
                      }
                      btnClickBookmark = {
                        onPress: (event) => {
                          event.stopPropagation();
                          applyStoryActions(
                            Number(item.id),
                            "bookmark",
                            item.user_story_action &&
                              item.user_story_action.bookmark
                              ? false
                              : true
                          );
                        },
                      };
                      btnClickLike = {
                        onPress: (event) => {
                          event.stopPropagation();
                          applyStoryActions(
                            Number(item.id),
                            "like",
                            item.user_story_action &&
                              item.user_story_action.like
                              ? false
                              : true
                          );
                        },
                      };
                    }
                    const typesArray = item?.types?.map(item => {
                      return item.name;
                    })
                    let btnCategoryClick = {
                      to: item?.newsletter?.name
                        ? "/newsletters/" + item?.newsletter?.slug
                        : "/category/" + item?.category?.slug,
                      handleClick: item?.newsletter?.name
                        ? () =>
                            this.pushNewsletterCategoryClickToDataLayer(
                              item?.newsletter?.slug,
                              typesArray.join(",")
                            )
                        : () =>
                            this.pushArticleCategoryClickToDataLayer(
                              item?.category?.slug,
                              typesArray.join(",")
                            ),
                    };
                    return (
                      <View
                        style={{
                          paddingHorizontal: helpers.isDesktop() ? 16 : 0,
                          width: helpers.isDesktop() ? "50%" : "100%",
                        }}
                      >
                        <View
                          style={{
                            marginBottom: helpers.isDesktop() ? 35 : 26,
                          }}
                        >
                          <TouchableOpacityLink {...btnCategoryClick}>
                            <Text
                              style={[
                                {
                                  display: "block",
                                  marginBottom: helpers.isDesktop() ? 8 : 5,
                                  textAlign: "left",
                                  textTransform: "uppercase",
                                  fontFamily: FontFamily.regular,
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  color:
                                    item.category.name === "Business"
                                      ? "#2CB680"
                                      : item.category.name === "Chaos"
                                      ? "#907cff"
                                      : item.category.name === "Internet"
                                      ? "#FC876D"
                                      : "#ECB731",
                                  fontSize: helpers.isDesktop() ? 19 : 13,
                                  lineHeight: helpers.isDesktop() ? 22.8 : 16,
                                  letterSpacing: helpers.isDesktop() ? 1 : 0.5,
                                  fontFamily: "HaasGrotesk-Medium",
                                },
                                !helpers.isWeb() && {
                                  fontFamily: FontFamily.regular,
                                },
                                darkMode && { color: "#5263F4" },
                              ]}
                            >
                              {item?.newsletter?.name ?? item?.category?.name}
                            </Text>
                          </TouchableOpacityLink>
                          <TouchableOpacityLink
                            {...btnClick}
                            style={{ width: !helpers.isDesktop() && "100%" }}
                          >
                            <View
                              style={{
                                marginBottom: helpers.isDesktop() ? 16 : 8,
                              }}
                            >
                              <CustomImage
                                source={item?.featured_image?.url}
                                require={false}
                                style={{
                                  width: "100%",
                                  height: helpers.isDesktop() ? 243 : 210,
                                  objectFit: "cover",
                                }}
                                webStyle={{
                                  width: "100%",
                                  height: helpers.isDesktop() ? 243 : 210,
                                  objectFit: "cover",
                                }}
                                resizeMode={"cover"}
                                altText={"mng"}
                              />
                            </View>
                          </TouchableOpacityLink>
                          <View>
                            <TouchableOpacityLink {...btnClick}>
                              <Text
                                accessibilityRole="header"
                                aria-level={"3"}
                                style={[
                                  articleItemHorizontal.articleTitle,
                                  !helpers.isWeb() &&
                                    articleItemHorizontal.articleTitleApp,
                                  {
                                    display: "block",
                                    color: "#292929",
                                    fontWeight: "700",
                                    fontFamily: "PlayfairDisplay-Bold",
                                    fontSize: helpers.isDesktop() ? 24 : 24,
                                    lineHeight: helpers.isDesktop() ? 36 : 36,
                                    marginBottom: 16,
                                  },
                                ]}
                              >
                                {item.title}
                              </Text>
                            </TouchableOpacityLink>
                            {/* </View> */}
                            <TouchableOpacityLink {...btnClick}>
                              <Text
                                style={[
                                  // articleLists.badge,
                                  {
                                    // width: 150,
                                    marginBottom: helpers.isDesktop() ? 15 : 10,
                                    textAlign: "left",
                                    fontFamily: FontFamily.regular,
                                    color: "#292929",
                                    fontSize: helpers.isDesktop() ? 18 : 14,
                                    lineHeight: helpers.isDesktop() ? 27 : 21,
                                    letterSpacing: helpers.isDesktop()
                                      ? 0.3
                                      : 0.3,
                                  },
                                  // !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                                  darkMode && { color: "#292929" },
                                ]}
                              >
                                {item.strapline}
                              </Text>
                            </TouchableOpacityLink>
                            <View
                              style={{
                                width: 100,
                                height: 1,
                                backgroundColor: "#907CFF",
                                marginTop: helpers.isDesktop() ? 16 : 8,
                                marginBottom: helpers.isDesktop() ? 18 : 10,
                              }}
                            ></View>
                            <View
                              style={[
                                Flex.row,
                                Flex.alignCenter,
                                { width: "100%", flexWrap: "wrap" },
                              ]}
                            >
                              <TouchableOpacityLink {...authorBtnClick}>
                                <View
                                  style={[
                                    Flex.row,
                                    Flex.alignCenter,
                                    { marginRight: 15 },
                                  ]}
                                >
                                  <View
                                    style={{
                                      width: 34,
                                      height: 34,
                                      overflow: "hidden",
                                      marginRight: 10,
                                      borderRadius: "50%",
                                      padding: 1,
                                      borderStyle: "solid",
                                      borderWidth: 1,
                                      borderColor: "#FC876D",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: 30,
                                        height: 30,
                                        overflow: "hidden",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      <CustomImage
                                        source={item.author[0].profile_image}
                                        require={true}
                                      />
                                    </View>
                                  </View>
                                  <Text
                                    style={[
                                      {
                                        fontWeight: "400",
                                        fontSize: helpers.isDesktop() ? 16 : 16,
                                        lineHeight: helpers.isDesktop()
                                          ? 19
                                          : 22,
                                      },
                                      darkMode && {
                                        color: "rgba(255,255,255,0.87)",
                                      },
                                      { fontFamily: FontFamily.regular },
                                    ]}
                                  >
                                    {item.author[0].name}
                                  </Text>
                                </View>
                                {seeSecondAuthor[index] && item.author[1] && (
                                  <TouchableOpacityLink {...author2BtnClick}>
                                    <View
                                      style={[
                                        Flex.row,
                                        Flex.alignCenter,
                                        { marginRight: 15 },
                                      ]}
                                    >
                                      <View
                                        style={{
                                          width: 34,
                                          height: 34,
                                          overflow: "hidden",
                                          marginRight: 12,
                                          borderRadius: "50%",
                                          padding: 1,
                                          borderStyle: "solid",
                                          borderWidth: "0.45px",
                                          borderColor: "#FC876D",
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: 30,
                                            height: 30,
                                            overflow: "hidden",
                                            borderRadius: "50%",
                                          }}
                                        >
                                          <CustomImage
                                            source={
                                              item.author[1].profile_image
                                            }
                                            require={true}
                                          />
                                        </View>
                                      </View>
                                      <Text
                                        style={[
                                          {
                                            fontWeight: "500",
                                            fontSize: helpers.isDesktop()
                                              ? 16
                                              : 14,
                                            lineHeight: helpers.isDesktop()
                                              ? 19
                                              : 16.8,
                                          },
                                          darkMode && {
                                            color: "rgba(255,255,255,0.87)",
                                          },
                                          { fontFamily: FontFamily.regular },
                                        ]}
                                      >
                                        {item.author[1].name}
                                      </Text>
                                    </View>
                                  </TouchableOpacityLink>
                                )}
                              </TouchableOpacityLink>
                              {item.author.length > 1 &&
                                !seeSecondAuthor[index] && (
                                  <TouchableOpacity
                                    onPress={() => {
                                      this.setSecondAuth(index);
                                    }}
                                  >
                                    <View
                                      style={[
                                        {
                                          borderStyle: "solid",
                                          fontFamily: FontFamily.regular,
                                          fontSize: helpers.isDesktop()
                                            ? 16
                                            : 16,
                                          lineHeight: helpers.isDesktop()
                                            ? 19
                                            : 22,
                                          borderWidth: 1,
                                          backgroundColor: "#F3F5FF",
                                          borderColor: "#907CFF",
                                          width: 28,
                                          height: 28,
                                          borderRadius: 28,
                                        },
                                        Flex.alignCenter,
                                        Flex.row,
                                        Flex.justfiyCenter,
                                      ]}
                                    >
                                      +1
                                    </View>
                                  </TouchableOpacity>
                                )}
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              )}
              {/* For 2 story or multi story */}
              {TodayStoryData &&
                (TodayStoryData.length == 2 || TodayStoryData.length >= 4) &&
                TodayStoryData.slice(1).map((item, index) => {
                  let btnClick,
                    authorBtnClick,
                    author2BtnClick,
                    btnClickBookmark,
                    btnClickLike;
                  if (helpers.isWeb()) {
                    btnClick = {
                      to: {
                        pathname: "/" + item.category.slug + "/" + item.slug,
                        postData: item,
                      },
                    };
                    authorBtnClick = {
                      to: "/writers/" + item.author[0].slug,
                    };
                    if (item.author[1]) {
                      author2BtnClick = {
                        to: "/writers/" + item.author[1].slug,
                      };
                    }
                  } else {
                    btnClick = {
                      onPress: () =>
                        viewArticle(item.category.slug, item.slug, item),
                    };
                    authorBtnClick = {
                      onPress: (event) => {
                        event.stopPropagation();
                        viewAuthor(item.author[0].slug);
                      },
                    };
                    if (item.author[1]) {
                      author2BtnClick = {
                        onPress: (event) => {
                          event.stopPropagation();
                          viewAuthor(item.author[1].slug);
                        },
                      };
                    }
                  }
                  const typesArray = item?.types?.map(item => {
                    return item.name;
                  })
                  let btnCategoryClick = {
                    to: item?.newsletter?.name
                      ? "/newsletters/" + item?.newsletter?.slug
                      : "/category/" + item?.category?.slug,
                    handleClick: item?.newsletter?.name
                      ? () =>
                          this.pushNewsletterCategoryClickToDataLayer(
                            item?.newsletter?.slug,
                            typesArray.join(",")
                          )
                      : () =>
                          this.pushArticleCategoryClickToDataLayer(
                            item?.category?.slug,
                            typesArray.join(",")
                          ),
                  };
                  return (
                    <View>
                      <View
                        style={{ marginRight: helpers.isDesktop() ? 32 : 0 }}
                      >
                        <View
                          style={{ marginBottom: helpers.isDesktop() ? 0 : 26 }}
                        >
                          <TouchableOpacityLink {...btnCategoryClick}>
                            <Text
                              style={[
                                {
                                  display: "block",
                                  marginBottom: helpers.isDesktop() ? 8 : 5,
                                  textAlign: "left",
                                  textTransform: "uppercase",
                                  fontFamily: FontFamily.regular,
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  color:
                                    item.category.name === "Business"
                                      ? "#2CB680"
                                      : item.category.name === "Chaos"
                                      ? "#907cff"
                                      : item.category.name === "Internet"
                                      ? "#FC876D"
                                      : "#ECB731",
                                  fontSize: helpers.isDesktop() ? 19 : 13,
                                  lineHeight: helpers.isDesktop() ? 22.8 : 16,
                                  letterSpacing: helpers.isDesktop() ? 1 : 0.5,
                                  fontFamily: "HaasGrotesk-Medium",
                                },
                                !helpers.isWeb() && {
                                  fontFamily: FontFamily.regular,
                                },
                                darkMode && { color: "#5263F4" },
                              ]}
                            >
                              {item?.newsletter?.name ?? item?.category?.name}
                            </Text>
                          </TouchableOpacityLink>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: helpers.isDesktop()
                                ? "row"
                                : "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              marginBottom: helpers.isDesktop() ? 32 : 0,
                            }}
                          >
                            <TouchableOpacityLink
                              {...btnClick}
                              style={{ width: !helpers.isDesktop() && "100%" }}
                            >
                              <View
                                style={{
                                  marginRight: helpers.isDesktop() ? 32 : 0,
                                  marginBottom: helpers.isDesktop() ? 0 : 8,
                                  width: !helpers.isDesktop() && "100%",
                                }}
                              >
                                <CustomImage
                                  source={item?.featured_image?.url}
                                  require={false}
                                  style={{
                                    width: helpers.isDesktop() ? 280 : "100%",
                                    height: helpers.isDesktop()
                                      ? TodayStoryData.length == 2
                                        ? 240
                                        : 210
                                      : 210,
                                    objectFit: "cover",
                                  }}
                                  webStyle={{
                                    width: helpers.isDesktop()
                                      ? "280px"
                                      : "100%",
                                    height: helpers.isDesktop()
                                      ? TodayStoryData.length == 2
                                        ? 240
                                        : 210
                                      : 210,
                                    objectFit: "cover",
                                  }}
                                  resizeMode={"cover"}
                                  altText={"mng"}
                                />
                              </View>
                            </TouchableOpacityLink>
                            <View
                              style={{
                                flexShrink: 1,
                              }}
                            >
                              <TouchableOpacityLink {...btnClick}>
                                <Text
                                  accessibilityRole="header"
                                  aria-level={"3"}
                                  style={[
                                    articleItemHorizontal.articleTitle,
                                    !helpers.isWeb() &&
                                      articleItemHorizontal.articleTitleApp,
                                    {
                                      display: "block",
                                      color: "#292929",
                                      fontWeight: "700",
                                      fontFamily: "PlayfairDisplay-Bold",
                                      fontSize: helpers.isDesktop() ? 24 : 24,
                                      lineHeight: helpers.isDesktop() ? 36 : 36,
                                      marginBottom: 16,
                                    },
                                  ]}
                                >
                                  {item.title}
                                </Text>
                              </TouchableOpacityLink>
                              <TouchableOpacityLink {...btnClick}>
                                <Text
                                  style={[
                                    // articleLists.badge,
                                    {
                                      marginBottom: helpers.isDesktop()
                                        ? 16
                                        : 16,
                                      textAlign: "left",
                                      fontFamily: FontFamily.regular,
                                      color: "#292929",
                                      fontSize: helpers.isDesktop() ? 18 : 14,
                                      lineHeight: helpers.isDesktop() ? 27 : 21,
                                      letterSpacing: helpers.isDesktop()
                                        ? 0.3
                                        : 0.3,
                                    },
                                    // !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                                    darkMode && { color: "#292929" },
                                  ]}
                                >
                                  {item.strapline}
                                </Text>
                              </TouchableOpacityLink>
                              <View
                                style={{
                                  width: 100,
                                  height: 1,
                                  backgroundColor: "#907CFF",
                                  marginTop: helpers.isDesktop() ? 16 : 8,
                                  marginBottom: helpers.isDesktop() ? 18 : 10,
                                }}
                              ></View>
                              <View
                                style={[
                                  Flex.row,
                                  Flex.alignCenter,
                                  { width: "100%", flexWrap: "wrap" },
                                ]}
                              >
                                <TouchableOpacityLink {...authorBtnClick}>
                                  <View
                                    style={[
                                      Flex.row,
                                      Flex.alignCenter,
                                      { marginRight: 15 },
                                    ]}
                                  >
                                    <View
                                      style={{
                                        width: 34,
                                        height: 34,
                                        overflow: "hidden",
                                        marginRight: 10,
                                        borderRadius: "50%",
                                        padding: 1,
                                        borderStyle: "solid",
                                        borderWidth: 1,
                                        borderColor: "#FC876D",
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: 30,
                                          height: 30,
                                          overflow: "hidden",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        <CustomImage
                                          source={item.author[0].profile_image}
                                          require={true}
                                        />
                                      </View>
                                    </View>
                                    <Text
                                      style={[
                                        {
                                          fontWeight: "400",
                                          fontSize: helpers.isDesktop()
                                            ? 16
                                            : 16,
                                          lineHeight: helpers.isDesktop()
                                            ? 19
                                            : 22,
                                        },
                                        darkMode && {
                                          color: "rgba(255,255,255,0.87)",
                                        },
                                        { fontFamily: FontFamily.regular },
                                      ]}
                                    >
                                      {item.author[0].name}
                                    </Text>
                                  </View>
                                </TouchableOpacityLink>
                                {seeSecondAuthor[index] && item.author[1] && (
                                  <TouchableOpacityLink {...author2BtnClick}>
                                    <View
                                      style={[
                                        Flex.row,
                                        Flex.alignCenter,
                                        { marginRight: 15 },
                                      ]}
                                    >
                                      <View
                                        style={{
                                          width: 34,
                                          height: 34,
                                          overflow: "hidden",
                                          marginRight: 12,
                                          borderRadius: "50%",
                                          padding: 1,
                                          borderStyle: "solid",
                                          borderWidth: "0.45px",
                                          borderColor: "#FC876D",
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: 30,
                                            height: 30,
                                            overflow: "hidden",
                                            borderRadius: "50%",
                                          }}
                                        >
                                          <CustomImage
                                            source={
                                              item.author[1].profile_image
                                            }
                                            require={true}
                                          />
                                        </View>
                                      </View>
                                      <Text
                                        style={[
                                          {
                                            fontWeight: "500",
                                            fontSize: helpers.isDesktop()
                                              ? 16
                                              : 14,
                                            lineHeight: helpers.isDesktop()
                                              ? 19
                                              : 16.8,
                                          },
                                          darkMode && {
                                            color: "rgba(255,255,255,0.87)",
                                          },
                                          { fontFamily: FontFamily.regular },
                                        ]}
                                      >
                                        {item.author[1].name}
                                      </Text>
                                    </View>
                                  </TouchableOpacityLink>
                                )}
                                {item.author.length > 1 &&
                                  !seeSecondAuthor[index] && (
                                    <TouchableOpacity
                                      onPress={() => {
                                        this.setSecondAuth(index);
                                      }}
                                    >
                                      <View
                                        style={[
                                          {
                                            borderStyle: "solid",
                                            fontFamily: FontFamily.regular,
                                            fontSize: 16,
                                            lineHeight: 19,
                                            borderWidth: 1,
                                            backgroundColor: "#F3F5FF",
                                            borderColor: "#907CFF",
                                            width: 24,
                                            height: 24,
                                            borderRadius: "50%",
                                          },
                                          Flex.alignCenter,
                                          Flex.row,
                                          Flex.justfiyCenter,
                                        ]}
                                      >
                                        +1
                                      </View>
                                    </TouchableOpacity>
                                  )}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                })}
              {helpers.isDesktop() ? (
                <View
                  style={{
                    display: "flex",
                    marginTop: helpers.isDesktop() ? 0 : 71,
                    marginBottom: helpers.isDesktop() ? 0 : 59,
                    justifyContent: "flex-start",
                    width: helpers.isDesktop() ? 240 : "100%",
                  }}
                >
                  <ButtonGradient
                    rounded={true}
                    title={"Last Week's Stories"}
                    rootStyle={{
                      btnWrapper: [
                        button.primaryGradient,
                        { width: "100%", borderRadius: 0 },
                      ],
                      btnText: [
                        button.primaryGradientText,
                        {
                          fontSize: helpers.isDesktop() ? 17 : 16,
                          lineHeight: 20,
                          letterSpacing: "0.05em",
                          textTransform: "uppercase",
                          fontWeight: "600",
                          fontFamily: FontFamily.regular,
                        },
                      ],
                    }}
                    onClick={() => 
                      {
                        this.pushInformationToDataLayer()
                        this.props.navigateToScreen(`/last-week`)
                      }
                    }
                    darkMode={darkMode}
                    isNewGradiant={true}
                  />
                </View>
              ) : null}
            </View>
            {this.renderYesterdayStory(darkMode)}
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({});

export default TodayEdition;
