import React, { Component } from "react";
import { Text, View, Image, ScrollView, Dimensions } from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Badges,
  Helpers,
  postContentTags,
  postContentClasses,
  postTags,
  modalStyle,
  Colors,
  FontFamily,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import moment from "moment";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import ContentLoader from "react-native-easy-content-loader";
import ZoomImageNews from "../zoom-image-news";
import PostActionBar from "../../components/post-actionbar";

const mostViewUpIcon = getImagePath("icons/most-viewed.png");
const unlockIconGreen = getImagePath("icons/unlockIconGreen.png");
const WORDS_READ_PER_MINUTE = 200

const STORY_TYPE_SLUG_MAPPING = {
  "regular": "LONGREAD / ",
  "short": "BRIEF / ",
  "yesterday": "EXPLAINER / ",
  "newsletter": "NEWSLETTER / "
}

export class PostSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleFont: false,
      subscriptionComplete: false,
      showImageModal: false,
    };
  }

  componentDidMount = () => {
    if (
      helpers.isWeb() &&
      window.location.search.includes("titleFontOverride=")
    ) {
      let searchArr = window.location.search.replace("?", "").split("&");
      let foundSearch = searchArr.find((searchParam) => {
        return searchParam.includes("titleFontOverride=");
      });
      if (foundSearch) {
        let search = foundSearch.replace("titleFontOverride=", "");
        if (search != "") {
          this.setState({ titleFont: search });
        }
      }
    }
  };

  renderNewsletterCategory(newsletter) {
    let newsletterBtnClick;
    if (helpers.isWeb()) {
      newsletterBtnClick = {
        to: "/newsletters/" + newsletter.slug,
      };
    } else {
      newsletterBtnClick = {
        onPress: () => this.props.getNewsletterClick(newsletter.slug),
      };
    }
    return (
      <View style={[Flex.alignCenter]}>
        <TouchableOpacityLink {...newsletterBtnClick}>
          <CustomImage
            webStyle={{
              maxWidth: 140,
              width: "50%",
              height: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            style={{ width: 100, height: 55 }}
            source={newsletter.logo}
            resizeMode={"contain"}
            altText={newsletter.name}
          />
        </TouchableOpacityLink>
      </View>
    );
  }

  render() {
    const {
      data,
      getAuthorClick,
      getCategoryClick,
      unlockLoader,
      postUnlocked,
      insufficientCredits,
      storyActions,
      applyStoryActions,
      sharePost,
      actionsLoader,
      darkMode,
    } = this.props;
    console.log("Props debugging3:>", this.props)
    const { titleFont, showImageModal } = this.state;
    const webView = helpers.isWeb();
    let catBtnClick;
    if (data.category) {
      if (webView) {
        catBtnClick = {
          to: "/category/" + data.category.slug,
        };
      } else {
        catBtnClick = {
          onPress: () => getCategoryClick(data.category.slug),
        };
      }
    }

    return (
      <View
        style={[
          helpers.isDesktop() && {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "87.5px",
            alignItems: 'center',
            justifyContent: 'center'
          },
        ]}
      >
        <View
          style={[
            !helpers.isDesktop() && showImageModal && { zIndex: 99999 },
            darkMode && {
              backgroundColor: Colors.darkBlackColorBg,
              color: "rgba(255, 255, 255, 0.89)",
            },
            helpers.isDesktop() && {
              width: "50%",
              marginTop: data.newsletter ? 0 : 10,
              justifyContent: "center",
              zIndex: this.state.showImageModal ? 0 : 1
            },
          ]}
        >
          <View
            style={[
              postSingle.summaryWrapperNews,
              helpers.isDesktop() && postSingle.summaryWrapperDesktop,
              Helpers.conatinerWeb,
            ]}
          >
            <View
              style={[
                helpers.isDesktop() && postSingle.summaryInnerWrapper,
                !helpers.isDesktop() && postSingle.summaryInnerWrappermob,
                darkMode && {
                  backgroundColor: Colors.darkBlackColorBg,
                  color: "rgba(255, 255, 255, 0.89)",
                },
              ]}
            >
              {data.statsLoader ? (
                <></>
              ) : (
                // <View
                //   style={[
                //     {
                //       backgroundColor: "#ffffff",
                //       maxWidth: helpers.isDesktop() ? "100%" : "90%",
                //       width: "100%",
                //       height: 16,
                //       marginBottom: helpers.isDesktop() ? 10 : 0,
                //       marginTop: helpers.isDesktop() ? 10 : 0,
                //       marginLeft: "auto",
                //       marginRight: "auto",
                //     },
                //     darkMode && { backgroundColor: Colors.darkBlackColorBg },
                //   ]}
                // >
                //   <View
                //     style={{
                //       alignItems: "center",
                //       justifyContent: "center",
                //       width: helpers.isDesktop() ? "140px" : "70%",
                //       marginLeft: "auto",
                //       marginRight: "auto",
                //     }}
                //   >
                //     <ContentLoader
                //       containerStyles={{ marginBottom: 0 }}
                //       active={true}
                //       pHeight={[10]}
                //       title={false}
                //       pRows={1}
                //       pWidth={["100%"]}
                //       animationDuration={400}
                //       primaryColor={
                //         darkMode
                //           ? "rgba(45, 48, 45,1)"
                //           : "rgba(220, 220, 220, 1)"
                //       }
                //       secondaryColor={
                //         darkMode
                //           ? "rgba(45, 48, 45,0.8)"
                //           : "rgba(200, 200, 200, 1)"
                //       }
                //     />
                //   </View>
                // </View>
                <View
                  style={[
                    postSingle.mostViewedWrap,
                    {
                      marginBottom: helpers.isDesktop() ? 10 : 0,
                      marginTop: helpers.isDesktop() ? 10 : 0,
                    },
                  ]}
                >
                  {data.storyStats && (
                    <>
                      <CustomImage
                        webStyle={{ width: 16, height: 16 }}
                        style={{ width: 16, height: 16 }}
                        source={mostViewUpIcon}
                        require={true}
                      />
                      <Text
                        style={[
                          postSingle.mostViewText,
                          darkMode && { color: "#55C79A" },
                        ]}
                      >
                        {data.storyStats}
                      </Text>
                    </>
                  )}
                </View>
              )}
              {false ? (
                <View style={[helpers.isDesktop() && Margin.mt_2]}>
                  {this.renderNewsletterCategory(data.newsletter)}
                </View>
              ) : (
                <>
                  {/* {data.category && (
                    <View style={[Flex.alignCenter, Margin.mt_2]}>
                      <TouchableOpacityLink {...catBtnClick}>
                        <Text
                          style={[
                            Badges.infoBage,
                            helpers.isDesktop() && Badges.infoBageDesktop,
                            Badges.infoBagePurple,
                            helpers.isDesktop() && Badges.infoBagePurpleDesktop,
                            {
                              marginRight: 0,
                              fontSize: helpers.isDesktop() ? 16 : 14,
                            },
                            darkMode && { backgroundColor: "#5263F4" },
                            data.category.name == "Internet"
                              ? { backgroundColor: "#FC876D" }
                              : "",
                            data.category.name == "Business"
                              ? { backgroundColor: "#2CB680" }
                              : "",
                            data.category.name == "Newsletters"
                              ? { backgroundColor: "#ECB731" }
                              : "",
                          ]}
                        >
                          {data.category.name + "DEBUG"}
                        </Text>
                      </TouchableOpacityLink>
                    </View>
                  )} */}
                  {
                    data.category && data.type && data.type.length && (
                      <TouchableOpacityLink {...catBtnClick} style={{ display: 'flex', flexDirection: 'row', marginTop: helpers.isDesktop() ? null : 40 }}>
                        <Text style={{ color: '#6B7280', fontFamily: 'HaasGrotesk-Medium', lineHeight: 16, fontSize: 12, fontWeight: 500, letterSpacing: 1 }}>{STORY_TYPE_SLUG_MAPPING[data.type[0].slug]}</Text><Text style={{
                          color:
                            data.category.name === "Internet"
                              ? "#FC876D"
                              : data.category.name === "Business"
                                ? "#2CB680"
                                : data.category.name === "Newsletters"
                                  ? "#ECB731"
                                  : data.category.name === "Chaos"
                                    ? "#907CFF"
                                    : "#ECB731", // Default color if no match
                          fontSize: 12,
                          fontFamily: 'HaasGrotesk-Medium',
                          lineHeight: 16,
                          fontWeight: 500,
                          letterSpacing: 1
                        }}>{STORY_TYPE_SLUG_MAPPING[data.type[0].slug] && (data.newsletter ? data.newsletter.name.toUpperCase() : data.category.name.toUpperCase())}</Text>
                      </TouchableOpacityLink>
                    )
                  }
                </>
              )}

              {data.postTitle && (
                <Text
                  accessibilityRole="header"
                  aria-level="1"
                  style={[
                    helpers.isDesktop() && postSingle.postTitle,
                    !helpers.isDesktop() && postSingle.postTitlemob,
                    helpers.isDesktop() && postSingle.postTitleDesktopNews,
                    helpers.isDesktop() ? Margin.mt_2 : Margin.mt_15,
                    !helpers.isDesktop() && Margin.mb_15,
                    { color: "#000" },
                    helpers.isDesktop() && { fontSize: 34, fontWeight: 700, lineHeight: '150%' },
                    !helpers.isDesktop() && { fontSize: 28, lineHeight: 39 },
                    titleFont && { fontFamily: titleFont },
                    { fontFamily: "PlayfairDisplay-Bold" },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {data.postTitle}
                </Text>
              )}
              {!data.newsletter && (
                <Text
                  accessibilityRole="header"
                  aria-level="1"
                  style={[
                    !helpers.isDesktop() && {
                      fontSize: 16,
                      lineHeight: '150%',
                      textAlign: "left",
                      fontWeight: 400,
                      letterSpacing: 0.3,
                      fontFamily: "HaasGrotesk-Roman",
                      color: '#292929'
                    },
                    helpers.isDesktop() && postSingle.postTitleDesktopNews,
                    helpers.isDesktop() && Margin.mt_2,
                    helpers.isDesktop() && {
                      fontFamily: "HaasGrotesk-Roman",
                      fontSize: 17,
                      lineHeight: '150%',
                      textAlign: "left",
                      letterSpacing: 0.3,
                      color: '#292929',
                      fontWeight: 400
                    },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {data.story.strapline}
                </Text>
              )}
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: '#CBD5E1', marginBottom: '2vh', width: helpers.isDesktop() ? null : '90%', alignSelf: helpers.isDesktop() ? null : 'center' }} />
          </View>
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb800,
            ]}
          >
            {data.author && data.author.length > 1 ? (
              <View>
                <View
                  style={[
                    postSingle.postInfoWrapper,
                    !helpers.isDesktop && { paddingLeft: 10 },
                  ]}
                >
                  {data.author &&
                    data.author.map((item, index) => {
                      let authorBtnClick;
                      if (webView) {
                        authorBtnClick = {
                          to: "/writers/" + item.slug,
                        };
                      } else {
                        authorBtnClick = {
                          onPress: () => getAuthorClick(item.slug),
                        };
                      }
                      return (
                        <View
                          key={`postSumm_${index}`}
                          style={{
                            alignItems: helpers.isDesktop() ? "center" : "flex-start",
                            display: "flex",
                            flexDirection: "row",
                            paddingLeft: helpers.isDesktop() ? 0 : 10
                          }}
                        >
                          <View
                            style={[
                              postSingle.authorDetailsWrap,
                              !helpers.isDesktop() && Margin.mb_1,
                              index == 1 && Margin.ml_1,
                            ]}
                          >
                            <View
                              key={`author_${index}`}
                              style={[postSingle.authorPicWrap]}
                            >
                              <TouchableOpacityLink {...authorBtnClick}>
                                <CustomImage
                                  webStyle={{
                                    width: 38,
                                    height: 38,
                                    borderRadius: 100,
                                    marginTop: 4,
                                  }}
                                  resizeMode={"contain"}
                                  style={postSingle.authorThumbPic}
                                  altText={item.name}
                                  source={item.profile_image}
                                />
                              </TouchableOpacityLink>
                            </View>
                            <View style={[Padding.pl_1]}>
                              <TouchableOpacityLink {...authorBtnClick}>
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                              </TouchableOpacityLink>
                              {/* {!!item.location && (
                                <Text
                                  style={[
                                    postSingle.authorLocation,
                                    helpers.isDesktop() &&
                                    postSingle.authorLocationDesktop,
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  {item.location}
                                </Text>
                              )} */}
                            </View>
                          </View>
                          {/* {helpers.isDesktop() &&
                                                <View style={[postSingle.dotSeprator, { marginLeft: 0, marginRight: 15 }]} />
                                            } */}
                        </View>
                      );
                    })}
                </View>
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justfiyCenter,
                    helpers.isDesktop() ? Margin.mt_2 : Margin.mt_1,
                    helpers.isDesktop() && Flex.justfiyCenter,
                  ]}
                ></View>
                {
                  <View
                    style={[
                      helpers.isDesktop() && Flex.row,
                      Margin.mt_0,
                      Helpers.conatinerWeb,
                      helpers.isDesktop() && Helpers.conatinerWeb680,
                      { alignItems: "center", justifyContent: "space-between" },
                      helpers.isDesktop() && Margin.mt_1,
                    ]}
                  >
                    {!helpers.isDesktop() && (
                      <View
                        style={[
                          {
                            height: 0.3,
                            width: "100%",
                            borderTopWidth: 0.3,
                            borderTopColor: "rgba(102, 102, 102, 0.6)",
                            marginVertical: 15,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: 50,
                          },
                        ]}
                      ></View>
                    )}
                    {(!actionsLoader || !helpers.isDesktop()) && (
                      <Text
                        style={[
                          postSingle.postDate,
                          helpers.isDesktop() && { paddingRight: 10 },
                          helpers.isDesktop() && postSingle.postDateDesktop,
                          !helpers.isDesktop() && Padding.pv_05,
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                          { fontFamily: "HaasGrotesk-Roman" }
                        ]}
                      >
                        {moment(data?.story?.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                      </Text>
                    )}
                    {!actionsLoader && helpers.isWeb() && (
                      <PostActionBar
                        updateFontSize={() => this.changeFontSize()}
                        storyActions={storyActions}
                        applyStoryActions={(type, value) =>
                          applyStoryActions(type, value)
                        }
                        sharePost={(slug) => sharePost(slug)}
                        shareTitle={data.story.title}
                        darkMode={darkMode}
                        token={this.props.token}
                        profileInfo={this.props.profileInfo}
                        data={this.props.data}
                        openGiftSubModal={this.props.openGiftSubModal}
                      />
                    )}
                    {actionsLoader && (
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: helpers.isDesktop() ? 300 : 160,
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginVertical: 15,
                        }}
                      >
                        <ContentLoader
                          containerStyles={{ marginBottom: 0 }}
                          active={true}
                          pHeight={[30]}
                          title={false}
                          pRows={1}
                          pWidth={["100%"]}
                          animationDuration={400}
                          primaryColor={
                            darkMode
                              ? "rgba(45, 48, 45,1)"
                              : "rgba(220, 220, 220, 1)"
                          }
                          secondaryColor={
                            darkMode
                              ? "rgba(45, 48, 45,0.8)"
                              : "rgba(200, 200, 200, 1)"
                          }
                        />
                      </View>
                    )}
                  </View>
                }
                {data.loaderSummary != true && (
                  <View
                    style={[
                      helpers.isDesktop() && {
                        flexDirection: "row",
                        marginRight: 0,
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      { marginTop: 20 },
                      !helpers.isDesktop() && { alignItems: "center" },
                      !helpers.isWeb() && { marginTop: 40 },
                    ]}
                  >
                    {data.story && data.story.credits && this.creditStory()}
                  </View>
                )}
              </View>
            ) : (
              <View
                style={[
                  postSingle.postInfoWrapper,
                  Flex.justifyBetween,
                  helpers.isDesktop() && { flexDirection: "column" },
                  !helpers.isDesktop() && {
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingHorizontal: '5vw'
                  },
                ]}
              >
                {data.author &&
                  data.author.map((item, index) => {
                    let authorBtnClick;
                    if (webView) {
                      authorBtnClick = {
                        to: "/writers/" + item.slug,
                      };
                    } else {
                      authorBtnClick = {
                        onPress: () => getAuthorClick(item.slug),
                      };
                    }
                    return (
                      <>
                        {!helpers.isDesktop() && (
                          <View
                            style={[
                              {
                                height: 0.3,
                                width: "100%",
                                marginBottom: 15,
                                marginTop: !helpers.isDesktop() ? 5 : 15,
                                alignItems: "center",
                                justifyContent: "center",
                                width: !helpers.isDesktop() ? 190 : 50,
                              },
                            ]}
                          ></View>
                        )}
                        <View
                          style={[postSingle.authorDetailsWrap, Padding.pr_1]}
                        >
                          <View
                            key={`author_${index}`}
                            style={[postSingle.authorPicWrap]}
                          >
                            <TouchableOpacityLink
                              key={`postSumm_${index}`}
                              {...authorBtnClick}
                            >
                              <CustomImage
                                webStyle={{
                                  width: 38,
                                  height: 38,
                                  borderRadius: 100,
                                  marginTop: 4,
                                }}
                                resizeMode={"contain"}
                                style={postSingle.authorThumbPic}
                                altText={item.name}
                                source={item.profile_image}
                              />
                            </TouchableOpacityLink>
                          </View>
                          <View
                            style={[
                              Padding.pl_1,
                              helpers.isDesktop() && {
                                flexDirection: "row",
                                alignItems: "center",
                              },
                            ]}
                          >
                            <View style={[Flex.row, Flex.alignCenter]}>
                              <TouchableOpacityLink
                                key={`postSumm_${index}`}
                                {...authorBtnClick}
                              >
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                              </TouchableOpacityLink>
                              {/* {!!item.location && (
                                <>
                                  <View style={[postSingle.dotSeprator]} />
                                  <Text
                                    style={[
                                      postSingle.authorLocation,
                                      helpers.isDesktop() &&
                                      postSingle.authorLocationDesktop,
                                      darkMode && {
                                        color: "rgba(255, 255, 255, 0.89)",
                                      },
                                    ]}
                                  >
                                    {item.location}
                                  </Text>
                                </>
                              )} */}
                            </View>
                          </View>
                        </View>
                      </>
                    );
                  })}
                {
                  <View
                    style={[
                      Flex.row,
                      Margin.mt_0,
                      Helpers.conatinerWeb,
                      helpers.isDesktop() && Helpers.conatinerWeb680,
                      { alignItems: helpers.isDesktop() ? "center" : null, justifyContent: "flex-start" },
                      helpers.isDesktop() && { marginTop: '1vh' },
                    ]}
                  >
                    {(!actionsLoader || !helpers.isDesktop()) && (
                      <Text
                        style={[
                          postSingle.postDate,
                          // helpers.isDesktop() && { paddingRight: 10 },
                          helpers.isDesktop() && postSingle.postDateDesktop,
                          !helpers.isDesktop() && Padding.pv_05,
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}
                      >
                        {moment(data?.story?.published_date).add(5, 'hours').add(30, 'minutes').format(
                          "DD MMMM, YYYY"
                        )}
                        <Text style={[
                          { marginLeft: 8 },
                          postSingle.postDate,
                          // helpers.isDesktop() && { paddingRight: 10 },
                          helpers.isDesktop() && postSingle.postDateDesktop,
                          !helpers.isDesktop() && Padding.pv_05,
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}>
                          {" • "}
                        </Text>
                      </Text>
                    )}
                    {(!actionsLoader || !helpers.isDesktop()) && (
                      <Text
                        style={[
                          postSingle.postDate,
                          helpers.isDesktop() && { paddingRight: 10 },
                          helpers.isDesktop() && postSingle.postDateDesktop,
                          !helpers.isDesktop() && Padding.pv_05,
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                          { marginLeft: 8 }
                        ]}
                      >
                        {`${data?.story?.read_time || 5} min read`}
                      </Text>
                    )}
                    {!actionsLoader && (
                      <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <View style={{ alignSelf: 'flex-end' }}>
                          <PostActionBar
                            updateFontSize={() => this.changeFontSize()}
                            storyActions={storyActions}
                            applyStoryActions={(type, value) =>
                              applyStoryActions(type, value)
                            }
                            sharePost={(slug) => sharePost(slug)}
                            pushShareStoryEventToDataLayer={(name) => this.props.pushShareStoryEventToDataLayer(name)}
                            shareTitle={data.story.title}
                            darkMode={darkMode}
                            token={this.props.token}
                            profileInfo={this.props.profileInfo}
                            data={this.props.data}
                            openGiftSubModal={this.props.openGiftSubModal}
                          />
                        </View>
                      </View>
                    )}
                    {actionsLoader && (
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: helpers.isDesktop() ? 300 : 160,
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginVertical: 15,
                        }}
                      >
                        <ContentLoader
                          containerStyles={{ marginBottom: 0 }}
                          active={true}
                          pHeight={[30]}
                          title={false}
                          pRows={1}
                          pWidth={["100%"]}
                          animationDuration={400}
                          primaryColor={
                            darkMode
                              ? "rgba(45, 48, 45,1)"
                              : "rgba(220, 220, 220, 1)"
                          }
                          secondaryColor={
                            darkMode
                              ? "rgba(45, 48, 45,0.8)"
                              : "rgba(200, 200, 200, 1)"
                          }
                        />
                      </View>
                    )}
                  </View>
                }
                {/* {data.loaderSummary != true && (
                  <View
                    style={[
                      helpers.isDesktop() && {
                        flexDirection: "row",
                        marginRight: 0,
                        alignItems: "center",
                      },
                      { marginTop: 30 },
                      !helpers.isWeb() && { marginTop: 60 },
                    ]}
                  >
                    {data.story && data.story.credits && this.creditStory()}
                  </View>
                )} */}
              </View>
            )}
          </View>
        </View>
        <View style={{ justifyContent: 'center', marginTop: '2vh' }}>
          {helpers.isDesktop() && (
            <ZoomImageNews
              featuredImage={data.featured_image}
              featuredTitle={data.postTitle}
              lazyLoad={data.lazyLoad}
              darkMode={darkMode}
              showImageModal={showImageModal}
              setShowImageModal={(value) => {
                this.setState({ showImageModal: value });
                this.props.setScrollEnabled(!value);
              }}
            />
          )}
          {!helpers.isDesktop() && (
            <ZoomImageNews
              featuredImage={data.featured_image}
              featuredTitle={data.postTitle}
              lazyLoad={data.lazyLoad}
              darkMode={darkMode}
              showImageModal={showImageModal}
              setShowImageModal={(value) => {
                this.setState({ showImageModal: value });
                this.props.setScrollEnabled(!value);
              }}
            />
          )}
        </View>
      </View>
    );
  }

  creditStory = () => {
    const {
      data,
      getAuthorClick,
      getCategoryClick,
      unlockLoader,
      postUnlocked,
      insufficientCredits,
      darkMode,
      creditPlan,
    } = this.props;
    return (
      <>
        {postUnlocked ? (
          null
        ) : (
          <>
            {/* {creditPlan && creditPlan.description &&
                            <View style={[Margin.mv_1, Flex.row]}>
                                {data.is_free != true &&
                                    <View style={[postSingle.creditBoxBlue, Flex.row, Flex.alignCenter, darkMode && { borderColor: Colors.purpleBorder }]}>
                                        <CustomImage
                                            webStyle={{ width: 23, height: 23, marginRight: 5 }}
                                            style={{ width: 23, height: 23, marginRight: 5 }}
                                            source={data.story.type === 'privileged' ? goldCoin : silverCoin}
                                            require={true}
                                            resizeMode="contain"
                                            altText={'Credits'}
                                        />
                                        <Text style={[postSingle.creditText, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>{data.story.credits.unlock}</Text>
                                    </View>
                                }

                                <Button
                                    title={data.is_free ? 'SIGN IN' : 'UNLOCK NOW!'}
                                    rootStyle={{
                                        btnWrapper: [darkMode ? postSingle.creditButtonPurlple : postSingle.creditButton, Flex.alignCenter, Flex.justfiyCenter, { width: 160 }, data.is_free && { height: 46 }, !helpers.isWeb() && { width: 180 }],
                                        btnText: [postSingle.creditButtonText],
                                    }}
                                    onClick={() => this.props.unlockPost()}
                                    loader={unlockLoader}
                                    disable={unlockLoader}
                                    indicatorColor={'#ffffff'}
                                />
                            </View>
                        } */}
          </>
        )}
      </>
    );
  };
}

export default PostSummary;
