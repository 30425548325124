import React, { useState, useEffect } from 'react';
import { View, Modal, Text, StyleSheet, TouchableOpacity, Animated, Dimensions } from 'react-native';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';
import Button from '../button';
import { button } from '../../styles/appStyles.web';
import { helpers } from '../../utils/helpers';
import { useHistory } from "react-router-dom";
import apiHelpers from '../../utils/api-helpers';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

const storyModalBgMob = getImagePath('img/story-modal-bg-mob.png');
const closeIcon = getImagePath('icons/close-icon.png')
const copyLinkIcon = getImagePath("icons/copy-purple-icon.svg")
const emailIcon = getImagePath("icons/email-purple-icon.svg")
const whatsappIcon = getImagePath("icons/whatsapp-purple-icon.svg")


const OutlineContainer = ({ text }) => (
    <View style={{ position: 'relative', borderRadius: 4, overflow: 'hidden', width: '90%' }}>
        <Svg height="100%" width="100%" style={styles.svgContainer}>
            <Defs>
                <LinearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
                    <Stop offset="0%" stopColor="#55C79A" stopOpacity="1" />
                    <Stop offset="50%" stopColor="#907CFF" stopOpacity="1" />
                    <Stop offset="100%" stopColor="#FC876D" stopOpacity="1" />
                </LinearGradient>
            </Defs>
            <Rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="transparent" // Transparent fill for the background
                stroke="url(#grad)" // Apply gradient to the outline
                strokeWidth="2" // Customize the thickness of the border
                rx="4" // Add rounded corners (same as borderRadius)
                ry="4"
            />
        </Svg>
        <View style={styles.outlineContainer}>
            <View style={styles.contentWrapper}>
                <Text style={styles.gift30DaySubscriptionText}>{text}</Text>
            </View>
        </View>
    </View>
);

const BottomModal = (props) => {
    const { token, openGiftSubModal } = props
    const [modalVisible, setModalVisible] = useState(true);
    const [copyButtonText, setCopyButtonText] = useState("Copy Link")
    const screenHeight = Dimensions.get('window').height;
    const history = useHistory()
    // Animation setup
    const slideAnim = new Animated.Value(screenHeight); // Start from off-screen (bottom)

    useEffect(() => {
        // Slide up animation
        Animated.timing(slideAnim, {
            toValue: screenHeight * 0.1, // 10vh from the top
            duration: 300,
            useNativeDriver: true,
        }).start();
    }, []);

    const closeModal = () => {
        // Slide down animation
        Animated.timing(slideAnim, {
            toValue: screenHeight, // Move back off-screen
            duration: 300,
            useNativeDriver: true,
        }).start(() => setModalVisible(false));
    };

    const handleSubscribeClick = () => {
        console.log('handleSubscribeClick');
        history.push('/pricing');
    };

    const handleSignInClick = () => {
        console.log('handleSignInClick');
        history.push('/sign-in');
    };

    const onCopyButtonClick = async () => {
        if (copyButtonText === "Copied") {
            return;
        }
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data?.story?.id || props.data?.id,
            slug: props.data?.story?.slug || props.data?.slug
        }

        if (typeof ClipboardItem !== "undefined" && navigator.clipboard.write) {
            const dataPromise = apiHelpers.apiRequest(endpoint, data, "POST", true, token);
            const textPromise = dataPromise.then(res => `${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`);

            Promise.all([dataPromise, textPromise])
                .then(([res, text]) => {
                    const blob = new Blob([text], { type: "text/plain" });
                    const clipboardItem = new ClipboardItem({ "text/plain": blob });
                    return navigator.clipboard.write([clipboardItem]);
                })
                .then(() => {
                    setCopyButtonText("Copied")
                    setTimeout(() => {
                        setCopyButtonText("Copy Link")
                    }, 1000);
                })
                .catch(error => {
                    console.error("Error occurred:", error);
                });
        } else {
            apiHelpers.apiRequest(endpoint, data, "POST", true, token)
                .then(res => `${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`)
                .then((text) => {
                    navigator.clipboard.writeText(text);
                    setCopyButtonText("Copied")
                    setTimeout(() => {
                        setCopyButtonText("Copy Link")
                    }, 1000);
                })
                .catch(error => {
                    console.error("Error occurred:", error);
                });
        }
    }

    const handleWhatsappShare = () => {
        console.log("Clicked on gifting via Whatsapp option")
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data?.story?.id || props.data?.id,
            slug: props.data?.story?.slug || props.data?.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
            console.log(res)
            if (res.success) {
                const expression = /(iPhone|iPod|iPad)/i;

                if (expression.test(navigator.platform)) {
                    // console.log('Apple device detected!');
                    window.location.href = encodeURI(`https://wa.me/?text=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode=' + res.data.gift_code}`)
                } else {
                    // console.log('Other device detected!');
                    window.open(encodeURI(`https://wa.me/?text=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode=' + res.data.gift_code}`))
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleEmailShare = () => {
        console.log("Clicked on gifting via Email option")
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data.story.id,
            slug: props.data.story.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
            console.log(res)
            if (res.success) {
                window.location.href = `mailto:?subject=Gift from ${props.profileInfo.name || props.profileInfo.email}&body=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.
                ${document.location.href + '?giftCode=' + res.data.gift_code}`
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const LoggedOutView = () => {
        return <> <TouchableOpacity onPress={closeModal} style={styles.closeIconContainer}>
            <CustomImage
                source={closeIcon}
                style={{
                    width: 10,
                    height: 10
                }}
                webStyle={{
                    width: 15,
                    height: 15
                }}
            />
        </TouchableOpacity>
            <View style={styles.modalImageContainer}>
                <CustomImage
                    source={storyModalBgMob}
                    style={
                        {
                            height: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                    }
                    resizeMode="contain"
                    webStyle={{
                        height: 'auto',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
            </View>
            <View style={styles.giftStoryTextContainer}>
                <Text style={styles.giftStoryText}>Gift a Story</Text>
            </View>
            <View style={styles.giftStorySubTextContainer}>
                <Text style={styles.giftStorySubText}>Morning Context subscribers can gift stories that anyone can read.</Text>
            </View>
            <Button
                loader={false}
                title={"SUBSCRIBE"}
                rootStyle={{
                    btnWrapper: [
                        true
                            ? button.primary
                            : button.primary,
                        { height: 36, cursor: "pointer", width: '40%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1E1B4B', marginTop: 20 },
                    ],
                    btnText: [
                        true
                            ? button.primaryText
                            : button.primaryText,
                        { fontWeight: "700", paddingHorizontal: 15, fontSize: 17, fontFamily: "HaasGrotesk-Medium" },
                    ],

                }}
                onClick={handleSubscribeClick}
            />
            <TouchableOpacity onPress={handleSignInClick}>
                <Text style={styles.alreadyHaveAnAccountText}>Already have an account? <Text style={styles.signInText}>Sign in</Text></Text>
            </TouchableOpacity>
        </>
    }

    const LoggedInView = () => {
        return <> <TouchableOpacity onPress={closeModal} style={styles.closeIconContainer}>
            <Text style={styles.giftThisStoryText}>Gift This Story</Text>
            <CustomImage
                source={closeIcon}
                style={{
                    width: 10,
                    height: 10
                }}
                webStyle={{
                    width: 15,
                    height: 15
                }}
            />
        </TouchableOpacity>
            <View style={styles.giftStorySubTextContainer}>
                <Text style={[styles.giftStorySubText, { textAlign: 'left' }]}>Give this subscriber-only story to a friend and help them read it for free.</Text>
            </View>
            <View style={styles.actionButtonsContainer}>
                <TouchableOpacity style={styles.copyLinkContainer} onPress={onCopyButtonClick}>
                    <CustomImage
                        source={copyLinkIcon}
                        require={true}
                        style={{ width: 21, height: 22 }}
                        webStyle={{
                            width: 21,
                            height: 22,
                        }}
                        altText={"Copy Link"}
                    />
                    <Text style={styles.copyLinkText}>
                        {copyButtonText}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.emailContainer} onPress={handleEmailShare}>
                    <CustomImage
                        source={emailIcon}
                        require={true}
                        style={{ width: 21, height: 22 }}
                        webStyle={{
                            width: 21,
                            height: 22,
                        }}
                        altText={"Email"}
                    />
                    <Text style={styles.copyLinkText}>
                        {"Email"}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.whatsappContainer} onPress={handleWhatsappShare}>
                    <CustomImage
                        source={whatsappIcon}
                        require={true}
                        style={{ width: 21, height: 22 }}
                        webStyle={{
                            width: 21,
                            height: 22,
                        }}
                        altText={"WhatsApp"}
                    />
                    <Text style={styles.copyLinkText}>
                        {"WhatsApp"}
                    </Text>
                </TouchableOpacity>
            </View>
            <TouchableOpacity onPress={()=>{
                setModalVisible(false)
                openGiftSubModal()
                }} style={styles.gift30DaySubscriptionContainer}>
                <OutlineContainer text={"Gift a 30-day subscription"} />
            </TouchableOpacity>
        </>
    }

    return (
        <View style={styles.container}>
            <Modal
                transparent={true}
                visible={modalVisible}
                animationType="none" // We'll handle animation manually
            >
                <Animated.View style={[styles.modalContainer, { transform: [{ translateY: slideAnim }] }, token ? { height: '60%' } : {}]}>
                    <View style={[styles.modalContent]}>
                        {
                            token ? LoggedInView() : LoggedOutView()
                        }
                    </View>
                </Animated.View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        position: 'absolute',
        width: '100%',
        height: '90%', // 90% of the screen height
        backgroundColor: 'white',
        bottom: 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    modalContent: {
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    closeIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between', // Align items to the left and right
        width: '100%',
        paddingTop: 20,
        paddingRight: 20,
        paddingLeft: 20, // Add padding to the left
        marginRight: 10,
        alignItems: 'center',
    },
    modalImageContainer: {
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    giftStoryTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: '-35vh',
    },
    giftStoryText: {
        fontSize: 24,
        fontFamily: 'PlayfairDisplay-Bold',
        fontStyle: 'normal',
        lineHeight: 35.5,
        textAlign: 'center',
        color: '#292929',
        fontWeight: '700',
    },
    giftStorySubTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        marginTop: 10,
    },
    giftStorySubText: {
        fontSize: 14,
        fontFamily: 'HaasGrotesk-Roman',
        fontStyle: 'normal',
        lineHeight: 20,
        textAlign: 'center',
        color: '#6B7280',
        fontWeight: '400',
    },
    alreadyHaveAnAccountText: {
        color: '#292929',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 20,
        marginTop: helpers.isDesktop() ? 40 : 24
    },
    signInText: {
        color: '#292929',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 20,
        textDecorationLine: 'underline',
    },
    giftThisStoryText: {
        color: '#292929',
        textAlign: 'right',
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        letterSpacing: 0.3,
        alignSelf: 'flex-start',
    },
    actionButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        rowGap: 10,
        alignSelf: 'center',
        marginTop: 20
    },
    copyLinkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        alignSelf: 'stretch'
    },
    emailContainer: {
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(255, 255, 255, 0.30)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        alignSelf: 'stretch'
    },
    whatsappContainer: {
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(255, 255, 255, 0.30)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        alignSelf: 'stretch'
    },
    copyLinkText: {
        color: '#292929',
        fontFamily: 'HaasGrotesk-Roman',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 20,
    },
    gift30DaySubscriptionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5vh'
    },
    gift30DaySubscriptionText: {
        color: '#3730A3',
        textAlign: 'center',
        fontFamily: 'HaasGrotesk-Medium',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 16,
        letterSpacing: 1,
        textTransform: 'uppercase',
        padding: 10,
    },
    outlineContainer: {
        paddingVertical: 2,
        paddingHorizontal: 4,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        borderRadius: 4,
    },
    svgContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 4,
    },
    contentWrapper: {
        position: 'relative',
        zIndex: 1, // Ensures the text is above the gradient
    },
    giftSubWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: '1vh',
        paddingHorizontal: '0.5vw'
    },
});

export default BottomModal;