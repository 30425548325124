import { Image, StyleSheet, View, Text, TouchableOpacity } from "react-native"
import getImagePath from "../../utils/image-helper"
import CustomImage from "../custom-image"
import { helpers } from "../../utils/helpers"
import { useState } from "react"
import apiHelpers from "../../utils/api-helpers"

const giftCardImage = getImagePath("img/gift-card-image.png")
const copyLinkIcon = getImagePath("icons/copy-link-blue-icon.svg")
const emailIcon = getImagePath("icons/email-blue-icon.svg")
const whatsappIcon = getImagePath("icons/whatsapp-blue-icon.svg")
const giftIcon = getImagePath("icons/gift-outline-icon.svg")

const GiftStoryNudge = (props) => {
    const { token, openGiftSubModal } = props
    console.log("Props debug: ", token)
    const [copyButtonText, setCopyButtonText] = useState("Copy Link")
    const [openGiftModal, setOpenGiftModal] = useState(false)

    const onCopyButtonClick = async () => {
        if (copyButtonText === "Copied") {
            return;
        }
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data?.story?.id || props.data?.id,
            slug: props.data?.story?.slug || props.data?.slug
        }

        if (typeof ClipboardItem !== "undefined" && navigator.clipboard.write) {
            const dataPromise = apiHelpers.apiRequest(endpoint, data, "POST", true, token);
            const textPromise = dataPromise.then(res => `${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`);

            Promise.all([dataPromise, textPromise])
                .then(([res, text]) => {
                    const blob = new Blob([text], { type: "text/plain" });
                    const clipboardItem = new ClipboardItem({ "text/plain": blob });
                    return navigator.clipboard.write([clipboardItem]);
                })
                .then(() => {
                    setCopyButtonText("Copied")
                    setTimeout(() => {
                        setCopyButtonText("Copy Link")
                    }, 1000);
                })
                .catch(error => {
                    console.error("Error occurred:", error);
                });
        } else {
            apiHelpers.apiRequest(endpoint, data, "POST", true, token)
                .then(res => `${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`)
                .then((text) => {
                    navigator.clipboard.writeText(text);
                    setCopyButtonText("Copied")
                    setTimeout(() => {
                        setCopyButtonText("Copy Link")
                    }, 1000);
                })
                .catch(error => {
                    console.error("Error occurred:", error);
                });
        }
    }

    const handleWhatsappShare = () => {
        console.log("Clicked on gifting via Whatsapp option")
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data?.story?.id || props.data?.id,
            slug: props.data?.story?.slug || props.data?.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
            console.log(res)
            if (res.success) {
                const expression = /(iPhone|iPod|iPad)/i;

                if (expression.test(navigator.platform)) {
                    // console.log('Apple device detected!');
                    window.location.href = encodeURI(`https://wa.me/?text=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode=' + res.data.gift_code}`)
                } else {
                    // console.log('Other device detected!');
                    window.open(encodeURI(`https://wa.me/?text=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode=' + res.data.gift_code}`))
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleEmailShare = () => {
        console.log("Clicked on gifting via Email option")
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data.story.id,
            slug: props.data.story.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
            console.log(res)
            if (res.success) {
                window.location.href = `mailto:?subject=Gift from ${props.profileInfo.name || props.profileInfo.email}&body=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.
                ${document.location.href + '?giftCode=' + res.data.gift_code}`
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGiftButtonClickMob = () => {
        if (openGiftModal) {
            setOpenGiftModal(false)
        } else {
            setOpenGiftModal(true)
        }
    }

    return <View style={styles.wrapper}>
        <View style={styles.centeredGiftContainer}>
            <View style={styles.giftImageContainer}>
                <CustomImage
                    source={giftCardImage}
                    require={true}
                    altText={"Gift Card"}
                    style={{
                        width: helpers.isDesktop() ? 270 : 270,
                        height: helpers.isDesktop() ? 200 : 200,
                    }}
                    webStyle={{
                        width: helpers.isDesktop() ? 270 : 270,
                        height: helpers.isDesktop() ? 200 : 200,
                    }}
                />
            </View>
            <View style={styles.giftDetailsContainer}>
                <View style={styles.giftDetailsInnerContainer}>
                    <View style={styles.giftDetailsBorderContainer}>
                        <Text style={styles.primaryText}>
                            Gift This Story
                        </Text>
                        <Text style={styles.secondaryText}>
                            Give this subscriber-only Story to a friend and help them read it for free via:
                        </Text>
                        <View style={styles.actionButtons}>
                            <View style={styles.individualActionButton}>
                                <TouchableOpacity style={styles.actionButton} onPress={onCopyButtonClick}>
                                    <CustomImage
                                        source={copyLinkIcon}
                                        require={true}
                                        style={{ width: 13, height: 13 }}
                                        webStyle={{ width: 13, height: 13 }}
                                        altText={"Copy"}
                                        resizeMode="contain"
                                    />
                                    <Text style={styles.actionButtonText}>{copyButtonText}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.individualActionButton}>
                                <TouchableOpacity style={styles.actionButton} onPress={handleEmailShare}>
                                    <CustomImage
                                        source={emailIcon}
                                        require={true}
                                        style={{ width: 13, height: 13 }}
                                        webStyle={{ width: 13, height: 13 }}
                                        altText={"Email"}
                                        resizeMode="contain"
                                    />
                                    <Text style={styles.actionButtonText}>Email</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.individualActionButton}>
                                <TouchableOpacity style={styles.actionButton} onPress={handleWhatsappShare}>
                                    <CustomImage
                                        source={whatsappIcon}
                                        require={true}
                                        style={{ width: 13, height: 13 }}
                                        webStyle={{ width: 13, height: 13 }}
                                        altText={"WhatsApp"}
                                        resizeMode="contain"
                                    />
                                    <Text style={styles.actionButtonText}>WhatsApp</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.separator}>
                    <View style={styles.halfSeparator} />
                    <Text style={styles.separatorText}>Or</Text>
                    <View style={styles.halfSeparator} />
                </View>
                <TouchableOpacity onPress={openGiftSubModal} style={styles.secondSection}>
                    <CustomImage
                        source={giftIcon}
                        require={true}
                        style={{ width: 16, height: 16 }}
                        webStyle={{ width: 16, height: 16 }}
                        altText={"Email"}
                        resizeMode="contain"
                    />
                    <Text style={styles.giftSubText}>
                        Gift a 30-day Subscription
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    </View>
}

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        backgroundColor: '#F3F5FF',
        width: '100%',
        flexDirection: 'row',
        paddingVertical: 40,
        justifyContent: 'center'
    },
    centeredGiftContainer: {
        width: helpers.isDesktop() ? '100%' : '90%',
        display: 'flex',
        flexDirection: helpers.isDesktop() ? 'row' : 'column',
        columnGap: 32,
        rowGap: 32,
        justifyContent: helpers.isDesktop() ? 'center' : null,
        alignItems: helpers.isDesktop() ? 'center' : null
    },
    giftDetailsInnerContainer: {
        backgroundColor: 'white',
        padding: 8,
        height: 210,
        width: helpers.isDesktop() ? 378 : '100%'
    },
    giftDetailsBorderContainer: {
        borderWidth: 1,
        borderColor: '#E7D6C9',
        height: '100%',
        width: '100%',
        padding: 20
    },
    primaryText: {
        color: '#1E1B4B',
        fontFamily: 'PlayfairDisplay-Bold',
        fontSize: 30,
        fontWeight: 700,
        lineHeight: '140%',
        marginBottom: 16
    },
    secondaryText: {
        fontFamily: 'HaasGrotesk-Roman',
        fontSize: 14,
        lineHeight: 20,
        color: '#292929',
        marginBottom: 24
    },
    separator: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1vw'
    },
    separatorText: {
        fontSize: 12,
        color: '#CBD5E1',
        lineHeight: 16,
        fontFamily: 'HaasGrotesk-Roman',

    },
    halfSeparator: {
        borderBottomColor: '#CBD5E1',
        borderBottomWidth: 1,
        width: '42%'
    },
    giftSubText: {
        color: '#3730A3',
        fontFamily: 'HaasGrotesk-Medium',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 16,
        textAlign: 'center'
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: '3vw'
    },
    individualActionButton: {
        display: 'flex',
        flexDirection: 'row'
    },
    actionButton: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: "1vw",
        justifyContext: 'center',
        alignItems: 'center'
    },
    actionButtonText: {
        color: '#3730A3',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 16
    },
    secondSection: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1vw'
    }
})

export default GiftStoryNudge