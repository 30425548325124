import { View, Text, StyleSheet, TouchableOpacity } from "react-native"
import { useHistory } from 'react-router-dom'
import CustomImage from "../custom-image"
import getImagePath from "../../utils/image-helper"
import Button from "../button";
import { button } from "../../styles/appStyles.web";
import { helpers } from "../../utils/helpers";

const whySubscribeBg = getImagePath("img/story-modal-bg-web.png");
const whySubscribeBgMob = getImagePath("img/story-modal-bg-mob.png");
const starterCreditCoinIcon = getImagePath("icons/starter-credit-coin-icon.svg");

const SubscribeToUnlock = (props) => {
    const { isStarterCreditStory, data, starterCredits, unlockPost, token } = props;
    const history = useHistory()
    if (!helpers.isDesktop()) {
        return <View style={{ display: 'flex', width: '100vw', objectFit: 'cover' }}>
            <CustomImage
                source={whySubscribeBgMob}
                style={{ width: '100%', height: 'auto', zIndex: 0 }}
                webStyle={{ width: '100%', height: 'auto', zIndex: 0 }}
                className={'why-subscribe-img'}
                altText={name}
            />
            <View style={{ width: '95%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', bottom: '40vh', marginBottom: '-35vh' }}>
                <Text style={styles.subscribeToUnlockText}>{isStarterCreditStory ? "Read this story for free" : "Subscribe to read this story"}</Text>
                <Text style={styles.getUnlimitedAccessText}>{isStarterCreditStory ? null : "We publish over 500 original, detailed stories every year on startups, corporates, stock markets and economic current affairs."}</Text>
                {isStarterCreditStory ? null : <Text style={styles.limitedTimeOfferText}>Limited time offer <Text style={styles.strikedText}>$125</Text> <Text>$99 for 1 year</Text></Text>}
                {isStarterCreditStory ?
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={[styles.getUnlimitedAccessText, { fontSize: 14, lineHeight: 20, marginBottom: 0 }]}> Your credit balance: </Text>
                        <CustomImage source={starterCreditCoinIcon} style={{ width: 20, height: 20 }} webStyle={{ width: 20, height: 20, }} />
                        <Text style={{ fontSize: 14, fontWeight: 500, lineHeight: 20, color: '#051462', fontFamily: "HaasGrotesk-Medium" }}>{starterCredits.available}</Text>
                    </View> :
                    null}
                <View style={styles.buttonWrapper}>
                    <Button
                        loader={false}
                        title={isStarterCreditStory ? "READ STORY USING" : "SUBSCRIBE"}
                        rootStyle={{
                            btnWrapper: [
                                true
                                    ? button.primary
                                    : button.primary,
                                { height: 36, cursor: "pointer", width: isStarterCreditStory ? '70%' : '40%', zIndex: -5, alignItems: 'center', justifyContent: 'center', backgroundColor: '#1E1B4B' },
                            ],
                            btnText: [
                                true
                                    ? button.primaryText
                                    : button.primaryText,
                                { fontWeight: "700", paddingHorizontal: 15, fontSize: 17, fontFamily: "HaasGrotesk-Medium" },
                            ],

                        }}
                        onClick={() => {
                            if (isStarterCreditStory) {
                                unlockPost()
                            } else {
                                history.push('/pricing')
                            }
                        }}
                    />
                    {
                        isStarterCreditStory ? <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 0, marginLeft: '-2.5rem' }}>
                            <CustomImage source={starterCreditCoinIcon} style={{ width: 20, height: 20 }} webStyle={{ width: 20, height: 20 }} />
                            <Text style={styles.starterCreditButtonText}>{"1"}</Text>
                        </View> : null
                    }
                </View>
                {(isStarterCreditStory || token) ? null : <TouchableOpacity onPress={() => history.push('/sign-in')}>
                    <Text style={styles.alreadyHaveAnAccountText}>Already have an account? <Text style={styles.signInText}>Sign in</Text></Text>
                </TouchableOpacity>}
            </View>
        </View>
    }
    return <View style={{ display: 'flex', width: '100vw', objectFit: 'cover' }}>
        <CustomImage
            source={whySubscribeBg}
            style={{ width: '100%', height: 'auto', zIndex: 0 }}
            webStyle={{ width: '100%', height: 'auto', zIndex: 0 }}
            className={'why-subscribe-img'}
            altText={name}
        />
        <View style={{ width: '25%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', bottom: '40vh', marginBottom: '-35vh' }}>
            <Text style={[styles.subscribeToUnlockText, isStarterCreditStory && { fontSize: 30, lineHeight: '140%' }]}>{isStarterCreditStory ? "Read this story for free" : "Subscribe to read this story"}</Text>
            <Text style={[styles.getUnlimitedAccessText, isStarterCreditStory && { fontSize: 14, lineHeight: 20 }]}>{isStarterCreditStory ? null : "We publish over 500 original, detailed stories every year on startups, corporates, stock markets and economic current affairs."}</Text>
            {isStarterCreditStory ? null : <Text style={styles.limitedTimeOfferText}>Limited time offer <Text style={styles.strikedText}>$125</Text> <Text>$99 for 1 year</Text></Text>}
            {isStarterCreditStory ?
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={[styles.getUnlimitedAccessText, { fontSize: 14, lineHeight: 20, marginBottom: 0 }]}> Your credit balance: </Text>
                    <CustomImage source={starterCreditCoinIcon} style={{ width: 20, height: 20 }} webStyle={{ width: 20, height: 20, }} />
                    <Text style={{ fontSize: 14, fontWeight: 500, lineHeight: 20, color: '#051462', fontFamily: "HaasGrotesk-Medium" }}>{starterCredits.available}</Text>
                </View> :
                null}
            <View style={styles.buttonWrapper}>
                <Button
                    loader={false}
                    title={isStarterCreditStory ? "READ STORY USING" : "SUBSCRIBE"}
                    rootStyle={{
                        btnWrapper: [
                            true
                                ? button.primary
                                : button.primary,
                            { height: 36, cursor: "pointer", marginTop: 25, width: isStarterCreditStory ? '73%' : '40%', zIndex: -5, alignItems: 'center', justifyContent: 'center', backgroundColor: '#1E1B4B' },
                        ],
                        btnText: [
                            true
                                ? button.primaryText
                                : button.primaryText,
                            { fontWeight: "700", paddingHorizontal: 15, fontSize: 17, fontFamily: "HaasGrotesk-Medium" },
                        ],

                    }}
                    onClick={() => {
                        console.log("Clicked on the subscribe button!")
                        if (isStarterCreditStory) {
                            unlockPost()
                        } else {
                            history.push('/pricing')
                        }
                    }}
                />
                {
                    isStarterCreditStory ? <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 23, marginLeft: '-2.5rem' }}>
                        <CustomImage source={starterCreditCoinIcon} style={{ width: 20, height: 20 }} webStyle={{ width: 20, height: 20 }} />
                        <Text style={styles.starterCreditButtonText}>{"1"}</Text>
                    </View> : null
                }
            </View>

            {(isStarterCreditStory || token) ? null : <TouchableOpacity onPress={() => history.push('/sign-in')}>
                <Text style={styles.alreadyHaveAnAccountText}>Already have an account? <Text style={styles.signInText}>Sign in</Text></Text>
            </TouchableOpacity>}
        </View>
    </View>
}

const styles = StyleSheet.create({
    subscribeToUnlockText: {
        color: '#292929',
        textAlign: 'center',
        fontFamily: "PlayfairDisplay-Bold",
        fontSize: 24,
        fontWeight: '700',
        lineHeight: '148%',
        marginBottom: helpers.isDesktop() ? 24 : 16
    },
    getUnlimitedAccessText: {
        color: '#6B7280',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 20,
        marginBottom: 24
    },
    limitedTimeOfferText: {
        color: '#292929',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '150%',
        letterSpacing: 0.3,
        marginBottom: helpers.isDesktop() ? 40 : 24
    },
    strikedText: {
        color: '#E7D6C9',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '150%',
        letterSpacing: 0.3,
        textDecorationLine: 'strikethrough'
    },
    alreadyHaveAnAccountText: {
        color: '#292929',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 20,
        marginTop: helpers.isDesktop() ? 40 : 24
    },
    signInText: {
        color: '#292929',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 20,
        textDecorationLine: 'underline',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    starterCreditButtonText: {
        color: '#FFF',
        textAlign: 'center',
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 20,
        marginLeft: 3
    }
})

export default SubscribeToUnlock