import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../utils/mixpanel";
export class TouchableOpacityLink extends Component {
  render() {
    let { to, ...restOfProps } = { ...this.props };
    let restOfObj = {};
    if (typeof to == "string") {
      restOfObj = { pathname: to };
    } else if (typeof to == "object") {
      restOfObj = { ...to };
    } else if (typeof to == "function") {
      restOfObj = to(location);
    }
    return (
      <Link
        to={(location) => ({ prevPath: location.pathname, ...restOfObj })}
        {...restOfProps}
        onClick={() => this.handleClick(window.location.pathname)}
      />
    );
  }

  handleClick = (location) => {
    try {
      Mixpanel.track("Page link clicked", {
        pagename: this.props.to,
        from_page: location,
      });
    } catch (error) {
      console.log("mix panel trackin error =>", error);
    }
    if (this.props.handleClick) {
      this.props.handleClick(location, this.props.to);
    }
    if (this.props?.to?.pathname) {
      window.location.href = this.props.to.pathname;
    }
  };
}

export default TouchableOpacityLink;
