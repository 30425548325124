import { useEffect, useRef, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions } from "react-native";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../custom-image";
import GiftBox from "../gift-box";
import { helpers } from "../../utils/helpers";

const bookmarkOutlineIcon = getImagePath("icons/bookmark-outline-icon.svg")
const facebookOutlineIcon = getImagePath("icons/facebook-outline-icon.svg")
const xOutlineIcon = getImagePath("icons/x-outline-icon.svg")
const giftOutlineIcon = getImagePath("icons/gift-outline-icon.svg")

const UpdatedFloatingNudge = (props) => {
    const [showGiftDropdown, setShowGiftDropdown] = useState(helpers.isDesktop() ? true : false)

    const [isNudgeVisible, setIsNudgeVisible] = useState(false);
    const giftDropdownRef = useRef(null);

    useEffect(() => {
        const viewportHeight = Dimensions.get('window').height;
        const showNudgeAfterScroll = viewportHeight * 0.2; // 20vh

        const checkScrollPosition = (event) => {
            const scrollPosition = event.nativeEvent.contentOffset.y;
            if (scrollPosition >= showNudgeAfterScroll) {
                setIsNudgeVisible(true);
            }
        };

        const handleClickOutside = (event) => {
            if (giftDropdownRef.current && !giftDropdownRef.current.contains(event.target)) {
                setShowGiftDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return <View style={[styles.wrapper]}>
        <View style={styles.actionButtonList}>
            <View style={styles.actionButtons}>
                <TouchableOpacity style={styles.actionButton}>
                    <CustomImage
                        source={bookmarkOutlineIcon}
                        require={true}
                        style={{ width: 23, height: 23 }}
                        webStyle={{ width: 23, height: 23 }}
                        altText={"Bookmark"}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                <TouchableOpacity style={styles.actionButton}>
                    <CustomImage
                        source={facebookOutlineIcon}
                        require={true}
                        style={{ width: 23, height: 23 }}
                        webStyle={{ width: 23, height: 23 }}
                        altText={"Facebook"}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                <TouchableOpacity style={styles.actionButton}>
                    <CustomImage
                        source={xOutlineIcon}
                        require={true}
                        style={{ width: 23, height: 23 }}
                        webStyle={{ width: 23, height: 23 }}
                        altText={"X (Twitter)"}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                <View style={styles.separator} />
                <TouchableOpacity style={styles.actionButton} onPress={() => setShowGiftDropdown((prev) => !prev)}>
                    <CustomImage
                        source={giftOutlineIcon}
                        require={true}
                        style={{ width: 23, height: 23 }}
                        webStyle={{ width: 23, height: 23 }}
                        altText={"Gift"}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
                {showGiftDropdown &&
                    <View style={styles.giftDropdownWrapper} ref={giftDropdownRef}>
                        <GiftBox token={props.token} data={props.data} profileInfo={props.profileInfo} openGiftSubModal={props.openGiftSubModal} />
                    </View>
                }
            </View>
        </View>
    </View>
}

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: 1
    },
    actionButtonList: {
        display: 'flex',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: '#CBD5E1',
        borderRadius: 12,
        paddingHorizontal: '1vw',
        paddingVertical: '2vh'
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1vh'
    },
    separator: {
        width: '100%',
        borderBottomColor: '#E5E7EB',
        borderBottomWidth: 1
    },
    giftDropdownWrapper: {
        display: 'flex',
        width: '100%'
    }

})

export default UpdatedFloatingNudge;